.region-wine-card-name {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
  width: 100%;
  white-space: pre-wrap;
  max-width: 11ch;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.new-ht {
  height: 14em;
}
.new-reg-wine-logo {
  width: 40%;
}
.fl-wrap {
  flex-wrap: wrap;
}
.m-top10 {
  margin-top: 10%;
}
.region-based-search-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .region-based-search-bar {
    // width: calc(80% + 2em) !important;
  }
}
@media (min-width: 1025px) {
  .new-reg-wine-logo {
    width: 29%;
  }
  .new-ht {
    height: 15em !important;
  }
  .media-width {
    width: 38% !important;
  }
  .new-logo-winery-width {
    width: 40% !important;
  }
  .visit-page-winery-container {
    width: 100% !important;
  }
  .region-based-search-bar {
    // width: calc(75% + 6.5em) !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .new-ht {
    height: 16em !important;
  }
  .new-logo-winery-width {
    width: 29% !important;
  }
  .media-width {
    width: 22% !important;
  }
}
@media (width: 1024px) {
  .new-ht {
    height: 17em !important;
  }
  .new-logo-winery-width {
    width: 15% !important;
  }
}
.new-logo-winery-width {
  width: 65%;
}
.reg-wine-logo-container {
  height: 100px !important;
}
.swipe-container {
  flex-wrap: wrap;
  display: flex !important;
  justify-content: center;
  width: 100%;
}
// .swipe-container::after {
//     content: "";
//     display: block;
//     height: 10px;
//     width: 100%;
// }
.visit-page-mob-winery-container {
  // margin-top: -9vh;
  width: 100%;
  position: absolute;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
}
.slick-slider-overwrite {
  overflow: hidden !important;
  padding: bottom 2%;
}
.slick-slider-overwrite::after {
  content: "";
  display: block;
  height: 35px;
  width: 100%;
}
.slick-slider-overwrite-promo {
  overflow: hidden !important;
}
.slick-slider-overwrite-promo::after {
  content: "";
  display: block;
  height: fit-content;
  width: 100%;
  margin-bottom: 2%;
}
