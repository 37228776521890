// For JIRA PAIR-1647 PAIR 1648
.content-style {
  font-family: Poppins, Regular sans-serif !important;
  font-size: 18px !important;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  overflow: hidden;
  padding: 30px 20px 30px 20px;
}

.plaid-friday-content-style {
  font-family: Poppins, Regular sans-serif !important;
  color: black;
  font-size: 18px !important;
  background: white;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-content: center;
  text-align: center;
  overflow: hidden;
  //   padding: 30px 20px 30px 20px;
}

.discount-percent-off {
  margin: 0.5% 0 0 0;
  font-size: 30px;
  font-family: Poppins !important;
  font-weight: bold;
  // overflow-y: hidden !important;
  // overflow-x: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.discount-code-style {
  font-size: 30px;
  margin: 0 0 1% 0;
  font-weight: bold;
  background: #ffffff;
  color: #a84551;
  border: 2px dashed #7d7d7d;

  // color: brown !important;
  justify-content: center;
  align-self: center;
  align-content: center;
  text-align: center;
  width: 220px;
  // overflow-y: hidden !important;
  // overflow-x: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.font-bold {
  font-weight: bold !important;
}
.double-column {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  flex-wrap: wrap !important;
}
.line-break {
  width: 100%;
}
// .exit-button {
//     // float: left;
//     position: static !important;
//     // margin: -10% 5% 0 1% !important;
//     bottom: 90% !important;
//     right: 83% !important;
//     overflow-y: hidden;
//     overflow-x: hidden;

//     background: none;
//     border: none;
// }

.exit-button {
  position: relative;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}
.exit-button::before,
.exit-button::after {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 13px;
  height: 3px;
}

.exit-button-desktop {
  position: relative;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}
.exit-button-desktop::before,
.exit-button-desktop::after {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 13px;
  height: 3px;
  display: none;
}
// .exit-button-desktop {
//     margin: 0 10% 0 1%;
//     position: absolute;
//     overflow-y: hidden;
//     overflow-x: hidden;
//     bottom: 90%;
//     right: 85%;
//     background: none;
//     border: none;
// }

.shop-now-button {
  font-size: 18px !important;
  margin-top: 5px;
  padding: 8px 68px 8px 68px !important;
  text-align: "center";
  color: black;
  background: #a84551;
  border-radius: 5px;
  border: none;
}
.amador-button {
  font-size: 18px !important;
  margin-top: 5px;
  padding: 8px 68px 8px 68px !important;
  text-align: "center";
  color: white;
  background: #00344c;
  border-radius: 5px;
  border: none;
}

.holiday-button {
  font-size: 16px !important;
  // font-weight: bold !important;
  margin-top: 5px;
  width: 75%;
  padding: 8px 15px 8px 15px !important;
  text-align: "center";
  color: white;
  background: #a84551;
  border-radius: 5px;
  border: none;
}
.plan-visit-button {
  font-size: 14px !important;
  margin-top: 5px;
  padding: 4px 68px 4px 68px !important;
  text-align: "center";
  color: black;
  background: #a84551;
  border-radius: 5px;
  border: none;
}
.questions-button {
  margin-top: 5px;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  color: blue;
  background: none;
  border: none;
}
.discount-percent-off::-webkit-scrollbar {
  display: none;
}
.discount-code-style::-webkit-scrollbar {
  display: none;
}
.content-style::-webkit-scrollbar {
  display: none;
}
.plaid-friday-content-style::-webkit-scrollbar {
  display: none;
}
.text-one {
  overflow: visible;
  color: black;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 1%;
}
.white-font {
  color: white;
}
.green-font {
  color: green;
}
.text-two {
  font-size: 14px;

  overflow: visible;
  margin-bottom: 5px;
}
.highlight-text {
  color: #a84551;
}
.text-three {
  overflow: visible;

  font-size: 12px;
}
.disclaimer-text {
  overflow: visible;
  font-size: 8px;
}
.image {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  max-height: 75px;
  max-width: 200px;
}
.bos-image {
  margin-left: auto;
  margin-right: auto;
  // margin-top: auto;
  height: 133px;
  width: 200px;
}
.amador-image {
  margin-left: auto;
  margin-right: auto;
  // margin-top: auto;
  height: 180px;
  width: 400px;
}
.mobile-amador-image {
  // margin-left: auto;
  // margin-right: auto;
  // margin-top: auto;

  //max-width: 400px;
}
.SHW-image {
  margin-top: -20%;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 50%;
  width: 150%;
}
.heringer-image {
  margin-top: -20%;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 25%;
  width: 100%;
}
.heringer-text {
  font-size: 25px;
  color: red;
  font-weight: bold;
}
.top-spacing {
  margin-top: 5% !important;
}

.reduced-margin {
  margin-top: -10% !important;
}

// @media (max-width: 361px) {
//     .image {
//         margin-top: -100% !important;
//     }
// }
@media (max-width: 325px) {
  .image {
    margin-top: 30% !important;
    max-height: 50px !important;
    max-width: 200px !important;
  }
  .text-one {
    font-size: 16px !important;
  }
  .text-two {
    font-size: 12px !important;
  }
  .text-three {
    font-size: 10px !important;
  }
  .shop-now-button {
    min-width: 200px !important;
    padding: 5px 58px 5px 58px !important;
    font-size: 14px !important;
  }
}
@media (min-width: 326px) and (max-width: 374px) {
  .image {
    margin-top: 10% !important;
    max-height: 50px !important;
    max-width: 200px !important;
  }
  .exit-button {
    bottom: 95% !important;
    right: 81% !important;
  }
  .discount-percent-off {
    font-size: 19px !important;
  }

  .discount-code-style {
    font-size: 19px !important;
    width: 200px !important;
    max-height: 100px !important;
  }
  .shop-now-button {
    padding: 5px 58px 5px 58px !important;
  }
  .text-one {
    font-size: 14px !important;
  }
  .text-two {
    font-size: 12px !important;
  }
  .text-three {
    font-size: 12px !important;
  }
}
.content-style {
  font-family: Poppins, Regular sans-serif !important;
  width: 100%;
  text-align: justify;
  color: black;
  font-size: 18px !important;
  background: white;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-content: center;
  text-align: center;
  overflow: hidden;
  //   padding: 30px 20px 30px 20px;
}
@media (max-width: 300px) {
  .image {
    margin-top: -10% !important;
  }
  .bos-image {
    margin-left: auto;
    margin-right: auto;
    // margin-top: auto;
    height: 110px;
    width: 175px;
  }
  // .SHW-image {
  //     margin-left: auto;
  //     margin-right: auto;
  //     // margin-top: auto;
  //     height: 110px;
  //     width: 175px;
  // }
}

@media (width: 375px) and (min-height: 780px) {
  .image {
    margin-top: -10% !important;
  }
}

//   @media (width: 375px) and (min-height: 700px) {
//     .image {
//         margin-top: 10% !important;
//     }
//}
@media (width: 375px) and (min-height: 600px) and (max-height: 700px) {
  .image {
    margin-top: 20% !important;
    max-width: 200px !important;
    max-height: 100px !important;
  }
}
@media (min-width: 376px) and (max-width: 1023px) {
  .exit-button {
    bottom: 95% !important;
    right: 83% !important;
  }
  .image {
    margin-top: 10% !important;
    max-height: 100px !important;
    max-width: 200px !important;
  }
}
@media (min-width: 750px) {
  .exit-button {
    bottom: 97% !important;
    right: 90% !important;
  }
  .bos-image {
    margin-left: auto;
    margin-right: auto;
    // margin-top: auto;
    height: 300px;
    width: 500px;
  }

  .SHW-image {
    margin-top: -20%;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 75%;
    width: 150%;
  }
  .heringer-image {
    margin-top: -20%;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 25%;
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 2000px) {
  .exit-button {
    bottom: 97% !important;
    right: 90% !important;
  }
  .image {
    margin-top: 5% !important;
    max-height: 100px !important;
    max-width: 300px !important;
  }
  .reduced-margin {
    margin-top: 5% !important;
  }
  .SHW-image {
    align-items: center;
    align-self: center;
    align-content: center;
    height: 84%;
    width: 150%;
  }
  .heringer-image {
    margin-top: -20%;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 50%;
    width: 100%;
  }
}

.close-button {
  float: right;
  border: none;
  background: none;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}
