@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.fruit-preferences-next-btn {
  margin-bottom: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.2em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 75%;
}
.skip {
  color: white;
  margin-right: 10%;
  font-family: "poppins", sans-serif !important;
  font-style: italic;
  font-size: 1.25em;
}

.taste-preference-text {
  float: left;
  font-size: 2em;
  color: #e8e8e8;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}
.header-text-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*  default css className for Bootstrap Card */
.card {
  border-radius: 10px !important;
  margin: 1em;
  padding: 1em;
  box-shadow: 1px #1d1d1d1a !important;
  border: none;
}

.next-btn {
  /* margin-bottom: 5em; */
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
  font-size: 2em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  border-radius: 10%;
  width: 90% !important;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center !important;
  flex-wrap: wrap;
  position: relative;
  overflow: auto;
  overflow-x: hidden !important;
}

.chat-btn-container {
  bottom: 0;
  width: 100%;
  position: fixed;
  margin-bottom: 20%;
}

.chat-icon {
  float: right;
  margin-right: 10%;
  border-radius: 50%;
  margin-bottom: 2%;
  background-color: white;
}

/* Empty Page Component CSS */
.empty-page-main-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 35%;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(238, 238, 238);
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.desktop-div-scroll {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* 
  #NOTE: .form-errors-display CSS Class is used in Forgot Password, Reset Password, Signin ,
          signup screens for handling error messages
*/
@media (min-width: 1025px) {
  .form-errors-display {
    text-align: center;
    font-size: 1.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
  .success-inner-container {
    box-shadow: 0px 3px 200px -15px #989898;
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    background-color: whitesmoke;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 25px;
    width: 430px !important;
    /* height: -moz-fit-content !important; */
    /* height: auto !important; */
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .success-footer-container {
    width: 430px !important;
    background: none !important;
  }
}
@media only screen and (min-width: 1000px) and (max-height: 1400px) {
  .form-errors-display {
    text-align: center;
    font-size: 1.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-errors-display {
    text-align: center;
    font-size: 1.75em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) /* and (max-height: 1024px) */ {
  .form-errors-display {
    text-align: center;
    font-size: 1.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
}

.form-errors-display {
  text-align: center;
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-errors-display {
    text-align: center;
    font-size: 1em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ContentFadeIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes ImageFadeIn {
  0% {
    transform: translate(-0.5rem, -0.5rem) scale(1.05);
    opacity: 0;
    filter: blur(2px);
  }
  50% {
    opacity: 1;
    filter: blur(2px);
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0) translateX(-100%);
  }
  /* 90% {
		transform: scale(1.05);
	} */
  100% {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
}

@keyframes init {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    opacity: 1;
  }
}

@keyframes puff {
  0% {
    top: 100%;
    height: 0px;
    padding: 0px;
  }
  100% {
    top: 50%;
    height: 100%;
    padding: 0px 100%;
  }
}

@keyframes borderRadius {
  0% {
    border-radius: 100%;
  }
  100% {
    border-radius: 0px;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes right {
  0%,
  100% {
    transform-origin: 17% 100%;
  }
  50% {
    transform: rotate(-12deg) skewX(12deg);
  }
}

@keyframes left {
  0%,
  100% {
    transform-origin: 83% 100%;
  }
  50% {
    transform: rotate(12deg) skewX(-12deg);
  }
}

@keyframes left-end {
  0%,
  100% {
    transform-origin: 83% 100%;
  }
  40% {
    transform: rotate(6deg) skewX(-4deg) scaleX(0.92);
  }
  70% {
    transform: rotate(-1deg) skewX(8deg) scaleX(1.04);
  }
}

@keyframes move-to-left {
  40% {
    transform: translateX(-3px) rotate(var(--r));
  }
}

@keyframes active-4 {
  40% {
    transform: scale(1.25);
  }
}

@keyframes active-span {
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.16);
    opacity: 0;
  }
}

@keyframes active-5 {
  15% {
    transform: rotate(180deg) scale(1.1);
  }
  30% {
    transform: rotate(360deg) scale(1.2);
  }
  70% {
    transform: rotate(360deg) translateY(14%) scaleY(0.72);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes double-right {
  60% {
    filter: drop-shadow(-5px 0 1px var(--active-pale))
      drop-shadow(4px 0 1px var(--active-pale));
  }
}

@keyframes scale {
  60% {
    transform: scaleX(1.32);
    filter: blur(0.5px);
  }
}

@keyframes blink {
  0%,
  5%,
  15%,
  100% {
    transform: scaleY(1);
  }
  10% {
    transform: scaleY(0.4);
  }
}

@keyframes flip-scale-down-diag-2 {
  0% {
    transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    transform: scale(0.4) rotate3d(-1, 1, 0, -90deg);
  }
  100% {
    transform: scale(1) rotate3d(-1, 1, 0, -180deg);
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.error-boundary-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
}
.error-boundary-title {
  font-size: 1.75em;
  color: #a84551;
  font-family: "poppins", sans-serif !important;
  text-align: center;
}
.error-boundary-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-boundary-image {
  width: 80%;
}

.page-main-container {
  width: 100%;
  height: 100vh;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  display: inline-table;
}
.font-family {
  font-family: "poppins", sans-serif !important;
}
.font-color {
  color: #a84551;
}
.ft-wt-600 {
  font-weight: 600;
}
.ft-s-m {
  font-size: medium;
}
.common-page-header {
  width: 100%;
  /* align-content: center !important; */
  background-color: #193c38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 4%;
  min-height: 60px;

  /* position: absolute; */
}
.input:focus {
  background-color: white;
}
.common-page-sub-header {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
  align-items: center;
}
.common-sub-header-back-icon {
  width: 3%;
  margin-left: 1.5rem !important;
}
.common-sub-header-text {
  font-size: 1.25em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 300 !important;
  margin-left: 5%;
}
.common-sub-header-logo {
  width: 10%;
  margin-right: 3%;
  margin-left: auto;
}
.page-container-bg {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.theme-color {
  color: #a84551;
}
.ft-color-light-grey {
  color: #727272;
}
.ft-size-1em {
  font-size: 1em !important;
}
.mobile-compatible-msg {
  /* box-shadow: 0px 0px 15px 15px #d2d2d2; */
  /* position: relative; */
  /* margin-top: 5% !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  /* width: 30% !important; */
  height: 100vh !important;
  /* border: 2px solid #C0C0C0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pair-err-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pair-err-logo {
  width: 38%;
  height: auto;
}
.pair-mob-compatable-span-msg {
  font-size: 1.25em;
  color: #a84551;
}
.ptr-clss {
  cursor: pointer;
}
.desktop-error-container-margin {
  margin-top: 10% !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ft-weight-normal {
  font-weight: normal !important;
}
.cursor-none {
  cursor: none;
}
.cursor-text {
  cursor: text;
}

.txt-transform-uppercase {
  text-transform: uppercase;
}
.ft-w-normal {
  font-weight: normal !important;
}
.theme-color {
  color: #a84551;
}
.bl-none {
  border-left-style: none;
}
.bl-none {
  border-left-style: none;
}


.splash-icon {
  width: 80%;
  animation: 1s ease-out 0s 1 init;
  height: auto;
}

.splash-container {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.age-confirmation-container {
  margin-top: 15px;
}

.age-confirm-button {
  width: 50%;
  margin-bottom: 10px;
  margin-top: 10%;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1.1 !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  justify-content: center;
}

.age-confirm-head {
  margin-top: 10vh;
}

.age-container {
  height: 70vh;
  display: flex;
}

.age-text {
  margin-bottom: 10% !important;
}
@media (min-width: 1025px) {
  .sign-in-btn {
    margin-top: 4% !important;
    margin-bottom: 4% !important;
    font-family: "poppins", sans-serif !important;
    font-size: 18px !important;
  }
  .welcome-title {
    font-size: 30px !important;
    margin-left: 7.5% !important;
    justify-content: left !important;
  }
  .signin-form-row-input {
    margin-top: 2% !important;
  }
  .signin-form-email-text {
    margin-top: 10px;
    font-family: "poppins", sans-serif !important;
    font-size: 14px;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    letter-spacing: 0px;
    color: #69696d;
    opacity: 1;
  }
  .signin-form-password-text {
    font-family: "poppins", sans-serif !important;
    font-size: 14px;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: Regular 18px/27px Poppins;
    letter-spacing: 0px;
    color: #69696d;
    opacity: 1;
  }
  .sign-in-main-container {
    box-shadow: 0px 3px 200px -15px rgba(146, 143, 143, 0.3843137255);
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 430px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    border-radius: 25px;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .logo-container {
    padding: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 40% !important;
  }
  .signin-form-row {
    font-size: 1.5em !important;
  }
  .forgot-password-text {
    margin-bottom: 25px;
    cursor: pointer;
    font-family: "poppins", sans-serif !important;
    font-size: 18px;
    padding: 10px;
  }
  .new-sign-up-div {
    margin-bottom: 25px;
    font-size: 18px;
    padding: 10px;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .signin-form-row-input {
    margin-top: 5%;
    color: #2b2a2a;
    font-family: "poppins", sans-serif !important;
    font-size: 1.25em;
    border: none;
    height: 40px !important;
    border: 1px solid rgba(29, 29, 29, 0.1019607843);
    width: 100% !important;
  }
}
.sign-in-main-container {
  margin-top: 5%;
  overflow: auto !important;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain !important;
}

.logo {
  width: 55%;
  height: 50%;
}

.header {
  width: 100%;
}

.sign-up-text {
  margin-top: 5%;
  margin-right: 5%;
  font-size: 1.25em;
  color: #a84551;
  text-align: end;
}

.new-account-text {
  color: #2b2a2a !important;
  margin-right: 2%;
}

.logo-container {
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-title {
  display: flex;
  align-items: center;
  color: #2b2a2a;
  justify-content: center;
  font-size: 1.5em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}

.sign-in-form-container {
  width: 85%;
  text-align: center;
}

.sign-in-text-container {
  width: 100%;
}

.sign-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-in-form-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signin-form-group {
  width: 100% !important;
}

@media (min-width: 400px) {
  .signin-form-row-input {
    margin-top: 5%;
    color: #2b2a2a;
    font-family: "poppins", sans-serif !important;
    font-size: 1.25em;
    border: none;
    height: 50px !important;
    border: 1px solid rgba(29, 29, 29, 0.1019607843);
    width: 100% !important;
  }
}
.signin-form-row {
  font-size: 1.25em !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 5%;
  margin-top: 10%;
  width: 100%;
  border: 1px solid rgba(29, 29, 29, 0.1019607843) !important;
  box-shadow: none !important;
}

.form-row-error {
  padding: 4%;
  margin-top: 10%;
  width: 95%;
  box-shadow: 1px 1px 1px 1px rgba(29, 29, 29, 0.1019607843);
  border: 1px solid #ff0000;
}

.next-btn {
  margin-top: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  border-radius: 10%;
}

.sign-in-btn {
  margin-top: 10%;
  margin-bottom: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
}

::placeholder {
  color: #959292;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #959292;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #959292;
}

.sign-in-using-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.5em;
  color: #2b2a2a;
  margin-top: 1%;
}

.facebook-logo {
  width: 18%;
}

.forgot-password-text {
  font-size: 1.25em;
  color: #a84551;
  float: right !important;
}

.new-sign-up-div {
  font-size: 1.25em;
  font-family: "poppins", sans-serif !important;
  color: #000000;
  text-align: center;
}

.new-frgt-pwd-text {
  color: #a84551;
  float: right !important;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
}

.new-sign-up-color {
  color: #a84551;
  font-weight: 550;
}

.desktop-div-scroll-signin {
  height: 80vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
.google-btn-span .button[disabled] {
  opacity: 0.65 !important;
}
.google-btn-span ::selection {
  color: #fff;
  background: #F14436;
}

.google-btn {
  display: flex;
  width: 149px;
  height: 45px;
  color: #fff;
  -webkit-user-select: none;
          user-select: none;
  justify-content: space-around;
  align-items: center;
  border: none;
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  background: #F14436 0% 0% no-repeat padding-box;
  border-radius: 3px;
}
.google-btn :selection {
  color: #FFFFFF !important;
  background: #F14436;
}
.google-btn :hover {
  color: #FFFFFF !important;
  background: #F14436;
}
.google-btn :disabled {
  transition: opacity 0.5s ease 0s;
}

.google-btn:disabled {
  transition: opacity 0.5s ease 0s;
}

.google-btn:disabled,
.google-btn[disabled] {
  transition: opacity 0.5s ease 0s;
}

@media (min-width: 300px) and (max-width: 375px) {
  .google-btn {
    display: flex;
    width: 130px !important;
    height: 45px;
    color: #fff;
    justify-content: space-around;
    align-items: center;
    border: none;
    text-align: left;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #F14436 0% 0% no-repeat padding-box;
    border-radius: 3px;
  }
}
.thanks-fonts-style {
  margin: 0% 0% 4% 0%;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
}

.logo-wwidth {
  width: 36% !important;
}
@media (min-width: 1025px) {
  .sign-up-main-container {
    box-shadow: 0px 0px 15px 15px #d2d2d2;
    overflow: scroll !important;
    position: relative;
    background-image: url(/static/media/sign-up-background.dd0970a9.png);
    background-repeat: no-repeat;
    background-size: contain !important;
    margin-top: 5%;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 30% !important;
    height: 80vh !important;
    border: 2px solid #C0C0C0;
  }
}
.sign-up-main-container {
  overflow: scroll !important;
  position: relative;
  background-image: url(/static/media/sign-up-background.dd0970a9.png);
  background-repeat: no-repeat;
  background-size: contain !important;
}

.logo {
  width: 40%;
  height: 50%;
}

.header {
  width: 100%;
}

.sign-in-text {
  margin-top: 5%;
  margin-right: 5%;
  font-size: 1.25em;
  color: #a84551;
  text-align: end;
}

.logo-container {
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hello-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2B2A2A;
  font-size: 3em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}

.sign-up-form-container {
  width: 80%;
  text-align: center;
}

.sign-in-text-container {
  width: 100%;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign-up-form-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-row {
  padding: 4%;
  margin-top: 10%;
  width: 95%;
  border: none;
  box-shadow: 1px 1px 1px 1px rgba(29, 29, 29, 0.1019607843);
}

.form-row-error {
  padding: 4%;
  margin-top: 10%;
  width: 95%;
  box-shadow: 1px 1px 1px 1px rgba(29, 29, 29, 0.1019607843);
  border: 1px solid #ff0000;
}

.form-errors-display {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  text-align: center;
}

.next-btn {
  margin-top: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  border-radius: 10%;
}

.sign-up-btn {
  margin-top: 10%;
  margin-bottom: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 95%;
}

::placeholder {
  color: #959292;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #959292;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #959292;
}

.sign-up-using-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.5em;
  color: #2B2A2A;
  margin-top: 1%;
}

.facebook-logo {
  width: 18% !important;
}
.winery-details-main-container {
  margin-bottom: 20%;
  overflow: auto;
  width: 100%;
  position: absolute;
  margin: auto;
}

.winery-details-main-container:after {
  content: "";
  display: block;
  height: 70px;
  width: 100%;
}

.winery-details-header {
  padding: 5%;
  display: flex;
  align-items: center;
}

.winery-location-btn {
  height: 10px;
  width: 10px;
  margin-right: 2%;
  margin-top: 5px;
}

.winery-logo-div {
  height: 233px;
  display: flex;
  width: 100%;
}

.winery-logo-parent-div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
}

.winery-row {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.winery-details-title {
  font-weight: 500 !important;
  font-size: 1.75em;
  color: #2b2a2a;
}

.winery-logo {
  width: 100px;
  height: 100px;
}

.winery-name-title {
  padding-top: 2%;
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1.5em;
  font-family: "poppins", sans-serif !important;
  color: white;
  max-width: 170px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.winery-name {
  margin-top: 5%;
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: bold;
  font-family: "poppins", sans-serif !important;
}

.winery-website-url {
  margin-top: 5%;
}

.winery-website-url,
.winery-phone-number {
  font-family: "poppins", sans-serif !important;
  font-style: italic;
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  color: white;
}

.winery-details-card,
.winery-location-details {
  box-shadow: 0px 0px 10px 0px rgba(29, 29, 29, 0.1019607843);
}

.winery-call-details {
  width: 50%;
  border-color: rgba(29, 29, 29, 0.1019607843);
  float: left;
}

.winery-call-details {
  width: 50%;
  float: left;
}

.winery-details-card-header {
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.winery-details-card-subheader {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-right: 1em;
  margin-left: 1em;
}

.winery-details {
  margin-left: 1px;
  float: left;
  width: 49%;
  border: 1px solid #efefef;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
}

.call-btn,
.visit-website-btn {
  width: 10%;
}

.call-text,
.visit-website-text {
  margin-left: 5%;
  font-size: 1.25em;
  color: #959292;
}

.winery-location-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.winery-shw-location-container {
  display: flex;
  margin-left: 13px;
  align-items: center;
}

.winery-location-text {
  text-align: center;
  color: rgba(197, 8, 8, 0.6784313725);
  font-size: 1em;
  margin: 5%;
  text-transform: capitalize;
}

.winery-about-title {
  color: #2b2a2a;
  font-size: 1.5em;
}

.winery-message-title {
  margin-top: 10px;
  color: #2b2a2a;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.winery-contact-title {
  margin-top: 10px;
  color: #2b2a2a;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.winery-contact-contents {
  white-space: pre-wrap;
  color: #959292;
  font-family: "poppins", sans-serif !important;
}

.winery-about-title-container {
  width: 100%;
}

.contact-container {
  width: 100%;
  margin-top: 10px;
}

.winery-about-message {
  white-space: pre-wrap;
  color: #959292;
  font-family: "poppins", sans-serif !important;
}

.winery-about-text {
  white-space: pre-wrap;
  color: #959292;
  font-family: "poppins", sans-serif !important;
}

.winery-about-text:first-letter {
  text-transform: uppercase;
}

.rounded-circle {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0% !important;
}

.avatar {
  position: relative;
  display: inline-block;
}

.avatar-xl {
  width: 17rem;
  height: 10rem;
}

.winery-details-main-container {
  animation: 0.5s ImageFadeIn ease-out 0s 1 forwards;
}

.winery-about-container {
  text-align: justify;
}

.winery-social-media-container {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.new-winery-d-header-container {
  justify-content: space-between;
}

@media (min-width: 1025px) {
  .winery-details-main-container {
    height: 80vh;
  }
  .winery-name-title {
    padding-top: 2%;
    font-size: 1.4em;
    font-weight: bold;
    font-family: "poppins", sans-serif !important;
    color: white;
  }
  .winery-location-details,
  .winery-details-card {
    width: 90% !important;
    margin-left: 5% !important;
  }
  .winery-details-card-header {
    margin-bottom: 0% !important;
  }
  .winery-social-media-container {
    margin-top: 1% !important;
  }
  .winery-website-url {
    margin-top: 0% !important;
  }
  .bottom-navigation {
    display: flex;
    align-content: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .winery-details-container {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .winery-details-title {
    font-weight: 500 !important;
    font-size: 1.5em;
    color: #2b2a2a;
  }
  .winery-details-header {
    padding: 3%;
    display: flex;
    align-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.shw-ve-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 20px;
}

.strip-title {
  font-weight: bold;
}

.strip-image {
  justify-content: center;
  object-fit: cover;
  border-radius: 5px;
  width: 88px;
  height: 88px;
  flex: 1 1;
}

.strip-text {
  padding: 10px;
  flex: 4 1;
  max-height: 88px;
}

.strip {
  border: 1px #ccc solid;
  border-radius: 5px;
  margin: 20px;
  display: flex;
  flex-direction: row;
}

.address-title {
  padding-left: 10px;
  /* Subheading 12 gray */
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold !important;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  /* Secondary / Mine Shaft */
  color: #343434;
}

.T {
  text-align: left;
  margin-right: 45px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* or 140% */
  color: #7D7D7D;
}

.T {
  width: 112px;
  height: 14px;
  left: 43px;
  top: 305px;
  /* Body 10 gray */
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */
  /* Primary / Dark Gray */
  color: #7D7D7D;
}

.heading-logo-section {
  margin: 20px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.heading-text-section {
  padding-left: 35px;
  margin-right: 35px;
  display: flex;
  flex-direction: row;
}

.heading-right {
  padding-left: 30px;
}

.T {
  margin: 10px;
}

.L-container {
  display: flex;
  flex-direction: column;
}
.L-container:hover {
  cursor: pointer;
}

.section-title {
  /* Title 16 */
  padding-left: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */
  /* Secondary / Mine Shaft */
  color: #343434;
}

.section {
  padding-top: 10px;
  padding-bottom: 10px;
}

.section:nth-child(even) {
  background-color: white !important;
}

.L-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  /* Primary / Burgundy */
  color: #951901;
}

.header {
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 35px;
  background-color: white !important;
}

.headerImg1 {
  position: relative;
  top: 0;
  left: 0;
}

.headerImg2 {
  position: absolute;
  top: 100px;
  left: 20px;
}

.address-entry {
  padding: 5px;
  margin-left: 25px;
}

.address-flex-container {
  /* Body 10 gray */
  padding-left: 35px;
  margin-right: 35px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */
  /* Primary / Dark Gray */
  color: #7D7D7D;
  display: flex;
  flex-direction: row;
}

.logo-text {
  padding: 1px;
}

.address-flex-container-left, .address-flex-container-right {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.address-flex-container-right {
  left: 300px;
  padding-left: 60px;
}

.schedule-button-container {
  display: flex;
  justify-content: center;
}

.schedule-button {
  margin: 20px;
  align-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: white;
  width: 360px;
  height: 44px;
  left: 27px;
  top: 993px;
  background: #951901;
  border-radius: 5px;
  border: none;
}

.events-container {
  display: flex;
  flex-direction: row;
}

.events-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.events-container .event-card {
  flex: 0 0 auto;
}

.events-container {
  -webkit-overflow-scrolling: touch;
}

.events-container::-webkit-scrollbar {
  display: none;
}

.event-card {
  flex: 1 1;
  border-radius: 6px;
  border-color: grey;
  border: solid lightgray;
  border-width: 1px;
  margin: 10px;
  width: 300px;
}

.event-image {
  width: 299px;
  height: 233px;
  border-radius: 5px;
  object-fit: cover;
}

.event-image-container {
  width: 299px;
  height: 233px;
  border-color: red;
  margin-bottom: 12px;
}

.event-date-title-container {
  display: flex;
  flex-direction: row;
}

.event-date-container {
  flex: 1 1 !important;
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  background: #F5EAE8;
  height: 60px !important;
  width: 50px !important;
  border-radius: 5px;
  margin: 15px;
}

.event-title-address-container {
  flex: 3 1 !important;
  display: flex !important;
  flex-direction: column !important;
}

.event-title {
  /* Subheading 12 gray */
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* or 117% */
  /* Secondary / Mine Shaft */
  color: #343434;
}

.event-month {
  flex: 1 1;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* or 160% */
  padding-top: 10px;
  text-align: center;
  /* Primary / Burgundy */
  color: #951901;
}

.event-day {
  flex: 1 1;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */
  text-align: center;
  /* Primary / Burgundy */
  color: #951901;
}

.event-title-address-container {
  display: flex;
  flex-direction: row;
}

.logo-container {
  margin-top: 5px;
  margin-left: 35px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-around;
}

.youtube-logo {
  flex: 1 1;
}

.facebook-logo {
  flex: 1 1;
}

.instagram-logo {
  flex: 1 1;
}

.twitter-logo {
  flex: 1 1;
}

.yelp-logo {
  flex: 1 1;
}

.follow-us-text {
  /* Subheading 12 gray */
  margin-top: 20px;
  margin-left: 35px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  /* Primary / Burgundy */
  color: #951901;
}
.follow-us-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.map-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.strip-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  /* Secondary / Mine Shaft */
  color: #343434;
}

.strip-paragraph {
  /* Body 10 gray */
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* or 140% */
  /* Secondary / Mine Shaft */
  color: #343434;
}
.nav-link-bottom-nav:focus {
  outline: none;
}

.nav-link {
  border-radius: 0px !important;
  color: white !important;
}

.nav-tabs .nav-link {
  border: unset !important;
}

.nav-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom-nav-title {
  font-size: 0.9em !important;
}

.bottom-nav-active-title {
  font-size: 1em !important;
  color: #a84551;
}

.bottom-nav-icon-disabled {
  opacity: 0.5;
}

.events-btn-not-selected {
  opacity: 0.6;
  height: 19.67px;
  width: 19.25px;
}

.events-btn-selected {
  height: 19.67px;
  width: 19.25px;
}
.content-style {
  font-family: Poppins, Regular sans-serif !important;
  font-size: 18px !important;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  overflow: hidden;
  padding: 30px 20px 30px 20px;
}

.plaid-friday-content-style {
  font-family: Poppins, Regular sans-serif !important;
  color: black;
  font-size: 18px !important;
  background: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  overflow: hidden;
}

.discount-percent-off {
  margin: 0.5% 0 0 0;
  font-size: 30px;
  font-family: Poppins !important;
  font-weight: bold;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.discount-code-style {
  font-size: 30px;
  margin: 0 0 1% 0;
  font-weight: bold;
  background: #ffffff;
  color: #a84551;
  border: 2px dashed #7d7d7d;
  justify-content: center;
  align-self: center;
  align-content: center;
  text-align: center;
  width: 220px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.font-bold {
  font-weight: bold !important;
}

.double-column {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  flex-wrap: wrap !important;
}

.line-break {
  width: 100%;
}

.exit-button {
  position: relative;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

.exit-button::before,
.exit-button::after {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 13px;
  height: 3px;
}

.exit-button-desktop {
  position: relative;
  width: 23px;
  height: 23px;
  border-radius: 50%;
}

.exit-button-desktop::before,
.exit-button-desktop::after {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 13px;
  height: 3px;
  display: none;
}

.shop-now-button {
  font-size: 18px !important;
  margin-top: 5px;
  padding: 8px 68px 8px 68px !important;
  text-align: "center";
  color: black;
  background: #a84551;
  border-radius: 5px;
  border: none;
}

.amador-button {
  font-size: 18px !important;
  margin-top: 5px;
  padding: 8px 68px 8px 68px !important;
  text-align: "center";
  color: white;
  background: #00344c;
  border-radius: 5px;
  border: none;
}

.holiday-button {
  font-size: 16px !important;
  margin-top: 5px;
  width: 75%;
  padding: 8px 15px 8px 15px !important;
  text-align: "center";
  color: white;
  background: #a84551;
  border-radius: 5px;
  border: none;
}

.plan-visit-button {
  font-size: 14px !important;
  margin-top: 5px;
  padding: 4px 68px 4px 68px !important;
  text-align: "center";
  color: black;
  background: #a84551;
  border-radius: 5px;
  border: none;
}

.questions-button {
  margin-top: 5px;
  padding: 5px 5px 5px 5px;
  font-size: 12px;
  color: blue;
  background: none;
  border: none;
}

.discount-percent-off::-webkit-scrollbar {
  display: none;
}

.discount-code-style::-webkit-scrollbar {
  display: none;
}

.content-style::-webkit-scrollbar {
  display: none;
}

.plaid-friday-content-style::-webkit-scrollbar {
  display: none;
}

.text-one {
  overflow: visible;
  color: black;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 1%;
}

.white-font {
  color: white;
}

.green-font {
  color: green;
}

.text-two {
  font-size: 14px;
  overflow: visible;
  margin-bottom: 5px;
}

.highlight-text {
  color: #a84551;
}

.text-three {
  overflow: visible;
  font-size: 12px;
}

.disclaimer-text {
  overflow: visible;
  font-size: 8px;
}

.image {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  max-height: 75px;
  max-width: 200px;
}

.bos-image {
  margin-left: auto;
  margin-right: auto;
  height: 133px;
  width: 200px;
}

.amador-image {
  margin-left: auto;
  margin-right: auto;
  height: 180px;
  width: 400px;
}

.SHW-image {
  margin-top: -20%;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 50%;
  width: 150%;
}

.heringer-image {
  margin-top: -20%;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 25%;
  width: 100%;
}

.heringer-text {
  font-size: 25px;
  color: red;
  font-weight: bold;
}

.top-spacing {
  margin-top: 5% !important;
}

.reduced-margin {
  margin-top: -10% !important;
}

@media (max-width: 325px) {
  .image {
    margin-top: 30% !important;
    max-height: 50px !important;
    max-width: 200px !important;
  }
  .text-one {
    font-size: 16px !important;
  }
  .text-two {
    font-size: 12px !important;
  }
  .text-three {
    font-size: 10px !important;
  }
  .shop-now-button {
    min-width: 200px !important;
    padding: 5px 58px 5px 58px !important;
    font-size: 14px !important;
  }
}
@media (min-width: 326px) and (max-width: 374px) {
  .image {
    margin-top: 10% !important;
    max-height: 50px !important;
    max-width: 200px !important;
  }
  .exit-button {
    bottom: 95% !important;
    right: 81% !important;
  }
  .discount-percent-off {
    font-size: 19px !important;
  }
  .discount-code-style {
    font-size: 19px !important;
    width: 200px !important;
    max-height: 100px !important;
  }
  .shop-now-button {
    padding: 5px 58px 5px 58px !important;
  }
  .text-one {
    font-size: 14px !important;
  }
  .text-two {
    font-size: 12px !important;
  }
  .text-three {
    font-size: 12px !important;
  }
}
.content-style {
  font-family: Poppins, Regular sans-serif !important;
  width: 100%;
  text-align: justify;
  color: black;
  font-size: 18px !important;
  background: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  overflow: hidden;
}

@media (max-width: 300px) {
  .image {
    margin-top: -10% !important;
  }
  .bos-image {
    margin-left: auto;
    margin-right: auto;
    height: 110px;
    width: 175px;
  }
}
@media (width: 375px) and (min-height: 780px) {
  .image {
    margin-top: -10% !important;
  }
}
@media (width: 375px) and (min-height: 600px) and (max-height: 700px) {
  .image {
    margin-top: 20% !important;
    max-width: 200px !important;
    max-height: 100px !important;
  }
}
@media (min-width: 376px) and (max-width: 1023px) {
  .exit-button {
    bottom: 95% !important;
    right: 83% !important;
  }
  .image {
    margin-top: 10% !important;
    max-height: 100px !important;
    max-width: 200px !important;
  }
}
@media (min-width: 750px) {
  .exit-button {
    bottom: 97% !important;
    right: 90% !important;
  }
  .bos-image {
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    width: 500px;
  }
  .SHW-image {
    margin-top: -20%;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 75%;
    width: 150%;
  }
  .heringer-image {
    margin-top: -20%;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 25%;
    width: 100%;
  }
}
@media (min-width: 1024px) and (max-width: 2000px) {
  .exit-button {
    bottom: 97% !important;
    right: 90% !important;
  }
  .image {
    margin-top: 5% !important;
    max-height: 100px !important;
    max-width: 300px !important;
  }
  .reduced-margin {
    margin-top: 5% !important;
  }
  .SHW-image {
    align-items: center;
    align-self: center;
    align-content: center;
    height: 84%;
    width: 150%;
  }
  .heringer-image {
    margin-top: -20%;
    align-items: center;
    align-self: center;
    align-content: center;
    height: 50%;
    width: 100%;
  }
}
.close-button {
  float: right;
  border: none;
  background: none;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}
.side-nav-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-nav-logo-width {
  width: 56%;
}

.side-nav-links-container {
  padding-top: 38px;
}

.nav-link-container {
  color: #2B2B2B;
  font-weight: 400;
  font-size: 17px;
  padding: 8% 15%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}

.nav-right-arrow {
  margin-top: 6px;
  margin-left: auto;
}
.checkintext-style {
  display: flex;
  margin: 1em;
  font-size: 17px;
  color: #000000;
  justify-content: center;
}

.new-thin-line-div {
  height: auto !important;
}

.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px; /* IE 9 */ /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_circle {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: green;
  border-radius: 11px;
  left: 0;
  top: 0;
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fff;
  left: 8px;
  top: 12px;
}
.shipping-address-main-container {
  display: flex;
  flex-direction: column;
}

.shipping-address-header {
  padding: 5%;
  display: flex;
}

.shipping-address-card-header {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 5%;
}

.form-row-label-header {
  color: #959292;
  font-size: 1.25em;
  font-family: "poppins", sans-serif !important;
  font-weight: 500 !important;
  margin-left: 5%;
}

.shipping-address-title {
  width: 100%;
  font-weight: 500 !important;
  font-size: 1.75em;
  color: #2b2a2a;
}

.shipping-address-header-subcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media (min-width: 1025px) {
  .confirm-shipping-address-btn {
    width: 67% !important;
  }
  .shipping-address-header {
    padding: 3% !important;
  }
  .confirm-shipping-address-btn-container {
    margin-bottom: 30% !important;
  }
  .shipping-address-form {
    margin-bottom: 4% !important;
    margin-top: 0% !important;
  }
  .shipping-address-main-container {
    display: flex;
    flex-direction: column;
  }
  .shipping-address-title {
    width: 100%;
    font-weight: 500 !important;
    font-size: 1.5em;
    color: #2b2a2a;
  }
}
.shipping-address-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.shipping-address-form {
  width: 100%;
  margin-bottom: 7%;
}

.form-row-label {
  color: #959292;
  font-size: 1.25em;
  font-family: "poppins", sans-serif !important;
  font-weight: 500 !important;
}

.form-row-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 40px !important;
  text-transform: capitalize;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
}

.form-row-address-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
}

.confirm-shipping-address-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30%;
}

.confirm-shipping-address-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 90%;
  opacity: 1 !important;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .confirm-shipping-address-btn {
    bottom: 11% !important;
  }
}
.form-error-shipping-address-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  text-align: center;
}

.form-error-shipping-address {
  color: red;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em !important;
}

.txt-format-none {
  text-transform: none !important;
}

.new-pinfo-header-container {
  justify-content: space-between;
}
.radio-btn-address-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: medium;
}

.address-selection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.shpng-address-details {
  margin-left: 3.1em;
  font-weight: 500;
}

.address-type-label {
  margin-left: 1em;
  font-weight: 700;
}

.shpng-lctn {
  opacity: 0.7;
}

.shpng-address-edit-delete {
  margin-left: 3.1em;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  color: #a84551;
}

.select-address-radio input[type=radio] {
  -webkit-appearance: none;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  outline: none;
  border: 3px solid gray;
}
.select-address-radio input[type=radio]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 18% auto;
  border-radius: 50%;
}
.select-address-radio input[type=radio]:checked:before {
  background: #a84551;
  opacity: 0.9;
}
.select-address-radio input[type=radio]:checked {
  border-color: #a84551;
  opacity: 0.9;
}
.override-shipping-address-form-header {
  padding: 0 !important;
  font-size: small !important;
  text-align: center !important;
}

.fadeIn-css {
  opacity: 0.4;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.shpng-form-save-btn {
  color: white;
  background: #a84551;
  padding: 6px 30px;
  font-size: medium;
  font-weight: 500;
  border: 1px solid #a84551;
}

.shpng-form-cancel-btn {
  color: #a84551;
  background: white;
  padding: 6px 20px;
  border: 1px solid #a84551;
  border: 1px thin;
  font-size: medium;
  font-weight: 500;
}

.margin-change {
  margin: 0em 1em 1em 1em !important;
}

.address-title-change {
  margin-left: 0 !important;
}

.font-family-address-label {
  font-family: "poppins", sans-serif !important;
}
.shopping-cart-fade-in-position-change {
  opacity: 0.4 !important;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  position: absolute !important;
}

.new-common-header-text {
  margin-left: unset !important;
}

@media (min-width: 1025px) {
  .new-common-header-text {
    margin-left: unset !important;
  }
}
.modal-style {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-bg {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
}

.white-bg {
  background-color: #ffffff;
}

.headertext-style {
  display: flex;
  margin: 1em;
  font-size: 17px;
  color: #000000;
}

.new-thin-line-div {
  height: auto !important;
}

.new-cancel-css {
  margin-right: 7%;
}

.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px; /* IE 9 */ /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_circle {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: green;
  border-radius: 11px;
  left: 0;
  top: 0;
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fff;
  left: 8px;
  top: 12px;
}

.new-cnacel-btn-css {
  margin-left: 0 !important;
}

.new-proceed-btn-css {
  padding: 6px 27px !important;
}

.new-yes-btn-css {
  padding: 6px 20px !important;
}

.new-footer-pop-btn-css {
  margin-bottom: 28px;
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1025px) {
  .modal-bg {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px !important;
  }
}
.profile-main-container {
  width: 100%;
  height: 100vh;
}

@media (min-width: 1025px) {
  .profile-main-container,
  .bottom-navigation {
    overflow: hidden;
  }
  .points-earned-main-container {
    margin-top: 0% !important;
  }
  .profile-container {
    width: 100% !important;
    margin-top: auto !important;
  }
  .profile-option-container {
    display: flex;
    justify-content: space-between;
    padding: none !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
  .profile-option-container-disabled {
    display: flex;
    justify-content: space-between;
    padding: none !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
    opacity: 0.5;
  }
  .profile-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }
  .profile-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }
  .profile-main-container {
    margin-left: auto !important;
    margin-right: auto !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .profile-header {
    width: 100% !important;
    height: 15% !important;
    background-color: #193c38 !important;
    align-content: center !important;
  }
  .profile-text {
    margin: 4% !important;
    float: left;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .profile-cart-btn {
    float: right;
    margin: 6% !important;
  }
  .profile-signout-btn {
    width: 3% !important;
    float: right;
  }
  .click-here-text-label {
    font-weight: 400 !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .click-here-text-label {
    font-weight: 500 !important;
  }
  .user-profile-pic {
    width: 80px !important;
    height: 80px !important;
    border-radius: 100px !important;
  }
  .profile-card-personal-details-subheader {
    margin-bottom: 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .profile-option-container {
    display: flex;
    justify-content: space-between;
    padding: 1% !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
  .profile-option-container-disabled {
    display: flex;
    justify-content: space-between;
    padding: 1% !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
    opacity: 0.5;
  }
}
.profile-header {
  width: 100%;
  height: 18%;
  background-color: #193c38;
  align-content: center !important;
}

.profile-text {
  margin: 7%;
  float: left;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.profile-cart-btn {
  float: right;
  margin: 10%;
}

.profile-container {
  background-color: #f2f2f2;
  width: 100%;
}

.profile-container:after {
  content: "";
  display: block;
  height: 130px;
  width: 100%;
}

.profile-card-personal-details-subheader {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 400px) {
  .profile-pic,
  .user-profile-pic {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
  .profile-option-container {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
  .profile-option-container-disabled {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
    opacity: 0.5;
  }
}
.profile-name {
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: 600 !important;
}

.profile-name:first-letter {
  text-transform: uppercase;
}

.profile-email {
  color: #959292;
  font-size: 1em;
}

.profile-row {
  bottom: 0;
}

.profile-order-cart-details {
  margin-left: 1px;
  float: left;
  width: 49%;
  border: 1px solid #959292;
  border-top-width: thin;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
  position: relative;
}

.profile-orders,
.profile-cart {
  color: #959292;
}

.profile-right-arrow-btn {
  float: right;
}

.profile-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  color: #959292;
  font-weight: 400;
  font-size: 1.15em;
  margin-top: 3%;
  margin-left: 3%;
}

.profile-container:after {
  content: "";
  display: block;
  height: 130px;
  width: 100%;
}

.profile-signout-text {
  float: left;
  margin-left: 3%;
}

.profile-signout-btn {
  float: right;
  width: 4%;
  float: right;
  margin-left: 72%;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
  z-index: 2;
}

.consumer-image-input {
  display: none !important;
}

.user-profile-pic-container {
  display: flex;
  justify-content: center;
}

.add-pic-image {
  width: 5%;
  height: 5%;
}

.points-earned-main-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}

.points-earned-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.points-value {
  color: #e9112c;
  font-weight: bold;
  font-family: "poppins", sans-serif !important;
  margin-left: 3%;
}

.chat-count {
  margin-left: 50%;
  color: red !important;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .chat-count {
    margin-left: 40% !important;
  }
  .profile-signout-btn {
    width: 5% !important;
    margin-left: 65% !important;
  }
}
.crop-container {
  width: 100%;
  height: 400px;
  position: relative;
}

.controls {
  margin: auto;
  width: 70%;
  display: flex;
  align-items: center;
}

.slider {
  padding: 30px 0px;
}

.button {
  text-align: center;
}

.sliderLableZoom {
  margin-right: 3rem;
  font-size: 1.3rem;
}

.sliderLableRotation {
  margin-right: 1.5rem;
  font-size: 1.3rem;
}

.MuiButton-root {
  font-size: 1.3rem !important;
}
.nav-link-top-nav:focus {
  outline: none;
}

.top-nav-main {
  background: #0c0524 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  height: 80px;
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-link-top-nav {
  display: flex;
  flex-direction: row;
}

@media (min-width: 1025px) {
  .nav-link {
    margin-right: 2% !important;
  }
}
.nav-tabs .nav-link {
  border: unset !important;
}

.nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1%;
  margin-right: 1%;
  cursor: pointer;
}

.top-nav-title-selected {
  margin-left: 7px;
  font-size: 16px;
  font-family: "poppins", sans-serif !important;
  border-bottom: 3px solid #ffffff;
}

.top-nav-title-not-selected {
  margin-left: 7px;
  font-size: 16px;
  font-family: "poppins", sans-serif !important;
  color: rgba(228, 228, 228, 0.7019607843);
}

.pair-logo-top-nav {
  height: 60px;
  width: auto;
}

.icon-style {
  width: 18px;
  height: 18px;
}

.top-nav-icon-disabled {
  opacity: 0.5;
}

.winery-details-top-nav {
  height: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  margin-right: 2%;
}

.winery-logo-top-nav {
  height: 60px;
  margin-left: 12px;
  width: 60px;
}

.winery-logo-container-top-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.winery-name-container-top-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
}

.more-winery-details-top-nav-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  opacity: 1;
  display: flex;
  position: absolute;
  z-index: 2;
  right: 0;
  margin-right: 5%;
  flex-direction: row;
  width: 30%;
}

.winery-logo-container-top-nav-popup {
  height: 60px;
  margin-top: 10px;
  width: auto;
  border-radius: 50%;
}

.winery-text-content-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.winery-name-popup {
  margin-top: 10px;
  margin-bottom: 10px;
  font: Medium 14px/21px var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-2b2b2b);
  text-align: left;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  font-weight: bold;
}

.winery-message-popup {
  margin-bottom: 15px;
}

.popup-icons-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.more-winery-details-top-nav {
  display: flex;
  flex-direction: row;
}

.chat-logo-popup {
  padding: 5px;
}

.chat-logo-popup-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  border-radius: 50%;
  margin-right: 15px;
}

.switch-winery-logo-popup {
  padding: 5px;
}

.switch-winery-logo-popup {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  opacity: 1;
  border-radius: 50%;
}

.hamburger-menu-top-nav {
  padding-left: 2%;
}
.available-wines-main-container {
  width: 100%;
  height: 100vh;
}

@media (min-width: 410px) {
  .available-wines-badge-notify {
    top: -6px !important;
    left: -9px !important;
  }
}
@media (min-width: 1025px) {
  .search-wine-for-dish-subheader {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  }
  .available-wines-container {
    margin-top: 2% !important;
  }
  .available-wines-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }
  .available-wines-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }
  .available-wines-header {
    width: 100% !important;
    height: 15% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .available-wines-text {
    margin: 7%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .available-wines-cart-btn {
    float: right;
  }
}
.available-wines-header {
  width: 100%;
  height: 14%;
  background-color: #a84551;
  align-content: center !important;
}

.available-wines-text {
  margin: 7%;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.available-wines-cart-btn {
  float: right;
}

.available-wines-container {
  width: 100%;
  position: relative;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
}

.cart-icon-margin {
  margin-top: 0.7rem !important;
}

.available-wines-badge-notify {
  background: #a84551;
  position: relative;
  top: -33px;
  font-size: smaller;
  left: 17px;
}

.input-height {
  height: 40px !important;
}

.available-wines-search-input {
  color: #959292;
  font-style: italic;
  justify-content: space-between;
  font-size: 1.25em;
  padding-left: 9px !important;
}

.available-wines-container:after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}

@media (min-width: 768px) {
  .cart-icon-padding {
    padding-left: 3.5em;
  }
}
@media (min-width: 1025px) {
  .cart-icon-padding {
    padding-left: 4.5em;
  }
}
@media (min-width: 400px) {
  .available-wines-pic {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
}
.available-wines-name {
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: 600 !important;
}

.available-wines-name:first-letter {
  text-transform: uppercase;
}

.available-wines-row {
  bottom: 0;
}

.available-wines-cart {
  color: #959292;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.available-wines-subheader {
  display: flex;
  justify-content: space-between;
}

.available-wines-container {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.mt-5em {
  margin-top: 5em !important;
}
.available-wine-name {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  margin-top: 10px;
}

.available-wine-name::first-letter {
  text-transform: capitalize;
}

.available-wine-subtype-subcontainer {
  display: flex;
  flex-direction: column;
  margin-right: 5%;
}

.hyper {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.hyper:hover {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.available-wine-subtype-name {
  color: #2b2a2a !important;
  font-family: "poppins", sans-serif !important;
}

.available-wine-subtype-title {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.available-wine-subtype-title::first-letter {
  text-transform: capitalize;
}

.available-wine-details {
  width: 100%;
}

.available-wine-price-text {
  color: #a84551;
  font-weight: bold;
}

.cart-qty-style {
  position: relative;
  display: table;
  border-collapse: separate;
  border: 1px solid #ccc;
}

.available-wine-details-row {
  bottom: 0;
}

.available-wine-details-data-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.available-wine-bottle-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.available-wine-bottle-logo {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.available-wine-bottle-logo-from-api {
  height: auto;
  max-height: 220px;
  width: auto;
  max-width: 200px;
}

.available-wines-add-to-cart-width {
  width: 100%;
  padding: 0px 0px;
}

.available-wines-add-to-cart-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
  padding: 0.5rem;
}

.available-wine-subcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.first-review-text {
  margin: 0;
}
.first-review-text:hover {
  text-decoration: underline;
}

.first-review-text-desktop {
  margin: 0;
}
.first-review-text-desktop:hover {
  text-decoration: underline;
}

.available-wine-card {
  width: 100%;
}

.available-wine-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  height: 70%;
  width: 100%;
}

.add-to-cart-button {
  margin-top: 5%;
}

.box {
  display: flex;
  overflow: hidden;
}

.wine-logo {
  flex-grow: 9;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.share {
  position: absolute;
  top: 0;
  right: 0;
}

.like {
  margin-right: 1em;
}

.wine-card-rating-start {
  font-size: 1.5em;
  color: #d2d2d2;
}

.wine-card-selected-rating-star {
  font-size: 1.5em;
  color: #f7d002;
}

.wine-text-div {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  width: 100%;
  justify-content: space-around;
}

.available-wine-details-list {
  width: 100%;
  height: 100%;
  display: flex;
}

@media (min-width: 1025px) {
  .available-wine-name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
    height: 70%;
    width: 100%;
  }
  .available-wine-card {
    width: 100% !important;
  }
  .available-wine-bottle-logo {
    height: auto;
    max-height: 200px;
    width: auto;
    max-width: 200px;
  }
  .available-wine-bottle-logo-from-api {
    height: auto;
    max-height: 220px;
    width: auto;
    max-width: 200px;
  }
}
.quantity-left-minus {
  color: #a84551;
}

.quantity-div {
  border: 1px solid #ccc;
}

.cart-input {
  border-bottom-style: none;
  border-top-style: none;
  font-weight: 700;
  width: 5rem;
}

.quantity-right-plus {
  color: #a84551;
}
.popup-label {
  font-family: "poppins", SemiBold 14px/21px sans-serif;
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 1.9rem;
}

:root {
  --borderWidth: 3px;
  --height: 21px;
  --width: 10px;
  --borderColor: white;
}

.check {
  display: inline-block;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
}

.add-to-cart-popup-container {
  display: flex;
  flex-direction: row;
}
.share-button {
  background-color: transparent;
  outline: none;
  border: none;
}

.share-image {
  max-width: 20px;
  max-height: 20px;
}
.textarea {
  background: "blue";
  white-space: nowrap;
  overflow: hidden;
}

.copy-button {
  background-color: transparent;
  outline: none;
  border: none;
}

.text {
  font-size: 50px;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  padding: 10px 0 10px 0;
  margin: 10px 0 10px 0 !important;
}

.copy-success-text {
  vertical-align: center;
}

::-webkit-scrollbar {
  height: 4px;
  border: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(189, 189, 189, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.wine-rating-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
}
.wine-rating-title img {
  height: 100%;
  margin-right: 25px;
}
.wine-rating-title p {
  font-size: 20px;
  margin: 0;
  font-family: "poppins", sans-serif !important;
}

.wine-rating-title-text {
  font-size: 28px;
  font-weight: 600;
  font-family: "poppins", sans-serif !important;
}

.wine-rating-review-title {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 0 10px;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.wine-rating-review-text {
  width: 100%;
  border-radius: 5px;
  border: 1px solid black;
  height: 130px;
  padding: 10px;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.wine-rating-submit-button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #cd4545;
  margin-top: 10px;
  margin-bottom: 20px;
  color: white;
  font-size: 22px;
  font-family: "poppins", sans-serif !important;
}
.wine-rating-submit-button:hover {
  cursor: pointer;
}

.wine-rating-star {
  font-size: 1.8em !important;
}

.wine-rating-error-text {
  font-family: "poppins", sans-serif !important;
  color: #cd4545;
}

.wine-review-thank-you-container {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wine-review-thank-you-container img {
  height: 120px;
  margin-bottom: 40px;
}
.wine-review-thank-you-container p {
  font-family: "poppins", sans-serif !important;
  font-size: 20px;
}
@media (min-width: 1025px) {
  .empty-page-card-content {
    margin-bottom: 15%;
  }
}
.empty-page-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.empty-page-card-type-subcontainer {
  display: flex;
  align-items: center;
}

.empty-page-card-title {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  font-weight: bold;
}

.empty-page-card-details {
  width: 100%;
}

.empty-page-card-title-container,
.empty-page-card-type-subcontainer {
  margin-bottom: 5%;
}

.empty-page-card-subcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.empty-page-card {
  width: 100%;
}

.empty-page-card-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.empty-page-card-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-page-card-content {
  text-align: center;
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  margin-top: 5%;
  font-style: italic;
}

.empty-page-card-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-page-card-logo {
  width: 20%;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .empty-page-card-content {
    font-size: 1em !important;
  }
}
.badge-notify-desktop {
  background: #a84551;
  position: relative;
  top: -8px;
  font-size: smaller;
  left: -8px;
}
@media (min-width: 1025px) {
  .set-password-main-container {
    box-shadow: 0px 3px 200px -15px rgba(146, 143, 143, 0.3843137255);
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: absolute !important;
    width: 430px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    overflow: auto;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    border-radius: 25px;
    background: #ffffff 0% 0% no-repeat padding-box;
    background-repeat: no-repeat;
    background-size: contain !important;
  }
  .user-greeting-title-desktop {
    text-align: center;
    font-size: 30px !important;
    font-family: "poppins", sans-serif !important;
    letter-spacing: 0px;
    color: #1e1e1e;
    opacity: 1;
    font-weight: 600;
  }
  .confirm-btn {
    margin-top: 1% !important;
    margin-bottom: 10% !important;
  }
}
.winery-name {
  font-weight: bold;
}

.set-password-main-container {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain !important;
  overflow: auto !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.logo {
  width: 40%;
  height: 50%;
}

.header {
  width: 100%;
}

.logo-container {
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.set-password-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b2a2a;
  font-size: 2em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
  margin-top: 5%;
  margin-bottom: 5% !important;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .user-greeting-title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2a2a;
    font-size: 1.25em !important;
    font-family: "poppins", sans-serif !important;
    margin-top: 5% !important;
    word-wrap: break-word;
    padding: 1%;
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .user-greeting-title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2a2a;
    font-size: 2em !important;
    font-family: "poppins", sans-serif !important;
    margin-top: 5% !important;
    word-wrap: break-word;
    padding: 1%;
  }
}
.user-greeting-title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b2a2a;
  font-size: 1.5em;
  font-family: "poppins", sans-serif !important;
  margin-top: 5%;
  word-wrap: break-word;
  padding: 1%;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.winery-name-set-password {
  text-transform: capitalize;
}

.set-password-form-container {
  width: 80%;
  text-align: center;
}

.sign-in-text-container {
  width: 100%;
}

.set-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.set-password-form-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-row {
  padding: 4%;
  margin-top: 10%;
  width: 95%;
  border: none;
  box-shadow: 1px 1px 1px 1px rgba(29, 29, 29, 0.1019607843);
}

.form-row-error {
  padding: 4%;
  margin-top: 10%;
  width: 95%;
  box-shadow: 1px 1px 1px 1px rgba(29, 29, 29, 0.1019607843);
  border: 1px solid #ff0000;
}

.confirm-btn {
  margin-top: 10%;
  margin-bottom: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100% !important;
}

.set-password-btn {
  margin-top: 10%;
  margin-bottom: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 95%;
}

::placeholder {
  color: #959292;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #959292;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #959292;
}

.set-password-using-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.5em;
  color: #2b2a2a;
  margin-top: 1%;
}
@media (min-width: 1025px) {
  .forgot-password-main-container {
    box-shadow: 0px 3px 200px -15px rgba(146, 143, 143, 0.3843137255);
    width: 430px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    /* border: 2px solid #C0C0C0; */
    border-radius: 15px;
    overflow: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .forgot-password-form-container {
    width: 85% !important;
  }
  .set-password-title {
    margin-top: 10%;
    justify-content: left !important;
    margin-left: 7.5% !important;
  }
  .form-email-text {
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 14px;
    letter-spacing: 0px;
    color: #69696d;
    opacity: 1;
  }
  .forgot-password-main-container-background {
    background: #f8f8f8 0% 0% no-repeat padding-box;
  }
  .back-to-signin-text {
    margin-bottom: 25px;
    cursor: default;
    font-size: 18px;
    color: #a84551;
    float: right !important;
  }
  .forgot-password-btn {
    margin-bottom: 25px;
  }
  .reset-password-text {
    color: #7d7d7d;
    font-family: "poppins", sans-serif !important;
    font-size: 1.25em !important;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
  .form-row {
    font-size: 1.5em !important;
    width: 100% !important;
    margin-top: 10% !important;
    border: 1px solid rgba(29, 29, 29, 0.1019607843) !important;
  }
  .forgot-password-form-row {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 4%;
    margin-top: 10%;
    width: 100%;
    border: 1px solid rgba(29, 29, 29, 0.1019607843) !important;
    box-shadow: none !important;
    font-size: 1.5em !important;
  }
}
.forgot-password-header {
  padding: 5%;
  display: flex;
  align-items: center;
}

.forgot-password-title {
  font-weight: 500 !important;
  margin-left: 3%;
  font-size: 1.75em;
  color: #2b2a2a;
}

.forgot-password-form-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.back-btn-container {
  margin-top: 5%;
  margin-left: 5%;
}

.forgot-password-form-container {
  width: 80%;
}

.forgot-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-row {
  font-size: 1.25em !important;
  width: 100% !important;
  margin-top: 10% !important;
  border: 1px solid rgba(29, 29, 29, 0.1019607843) !important;
}

.forgot-password-form-row {
  font-size: 1.25em !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 4%;
  margin-top: 10%;
  width: 100%;
  border: 1px solid rgba(29, 29, 29, 0.1019607843) !important;
  box-shadow: none !important;
  font-size: 1.25em !important;
}

.reset-password-text {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 1.125em;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.reset-password-text-container {
  margin-top: 5%;
}

.forgot-password-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
}
@media (min-width: 1025px) {
  .reset-password-main-container {
    box-shadow: 0px 3px 200px -15px rgba(146, 143, 143, 0.3843137255);
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 430px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    border-radius: 25px;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .reset-password-btn {
    margin-bottom: 10%;
    margin-top: 0% !important;
    width: 100% !important;
  }
  .reset-password-form-row {
    font-size: 1.25em !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 4%;
    margin-top: 10%;
    width: 100%;
    border: 1px solid rgba(29, 29, 29, 0.1019607843) !important;
    box-shadow: none !important;
    font-size: 1.25em !important;
  }
}
.reset-password-main-container {
  overflow: scroll !important;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain !important;
}

.logo {
  width: 40%;
  height: 50%;
}

.header {
  width: 100%;
}

.logo-container {
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b2a2a;
  font-size: 2em;
  margin-bottom: 3% !important;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}

.reset-password-form-container {
  width: 80%;
  text-align: center;
}

.sign-in-text-container {
  width: 100%;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset-password-form-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset-password-form-row {
  font-size: 1.25em !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 4%;
  margin-top: 10%;
  width: 100%;
  border: 1px solid rgba(29, 29, 29, 0.1019607843) !important;
  box-shadow: none !important;
  font-size: 1.25em !important;
}

.form-row-error {
  padding: 4%;
  margin-top: 10%;
  width: 95%;
  box-shadow: 1px 1px 1px 1px rgba(29, 29, 29, 0.1019607843);
  border: 1px solid #ff0000;
}

.confirm-btn {
  margin-top: 10%;
  margin-bottom: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 95%;
}

.reset-password-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
  margin-top: 10%;
}

::placeholder {
  color: #959292;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #959292;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #959292;
}

.reset-password-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b2a2a;
  font-size: 2em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}
.page-not-found-container {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-not-found-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-not-found-text1 {
  color: #2B2A2A;
  font-weight: bold;
  font-size: 2em;
  font-family: "poppins", sans-serif !important;
}

.page-not-found-text2 {
  color: #2B2A2A;
  font-weight: 500;
  font-size: 1.3em;
  font-family: "poppins", sans-serif !important;
}

@media (min-width: 300px) and (max-width: 400px) {
  .page-not-found-text2 {
    color: #2B2A2A;
    font-weight: 500;
    font-size: 1.25em !important;
    font-family: "poppins", sans-serif !important;
  }
}
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .page-not-found-text2 {
    color: #2B2A2A;
    font-weight: 500;
    font-size: 1em !important;
    font-family: "poppins", sans-serif !important;
  }
}
.page-not-found-text-subcontainer {
  margin-top: 5%;
}
@media (min-width: 1025px) {
  .set-password-success-main-container {
    box-shadow: 0px 3px 200px -15px rgba(146, 143, 143, 0.3843137255);
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 25px;
    width: 430px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .onboarding-text-container {
    margin-top: 0% !important;
    margin-right: 10% !important;
    margin-bottom: 0% !important;
    margin-left: 10% !important;
  }
  .set-password-success-btn {
    margin-top: 10% !important;
  }
}
.onboarding-text {
  color: #2b2a2a;
  font-size: 1.25em;
  font-family: "poppins", sans-serif !important;
  font-weight: 500;
}

.set-password-success-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.set-password-success-btn-sub-container {
  width: 90%;
  position: absolute;
  bottom: 0;
}

.set-password-success-btn {
  left: 0 !important;
  margin-bottom: 10% !important;
  width: 100% !important;
}

.onboarding-text-container {
  margin-top: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
  margin-left: 10%;
}

@media screen and (orientation: landscape) {
  .set-password-success-btn-sub-container {
    position: inherit !important;
  }
}
.set-password-title-success {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b2a2a;
  font-size: 2em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
  margin-top: 5%;
  margin-bottom: 5% !important;
}
@media (min-width: 1025px) {
  .preference-screen-card-main-container {
    border-radius: 25px;
    width: 100% !important;
  }
  .intro-screen-explainer-text {
    margin-top: 3% !important;
  }
  .intro-exit-preference-screen-card-container {
    margin-top: 2% !important;
  }
  .intro-screen-text-bottom {
    margin-bottom: 10% !important;
    margin-top: 10% !important;
  }
  .fruit-preferences-next-btn {
    margin-bottom: 0% !important;
  }
  .exit-btn-container {
    position: "absolute";
    bottom: 0;
    justify-content: center;
    width: 100%;
    display: flex;
    margin-top: 0em !important;
  }
  .later-text {
    margin-top: 40px;
  }
  .taste-preference {
    margin-bottom: 0% !important;
    font-family: "poppins", sans-serif !important;
    font-size: 1.2em !important;
    color: #ffffff !important;
    background-color: #a84551 !important;
    width: 45%;
  }
  .preference-screen-card-main-container {
    height: 90vh !important;
  }
  .exit-preference-screen-card-title {
    margin-top: 2% !important;
  }
}
.preference-screen-card-main-container {
  width: 100%;
  height: 85vh;
  position: relative;
}

.intro-exit-preference-screen-card-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  text-align: center;
}

.preference-screen-card-category-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.preference-screen-card-sub-container {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.intro-screen-explainer-text-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.intro-screen-explainer-text {
  margin-top: 10%;
  font-size: 1.5em;
  font-family: "poppins!important";
}

.later-text {
  color: #a84551;
  font-size: 1.2em !important;
  font-family: poppins !important;
}

.intro-screen-explainer-pair-anything-logo {
  width: 30%;
}

.gesture-logo {
  width: 75%;
}

.intro-screen-text-bottom {
  color: #2B2B2B;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em;
  margin-top: 15%;
}

.exit-screen-text-bottom {
  color: #2B2B2B;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  margin-top: 15%;
}

.exit-screen-text {
  color: #2B2B2B;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  margin-top: 15%;
}

.intro-screen-pairanything-text {
  color: #a84551;
}

.exit-screen-logo {
  width: 60%;
}

.preference-screen-card-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.question-name {
  font-size: 2.5em;
  text-transform: capitalize;
  font-family: "poppins", sans-serif !important;
}

@font-face {
  font-family: "Trajan Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Trajan Pro Regular"), url(/static/media/TrajanPro-Regular.d198b580.ttf) format("ttf");
}
.intro-exit-screen-content-text {
  font-family: "poppins", sans-serif !important;
  margin-top: 5%;
  font-size: 1.5em !important;
  color: black !important;
}

.intro-exit-preference-screen-card-title {
  font-family: "poppins", sans-serif !important;
  margin-top: 5%;
  font-size: 1.5em !important;
  color: black !important;
}

.exit-preference-screen-card-title {
  margin-top: 5%;
  width: 90%;
  font-size: 1.3em !important;
  color: black !important;
  font-family: "poppins", sans-serif !important;
}

.preference-screen-card-title {
  font-family: "poppins", sans-serif !important;
  margin-top: 5%;
  font-size: 1.5em !important;
  color: #918E8E !important;
}

.preference-screen-card-title::first-letter {
  text-transform: capitalize;
}

.selected-preference-screen-image {
  background-image: url(/static/media/selected-heart.a7e37bbc.svg);
}

.preference-screen-image {
  background-image: url(/static/media/not-selected-heart.608b3057.svg);
}

.next-btn-container {
  position: "absolute";
  bottom: 0;
  justify-content: center;
  width: 100%;
  display: flex;
  margin-top: 4em;
}

.exit-btn-container {
  position: "absolute";
  bottom: 0;
  justify-content: center;
  width: 100%;
  display: flex;
  margin-top: 4em;
}

.slidecontainer {
  width: 80%;
}

.swipe-card-slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 1.5em !important;
  height: 1.5em !important;
  border: 0 !important;
  background-image: url(/static/media/slider-heart.6237416c.svg) !important;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3.5em !important;
  height: 3.5em !important;
  border-radius: 0% !important;
  background: #d2d2dd !important;
  background: url(/static/media/slider-heart.6237416c.svg) !important;
  background-repeat: none !important;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  background: #a84551;
  cursor: pointer;
}

.exit-page-logo {
  margin-top: -9%;
  margin-bottom: 5%;
  width: 40%;
}

.heart-intro-icon {
  margin-top: 5% !important;
  width: 15%;
}

@media only screen and (min-width: 375px) and (max-width: 375px) and (min-height: 812px) and (max-height: 812px) {
  .exit-screen-logo {
    margin-top: 15% !important;
    margin-bottom: 15% !important;
  }
}
@media only screen and (min-width: 400px) and (max-height: 900px) {
  .exit-screen-logo {
    margin-top: 6% !important;
    margin-bottom: 6% !important;
  }
}
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .next-btn-container {
    position: relative !important;
  }
  .exit-btn-container {
    position: relative !important;
  }
  .intro-screen-text-bottom, .exit-preference-screen-card-title {
    font-size: 1.25em !important;
  }
  .intro-exit-preference-screen-card-title {
    font-size: 1.25em !important;
    margin-top: 2% !important;
  }
  .intro-screen-explainer-text {
    margin-top: 5% !important;
  }
  .intro-exit-screen-content-text {
    font-size: 1em !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .intro-exit-preference-screen-card-title, .intro-exit-screen-content-text {
    font-size: 1.25em !important;
    margin-top: 2% !important;
  }
  .intro-screen-explainer-text {
    margin-top: 5% !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 375px) {
  .intro-exit-preference-screen-card-title, .intro-exit-screen-content-text {
    font-size: 1.25em !important;
    margin-top: 5% !important;
  }
  .intro-screen-explainer-text {
    margin-top: 5% !important;
  }
  .preference-screen-card-main-container {
    height: auto;
  }
}
.exit-screen-button {
  margin-bottom: 15%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 90%;
}
@media (min-width: 1025px) {
  .taste-preference-option-card-main-container {
    width: 27% !important;
  }
  .intro-screen-explainer-text {
    margin-top: 3% !important;
  }
}
.taste-preference-option-card-main-container {
  display: flex;
  width: 40%;
  flex-direction: column;
}

.taste-preference-option-card-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heart-status-image {
  width: 35%;
}

.option-card-name {
  color: black;
  font-family: "poppins", sans-serif !important;
}

.option-card-name {
  text-transform: capitalize;
}

.taste-preference-option-card-name-container {
  text-align: center;
  justify-content: center;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.taste-preference-option-card-image-container {
  display: flex;
  justify-content: center;
  width: 70%;
}

.option-card-image {
  width: 100%;
}

.taste-preference-option-card-heart-image-container {
  display: flex;
  justify-content: center;
}
/* Swipeable card customization */
@media (min-width: 1025px) {
  .card-desktop-bg {
    box-shadow: 0px 3px 36px rgba(204, 204, 204, 0.3019607843);
    width: 50% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    /* border: 2px solid #C0C0C0; */
    border-radius: 25px;
    overflow: auto;
    background: #ffffff 0% 0% no-repeat padding-box;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .e-card-resize-container {
    padding: 10%;
    padding-top: 5% !important;
  }
  .card-control-section.swipe_card_layout #horizontal_product .e-card {
    margin-left: -13px !important;
    margin-top: 10px !important;
  }
  .slick-prev {
    left: 2% !important;
    z-index: 1;
  }
  .slick-next {
    right: 2% !important;
    z-index: 1;
  }
  .slick-prev:before {
    color: #a84551 !important;
    font-size: 30px !important;
    line-height: 3 !important;
  }
  .slick-next:before {
    color: #a84551 !important;
    font-size: 30px !important;
    line-height: 3 !important;
  }
}
.card-control-section.swipe_card_layout .row.card-layout {
  display: flex;
}

.card-control-section.swipe_card_layout .row.card-layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
  margin: 0 auto;
}

.card-control-section.swipe_card_layout .e-card.e-card-horizontal .e-card-stacked .e-card-actions {
  flex-grow: 1;
}

.card-control-section.swipe_card_layout .e-card.e-card-horizontal .e-card-stacked .e-card-actions button {
  background: #fff;
  margin-left: 5px;
  margin-right: 5px;
  background-color: transparent;
}

.card-control-section.swipe_card_layout .e-card.e-card-horizontal .e-card-stacked > :first-child {
  flex-grow: initial;
}

.card-control-section.swipe_card_layout #horizontal_product .e-card {
  -webkit-user-select: none;
          user-select: none;
  width: 100%;
}

.card-control-section.swipe_card_layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
  position: relative;
  top: 50px;
  width: 100%;
}

.card-control-section.swipe_card_layout {
  min-height: 100vh;
}

.card-control-section.swipe_card_layout #horizontal_product .e-card {
  position: absolute;
  transition: all 200ms;
  touch-action: none;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.card-out-left {
  animation: 0.3s ease-out 0s 1 card-out-left;
}

.card-out {
  animation: 0.3s ease-out 0s 1 card-out;
}

@keyframes card-out-left {
  0% {
    z-index: 20;
    transform: translateX(0px) translateY(0px);
  }
  10% {
    transform: translateX(-50px) translateY(0px);
  }
  20% {
    transform: translateX(-100px) translateY(0px);
  }
  30% {
    transform: translateX(-150px) translateY(0px);
  }
  40% {
    transform: translateX(-200px) translateY(0px);
  }
  50% {
    transform: translateX(-250px) translateY(0px);
  }
  60% {
    transform: translateX(-200px) translateY(0px);
    z-index: 0;
  }
  70% {
    transform: translateX(-150px) translateY(0px);
    z-index: 0;
  }
  80% {
    transform: translateX(-100px) translateY(0px);
    z-index: 0;
  }
  90% {
    transform: translateX(-50px) translateY(0px);
    z-index: 0;
  }
  100% {
    transform: translateX(0px) translateY(0px) scale(1);
  }
}
@keyframes card-out {
  0% {
    z-index: 20;
    transform: translateX(0px) translateY(0px);
  }
  10% {
    transform: translateX(50px) translateY(0px);
  }
  20% {
    transform: translateX(100px) translateY(0px);
  }
  30% {
    transform: translateX(150px) translateY(0px);
  }
  40% {
    transform: translateX(200px) translateY(0px);
  }
  50% {
    transform: translateX(250px) translateY(0px);
  }
  60% {
    transform: translateX(200px) translateY(0px);
    z-index: 0;
  }
  70% {
    transform: translateX(150px) translateY(0px);
    z-index: 0;
  }
  80% {
    transform: translateX(100px) translateY(0px);
    z-index: 0;
  }
  90% {
    transform: translateX(50px) translateY(0px);
    z-index: 0;
  }
  100% {
    transform: translateX(0px) translateY(0px) scale(1);
  }
}
.card-control-section.swipe_card_layout .e-card > * {
  max-width: 100%;
  justify-content: flex-start;
}

@media (min-width: 540px) {
  .card-control-section.swipe_card_layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
    width: 50%;
  }
  .card-control-section.swipe_card_layout .card-layout {
    margin: auto;
    max-width: 870px;
  }
}
.slick-prev {
  left: 2% !important;
  z-index: 1;
}

.slick-next {
  right: 2% !important;
  z-index: 1;
}

.slick-prev:before {
  color: #a84551 !important;
  font-size: 30px !important;
  line-height: 3 !important;
}

.slick-next:before {
  color: #a84551 !important;
  font-size: 30px !important;
  line-height: 3 !important;
}

.e-card .e-card-horizontal {
  height: 30% !important;
  margin-left: 50% !important;
}

.col-xs-6 {
  width: 100% !important;
}

.e-card-resize-container {
  padding: 10%;
  padding-top: 5%;
}

.row .card-layout {
  width: 100%;
}

.swipe-card-sub-header-section {
  display: inline-grid;
  margin-top: 5%;
  margin-left: 5%;
  width: 100%;
}

.header-text-row {
  margin-top: 3%;
  margin-left: 6%;
}

.taste-preference-sub-text {
  margin-left: 6%;
  color: white;
}

.swipe-header-section {
  width: 100%;
  box-shadow: rgba(29, 29, 29, 0.1019607843) !important;
  position: absolute;
  display: inline-block;
  align-content: center !important;
}

.e-card {
  box-shadow: none !important;
}

.card-out-margin {
  animation: MoveUpDown 0.8s;
}

.swipe-left-right-arrow-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  margin-top: 80%;
}

.swipe-right-arrow,
.swipe-left-arrow {
  width: 7%;
}

.slick-dots {
  bottom: 2px !important;
  font-size: 8px !important;
  overflow: auto !important;
}

.slick-dots li {
  width: 10px !important;
}

.slick-list {
  margin-bottom: -6% !important;
}

ul {
  margin-top: -17px !important;
  margin-right: 14% !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
}

@media (min-width: 300px) and (max-width: 400px) {
  ul {
    margin-top: 10px !important;
  }
}
* {
  outline: none !important;
}

.taste-main-container {
  width: 100%;
  height: 100vh;
}

.back-btn-recommended-wines {
  width: 3%;
}

.taste-card-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {
  .taste-card-container {
    display: flex;
    flex-direction: column;
  }
  .edit-icon {
    width: 14px;
    height: 14px;
  }
  .edit-text {
    margin-left: 1% !important;
    margin-top: 1px;
  }
  .common-sub-header-back-icon {
    width: 1.5% !important;
  }
  .common-sub-header-text {
    margin-left: 2% !important;
  }
  .common-sub-header-logo {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    height: 50px;
  }
  .common-page-header {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  }
  .taste-header {
    width: 100% !important;
    height: 14% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .taste-text {
    margin-left: 5%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .taste-header {
    width: 100%;
    height: 14%;
    background-color: #a84551;
    align-content: center !important;
  }
  .taste-text {
    margin-left: 5%;
    font-size: 1.5em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
}
.edit-text {
  margin-left: 2%;
  font: Medium 14px/24px Poppins;
  letter-spacing: 0px;
  color: #a84551;
  opacity: 1;
}

.sub-title {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 3%;
  opacity: 1;
}

.card-header {
  text-align: left;
  font: SemiBold Poppins;
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

.results {
  pointer-events: none;
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid #a84551;
}

.display-results-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.result-container {
  margin-right: 9px;
  margin-top: 5px;
}

.edit-icon {
  width: 14px;
  height: 14px;
}
* {
  outline: none !important;
}

.taste-main-container {
  width: 100%;
  height: 100vh;
}

.back-btn-recommended-wines {
  width: 3%;
}

.edit-taste-pref-card-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {
  .btn-grp {
    width: 80%;
    margin-left: 10%;
  }
  .edit-taste-pref-card-container {
    flex-direction: row !important;
    flex-wrap: wrap;
  }
  .edit-wine-type-card {
    width: 47% !important;
  }
  .taste-header {
    width: 100% !important;
    height: 14% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .taste-text {
    margin-left: 5%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .taste-header {
    width: 100%;
    height: 14%;
    background-color: #a84551;
    align-content: center !important;
  }
  .taste-text {
    margin-left: 5%;
    font-size: 1.5em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
}
.card-header {
  text-align: left;
  font: SemiBold Poppins;
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

.result-container {
  margin-right: 9px;
  margin-top: 5px;
}

.results-selected {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid #a84551;
}

.results-not-selected {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #a84551 !important;
  background-color: #ffffff !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid #a84551;
}

.save-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 80%;
  border-radius: 2px;
}

.cancel-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #a84551 !important;
  background-color: #fff !important;
  width: 80%;
  border-radius: 2px;
  border: 1px solid #a84551;
}

.display-results-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.btn-grp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.cancel-btn-container {
  width: 50%;
}

.save-btn-container {
  width: 50%;
}
.events-main-container-grey {
  width: 100%;
  background: #f2f2f2;
}

.events-main-container-white {
  width: 100%;
  height: 100vh;
  background: white;
}

@media (min-width: 1025px) {
  .events-main-container,
  .taste-container,
  .bottom-navigation {
    overflow: auto;
  }
  .events-main-container-grey {
    background: none !important;
  }
  .available-events-container::after {
    content: "";
    background: #f2f2f2;
    height: 50px;
  }
  .available-events-container {
    margin-bottom: 6% !important;
    flex-direction: row !important;
    flex-wrap: wrap;
  }
}
.recommendation-history-main-container-events-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  border-left: 1pt solid white;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.available-events-container {
  width: 100%;
  position: relative;
  margin-bottom: 15.5%;
  margin-left: 0% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-state-image {
  width: 70%;
  margin: auto;
  display: block;
}

.empty-state-main-container {
  height: 70%;
}

.empty-state-master-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-state-text {
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 23px;
  letter-spacing: 0px;
  color: #a84551;
  opacity: 1;
}
.event-desc-main-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: -1px;
}

.available-event-card {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.available-event-details {
  width: 100%;
}

.cover-picture {
  width: calc(100% + 28px);
  height: 28vw;
  object-fit: cover;
  margin-left: -14px;
  margin-top: -14px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media (min-width: 300px) and (max-width: 350px) {
  .event-name {
    width: 200px;
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 1.1em;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
    font-weight: 600;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .available-event-card {
    height: 350px;
  }
}
@media (min-width: 1025px) {
  .cover-picture {
    height: 22vh;
  }
  .event-desc-main-container {
    width: 50% !important;
    background: none !important;
  }
}
.event-name {
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 1.1em;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  font-weight: 600;
}

.event-title {
  width: 100%;
  margin-top: 20px;
  display: inline-flex;
  overflow-wrap: anywhere;
  flex-direction: row;
}

.title-icons {
  position: absolute;
  right: 0;
}

.share-icon {
  margin-right: 20px;
}

.like-icon {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
}

.event-card-bottom {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.event-price {
  text-align: left;
  color: #727272;
  font-size: 17px;
  margin-top: 7px;
  letter-spacing: 1px;
  font-family: "poppins", sans-serif !important;
  opacity: 1;
}

.rsvp-button {
  outline: none !important;
  width: 100px;
  height: 35px;
  position: absolute;
  right: 0;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  margin-right: 14px;
  justify-content: center;
}

.rsvp-button:focus {
  outline: none !important;
  box-shadow: none;
}

.event-date {
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  opacity: 1;
  display: flex;
  flex-direction: row;
}

.event-date-container {
  margin-top: 5px;
}

.calendar-icon {
  height: 14px;
  width: auto;
}

.date-content {
  margin-left: 10px;
  font-size: 17px;
}

.fully-booked-btn {
  outline: none !important;
  pointer-events: none;
  width: 107px;
  height: 35px;
  position: absolute;
  right: 0;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  color: #2b2b2b;
  margin-right: 33px;
  justify-content: center;
}

.fully-booked-btn:focus {
  outline: none !important;
  box-shadow: none;
}

.cancelled-btn {
  outline: none !important;
  pointer-events: none;
  width: 107px;
  height: 35px;
  position: absolute;
  right: 0;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  color: #2b2b2b;
  margin-right: 9px;
  justify-content: center;
}

.cancelled-btn :focus {
  outline: none !important;
  box-shadow: none;
}

.attending-btn {
  outline: none !important;
  pointer-events: none;
  width: 100px;
  height: 35px;
  position: absolute;
  right: 0;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #2b2b2b;
  margin-right: 26px;
  justify-content: center;
}

.attended-btn {
  outline: none !important;
  pointer-events: none;
  width: 100px;
  height: 35px;
  position: absolute;
  right: 0;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #2b2b2b;
  margin-right: 9px;
  justify-content: center;
}

.attending-btn:focus {
  outline: none !important;
  box-shadow: none;
}

.check-icon {
  height: 14px;
  width: auto;
  margin-right: 5px;
  margin-bottom: 2px;
}

.fully-booked-icon {
  height: 16px;
  margin-right: 5px;
  margin-bottom: 2px;
  width: auto;
}

.cancelled-icon {
  height: 16px;
  margin-right: 5px;
  margin-bottom: 3px;
  width: auto;
}
* {
  outline: none !important;
}

.bg {
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header-text {
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 18px;
  letter-spacing: 0px;
  color: #a84551;
  opacity: 1;
  margin-top: -25px;
}

.first-dd {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}

.first-dd-text {
  width: 50%;
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  margin-right: 5px;
  color: #2b2b2b;
  opacity: 1;
}

.second-dd {
  display: flex;
  flex-direction: column;
}

.btn-selector-grp-dd {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.three-btn-grp {
  margin-left: 18px;
  margin-top: 15px;
  height: 42px;
}

.btn-grp {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
}

.second-dd-text {
  text-align: left;
  margin-left: 5%;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  margin-top: 15px;
  margin-right: 5px;
  color: #2b2b2b;
  opacity: 1;
  margin-bottom: 5px;
}

.time-slot-dd {
  width: 100%;
}

.time-slot-container {
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.thin-line-div {
  height: 60px;
  width: 100%;
  vertical-align: middle;
}

.thin-line {
  background: #cccccc 0% 0% no-repeat padding-box;
  border-radius: 65px;
  opacity: 1;
  margin-left: 35%;
  height: 2px;
  width: 30%;
  display: inline-block;
}

.btn-grp-text {
  width: 50%;
  margin-left: 18px;
  margin-top: 8px;
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  margin-right: 5px;
  color: #2b2b2b;
  opacity: 1;
}

.footer-buttons {
  margin-bottom: 28px;
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cancel-event-rsvp {
  margin-left: 18px;
  font-family: "poppins", sans-serif !important;
  width: 200px;
  margin-top: 5px;
  color: #a84551;
}

.cancel-button {
  height: 33px;
  text-align: center;
  margin-left: 30px;
  letter-spacing: 0px;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  color: #a84551;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a84551;
  border-radius: 2px;
}

.reason-text-box {
  padding: 6px;
  width: 100%;
  font-size: 16px;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
  border-radius: 5px;
  resize: none;
  width: 100%;
}

.textarea-container {
  margin-left: 18px;
  margin-top: 25px;
  margin-right: 18px;
  margin-bottom: 35px;
}

.save-button {
  background: #a84551 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  height: 35px;
  margin-left: -14px;
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}

.cancel-button-edit-form {
  height: 33px;
  width: 100%;
  text-align: center;
  letter-spacing: 0px;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  color: #a84551;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a84551;
  border-radius: 2px;
}

.save-button-edit-form {
  background: #a84551 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  height: 35px;
  margin-top: -1px;
  margin-left: -18px;
  width: 100%;
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}

.cancelBtn-container {
  width: 50%;
  margin-left: 10%;
}

.saveBtn-container {
  width: 50%;
  margin-left: 10%;
}

.pass-count-center-btn {
  pointer-events: none;
}

.show-cancel-sucess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin-left: 10%;
  font-size: 17px;
  height: 350px;
  margin-top: -60px;
  text-align: center;
  font-family: "poppins", sans-serif !important;
}

.show-edit-sucess {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  margin-left: 10%;
  margin-top: -60px;
  height: 350px;
  font-size: 17px;
  text-align: center;
  font-family: "poppins", sans-serif !important;
}

.checkbox-container {
  margin-left: 13px;
  margin-top: 10px;
  height: 30px;
  display: flex;
  flex-direction: row;
}

.checkbox-contents {
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  margin-top: 1px;
  line-height: 30px;
  vertical-align: middle;
}

.saved-icon {
  width: 70px;
  height: auto;
  margin-bottom: 25px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1025px) {
  .MuiPaper-root {
    width: 50% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
* {
  outline: none !important;
}

.event-desc-card {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.event-desc-card-container {
  margin-bottom: 80px;
}

.event-desc-root-container {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.attending-btn-details {
  pointer-events: none;
  width: 100px;
  height: 35px;
  position: absolute;
  right: 0;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #2b2b2b;
  margin-top: -10px;
  margin-right: 20px;
  justify-content: center;
}

.cover-picture-desc {
  width: calc(100% + 28px);
  height: 38vw;
  object-fit: cover;
  margin-left: -14px;
  margin-top: -14px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.event-title {
  width: 100%;
  margin-top: 10px;
  display: inline-flex;
  flex-direction: row;
}

.event-name {
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 19px;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  font-weight: 600;
}

.event-date-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.time-slot-header {
  margin-bottom: 20px;
  margin-top: 10px;
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: #2b2b2b;
}

.event-date-content {
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 9px;
  margin-top: 10px;
}

.location-icon {
  height: 16px;
  width: auto;
  margin-bottom: 3px;
}

.online-icon {
  height: 16px;
  width: auto;
  margin-bottom: 3px;
}

.event-venue {
  margin-top: 10px;
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  display: flex;
  flex-direction: row;
}

.event-url {
  margin-top: 10px;
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
}

.event-venue-contents {
  margin-left: 8px;
}

.event-url-title {
  margin-left: 5px;
}

.event-url-contents {
  margin-left: 21px;
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 13px;
  letter-spacing: 0px;
  color: #727272;
  opacity: 1;
}

.event-venue-contents {
  margin-left: 5px;
}

.event-desc {
  margin-top: 10px;
  margin-bottom: 10px;
}

.event-desc-title {
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: #2b2b2b;
}

.time-slot-header-container {
  display: flex;
  flex-direction: row;
}

.event-desc-content {
  text-align: justify;
  letter-spacing: 0px;
  color: #727272;
  opacity: 1;
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
}

.date-slot-container {
  margin-top: -10px;
}

.rsvp-button-event-desc {
  width: 90%;
  margin-bottom: 10px;
  margin-top: 10px;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1.3em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  margin-left: 5%;
  justify-content: center;
}

.attending-button {
  pointer-events: none;
  width: 90%;
  margin-bottom: 10px;
  font-family: "poppins", sans-serif !important;
  font-size: 1.3em !important;
  align-content: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #a84551;
  border-radius: 3px;
  opacity: 1;
  margin-left: 5%;
  justify-content: center;
  color: #a84551;
}

.calendar-icon-desc {
  height: 14px;
  width: auto;
  margin-top: 13px;
}

.user-icon {
  height: 14px;
  width: 12px;
  margin-right: 5px;
  margin-left: 20px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.time-icon {
  height: 17px;
  width: 17px;
  margin-top: 13px;
  margin-right: 8px;
  margin-bottom: 5px;
}

.recommendations-custom-line-events {
  margin-left: 3%;
  width: 2px;
  background-color: #a84551;
}

.date-details-container {
  display: flex;
  flex-direction: row;
}

.dot {
  height: 17px;
  width: 17px;
  margin-left: -9.5px;
  background-color: #a84551;
  border-radius: 50%;
  display: inline-block;
  margin-top: -10px;
}

.dot-without-line {
  height: 17px;
  width: 17px;
  margin-top: -10px;
  margin-left: 2px;
  background-color: #a84551;
  border-radius: 50%;
  display: inline-block;
}

.user-contents {
  text-align: left;
  padding-left: 5px;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  margin-top: -1px;
}

.time-contents {
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  margin-left: 8px;
  margin-top: -2px;
  margin-bottom: 15px;
}

.event-winery-container {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
}

.events-winery-logo {
  width: 10%;
  height: 10%;
  margin-right: 10px;
}

.event-winery-name {
  font-family: "poppins", sans-serif !important;
  color: #2b2b2b !important;
  font-size: 1em;
  text-transform: capitalize;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
}

.event-users {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.event-times {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.edit-pass {
  margin-top: 10px;
  text-align: left;
  text-decoration: underline;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: #a84551;
  opacity: 1;
}

.cancel-pass {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: underline;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  color: #a84551;
  opacity: 1;
}

.bottom-btn-container {
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

@media (min-width: 1025px) {
  .cover-picture-desc {
    height: 22vh !important;
  }
  .event-desc-card-container {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .rsvp-button-event-desc {
    width: 76% !important;
    margin-left: 12% !important;
  }
  .events-winery-logo {
    width: 5% !important;
    height: 5% !important;
  }
}
@media screen and (max-width: 320px) {
  .dot-without-line {
    margin-left: 0px !important;
  }
}
@media screen and (min-device-width: 768px) {
  .dot-without-line {
    margin-left: 2.1% !important;
  }
}
.box {
  display: flex;
  overflow: hidden;
}

.event-details {
  flex-grow: 9;
}

.box .share-event {
  flex-grow: 1;
  justify-content: flex-end;
  text-align: right;
  padding-top: 10px;
}
.popup-label {
  font-family: "poppins", SemiBold 14px/21px sans-serif;
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 1.9rem;
}

:root {
  --borderWidth: 3px;
  --height: 21px;
  --width: 10px;
  --borderColor: white;
}

.check {
  display: inline-block;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
}

.popup-text {
  font-family: "poppins", sans-serif !important;
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #a84551;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 15px;
}
.share-button {
  background-color: transparent;
  outline: none;
  border: none;
}

.share-image {
  max-width: 20px;
  max-height: 20px;
}
.textarea {
  background: "blue";
  white-space: nowrap;
  overflow: hidden;
}

.copy-button {
  background-color: transparent;
  outline: none;
  border: none;
}

.text {
  font-size: 50px;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: row !important;
  padding: 10px 0 10px 0;
  margin: 10px 0 10px 0 !important;
}

.copy-success-text {
  vertical-align: center;
}

::-webkit-scrollbar {
  height: 4px;
  border: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(189, 189, 189, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.chat-main-container {
  overflow-x: hidden;
}

.chat-header {
  padding: 5%;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
}

.chat-title {
  font-weight: 500 !important;
  margin-left: 5%;
  font-size: 1.75em;
  color: #2b2a2a;
}

.chat-container {
  display: flex;
  height: calc(100vh - 57px);
  flex-direction: column;
}

.confirm-chat-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
}

.online-capsule-container {
  float: right;
}

.conversations {
  margin-bottom: 5%;
}

@media screen and (max-width: 991px) {
  .conversations {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.conversations {
  display: flex;
  flex-direction: column;
}

.conversations .message-header {
  background: #fff;
  padding: 0.5rem;
  box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.075);
  z-index: 1;
}

.conversations .conversations-body {
  padding: 1.5rem 1rem;
}

.conversations .conversations-body {
  flex: 1 1;
  overflow-y: auto;
  padding: 1.5rem 2rem;
}

.conversations .conversations-content {
  padding: 1.5em;
}

.messages-wrapper .messages-body .message-content-wrapper,
.conversations-body .message-content-wrapper {
  display: inline-block;
  width: 100%;
}

.conversations .messages-form {
  margin: 0;
  background: #fff;
}

.messages-form {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.conversations-body .message-in .message-body .message-content {
  background: rgba(29, 29, 29, 0.1019607843);
  padding: 12px 15px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow-wrap: anywhere;
}

.message-in .message-body .date {
  margin-left: 10px;
  margin-top: 8px;
  font-size: 11px;
  color: #83848a;
}

.message-out .message-body .date {
  margin-top: 8px;
  font-size: 11px;
  color: #83848a;
  margin-right: 10px;
  float: right;
}

.chat-winery-logo-placeholder {
  width: 2.5em;
  border-radius: 100px;
}

.avatar-img {
  width: 20% !important;
}

.message-avatar {
  vertical-align: none;
}

.conversations-body .message-out .message-body .message-content {
  background: #a84551;
  padding: 12px 15px;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow-wrap: anywhere;
}

.conversations-body {
  margin-bottom: 25px;
}

.message-body {
  margin-top: 1%;
}

.conversations-body .message-content-wrapper {
  display: inline-block;
  width: 100%;
}

.conversations-body .message-out {
  float: right;
  margin-left: 40px;
}

.conversations-body .message {
  display: table;
  table-layout: fixed;
  padding: 8px 0;
}

.conversations .messages-form {
  margin: 0;
  background: #fff;
}

.input-solid {
  background: #ffffff !important;
  border-color: #ffffff !important;
  border: none;
}

.messages-form-control {
  width: 100%;
  font-size: 18px;
  border-color: #ebedf2;
  height: inherit !important;
  margin-left: 5%;
}

.content {
  color: #ffffff;
  font-size: 1.25em;
}

.messages-form-send {
  margin-right: 5%;
}

.messages-form-send-text {
  color: #a84551;
}

.messages-form {
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: white;
}

.winery-admin-content {
  color: black;
  font-size: 1.25em;
}

.bottom-chat-container {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.75em !important;
}

.input-solid:focus {
  outline: none !important;
  border: none !important;
}

.chat-error-message-container {
  padding: 5%;
  text-align: center;
  margin-top: 50%;
}

.chat-not-found-page {
  margin-top: 1% !important;
}

.commonMessageDate {
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
}

.business-timing {
  font-size: 1em;
  color: grey;
  margin-left: 10%;
}

.width-88 {
  width: 88% !important;
}

.socket-network-error {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #ded5d5 0% 0% no-repeat padding-box; */
  /* border: 1px solid #CDCDCD; */
  border-radius: 3px;
  height: 2em;
  /* padding: 10px; */
  overflow: visible;
  font-size: 14px;
}

.socket-network-error-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  /* margin-left: 2%; */
  /* margin-left: 60px; */
  background: #ded5d5 0% 0% no-repeat padding-box;
  border: 1px solid #cdcdcd;
  /* padding-left: 10px; */
  /* margin-left: 4%; */
  width: 111%;
}

.refresh-now {
  color: #212529 !important;
  text-decoration: underline;
  padding-left: 5px;
}

.chat-new-arrow {
  width: 16px;
}

.new-chat-header {
  top: 0;
  position: fixed;
  z-index: 100;
}

.dummy-margin {
  margin-left: 1.5rem;
}

.new-business-timings-css {
  font-size: 1em;
  color: #ffffff;
  font-weight: normal !important;
}
.chat-main-container {
  overflow-x: hidden;
}

.top-navigation-chat {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}

.chat-header-desktop {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  padding: 2%;
  position: fixed;
  z-index: 1;
  align-items: center;
  width: 100%;
  background-color: white;
}

.chat-title-desktop {
  font-weight: 500 !important;
  margin-left: 3%;
  font-size: 1.75em;
  color: #2b2a2a;
}

@media (min-width: 1025px) {
  .chat-header-desktop {
    width: 100%;
  }
  .chat-main-container {
    display: flex;
    flex-direction: column;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
    height: 100vh !important;
    overflow: auto;
  }
  .chat-title {
    font-weight: 500 !important;
    font-size: 1.5em;
    color: #2b2a2a;
  }
}
.chat-container {
  display: flex;
  height: calc(100vh - 57px);
  flex-direction: column;
}

.confirm-chat-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
}

@media (min-width: 1025px) {
  .nav-link {
    margin-right: 2% !important;
  }
}
.online-capsule-container {
  float: right;
}

.conversations {
  margin-bottom: 5%;
}

@media screen and (max-width: 991px) {
  .conversations {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.conversations {
  display: flex;
  flex-direction: column;
}

.conversations .message-header {
  background: #fff;
  padding: 0.5rem;
  box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.075);
  z-index: 1;
}

.conversations .conversations-body {
  padding: 1.5rem 1rem;
}

.conversations .conversations-body {
  flex: 1 1;
  overflow-y: auto;
  padding: 1.5rem 2rem;
}

.conversations .conversations-content {
  padding: 1.5em;
}

.messages-wrapper .messages-body .message-content-wrapper,
.conversations-body .message-content-wrapper {
  display: inline-block;
  width: 100%;
}

.conversations .messages-form {
  margin: 0;
  background: #fff;
}

.messages-form {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.conversations-body .message-in .message-body .message-content {
  background: rgba(29, 29, 29, 0.1019607843);
  padding: 12px 15px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow-wrap: anywhere;
}

.message-in .message-body .date {
  margin-left: 10px;
  margin-top: 8px;
  font-size: 11px;
  color: #83848a;
}

.message-out .message-body .date {
  margin-top: 8px;
  font-size: 11px;
  color: #83848a;
  margin-right: 10px;
  float: right;
}

.chat-winery-logo-placeholder {
  width: 2.5em;
  border-radius: 100px;
}

.avatar-img {
  width: 20% !important;
}

.message-avatar {
  vertical-align: none;
}

.conversations-body .message-out .message-body .message-content {
  background: #a84551;
  padding: 12px 15px;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow-wrap: anywhere;
}

.conversations-body {
  margin-bottom: 25px;
}

.message-body {
  margin-top: 1%;
}

.conversations-body .message-content-wrapper {
  display: inline-block;
  width: 100%;
}

.conversations-body .message-out {
  float: right;
  margin-left: 40px;
}

.conversations-body .message {
  display: table;
  table-layout: fixed;
  padding: 8px 0;
}

.conversations .messages-form {
  margin: 0;
  background: #fff;
}

.input-solid {
  background: #ffffff !important;
  border-color: #ffffff !important;
  border: none;
}

.messages-form-control {
  width: 100%;
  font-size: 18px;
  border-color: #ebedf2;
  height: inherit !important;
  margin-left: 5%;
}

.content-desktop {
  color: #ffffff;
  font-size: 16px;
}

.messages-form-send {
  margin-right: 5%;
}

.messages-form-send-text {
  color: #a84551;
}

.messages-form {
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: white;
}

.winery-admin-content {
  color: black;
  font-size: 1.25em;
}

.bottom-chat-container-desktop {
  bottom: 0;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 4%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.75em !important;
}

.input-solid:focus {
  outline: none !important;
  border: none !important;
}

.chat-error-message-container {
  padding: 5%;
  text-align: center;
  margin-top: 50%;
}

.chat-not-found-page {
  margin-top: 1% !important;
}

.commonMessageDate {
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
}

.business-timing-desktop {
  font-size: 1em;
  color: grey;
  margin-left: 3%;
}

.width-88 {
  width: 88% !important;
}

.socket-network-error {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #ded5d5 0% 0% no-repeat padding-box; */
  /* border: 1px solid #CDCDCD; */
  border-radius: 3px;
  height: 2em;
  /* padding: 10px; */
  overflow: visible;
  font-size: 14px;
}

.socket-network-error-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  /* margin-left: 2%; */
  /* margin-left: 60px; */
  background: #ded5d5 0% 0% no-repeat padding-box;
  border: 1px solid #cdcdcd;
  /* padding-left: 10px; */
  /* margin-left: 4%; */
  width: 111%;
}

.refresh-now {
  color: #212529 !important;
  text-decoration: underline;
  padding-left: 5px;
}
@charset "UTF-8";
.recommendations-main-container {
  width: 100%;
  height: 100vh;
}

.back-btn-recommended-wines {
  width: 3%;
}

@media (min-width: 1025px) {
  .recommendations-main-container,
  .recommendations-container,
  .bottom-navigation {
    margin-top: 5% !important;
    overflow: auto;
  }
  .recommendations-option-container {
    display: flex;
    justify-content: space-between;
    padding: none !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
  .recommendations-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }
  .recommendations-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }
  .recommendations-main-container {
    box-shadow: 0px 0px 15px 15px #d2d2d2;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 30% !important;
    height: 80vh !important;
    border: 2px solid #c0c0c0;
    overflow: auto;
  }
  .recommendations-header {
    width: 100% !important;
    height: 14% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .recommendations-text {
    margin-left: 5%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .recommendations-cart-btn {
    float: right;
  }
  .recommendations-main-container {
    height: 70vh !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .recommendations-card-personal-details-subheader {
    margin-bottom: 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .recommendations-option-container {
    display: flex;
    justify-content: space-between;
    padding: 1% !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
}
.recommendations-header {
  width: 100%;
  height: 14%;
  background-color: #a84551;
  align-content: center !important;
}

.badge-notify {
  background: #a84551;
  position: relative;
  top: -2px;
  font-size: smaller;
  left: -8px;
}

.badge-notify-empty {
  background: none;
  position: relative;
  top: -2px;
  font-size: smaller;
  left: -8px;
}

.badge-notify-desktop-empty {
  background: none;
  position: relative;
  top: -8px;
  font-size: smaller;
  left: -8px;
}

.add-to-cart-icon-div {
  float: right;
}

.cart-icon-position {
  width: 2.5rem;
  margin-top: 1.5rem;
}

.recommendations-text {
  margin-left: 5%;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.recommendations-cart-btn {
  float: right;
}

.cont-pad {
  padding: 0 2em;
}

.recommendations-container {
  margin-top: 5% !important;
  width: 100%;
  overflow: hidden;
  margin-bottom: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recommendations-container:after {
  content: "";
  display: block;
  height: 130px;
  width: 100%;
}

.recommendations-card-personal-details-subheader {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 400px) {
  .recommendations-pic {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
  .recommendations-option-container {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
}
.recommendations-name {
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: 600 !important;
}

.recommendations-name:first-letter {
  text-transform: uppercase;
}

.recommendations-email {
  color: #959292;
  font-size: 1em;
}

.recommendations-row {
  bottom: 0;
}

.recommendations-order-cart-details {
  margin-left: 1px;
  float: left;
  width: 49%;
  border: 1px solid #959292;
  border-top-width: thin;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
  position: relative;
}

.recommendations-orders,
.recommendations-cart {
  color: #959292;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.recommendations-subheader {
  display: flex;
  padding: 5%;
}

.recommendation-dish-name {
  position: absolute;
  color: #7d7d7d;
  margin-top: 8vh;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 40px !important;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  width: 90%;
}

.recommendation-dish-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommendations-response-text,
.recommendations-pairing-note {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  margin-left: 5%;
  margin-right: 5%;
  display: block;
  word-break: break-word;
}

.recommendations-pairing-note {
  margin-top: 5%;
  font-style: italic;
}

.recommendations-winery-name,
.recommendations-dish-name {
  font-style: italic;
}

.recommendations-dish-name {
  text-transform: capitalize;
}

.recommendations-winery-name:before {
  content: " ";
}

.recommendations-winery-name:after {
  content: " ";
}

.recommendations-dish-name:before {
  content: " ";
}

.recommendations-dish-name:after {
  content: " ";
}

.recommendations-response-container,
.wine-name-container {
  animation: 0.75s ease-out 0s 1 slideInFromLeft;
}
.wine-type-subcontainer {
  display: flex;
  flex-direction: column;
}

.wine-subcontainer-parent {
  padding: 0 3% 0 3% !important;
}

.wine-name {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.2em;
  font-weight: 600;
  word-wrap: break-word;
}

.wd-card {
  padding: 1em 2.5em;
}

.wine-name {
  text-transform: capitalize;
}

.wine-subtype-subcontainer {
  display: flex;
  flex-direction: column;
}

.wine-subtype-name {
  color: grey !important;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  white-space: nowrap;
}

.wine-subtype-name::first-letter {
  text-transform: capitalize;
}

.wine-subtype-title {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 0.75em;
  display: flex;
  justify-content: center;
}

.wine-details {
  width: 100%;
}

.wine-price-text {
  color: #a84551;
  font-weight: bold;
}

.wine-bottle-size {
  color: #2b2a2a;
}

.wine-details-row {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wine-details-card-available {
  padding: 0% !important;
  background-color: white;
}

.wine-details-card-available-desktop {
  padding: 0% !important;
  background-color: white;
}

.wine-details-card-not-available {
  background-color: #e0e0e0;
}

.wine-details-order-cart {
  width: 50%;
  border: 1px solid #ece8e8;
  border-top-width: thin;
  border-bottom-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
  height: 70%;
  position: relative;
}

.wine-details-order-cart-desktop {
  width: 50%;
  border: 1px solid #ece8e8;
  border-top-width: thin;
  border-bottom-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
  position: relative;
  bottom: 0;
}

.pos {
  position: relative !important;
}

.br-none {
  border-right-style: none;
}

.bl-none {
  border-left-style: none;
}

.wine-details-add-cart-title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
}

.bg-color {
  background-color: #a84551;
}

.wine-details-price-title {
  color: #7d7d7d;
}

.add-to-cart {
  width: 100%;
  margin: 0 !important;
}

.shop-at-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  font-weight: 700;
  color: #a84551 !important;
  width: 100%;
  padding: 0.5rem;
  text-decoration: underline;
}

.wine-details-add-cart-title,
.wine-details-price-title {
  margin-right: 5%;
}

.wine-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wine-details-data-subholder {
  width: 100%;
  padding: 0.75em 0.75em 0.75em 0.75em;
  display: flex;
}

.wine-bottle-logo-container {
  justify-content: center;
  margin-bottom: 3%;
  align-items: center;
  display: flex;
}

.wine-bottle-logo {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.wine-bottle-logo-for-api {
  height: auto;
  max-height: 300px;
  width: auto;
  max-width: 200px;
}

.add-to-cart-new-design {
  padding: 0.8rem !important;
  width: 80% !important;
}

.share-wine-button {
  position: relative;
  left: 5%;
  margin-top: auto;
  margin-bottom: auto;
}

.wine-share {
  position: absolute;
  top: 0;
  right: 0;
}
.tp-detail-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tp-footer-pop-btn-css {
  margin-bottom: 28px;
  margin-top: 22px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-tp-btn {
  background: #a84551 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  height: 35px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}

.tp-do-it-later-btn-css {
  height: 33px;
  text-align: center;
  letter-spacing: 0px;
  font-size: 15px;
  color: #a84551;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.pa-title {
  font-style: italic;
  font-weight: bold;
}
.available-wines-main-container {
  width: 100%;
  height: 100vh;
}

@media (min-width: 410px) {
  .available-wines-badge-notify {
    top: -6px !important;
    left: -9px !important;
  }
}
@media (min-width: 1025px) {
  .search-wine-for-dish-subheader {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  }
  .available-wines-container {
    margin-top: 2% !important;
  }
  .available-wines-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }
  .available-wines-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }
  .available-wines-header {
    width: 100% !important;
    height: 15% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .available-wines-text {
    margin: 7%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .available-wines-cart-btn {
    float: right;
  }
}
.available-wines-header {
  width: 100%;
  height: 14%;
  background-color: #a84551;
  align-content: center !important;
}

.available-wines-text {
  margin: 7%;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.available-wines-cart-btn {
  float: right;
}

.available-wines-container {
  width: 100%;
  position: relative;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
}

.cart-icon-margin {
  margin-top: 0.7rem !important;
}

.available-wines-badge-notify {
  background: #a84551;
  position: relative;
  top: -33px;
  font-size: smaller;
  left: 17px;
}

.input-height {
  height: 40px !important;
}

.available-wines-search-input {
  color: #959292;
  font-style: italic;
  justify-content: space-between;
  font-size: 1.25em;
  padding-left: 9px !important;
}

.available-wines-container:after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}

@media (min-width: 768px) {
  .cart-icon-padding {
    padding-left: 3.5em;
  }
}
@media (min-width: 1025px) {
  .cart-icon-padding {
    padding-left: 4.5em;
  }
}
@media (min-width: 400px) {
  .available-wines-pic {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
}
.available-wines-name {
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: 600 !important;
}

.available-wines-name:first-letter {
  text-transform: uppercase;
}

.available-wines-row {
  bottom: 0;
}

.available-wines-cart {
  color: #959292;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.available-wines-subheader {
  display: flex;
  justify-content: space-between;
}

.available-wines-container {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.mt-5em {
  margin-top: 5em !important;
}

.new-common-header-change {
  justify-content: space-between;
}
.available-wines_availableWinesMainContainer__287Dg {
  width: 100%;
  height: 100vh;
}

@media (min-width: 410px) {
  .available-wines_available-wines-badge-notify__33xMX {
    top: -6px !important;
    left: -9px !important;
  }
}
@media (min-width: 1025px) {
  .available-wines_search-wine-for-dish-subheader__3b4fP {
    padding-top: 0% !important;
    padding-bottom: 0% !important;
  }
  .available-wines_avWinesContainer__1bJxn {
    margin-top: 2% !important;
  }
  .available-wines_available-wines-pic__EErov {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }
  .available-wines_available-wines-header__1o1Qe {
    width: 100% !important;
    height: 15% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .available-wines_available-wines-text__3BQ1l {
    margin: 7%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .available-wines_available-wines-cart-btn__14zR6 {
    float: right;
  }
}
.available-wines_available-wines-header__1o1Qe {
  width: 100%;
  height: 14%;
  background-color: #a84551;
  align-content: center !important;
}

.available-wines_available-wines-text__3BQ1l {
  margin: 7%;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.available-wines_available-wines-cart-btn__14zR6 {
  float: right;
}

.available-wines_avWinesContainer__1bJxn {
  width: 100%;
  position: relative;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
}

.available-wines_cart-icon-margin__2ABQl {
  margin-top: 0.7rem !important;
}

.available-wines_available-wines-badge-notify__33xMX {
  background: #a84551;
  position: relative;
  top: -33px;
  font-size: smaller;
  left: 17px;
}

.available-wines_input-height__3sYK4 {
  height: 40px !important;
}

.available-wines_available-wines-search-input__2OxWQ {
  color: #959292;
  font-style: italic;
  justify-content: space-between;
  font-size: 1.25em;
  padding-left: 9px !important;
}

.available-wines_avWinesContainer__1bJxn:after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}

@media (min-width: 768px) {
  .available-wines_cart-icon-padding__1X9Ic {
    padding-left: 3.5em;
  }
}
@media (min-width: 1025px) {
  .available-wines_cart-icon-padding__1X9Ic {
    padding-left: 4.5em;
  }
}
@media (min-width: 400px) {
  .available-wines_available-wines-pic__EErov {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
}
.available-wines_available-wines-row__1BUQt {
  bottom: 0;
}

.available-wines_available-wines-cart__19hOY {
  color: #959292;
}

.available-wines_bottom-navigation__209pi {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.available-wines_available-wines-subheader__3fuQ3 {
  display: flex;
  justify-content: space-between;
}

.available-wines_mt-5em__2ETG6 {
  margin-top: 5em !important;
}

.available-wines_new-common-header-change__3Sp81 {
  justify-content: space-between;
}
body {
  font-family: "poppins", sans-serif !important;
}

.content-container {
  width: 100%;
  height: 100vh;
}

@media (min-width: 1025px) {
  .content-container {
    box-shadow: 0 3px 200px -15px #989898;
    background-color: white;
    border-radius: 25px;
    width: 430px !important;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .bottom-nav {
    bottom: 0;
    border: solid 0.4px #d2d2d2;
    position: fixed;
    transition: top 0.6s;
    padding: 0.5rem !important;
    padding-bottom: 1em !important;
    width: 430px !important;
    margin-bottom: 5vh;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }
  .success-footer-container {
    width: 430px !important;
    background: none !important;
  }
}
.sub-container {
  padding: 1.5% 3% 3% 3%;
}

.new-discover-container {
  position: relative !important;
}

.bottom-nav {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

@media (min-width: 1025px) {
  .new-discover-select-card {
    width: 40% !important;
  }
  .new-discover-inner-container {
    height: 90vh !important;
    overflow: hidden;
  }
  .new-discover-inner-container-induvidual-wines {
    height: 90vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 799px) and (min-width: 1025px) {
  .new-discover-inner-container {
    height: 90vh !important;
    overflow: hidden;
  }
  .new-discover-inner-container-induvidual-wines {
    height: 90vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.new-discover-outer-container-div::after {
  content: unset !important;
  display: unset !important;
  height: unset !important;
  width: unset !important;
}

.new-discover-card-logo {
  width: 65%;
}

.thanks-fonts-style {
  margin: 0 0 4% 0%;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
}

.font-family {
  font-family: "poppins", sans-serif !important;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.new-discover-logo-container {
  padding-top: 5% !important;
}

.success-logo-container {
  padding: 10% 5% 5% 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0% 0% 4% 0%;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
}

.outer-container {
  padding: 3%;
  width: 100%;
  background: #EFEFEF 0% 0% no-repeat padding-box;
}

.outer-container-no-pad {
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.top-outer-container {
  width: 100%;
  height: 100vh;
  background: #EFEFEF 0% 0% no-repeat padding-box;
}

::-webkit-scrollbar {
  height: 4px;
  border: 1px;
  width: 10px;
  background-color: rgb(238, 238, 238);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(189, 189, 189, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.unset-children::after {
  content: unset !important;
  display: unset !important;
  height: unset !important;
  width: unset !important;
}

a {
  color: #337ab7;
  text-decoration: none;
}
a:hover, a:focus {
  color: shift-color(#337ab7, 20%);
  text-decoration: underline;
}

.header-logo {
  margin-right: unset !important;
  padding-right: 5px;
}

.search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  width: 100% !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.recommendation-input {
  color: #959292;
  font-style: italic;
  font-size: 1.25rem;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.posRelative {
  position: relative;
}

.page-bottom {
  padding-bottom: 8rem;
}

.mc {
  width: 100%;
  padding: 0 2.4rem 0 2.4rem;
}
.h1-30 {
  /* H1 - 30 ExtraBold */
  color: #343434;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px; /* 113.333% */
  letter-spacing: -0.84px;
}

.h2-22 {
  /* H2 - 22 ExtraBold */
  color: #343434;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.33px;
}

.h3-16 {
  /* 16 ExtraBold */
  color: #343434;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.448px;
}

.p1-reg {
  /* P1 - 14 Reg */
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.p1-bold {
  /* P1 - 14 Bold */
  color: #343434;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.p1-bold-red {
  /* P1 - 14 Bold */
  color: #951901;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.p1-semi {
  /* P1 - 14 Bold */
  color: #343434;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.p1-it {
  /* P1 - 14 Bold */
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
}

.p2-reg {
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.p3-bold {
  color: #343434;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.p4-x-bold {
  color: #343434;
  /* 16 ExtraBold */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.448px;
}

.p5-med {
  color: #343434;
  text-align: center;
  /* P5 - 12 Med */
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
}

.p7-reg {
  color: #7d7d7d;
  /* P7 - 12 Bold */
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}

.p7-bold {
  color: #343434;
  /* P7 - 12 Bold */
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
}

.pb-lg {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pb-sm {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.unset-margin-left {
  margin-left: unset !important;
}

.ml-0 {
  margin-left: 0;
}

.desktop-scroll {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.font-success {
  font-size: 17px;
  font-weight: 600;
}

.font-slim {
  font-weight: 500;
}

.font-medium {
  font-weight: 700;
}

.bg-none {
  background: none !important;
}

.no-shrink {
  flex-shrink: 0;
}

.m0 {
  margin: 0 !important;
}

.lh0 {
  line-height: 0;
}

.w100 {
  width: 100%;
}

.wa {
  width: auto;
}

.nmc {
  margin: 0 -2.4rem 0 -2.4rem;
}

.mcm {
  margin: 0 2.4rem 0 2.4rem;
}

svg {
  flex-shrink: 0;
  flex-grow: 0;
}

p {
  margin: 0;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

input {
  padding: 0;
  border: none;
}

label {
  margin: 0;
}

button {
  padding: 0;
}
.slide-from-left {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.header-burger_container__25mUp {
  width: 100%;
  background-color: #193c38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 4%;
  min-height: 60px;
}

@media (min-width: 1025px) {
  .header-burger_container__25mUp {
    padding-top: 0;
    padding-bottom: 0;
  }
  .header-burger_logo__2MzMW {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    height: 50px;
  }
}
.header-burger_innerContainer__1_dtN {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.header-burger_headerText__1nevV {
  font-size: 1.25em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 300 !important;
  margin-left: 5%;
}

.header-burger_logo__2MzMW {
  width: 10%;
  margin-right: 3%;
}

.header-burger_burger__3RIx9 {
  padding-left: 4%;
  margin-right: 4%;
}
.layout_verticalScroll__1OfRc {
  height: 100%;
  overflow-y: auto;
}

.layout_verticalScroll__1OfRc::-webkit-scrollbar {
  display: none;
}

.layout_pageBottom__NTt_q {
  padding-bottom: 6rem;
}
.search-wine-for-dish-main-container {
  width: 100%;
  height: 100vh;
}

.recommendation-input-error-message {
  font-size: 1.25em !important;
  color: red;
  font-family: "poppins", sans-serif !important;
}

.srch-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 92%;
  padding-top: 5px;
}

.srch-bar-input {
  color: #343434;
  background: #EFEFEF;
  opacity: 1;
  border-radius: 100px;
  border: none;
  height: 35px;
  width: 100%;
}

.search-main-swfd {
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  max-height: 75%;
  min-width: 90%;
  border-radius: 10px;
  padding-top: 1rem;
  background-color: white;
  display: table;
}

.search-btn {
  color: white;
  background: #a84551;
}

.search-btn-tab {
  color: #ffffff;
  width: 50%;
  border-color: #7d7d7d;
}

.search-btn:hover {
  color: white !important;
  text-decoration: none;
}

@media (min-width: 1025px) {
  .switch-winery-description {
    width: 93% !important;
  }
  .chat-switch-winery {
    width: 5% !important;
    text-align: right;
    padding-right: 0 !important;
  }
  .switch-winery-icon {
    width: 65% !important;
  }
  .search-box {
    width: 30% !important;
  }
  .search-wine-for-dish-main-container,
  .bottom-navigation {
    margin-top: 5%;
  }
  .search-wine-for-dish-container {
    width: 100% !important;
    overflow: auto;
    margin-top: 5% !important;
  }
  .search-wine-for-dish-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }
  .search-wine-for-dish-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }
  .search-wine-for-dish-header {
    width: 100% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    align-content: center !important;
  }
  .search-wine-for-dish-text {
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
    margin-left: 5%;
  }
  .search-wine-for-dish-main-container {
    height: 70vh !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .user-search-wine-for-dish-pic {
    width: 80px !important;
    height: 80px !important;
    border-radius: 100px !important;
  }
}
.search-wine-for-dish-header {
  width: 100%;
  align-content: center !important;
  background-color: #193c38;
}

.search-wine-for-dish-text {
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-left: 5%;
}

.search-wine-for-dish-empty-page-container {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 15%;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin-bottom: 25%;
}

.search-wine-for-dish-container {
  overflow: auto !important;
  margin-top: 5%;
}

.search-wine-for-dish-container:after {
  content: "";
  display: block;
  height: 5rem;
  width: 100%;
}

@media (min-width: 400px) {
  .search-wine-for-dish-pic,
  .user-search-wine-for-dish-pic {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
}
.search-wine-for-dish-name {
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: 600 !important;
}

.search-wine-for-dish-name:first-letter {
  text-transform: uppercase;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.top-navigation {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: #ffffff;
  padding: 0.5rem;
}

.user-search-wine-for-dish-pic-container {
  display: flex;
  justify-content: center;
}

.search-wine-for-dish-subheader {
  display: flex;
  justify-content: space-between;
  padding-top: 4%;
  align-items: center;
  padding-bottom: 4%;
}

.input-search-box {
  border: none;
  width: 80%;
}

.input-search-box-wine {
  border: none;
  padding: 0px;
  width: 90%;
}

.input-search-box:focus {
  outline: none;
}

.search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  width: 100% !important;
}

.search-wine-form-row-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 50px !important;
  text-transform: capitalize;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
  display: flex;
  align-items: center;
  width: 90%;
}

.search-wine-form-row-recommand-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: auto;
  text-transform: capitalize;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
  align-items: center;
  width: 90%;
}

.search-wine-form-tab-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 50px !important;
  text-transform: capitalize;
  display: flex;
  width: 98%;
}

.search-wine-form-tab-desktop-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 50px !important;
  text-transform: capitalize;
  display: flex;
  width: 93%;
}

@media (min-width: 300px) and (max-width: 400px) {
  .search-wine-for-dish-empty-page-container {
    margin-top: 0% !important;
  }
}
@media (min-width: 375px) and (max-width: 812px) {
  .search-wine-for-dish-empty-page-container {
    margin-top: 10% !important;
  }
}
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .search-wine-for-dish-empty-page-container {
    margin-top: 0% !important;
  }
  .search-wine-form-row-input {
    font-size: 1em !important;
  }
  .search-wine-for-dish-text {
    font-size: 1.25em !important;
  }
}
.recommendation-input {
  color: #959292;
  font-style: italic;
  font-size: 1.25em;
}

.recommendation-tab-input {
  color: #959292;
  font-size: 1.25em;
  margin-bottom: 1rem;
  margin: 0 auto;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
}

.search-wine-for-dish-pair-anything-logo {
  width: 10%;
  margin-right: 5%;
}

.search-wine-basedon-dish-btn {
  font-family: "poppins", sans-serif !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommendation-history-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 93%;
  border-left: 1pt solid white;
}

.submit-recommendation-container {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4%;
}

.recommendation-input-error-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
}

.recommendation-history-main-container,
.empty-page-main-container {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.switch-winery-accordion-item__line {
  padding: 0 0 0 0 !important;
}

.switch-winery-name {
  margin-left: 5%;
  font-family: "poppins", sans-serif !important;
  color: #a84551 !important;
  font-size: 1em;
  text-transform: capitalize;
}

.switch-winery-logo {
  width: 15%;
}

.switch-winery-description {
  font-family: "poppins", sans-serif !important;
  color: #2b2a2a !important;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}

.switch-winery-accordion-item__content {
  text-align: justify;
}

.switch-winery-accordion-item__inner-container {
  display: inherit;
  width: 30%;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-right: 6%;
}

.switch-winery-accordion-item__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
}

.switch-winery-icon {
  width: 83%;
}

.chat-winery-icon-disabled {
  width: 83%;
  opacity: 0.5;
}

.switch-winery-accordion-item__icon {
  color: #a84551;
}

.switch-winery-summary-container {
  align-items: center;
  display: flex;
  justify-content: start;
}

.reco-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.recommendations-custom-line {
  opacity: 0.5;
  margin-left: 6%;
  width: 2px;
  background-color: #a84551;
  background-image: radial-gradient(#cd4545, #fa9c9c);
}

.search-wine-empty-page-main-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.hamburger-menu {
  padding-left: 4%;
}

.new-logo-header-design {
  margin-right: unset !important;
  padding-right: 5px;
}

.new-search-wine-for-dish-text {
  margin-left: unset !important;
}

.new-header-text {
  text-align: center;
}

@media (min-width: 1200px) {
  .container {
    width: 300px;
  }
}
.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search .title {
  margin-bottom: 1em;
  margin-top: 5em;
  font-size: 27px;
  color: #cb2c59;
}
.search .content input {
  width: 15em;
  height: 42px;
  border: 0;
  border-radius: 3px;
  box-shadow: 0px 0px 15px 3px rgba(15, 15, 15, 0.2);
  padding: 0 16px;
  font-size: 18px;
  outline: 0;
  color: #2b2a2a;
  transition: all, 200ms ease-in-out;
}
.search .content input::placeholder {
  color: grey;
}
.search .content input:focus {
  box-shadow: 0px 0px 19px 3.1px rgba(88, 159, 207, 0.835);
}

.dropText {
  padding-left: 15px;
  padding-right: 15px;
  font-style: italic;
  color: #000;
  font-family: "poppins", sans-serif !important;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.popup {
  width: inherit;
  min-height: 5em;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 15px 2px rgba(15, 15, 15, 0.2);
  margin-top: 7px;
  border-radius: 0px 0px 5px 5px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.popup .container .content {
  height: 100%;
  padding: 0 0 10px 0;
}
.popup .container .content .item {
  color: #424444;
  cursor: pointer;
  transition: all, 230ms ease-in;
}
.popup .container .content .item:hover {
  background-color: rgba(170, 203, 224, 0.431372549);
}
.popup .container .footer {
  position: absolute;
  font-size: 13px;
  bottom: 3px;
  width: 99%;
  left: 0px;
  display: flex;
  border-top: 1px solid rgba(15, 15, 15, 0.2);
  padding: 2px 3px;
  color: blue;
}
.result-search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;
}

.pairing-search-bar {
  background: #EFEFEF;
  opacity: 0.5;
  border-radius: 100px;
  border: none;
  height: 35px;
  width: 100%;
}

.pairing-result-title {
  position: relative;
  margin-top: 5px;
}

.pairing-result-name {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: Montserrat;
  color: #343434;
  font-size: 12px;
}

.cheers {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bolder;
  color: #D0011B;
}

.pointer-reco {
  margin-bottom: -1rem;
}

.combo-patp-container {
  display: flex;
  width: 100%;
}

.pairing-result-combo-container-desktop {
  width: 50%;
  margin: 0 auto;
}

.pairing-result-combo-container {
  position: relative;
  margin-top: 2rem;
}

.pairing-result-combo-container-images {
  position: relative;
  margin-top: 2rem;
}

.pairing-result-main-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wine-image-container {
  position: absolute;
  transform: rotate(-13deg);
  width: 56px;
  height: 189px;
  max-width: 100%;
  margin: 0 auto;
  left: -100px;
  right: 0;
  z-index: 1;
}

.wine-image-container-foodres {
  position: absolute;
  transform: rotate(13deg);
  max-width: 56px;
  max-height: 189px;
  margin: 0 auto;
  left: 200px;
  margin-bottom: 1px;
  right: 0;
  z-index: 1;
}

.wine-image {
  object-fit: cover;
  width: 56px;
  height: 189px;
}

.food-image-container {
  position: absolute;
  width: 167px;
  height: 167px;
  max-width: 100%;
  left: 50px;
  right: 0;
  margin: 0 auto;
}

.food-image-container-foodres {
  position: absolute;
  width: 167px;
  height: 167px;
  max-width: 100%;
  left: -2px;
  right: 0;
  margin: 0 auto;
}

.food-image {
  object-fit: cover;
  width: 167px;
  height: 167px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
  border-radius: 50%;
}

.wine-title-container {
  position: relative;
  text-align: center;
  top: 100px;
  right: 100px;
}

.wine-text {
  display: block;
  margin-top: 2rem;
}

.wine-desc {
  display: block;
  position: relative;
  margin: 0 auto;
  height: 50px;
  width: 100px;
  font-family: Montserrat;
  font-size: 10px;
  font-style: italic;
  color: #343434;
}

.wine-desc-foodres {
  position: relative;
  margin: 0 auto;
  height: 50px;
  left: -50px;
  width: 100px;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.food-title-container {
  position: relative;
  text-align: center;
  left: 120px;
  top: -10px;
}

.food-desc {
  display: block;
  position: relative;
  margin: 0 auto;
  left: -50px;
  width: 100px;
  height: auto;
  color: #D0011B;
}

.food-text {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  color: #D0011B;
  width: 100px;
}

.new-pairing-result-buttons {
  grid-row-gap: 10px;
  row-gap: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 40px;
}

.shop-btn {
  margin-right: 0px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 0.5px solid #951901;
  width: -webkit-max-content;
  width: max-content;
  height: 41px;
  font-size: 12px;
  color: #951901;
  font-family: Montserrat;
  font-weight: 700;
}

.recipe-btn {
  margin-left: 0px !important;
  margin-right: 0px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #951901;
  border: none;
  width: -webkit-max-content;
  width: max-content;
  height: 41px;
  font-size: 12px;
  color: white;
  font-family: Montserrat;
  font-weight: 700;
}
.patpCard {
  font-family: Montserrat, serif;
  color: #7D7D7D;
  font-size: 12px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  margin: 0 auto;
  margin-top: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.patpCard-desktop {
  font-family: Montserrat, serif;
  color: #7D7D7D;
  font-size: 12px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
  width: 50%;
  margin: 0 auto;
  margin-top: 4%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.wine-varietal-text {
  color: #951901;
  font-weight: 800;
  font-size: 16px;
}

.tp-stats {
  width: 100%;
  margin-bottom: 2%;
}

.tp-stats-name {
  padding: 20px;
}

.dashed-separator {
  margin-top: 2%;
  margin-bottom: 2%;
  border-bottom: 1px dashed #951901;
}

.value-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.value-reached {
  font-weight: 700;
  color: #951901;
}

.value-not-reached {
  font-weight: 400;
  color: #343434;
}

.value-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.valued-bar {
  background: #951901;
  height: 3px;
  width: 47px;
  border-radius: 0;
}

.not-valued-bar {
  background: #EFEFEF;
  height: 3px;
  width: 47px;
  border-radius: 0;
}

.reviews {
  width: 100%;
  margin-top: 6%;
}

.reviews-list {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
}

.reviews-list-item {
  display: flex;
  flex-direction: row;
}

.reviews-list-item-text {
  padding-left: 10px;
}

.flavor-profile-list {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5%;
}

.flavor-profile-list-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 70px;
}

.flavor-profile-icon {
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  max-width: 30rem;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 0.8rem 0.1rem rgba(0, 0, 0, 0.06), 0 20px 30px -10px rgba(0, 0, 0, 0.2);
}
.accordion-list__item + .accordion-list__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion-item--opened .accordion-item__icon {
  transform: rotate(180deg);
}
.accordion-item--opened .accordion-item__inner {
  max-height: 100rem;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-duration: 0.5s;
  transition-property: max-height;
}
.accordion-item--opened .accordion-item__content {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity, transform;
}
.accordion-item__line {
  display: block;
  padding: 0.8rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.accordion-item__title {
  font-size: 1.6rem;
  margin: 0;
  font-weight: 700;
  color: #121212;
}
.accordion-item__icon {
  width: 1.2rem;
  height: 1.2rem;
  transition: transform 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
  opacity: 0.6;
}
.accordion-item__inner {
  max-height: 0;
  overflow: hidden;
  text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition-duration: 0.5s;
  transition-property: max-height;
  position: relative;
}
.accordion-item__content {
  opacity: 0;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 0.1s;
  transition-property: opacity, transform;
  transition-delay: 0.5s;
  padding: 1rem;
}
.accordion-item__paragraph {
  margin: 0;
  font-size: 1rem;
  color: #333;
  font-weight: 300;
  line-height: 1.3;
}

.accordion-item::after {
  content: "";
  position: absolute;
  left: -25px;
  border: 8px solid #a84551;
  border-radius: 100%;
}

.recommendation-history-card-accordion-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.recommendation-history-card-accordion-date {
  color: #7d7d7d;
  font-size: 0.8em;
}

.accordion-item__line {
  z-index: 0 !important;
}

.accordion-card-inner-content {
  display: flex;
  flex-direction: column;
}

.accordion-card-wine-name,
.accordion-card-wine-data {
  color: black;
  font-family: "poppins", sans-serif !important;
  font-size: 16;
}

.accordion-card-wine-name-desktop {
  color: #a84551;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.accordion-card-wine-data {
  margin-bottom: 5%;
}

.accordian-card-wine-specifications {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-family: "poppins", sans-serif !important;
  color: #2b2b2b;
}

.accordion-card-wine-name {
  font-size: 12px;
  color: #a84551;
}

.accordion-card-wine-tab-name {
  color: #a84551;
  font-size: 12px;
  font-family: "poppins", sans-serif !important;
}

.accordion-card-wine-name::first-letter,
.accordion-card-wine-name-desktop::first-letter,
.accordion-card-wine-data::first-letter {
  text-transform: capitalize;
}

.accordion-item__icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC) !important;
  opacity: 1 !important;
  padding: 5%;
}

.accordion-row-container {
  display: flex;
  flex-direction: row;
}

.accordion-item__paragraph {
  font-size: 1em !important;
}

.star-rating__star {
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.5em;
  color: #d2d2d2;
  transition: color 0.2s ease-out;
}
.star-rating__star:hover {
  cursor: pointer;
}
.star-rating__star.is-selected {
  color: #f7d002;
}
.star-rating__star.is-disabled:hover {
  cursor: default;
}

.recommendation-history-card-dishname {
  color: black;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  text-transform: capitalize;
}

.recommendation-history-card-dishname::first-letter {
  text-transform: capitalize;
}

.rating-recommendation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.rating-recommendation-text {
  color: black;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
}

.already-rated-ratingbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  animation: right 0.3s ease, left-end 0.4s ease 0.4s;
}

.is-selected {
  animation: right 0.3s ease, left-end 0.4s ease 0.4s;
}

.rate-recommendation-btn-container {
  display: flex;
  justify-content: flex-end;
}

.rate-recommendation-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100% !important;
  padding: 0.8rem 1.2rem;
}

.add-to-cart-reco {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100% !important;
  padding: 0.8rem 1.2rem;
  margin: 0 3% 0 3%;
}

.add-to-cart-reco-desktop {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100% !important;
  padding: 0.8rem 1.2rem;
}

.rate-recommendation-history-btn {
  font-family: "poppins", sans-serif !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
  padding: 1rem 0;
}

.li-dot-desktop {
  height: 10px;
  width: 10px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}

.li-dot {
  height: 8px;
  width: 8px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}

.accordion-card-wine-name-container {
  display: flex;
  flex-direction: row;
}

.li-dot-container {
  margin-right: 10px;
}

.share-food-pairing-button {
  position: absolute;
  bottom: 0.5%;
  right: 2.7%;
}

@media (min-width: 1025px) {
  .accordion-item__icon {
    padding: 2.5% !important;
  }
}

@media (min-width: 1025px) {
  .rate-recommendation-text-subcontainer {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
}
.rate-recommendation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center !important;
  flex-wrap: wrap;
  position: relative;
  overflow: auto;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  margin-bottom: 5%;
}

.rate-recommendation-bottom {
  display: flex;
  flex-direction: column;
}

.rate-recommendation-back-btn {
  margin-left: 5%;
  margin-top: 5%;
}

.rate-recommendation-text-container {
  margin-top: 10%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rate-recommendation-text-container-desktop {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rate-recommendation-dishname {
  text-align: center;
  color: #2b2a2a;
  font-size: 1.25em;
  font-family: "poppins", sans-serif !important;
  text-transform: capitalize;
}

.rate-recommendation-dishname-desktop {
  text-align: center;
  color: #2b2a2a;
  font-size: 1.25em;
  font-family: "poppins", sans-serif !important;
  text-transform: capitalize;
}

.rate-recommendation-text1 {
  text-align: center;
  color: #7d7d7d;
  font-size: 1em;
  font-family: "poppins", sans-serif !important;
}

.rate-recommendation-text1-desktop {
  text-align: center;
  color: #7d7d7d;
  font-size: 16px;
  margin-top: 5%;
  font-family: "poppins", sans-serif !important;
}

.rate-recommendation-text2 {
  color: #2b2a2a;
  font-weight: 500;
  font-size: 1.3em;
  font-family: "poppins", sans-serif !important;
}

@media (min-width: 300px) and (max-width: 400px) {
  .rate-recommendation-text2 {
    color: #2b2a2a;
    font-weight: 500;
    font-size: 1.25em !important;
    font-family: "poppins", sans-serif !important;
  }
}
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .rate-recommendation-text2 {
    color: #2b2a2a;
    font-weight: 500;
    font-size: 1em !important;
    font-family: "poppins", sans-serif !important;
  }
}
.rate-recommendation-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.rate-recommendation-icon-desktop {
  width: 50%;
  margin-top: 5%;
}

.rate-recommendation-text-subcontainer {
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rate-recommendation-icon {
  width: 80%;
  margin-top: 10%;
}

.rating-star-container {
  display: flex;
  flex-direction: row;
}

.star-rating__star-ratingpage {
  padding: 13px !important;
}

.star-rating-ratingpage__star {
  display: inline-block;
  padding: 13px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.75em;
  color: #d2d2d2;
  transition: color 0.2s ease-out;
}
.star-rating-ratingpage__star:hover {
  cursor: pointer;
}
.star-rating-ratingpage__star.is-selected {
  color: #f7d002;
}
.star-rating-ratingpage__star.is-disabled:hover {
  cursor: default;
}

.rating-star-recommendation-page-desktop {
  font-size: 1.3em !important;
}

.rating-star-recommendation-page {
  font-size: 1.75em !important;
}

.recommendation-rating-btn-container {
  width: 100%;
}

.feedback-text-container {
  margin-top: 5%;
  margin-bottom: 2%;
}

.rate-recommendation-submit-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
  padding: 0.8rem 1.2rem;
}

.rate-recommendation-submit-btn-desktop {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
  padding: 0.8rem 1.2rem;
}

.rate-recommendation-submit-btn-desktop {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 400px;
  padding: 0.8rem 1.2rem;
}

.rate-recommendation-feedback-text {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #7d7d7d;
  font-size: 1em;
  font-family: "poppins", sans-serif !important;
}
.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0 !important;
}

.card-title {
  text-align: left;
}

.img-square-wrapper {
  max-height: 200px;
  height: auto;
}
* {
  outline: none !important;
}

.taste-main-container {
  margin: auto;
  width: 100%;
  height: 100vh;
}

.back-btn-recommended-wines {
  width: 3%;
}

.edit-taste-pref-card-container {
  margin: auto !important;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-wine-type-card {
  width: 80% !important;
}

.card-header {
  text-align: left;
  font: SemiBold Poppins;
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

.solution-header {
  display: flex;
  flex-direction: row;
  font: SemiBold Poppins;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

.card-header-2 {
  display: flex;
  flex-direction: column;
  font: SemiBold Poppins;
  font-size: 16px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

.results-selected {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid #a84551;
}

.small-selected {
  font-size: 12px !important;
}

.results-not-selected {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #a84551 !important;
  background-color: #ffffff !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid #a84551;
}

.show-edit-sucess {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  margin-left: 10%;
  margin-top: -60px;
  height: 350px;
  font-size: 17px;
  text-align: center;
  font-family: "poppins", sans-serif !important;
}

.results-incorrect {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: white !important;
  background-color: black !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid black;
}

.save-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.2em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 80%;
  border-radius: 2px;
}

.next-question-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 0.9em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 80%;
  border-radius: 2px;
}

.return-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 0.9em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 80%;
  border-radius: 2px;
}

.cancel-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #a84551 !important;
  background-color: #ffffff !important;
  width: 80%;
  border: 1px solid #a84551;
  border-radius: 2px;
  margin: auto;
}

.display-results-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.display-results-row-2 {
  justify-content: left;
  justify-items: left;
  justify-self: left;
  align-items: left;
  align-content: left;
  align-self: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
}

.btn-grp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.cancel-btn-container {
  width: 50%;
}

.save-btn-container {
  width: 50%;
  margin: auto;
}

.next-question-btn-container {
  width: 50%;
  margin: auto;
}

.inner-div {
  background: #ffffff;
  overflow-y: auto;
}

.content-style {
  height: 100% !important;
  font-family: Poppins, Regular sans-serif !important;
  font-size: 18px !important;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  overflow-y: auto;
  padding: 50px 20px 30px 20px;
}

.font-bold {
  font-weight: bold !important;
}

.double-column {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  flex-wrap: wrap !important;
}

.line-break {
  width: 100%;
}

.exit-button {
  float: left;
  position: absolute !important;
  margin: -10% 5% 0 1% !important;
  bottom: 95%;
  right: 83%;
  overflow-y: hidden;
  overflow-x: hidden;
  background: none;
  border: none;
}

.exit-button-desktop {
  margin: 0 10% 0 1%;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  bottom: 90%;
  right: 85%;
  background: none;
  border: none;
}

.discount-percent-off::-webkit-scrollbar {
  display: none;
}

.discount-code-style::-webkit-scrollbar {
  display: none;
}

.content-style::-webkit-scrollbar {
  display: none;
}

.inner-div::-webkit-scrollbar {
  display: none;
}

@media (min-width: 200px) and (max-width: 328px) {
  .content-style {
    font-family: Poppins, Regular sans-serif !important;
    font-size: 12px !important;
    background: #ffffff;
    margin: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    overflow-y: auto;
    padding: 50px 20px 30px 20px;
  }
  .btn-grp {
    width: 100%;
  }
  .taste-header {
    width: 100%;
    height: 1%;
    background-color: #a84551;
    align-content: center !important;
  }
  .taste-text {
    margin-left: 5%;
    font-size: 1.5em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .results-incorrect {
    font-size: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .results-selected {
    font-size: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .results-not-selected {
    font-size: 8px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .save-btn {
    margin: auto;
    font-size: 0.8em !important;
    width: 100%;
    border-radius: 2px;
  }
  .cancel-btn {
    margin: auto;
    font-size: 0.8em !important;
    width: 100%;
    border-radius: 2px;
  }
  .next-question-btn {
    margin: auto;
    font-size: 0.8em !important;
    width: 100%;
  }
  .next-question-btn-container {
    width: 100%;
    margin: auto;
  }
  .display-results-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .display-results-row-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .card-header {
    font-size: 12px;
  }
  .btn {
    white-space: normal;
  }
}
@media (min-width: 330px) and (max-width: 480px) {
  .btn-grp {
    width: 100%;
  }
  .taste-header {
    width: 100%;
    height: 1%;
    background-color: #a84551;
    align-content: center !important;
  }
  .taste-text {
    margin-left: 5%;
    font-size: 1.5em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .results-incorrect {
    font-size: 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .results-selected {
    font-size: 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .results-not-selected {
    font-size: 12px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .save-btn {
    margin: auto;
    font-size: 1em !important;
    width: 80%;
    border-radius: 2px;
  }
  .cancel-btn {
    margin: auto;
    font-size: 1em !important;
    width: 80%;
    border-radius: 2px;
  }
  .next-question-btn {
    margin: auto;
    font-size: 1em !important;
    width: 100%;
  }
  .next-question-btn-container {
    width: 80%;
    margin: auto;
  }
  .display-results-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .display-results-row-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .card-header {
    font-size: 14px;
  }
  .btn {
    white-space: normal;
  }
}
@media (min-width: 1024px) {
  .btn-grp {
    width: 80%;
    margin-left: 10%;
  }
  .taste-header {
    width: 100% !important;
    height: 14% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .taste-text {
    margin-left: 5%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .next-question-btn {
    margin-right: 10%;
    margin-left: 10%;
    font-family: "poppins", sans-serif !important;
    font-size: 0.9em !important;
    color: #ffffff !important;
    background-color: #a84551 !important;
    width: 100%;
    border-radius: 2px;
  }
}
.powered-by-container {
  font-family: Montserrat, serif;
  line-height: 14px;
  font-size: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #FFFFFF;
}

.powered-by-pre {
  font-weight: 600;
  align-self: center;
  margin: 0;
  color: #7D7D7D;
  opacity: 0.5;
}

.powered-by-logo {
  width: 12px;
  height: 11px;
  margin-left: 5px;
  margin-right: 2px;
}

.powered-by-pair {
  color: orangered;
  font-weight: 800;
  align-self: center;
  margin: 0;
  padding-right: 1px;
}

.powered-by-anything {
  color: darkgray;
  font-weight: 800;
  align-self: center;
  margin: 0;
}
.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bottom-middle {
  position: absolute;
  bottom: 0;
  left: 35%;
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.entrance-content-container {
  position: relative;
  margin-top: 35%;
  padding-left: 10%;
  padding-right: 10%;
}

.entrance-title {
  font-family: "Montserrat", serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #951901;
}

.entrance-query {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #C73C32;
  padding-left: 0;
  width: 100%;
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 37px;
}

.entrance-query-container {
  position: relative;
}

.entrance-search-icon {
  position: absolute;
  right: 1%;
  top: 25%;
  height: 32px;
  width: 32px;
}

.entrance-subtitle-container {
  margin-top: 5%;
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #951901;
}

.bold {
  font-weight: 800 !important;
}
.individual-wine-details-main-container {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
  -webkit-animation: 0.5s ease-out 0s 1 slideInFromLeft;
  background-color: #f2f2f2;
}

.bottom-nav-induvidual-wines {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.individual-wine-details-main-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.individual-wine-details-image-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  border-radius: 10px !important;
  flex-direction: column;
  box-shadow: 1px rgba(29, 29, 29, 0.1019607843) !important;
  border: none;
  height: 300px;
}

.individual-wine-details-sub-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.individual-wine-details-sub-container1 {
  width: 100%;
}

.individual-wine-details-sub-container2 {
  padding-top: 0% !important;
  width: 100%;
  margin-bottom: 5%;
}

.individual-wine-details-price-type-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.individual-wine-details-scroll-container {
  margin-bottom: 20%;
  overflow: auto;
  width: 100%;
  position: absolute;
  margin-top: -9vh;
}

@media (min-width: 1025px) {
  .bottom-nav-induvidual-wines {
    bottom: 0;
    border: solid 0.4px #d2d2d2;
    position: fixed;
    transition: top 0.6s;
    padding: 0.5rem !important;
    padding-bottom: 1em !important;
    width: 430px !important;
    margin-bottom: 5vh;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }
  .type-text-cooperage {
    margin-left: 30% !important;
  }
  .individual-wine-details-subheader {
    padding: 3.5% !important;
  }
  .individual-wine-details-back-btn {
    width: 2% !important;
  }
  .individual-wine-details-title {
    margin-left: 2% !important;
    margin-top: 1px;
  }
  .individual-wine-details-header1 {
    height: 15% !important;
  }
  .individual-wine-details-sub-container2 {
    padding-top: 0% !important;
    width: 100%;
    margin-bottom: 5%;
  }
  .wine-detail-add-to-cart-btn {
    width: 90% !important;
    margin-bottom: 2% !important;
    margin-top: 2% !important;
  }
  .individual-wine-details-sub-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .individual-wine-details-scroll-container {
    margin-bottom: 20%;
    overflow: auto;
    width: 100%;
    position: absolute;
    margin-top: -7vh;
  }
  .individual-wine-details-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }
  .individual-wine-details-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }
  .individual-wine-detail-footer {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .individual-wine-details-header1 {
    width: 100%;
    height: 18%;
    background-color: #193c38;
    align-content: center !important;
  }
  .individual-wine-details-header {
    width: 100% !important;
    height: 38% !important;
    align-content: center !important;
    background-repeat: no-repeat;
    border-bottom-right-radius: 4%;
    border-bottom-left-radius: 4%;
  }
  .individual-wine-details-text {
    color: #2b2a2a;
    font-family: "poppins", sans-serif !important;
    font-size: 1em;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .individual-wine-details-text::first-letter {
    text-transform: uppercase;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .user-individual-wine-details-pic {
    width: 80px !important;
    height: 80px !important;
    border-radius: 100px !important;
  }
}
.individual-wine-details-title {
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-left: 5%;
}

.individual-wine-details-subheader {
  display: flex;
  padding: 5%;
  align-items: center;
}

.individual-wine-details-header1 {
  width: 100%;
  height: 18%;
  background-color: #193c38;
  align-content: center !important;
  padding-top: 4%;
  padding-bottom: 4%;
}

.individual-wine-details-header {
  width: 100%;
  height: 38%;
  align-content: center !important;
  background-color: #a84551;
  background-repeat: no-repeat;
  border-bottom-right-radius: 4%;
  border-bottom-left-radius: 4%;
}

.individual-wine-details-text {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.2em;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.individual-wine-details-text::first-letter {
  text-transform: uppercase;
}

.individual-wine-details-back-btn {
  float: left;
  width: 3%;
}

.individual-wine-details-container {
  width: 100%;
  position: absolute;
  overflow: auto;
  padding: 2%;
  display: flex;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
}

@media (min-width: 411px) and (max-height: 740) {
  .individual-wine-details-image-not-present-container {
    margin-top: 10%;
    margin-right: 5%;
  }
}
@media (min-width: 375px) and (max-width: 400px) and (height: 812px) {
  .individual-wine-details-image-not-present-container {
    margin-top: 30% !important;
    margin-right: 5% !important;
  }
}
@media (min-width: 411px) {
  .individual-wine-details-image-not-present-container {
    margin-top: 10%;
    margin-right: 5%;
  }
  .individual-wine-details-pic,
  .user-individual-wine-details-pic {
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
  .individual-wine-details-header {
    height: 33% !important;
  }
  .individual-wine-details-image {
    width: 25%;
    margin-top: 5%;
  }
}
.individual-wine-details-image-placeholder {
  height: 60% !important;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .type-text-header {
    font-family: "poppins", sans-serif !important;
    font-size: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .individual-wine-details-image {
    width: 25%;
    margin-top: 5%;
  }
  .individual-wine-details-image-present-container {
    margin-top: 2% !important;
  }
  .individual-wine-text-name {
    font-size: 1em !important;
  }
  .individual-wine-text-content,
  .individual-wine-paired-content {
    font-size: 0.8em !important;
  }
  .individual-wine-details-header {
    height: 40% !important;
  }
  .individual-wine-details-image {
    width: 25%;
    margin-top: 5%;
  }
  .individual-wine-details-text {
    font-size: 1.25em !important;
  }
  .individual-wine-details-text::first-letter {
    text-transform: uppercase;
  }
  .individual-wine-details-image-present-container {
    display: flex;
    justify-content: center;
  }
  .individual-wine-text-name {
    font-size: 1em !important;
  }
}
.individual-wine-details-subheader-row {
  display: flex;
  align-items: center;
  padding: 2%;
}

.individual-wine-details-type-content-container {
  margin: 5%;
  margin-top: 2%;
  padding: 2%;
}

.type-title-text,
.price-title-text {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  font-size: 1em;
  margin-top: 1%;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
}

.specifications-type-title {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  font-size: 1.15em;
  margin-top: 1%;
  margin-bottom: 1%;
  text-transform: capitalize;
  display: flex;
}

.price-text {
  color: #a84551;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  font-size: 1.25em;
  margin-top: 1%;
  text-transform: capitalize;
}

.specifications-type-container1 {
  margin-bottom: 5%;
}

.type-text-price {
  color: #a84551;
  word-wrap: break-word;
  font-weight: bold;
  font-family: "poppins", sans-serif !important;
  text-align: center !important;
  font-size: 1em;
  margin-top: 1%;
  text-transform: capitalize;
  text-align: left;
}

.type-text {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.15em;
  white-space: pre-wrap;
  word-break: keep-all;
  margin-top: 1%;
  text-align: left;
  border: none;
  background-color: transparent;
}

.type-text-cooperage {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.15em;
  margin-top: 1%;
  margin-left: 10%;
  text-transform: capitalize;
  text-align: left;
}

.type-text-vineyard {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.15em;
  margin-top: 1%;
  margin-left: 15%;
  text-transform: capitalize;
  text-align: left;
}

.type-text-header {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.type-text-wine-profile {
  word-wrap: break-word;
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.15em;
  margin-top: 1%;
  text-transform: capitalize;
  text-align: left;
}

.individual-wine-details-type-container,
.individual-wine-details-amount-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25%;
}

.individual-wine-details-content-container {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  width: 100%;
}

.individual-wine-details-content-subcontainer1,
.individual-wine-details-content-subcontainer2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}

.individual-wine-text-name {
  color: #2b2a2a;
  font-size: 1.5em !important;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-top: 2%;
  text-transform: capitalize;
}

.individual-wine-text-content {
  color: #2b2a2a;
  font-size: 1.25em !important;
  font-family: "poppins", sans-serif !important;
  margin-top: 2%;
}

.individual-wine-text-content::first-letter,
.individual-wine-paired-content::first-letter {
  text-transform: uppercase;
}

.individual-wine-paired-name {
  color: #2b2a2a;
  font-size: 1.5em !important;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-top: 2%;
}

.individual-wine-paired-content {
  color: #2b2a2a;
  font-size: 1em !important;
  font-family: "poppins", sans-serif !important;
  font-weight: lighter !important;
  margin-top: 2%;
}

.add-to-cart-btn-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.individual-wine-details-type-content-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.individual-wine-details-image {
  width: 25%;
  margin-top: 5%;
}

.individual-wine-details-content-type-price-container {
  display: flex;
  justify-content: space-between;
}

.individual-wine-based-on-title {
  color: #7d7d7d;
  font-size: 1em;
}

.individual-wine-based-on-text {
  color: #a84551;
  font-weight: bold;
  font-size: 1.25em;
}

@media (min-width: 300px) and (max-width: 400px) {
  .type-title-text,
  .type-text,
  .amount-title-text,
  .amount-text {
    font-size: 1.15em !important;
  }
  .individual-wine-details-image-not-present-container {
    margin-right: 5%;
    margin-top: 10%;
  }
  .individual-wine-details-image {
    width: 25%;
    margin-top: 5%;
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .individual-wine-details-container {
    justify-content: normal !important;
  }
  .individual-wine-details-content-container {
    width: 100%;
  }
}
.individual-wine-details-image-present-container {
  margin: 5%;
  margin-top: 2%;
  padding: 2%;
  margin-right: 5%;
}

.individual-wine-details-container-row {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.individual-wine-details-data-subcontainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.individual-wine-details-type-subcontainer,
.individual-wine-details-price-subcontainer {
  display: flex;
  flex-direction: column;
}

.individual-wine-details-wine-name {
  color: #2b2a2a;
  font-size: 1.25em !important;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  margin-bottom: 3%;
}

.accordion-item__icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC) !important;
  opacity: 1 !important;
  padding: 5%;
}

.accordion-rotate-item__icon {
  transform: rotate(180deg);
}

.individual-wine-detail-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.specifications-type-container {
  justify-content: space-between;
  margin-bottom: 2%;
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
}

.individual-wine-details-data-subcontainer,
.individual-wine-details-data-subcontainer {
  padding: 5%;
}

hr {
  position: relative;
  border: none;
  height: 1px;
  background: #cccccc;
  margin-top: 2% !important;
  margin-bottom: 0px !important;
  border: 0;
}

.individual-wine-details-type {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.individual-wine-details-url {
  color: #7d7d7d;
  font-size: 1em;
  font-style: italic;
}

.logo-container-header-individual-winedetails-page {
  width: 100%;
  display: flex;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.openbrowser-wineurl-image-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.winerylogo-container-ind-winedetails-page {
  display: flex;
  justify-content: flex-start;
}

.winerylogo-ind-winedetails-page {
  width: 30%;
  border-radius: 100px;
}

.wine-detail-add-to-cart-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 90%;
}

.individual-wine-details-scroll-container:after {
  content: "";
  display: block;
  height: 70px;
  width: 100%;
}

.dsktp-header {
  margin-top: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dsktp-header-title {
  margin-left: 3% !important;
  width: 100%;
  font-weight: 500 !important;
  font-size: 1.5em;
  color: #2b2a2a;
}

.individual-wine-image-card {
  position: relative;
  display: flex;
  flex-direction: row;
}

.box {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.wine-logo {
  flex-grow: 8;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.share {
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
}

.discount-like-box {
  display: flex;
  margin-left: 2%;
  margin-top: 2%;
}

.vimeoWrapper {
  padding-top: 10px;
  margin: 0 auto;
}
.wine-education-main-container {
  width: 100%;
  height: 100vh;
}

@media (min-width: 1025px) {
  .wine-education-main-container,
  .wine-education-container,
  .bottom-navigation {
    margin-top: 5%;
    overflow: hidden;
  }
  .wine-education-option-container {
    display: flex;
    justify-content: space-between;
    padding: none !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
  .wine-education-main-container {
    box-shadow: 0px 0px 15px 15px #d2d2d2;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 30% !important;
    height: 80vh !important;
    border: 2px solid #c0c0c0;
    overflow: auto;
  }
  .wine-education-header {
    width: 100% !important;
    height: 20% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .wine-education-text {
    margin: 5% !important;
    float: left;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .wine-education-main-container {
    height: 70vh !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .wine-education-card-personal-details-subheader {
    margin-bottom: 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wine-education-option-container {
    display: flex;
    justify-content: space-between;
    padding: 1% !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
}
.wine-education-header {
  width: 100%;
  height: 18%;
  background-color: #a84551;
  align-content: center !important;
}

.wine-education-text {
  margin: 7%;
  float: left;
  font-size: 2em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.wine-education-cart-btn {
  float: right;
  margin: 10%;
}

.wine-education-container {
  margin-top: -9vh;
  width: 100%;
  position: absolute;
  margin-bottom: 25%;
}

.wine-education-card-personal-details-subheader {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wine-education-row {
  bottom: 0;
}

.wine-education-right-arrow-btn {
  float: right;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.user-wine-education-pic-container {
  display: flex;
  justify-content: center;
}
.wine-education-card-container {
  overflow: auto;
}

.wine-education-card-type-subcontainer {
  display: flex;
  align-items: center;
}

.wine-education-card-title {
  color: #2B2A2A;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  font-weight: bold;
}

.wine-education-card-details {
  width: 100%;
}

.wine-education-card-title-container, .wine-education-card-type-subcontainer {
  margin-bottom: 5%;
}

.wine-education-card-subcontainer {
  display: flex;
  flex-direction: column;
}

.wine-education-card {
  width: 100%;
}

.wine-education-card-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wine-education-card-content {
  color: #7D7D7D;
  font-family: "poppins", sans-serif !important;
  font-size: 0.75em;
}

.wine-education-card-bottle-logo-container, .wine-education-card-bottle-logo {
  width: 100%;
}
.wine-news-feed-main-container {
  width: 100%;
  height: 100vh;
}

@media (min-width: 1025px) {
  .wine-news-feed-main-container,
  .wine-news-feed-container {
    margin-top: 5%;
    width: 30% !important;
    overflow: hidden;
  }
  .wine-news-feed-option-container {
    display: flex;
    justify-content: space-between;
    padding: none !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
  .wine-news-feed-main-container {
    box-shadow: 0px 0px 15px 15px #d2d2d2;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 30% !important;
    height: 80vh !important;
    border: 2px solid #c0c0c0;
    overflow: auto;
  }
  .wine-news-feed-header {
    width: 100% !important;
    height: 20% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }
  .wine-news-feed-text {
    margin: 5% !important;
    float: left;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .wine-news-feed-main-container {
    height: 70vh !important;
  }
}
@media (min-width: 300px) and (max-width: 400px) {
  .wine-news-feed-card-personal-details-subheader {
    margin-bottom: 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .wine-news-feed-option-container {
    display: flex;
    justify-content: space-between;
    padding: 1% !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
}
.wine-news-feed-header {
  width: 100%;
  height: 18%;
  background-color: #a84551;
  align-content: center !important;
}

.wine-news-feed-text {
  margin-left: 7%;
  margin-top: 7%;
  margin-right: 7%;
  float: left;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.wine-news-feed-cart-btn {
  float: right;
  margin: 10%;
}

.wine-news-feed-container {
  margin-top: -8vh;
  width: 100%;
  position: absolute;
  margin-bottom: 25%;
}

.wine-news-feed-row {
  bottom: 0;
}

.wine-news-feed-right-arrow-btn {
  float: right;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.wine-news-feed-header-container {
  display: flex;
  align-items: center;
}

.wine-news-feed-winery-name {
  margin-left: 7%;
  margin-bottom: 3%;
  background-color: none !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.winery-name-btn {
  border: none;
  color: black;
  padding: 0px 6px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 16px;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .wine-news-feed-text {
    font-size: 1.25em !important;
  }
}
.wine-news-feed-card-container {
  overflow: auto;
}

.wine-news-feed-card-type-subcontainer {
  display: flex;
  align-items: center;
}

.wine-news-feed-card-title {
  color: #2B2A2A;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  margin-left: 5%;
  font-size: 1.25em;
}

.wine-news-feed-card-details {
  width: 100%;
  padding: 2%;
  animation: 0.5s ContentFadeIn ease-out 0s 1 forwards;
}

.wine-news-feed-card-title-container, .wine-news-feed-card-type-subcontainer {
  margin-bottom: 5%;
}

.wine-news-feed-card-subcontainer {
  display: flex;
  flex-direction: column;
}

.wine-news-feed-card {
  width: 100%;
}

.wine-news-feed-card-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wine-news-feed-card-content {
  color: #7D7D7D;
  font-family: "poppins", sans-serif !important;
  font-size: 0.75em;
}

.wine-news-feed-card-feed-logo {
  width: 50px;
  height: 40px;
  border-radius: 50%;
}

.wine-news-feed-card-header {
  display: flex;
  align-items: center;
}

.wine-news-feed-card-container {
  margin-top: 3%;
}
.close-btn {
  margin-top: 8px;
  background: #efefef;
  border: none;
  border-radius: 50%;
  color: #7d7d7d;
  min-height: 35px;
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
  text-align: center;
  outline: none;
  height: 35px;
  width: 35px;
  position: absolute;
  top: 5%;
  right: 5%;
}

.close-btn:active,
.close-btn:focus {
  border-style: outset;
  outline: none;
}

.close-btn:hover {
  cursor: pointer;
}

.transition {
  animation: 0.6s slide-in;
}

@keyframes slide-in {
  0% {
    transform: translate(50%, 0%);
  }
  100% {
    transition: transform 1000ms ease-in-out;
  }
}
.tastePreferenceCard {
  display: block;
  margin-top: 46px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  width: 458px;
}

.mainCard {
  height: 90vh !important;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f5f5f5;
  box-shadow: 0px 3px 200px -15px #989898;
  border-radius: 25px !important;
}

.main {
  position: relative;
  top: -35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image1 {
  height: auto;
  width: auto;
}

.card-style {
  animation: fadeIn 0.2s forwards;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  width: 45%;
  border-radius: 5px !important;
  cursor: pointer;
}

.fade_Out {
  animation: fadeOut 0.2s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
.card-text {
  display: flex;
  align-items: center;
  height: 22px;
  margin-left: 5px;
  vertical-align: middle;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
}

.card-image {
  width: 100%;
}

.titleContainer {
  margin-top: 10%;
  display: flex;
  white-space: nowrap;
  margin-left: 10px;
}

.questionNumber {
  font-weight: 700;
  font-size: 11px;
  line-height: 10px;
  text-transform: uppercase;
  color: #951901;
}

.title {
  margin-left: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 10px;
  color: #7d7d7d;
}

.progress-bar {
  background-color: #951901;
}

.bar {
  width: 150px;
  margin-top: 5px;
  margin-left: 10px;
  background-color: #efefef;
  height: 0.5rem;
  min-height: 0.5rem;
  border-radius: 0px;
}

.AppTitle {
  margin: 20px 0 10px 0;
  position: relative;
  top: -20px;
  width: 350px;
  margin-left: 10px;
  font-size: 31px;
  font-weight: 800;
  line-height: 33px;
  text-align: left;
  padding: none;
}

.selectTitle {
  position: relative;
  top: -30px;
  font-size: 14px;
  font-weight: 500;
  color: #951901;
  margin: 20px 0 10px 10px;
}

.additionalOption {
  display: none;
  position: relative;
  top: -25px;
  margin-left: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  text-decoration: underline;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: #951901;
}

.continueBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 360px;
  height: 52px;
  cursor: pointer;
  background: #951901;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

@media (max-width: 1025px) {
  .tastePreferenceCard {
    content: unset !important;
    display: unset !important;
    height: unset !important;
    width: unset !important;
  }
  .mainContainer {
    margin-top: 20px;
  }
  .mainCard {
    height: 100% !important;
    margin-top: 40px !important;
    background-color: white;
    box-shadow: none;
    border-radius: none !important;
  }
  .titleContainer, .bar, .AppTitle, .selectTitle {
    margin-left: 8%;
  }
  .card-style {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: 30%;
  }
}
@media (max-width: 550px) {
  .mainContainer {
    margin-top: 10px;
  }
  .mainCard {
    margin-top: 20px !important;
  }
  .AppTitle {
    font-size: 28px;
    line-height: 30px;
    width: 290px;
    margin: 20px 0 10px 0;
  }
  .titleContainer, .bar, .AppTitle, .selectTitle {
    margin-left: 5%;
  }
  .card-style {
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
    width: 40%;
  }
  .card-text {
    font-size: 10px !important;
  }
  .additionalOption {
    top: -30px;
    font-size: 10px;
  }
  .continueBtn {
    width: 70%;
    height: 42px;
    font-size: 12px;
  }
}
@media (max-width: 385px) {
  .mainCard {
    margin-top: 5px !important;
  }
  .AppTitle {
    font-size: 25px;
    line-height: 25px;
    width: 280px;
    margin: 20px 0 10px 0;
  }
  .card-style {
    margin-left: 10px;
    margin-right: 10px;
    width: 40%;
  }
  .card-text {
    font-size: 8px !important;
  }
}
@media (max-width: 320px) {
  .close-btn {
    margin-left: 85%;
  }
  .AppTitle {
    width: 230px;
    font-size: 20px;
    margin: 20px 0 10px 0;
  }
}
@media (min-width: 1025px) {
  .date-of-birth-check-text2 {
    margin-top: 1% !important;
  }
  .date-of-birth-check-main-container {
    box-shadow: 0px 3px 200px -15px rgba(146, 143, 143, 0.3843137255);
    position: relative;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 430px !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    border-radius: 25px;
    overflow: auto;
    height: 95% !important;
  }
  .logo-container {
    padding: 1% !important;
  }
  .confirm-dob-btn {
    margin-left: 5%;
    margin-bottom: 5%;
  }
}
.date-of-birth-check-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: -2rem;
}

.date-of-birth-check-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.date-of-birth-check-text1 {
  color: #2b2a2a;
  font-weight: bold;
  font-size: 1.4em;
  font-family: "poppins", sans-serif !important;
}

.date-of-birth-check-text2 {
  color: #2b2a2a;
  font-weight: 500;
  font-size: 1.3em;
  font-family: "poppins", sans-serif !important;
  margin-top: 4%;
  text-align: center;
}

@media (min-width: 300px) and (max-width: 400px) {
  .date-of-birth-check-text2 {
    color: #2b2a2a;
    font-weight: 500;
    font-size: 1.25em !important;
    font-family: "poppins", sans-serif !important;
  }
}
@media only screen and (min-width: 300px) and (max-height: 400px) {
  .confirm-dob-btn {
    bottom: 4% !important;
  }
}
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .date-of-birth-check-container {
    margin-top: 1% !important;
  }
  .date-of-birth-check-text2 {
    color: #2b2a2a;
    font-weight: 500;
    font-size: 1em !important;
    font-family: "poppins", sans-serif !important;
  }
}
.date-of-birth-check-text-subcontainer {
  margin-top: 5%;
}

.date-of-birth-check-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dob-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dob-error-message-text {
  color: red;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em !important;
}

.dob-screen-pairanything-logo-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.dob-screen-pairanything-logo {
  width: 30%;
}

.submit-dob-container {
  justify-content: center;
  display: contents;
  align-items: center;
  margin-bottom: 10%;
}

.dob-check-picker {
  font-size: 1.5em !important;
}

input[type=date]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}

.confirm-dob-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 90%;
  margin-left: 5%;
  opacity: 1 !important;
}

.logo-container {
  margin-bottom: -1rem !important;
}

@media screen and (min-width: 1025px) and (max-height: 800px) {
  .date-of-birth-check-main-container {
    height: 95% !important;
  }
}
.dob-verification-container-padding {
  padding: 10px 20px 10px 20px;
}

.dob-before-margin-bottom {
  margin-bottom: 6px !important;
}

.dob-msg-css {
  font-size: 11px;
  float: right;
  color: #b1abab;
  margin-top: 3px;
}
@media (min-width: 1025px) {
  .height-for-terms-page {
    height: 80vh !important;
  }
}
.height-for-terms-page {
  padding: 10px 20px 10px 20px !important;
}

.all-terms-header-font {
  font-weight: bold;
  color: #2B2B2B;
  font-size: 17px !important;
}

.terms-btns-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.terms-accept-click {
  background: #a84551 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  color: white;
}

.terms-accept-click:hover {
  background: #a84551 0% 0% no-repeat padding-box;
  color: white;
}

.terms-cancel-click {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #a84551;
  border-radius: 2px;
  color: #a84551;
}

.terms-cancel-click:hover {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #a84551;
}

.terms-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.terms-conditions-justify {
  text-align: justify;
}

.p-terms-width {
  width: 33.33%;
}

.term-header-label {
  font-size: 17px !important;
  font-weight: bold;
}
.select-winery-main-container {
  width: 100%;
  height: 100vh;
}

.select-winery-pair-anything-logo {
  width: 30%;
  margin-top: 4%;
}

.select-winery-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.select-winery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .select-winery-pair-anything-logo {
    width: 20% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .select-winery-header {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
  .select-winery-title-container {
    margin-top: 3% !important;
    margin-bottom: 2% !important;
  }
}
@media (min-width: 1025px) {
  .select-winery-main-container,
  .select-winery-container {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .select-winery-title-container {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
  .select-winery-pair-anything-logo {
    width: 12% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }
  .select-winery-main-container {
    overflow: auto;
  }
  .select-winery-header {
    width: 100% !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    background-color: #3f3d56 !important;
    align-content: center !important;
  }
}
.select-winery-header {
  width: 100%;
  height: 16%;
  background-color: #3f3d56;
  align-content: center !important;
}

.select-winery-title {
  font-size: 1.5em;
  color: #000;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.select-winery-container {
  width: 100%;
  position: absolute;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
}

.select-winery-container:after {
  content: "";
  display: block;
  height: 130px;
  width: 100%;
}

.select-winery-subheader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-winery-container {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.error-data-from-selected-winery-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: absolute;
  margin-bottom: 5%;
}

.error-data-from-selected-winery {
  color: #a84551;
  text-align: center;
  font-size: 1.5em;
  font-family: "poppins", sans-serif !important;
}

.horizontal-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 1em;
}

.display-horizontal {
  flex: 0 0 auto;
}

.valid-text {
  color: #959292;
  font-size: 12px;
}

.promo-title {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
}

.discover-container {
  width: 100%;
  position: absolute;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
}

.discover-container {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}
.render-flex {
  display: flex !important;
  align-items: center;
  margin-top: 7px;
}

.strike-text {
  color: #4f4f4f;
  padding-left: 5px;
  font-size: 0.8em;
}

.-wine-price-text {
  color: #4f4f4f;
  font-weight: bold;
  font-size: 0.8em;
}

.badge-place {
  position: absolute;
  margin-top: 2%;
  right: 0;
  margin-right: -12px;
}

.badge-text {
  top: 0;
  position: absolute;
  right: 0;
  padding: 5px;
  margin: 7px;
  font-size: 12px;
  color: #ff0000;
  border: solid 2px #ff0000;
  background-color: white;
}

.badge-text-avail {
  position: relative;
  top: 2px;
  padding: 5px;
  font-size: 12px;
  color: #ff0000;
  border: solid 2px #ff0000;
  background-color: white;
}

.badge-text-desktop {
  top: 0;
  position: absolute;
  right: 0;
  padding: 5px;
  margin: 1em;
  font-size: 12px;
  color: #ff0000;
  border: solid 2px #ff0000;
  background-color: white;
}

.deal-name {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.deal-margin {
  margin-top: 10px;
}

.spot-winery {
  display: flex;
}

.spot-winery-name {
  margin-top: 10px;
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 10px;
  font-weight: 400;
  height: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deal-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  height: 65px;
  width: 100%;
}

.spot-winery-logo {
  width: 20px;
  height: 20px;
  margin-right: 2%;
  border-radius: 50%;
}

.spot-wine-type {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 9px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.spot-wine-bottle-logo {
  height: auto;
  max-height: 100px;
  width: auto;
  max-width: 100px;
}

.spot-wine-bottle-logo-from-api {
  height: auto;
  max-height: 100px;
  width: auto;
  max-width: 100px;
}

.spot-wine-card {
  width: 80% !important;
}

.spot-wines-view-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 12px !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
  padding: 3px;
}

@media (min-width: 1025px) {
  badge-place {
    position: absolute;
    right: 0;
    margin-right: -4%;
  }
  .deal-name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
    height: 55px;
    width: 100%;
  }
  .deal-name {
    color: #2b2a2a;
    font-family: "poppins", sans-serif !important;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .spot-wine-card {
    width: 80% !important;
  }
  .spot-wine-bottle-logo {
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
  }
  .spot-wine-bottle-logo-from-api {
    height: auto;
    max-height: 100px;
    width: auto;
    max-width: 100px;
  }
}
.spot-card {
  height: 300px;
  transition: 0.3s;
  background-color: #fff;
  border-radius: 0 !important;
  border-radius: 10px !important;
  margin: 1em 0.6em !important;
  padding: 1em !important;
  box-shadow: 1px rgba(29, 29, 29, 0.1019607843) !important;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
@media (min-width: 1025px) {
  .select-winery-card-card {
    width: 15% !important;
  }
  .select-winery-card-display {
    width: 40%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .select-winery-card-display-mob {
    width: 40%;
  }
  .select-winery-card-card-disabled {
    width: 15% !important;
    opacity: 0.5;
  }
  .select-winery-bg {
    background: #f2f2f2;
  }
}
.select-winery-card-display {
  width: 40%;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.select-winery-card-name {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-winery-card-name::first-letter {
  text-transform: capitalize;
}

.select-winery-card-details {
  width: 100%;
}

.select-winery-card-price-text {
  color: #a84551;
  font-weight: bold;
}

.select-winery-card-details-row {
  bottom: 0;
}

.select-winery-card-name-container {
  margin-bottom: 5%;
}

.select-winery-card-details-data-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-winery-card-bottle-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.select-winery-card-bottle-logo {
  width: 50%;
}

.select-winery-card-bottle-logo-from-api {
  width: 80%;
  max-height: 100px;
  object-fit: contain;
  background-repeat: no-repeat;
}

.select-winery-card-subcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.select-winery-card-card {
  width: 40%;
}

.select-winery-card-card-disabled {
  width: 40%;
  opacity: 0.5;
}

.select-winery-card-name-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
}

.select-wineries-heading-div {
  display: flex;
  justify-content: center;
}

.select-wineries-heading {
  padding-top: 5px;
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #0f0e0e;
  opacity: 1;
}

.select-winery-bg {
  height: 100%;
  width: 100%;
}

.select-winery-card-display-mob {
  width: 40%;
}

.select-type {
  text-align: center;
}

.selected-type {
  text-align: center;
  color: #a84551;
}

.selected-winery-type-card {
  border: 2px solid #a84551;
}
.shopping-cart-container {
  width: 100%;
  height: 100vh;
}

.current-shipping-address {
  display: flex;
  padding: 12px;
  background: #3f3d56 0% 0% no-repeat;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1607843137);
  opacity: 0.8;
}

.shipping-location {
  color: white;
  margin-left: 2rem;
  font-size: 1.5rem;
}

.empty-cart {
  margin-top: 17vh;
}

.empty-cart-error-msg-div {
  text-align: center;
}

.shopping-cart-heading {
  margin-right: 11rem;
  margin-left: 0%;
}

.dot-cart-stepper {
  background-color: transparent;
  border-radius: 50%;
  /* display: inline-block; */
  /* top: 14px; */
  /* display: flex; */
  /* align-items: center; */
  /* vertical-align: middle; */
  /* text-align: center; */
  margin-top: 0px;
  border: 3px solid #bbb;
  margin-left: -5px;
  height: 12px;
  width: 12px;
}

.dot-cart-stepper-select {
  background-color: transparent;
  border-radius: 50%;
  /* display: inline-block; */
  /* top: 14px; */
  /* display: flex; */
  /* align-items: center; */
  /* vertical-align: middle; */
  /* text-align: center; */
  margin-top: 0px;
  border: 3px solid #1d4403;
  margin-left: -5px;
  height: 12px;
  width: 12px;
}

.dot-cart-non-select-stepper {
  background-color: #bbb;
  border-radius: 50%;
  /* display: inline-block; */
  /* top: 14px; */
  /* display: flex; */
  /* align-items: center; */
  /* vertical-align: middle; */
  /* text-align: center; */
  margin-top: 0px;
  margin-left: -5px;
  height: 12px;
  width: 12px;
}

.line:after {
  right: 0;
}

.separator-stepper {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "poppins", SemiBold 13px/24px;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  width: 65%;
}

.separator-stepper::before {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #9a9a9a;
}

.separator-stepper::before {
  margin-right: 0.25em;
}

.separator-stepper::after {
  margin-left: 0.25em;
}

.cart-name-new-css {
  color: #3b3b3b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}

.separator-stepper-select {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "poppins", SemiBold 13px/24px;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  width: 65%;
}

.separator-stepper-select::before {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #1d4403;
}

.separator-stepper-select::before {
  margin-right: 0.25em;
}

.separator-stepper-select::after {
  margin-left: 0.25em;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "poppins", SemiBold 13px/24px;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #3b3b3b;
}

.separator::before {
  margin-right: 0.25em;
}

.separator::after {
  margin-left: 0.25em;
}

.cart-total {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  z-index: 5;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  background: #ffffff 0% 0% no-repeat padding-box;
}

.cart-total-value {
  /* padding: 7px 0px 1px 15px; */
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  width: 50%;
  /* height: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9px 10px 9px 17px;
}

.total-price-label {
  text-align: left;
  font-family: "poppins", SemiBold 16px/24px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.cart-proceed-div {
  background: #a84551 0% 0% no-repeat padding-box;
  opacity: 1;
  /* text-align: center; */
  /* margin-left: auto; */
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.proceed-to-next {
  transform: rotate(180deg);
  padding-right: 1rem;
  /* margin-bottom: 0.5rem; */
}

.proceed-lable {
  color: #ffffff;
  opacity: 1;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.continue-button-container {
  bottom: 0;
  position: fixed;
  align-items: center;
  width: 100%;
  font-family: "poppins", sans-serif;
  text-align: center;
}

.continue-button {
  color: #ffffff;
  background-color: #a84551;
  width: 95%;
  padding: 10px;
  font-size: 1.2em;
  letter-spacing: 0.5px;
}

.total-display {
  font-family: "poppins", SemiBold 16px/24px;
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  padding: 6px 0px;
  font-weight: 600;
  font-size: 1.6rem;
  width: 95%;
}

.total-label {
  font-size: 15px;
  color: #2b2b2b;
}

.total-amount {
  margin-left: auto;
  letter-spacing: 0.5px;
}

.new-empty-cart-width {
  width: 70% !important;
}

.new-shpng-cart-pages-dsktp-main-container-changes {
  background: #f2f2f2 0% 0% no-repeat padding-box !important;
}

@media (min-width: 1025px) {
  .continue-button-container {
    bottom: 0;
    position: fixed;
    align-items: center;
    width: 100%;
    font-family: "poppins", sans-serif;
    width: 430px !important;
    text-align: center;
    margin-bottom: 5.5vh !important;
  }
  .new-shpng-cart-pages-dsktp-main-container-changes {
    height: 100vh !important;
    margin-top: 0 !important;
    width: unset !important;
  }
  .empty-cart {
    margin-top: 10vh;
  }
  .new-empty-cart-width {
    width: 35% !important;
  }
  .first-stepper-css {
    width: 28% !important;
  }
  .second-stepper-css {
    width: 25% !important;
  }
  .final-stepper-css {
    width: 61% !important;
  }
}
@media (min-width: 768px) {
  .cart-stepper-header {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 5% 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.flex-prop {
  display: flex;
  flex-direction: column;
}

.new-cntr-dsgn {
  padding: 0% 3% 3% 3% !important;
  background: #f2f2f2 0% 0% no-repeat padding-box !important;
}

.cart-stepper-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 5% 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.stepper-label-non-select {
  color: #9a9a9a;
}

.promo-code-div {
  bottom: 66px;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  z-index: 5;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px -6px 10px rgba(154, 154, 154, 0.2);
  padding: 10px 17px 10px 17px;
  color: #2b2b2b;
  font-size: 14px;
}

.promo-right-icon-div {
  margin-left: auto;
  width: 8.5px;
}

.completed-cart-stepper {
  background-color: #1d4403 !important;
}

.first-stepper-css {
  width: 26%;
}

.second-stepper-css {
  width: 35%;
}

.final-stepper-css {
  width: 52%;
}
.cart-delete-icon {
  text-align: end;
}

.cart-delete-img {
  width: 2rem;
}

.cart-container {
  position: relative;
  padding-bottom: 10px;
  padding-top: 10px;
}

.new-wine-width {
  max-width: 60px;
  max-height: 60px;
}

.no-wine-image-width {
  width: 100% !important;
  padding: 7px;
}

.wine-bottle-desgin {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-wine-detail-container {
  width: 100%;
  /* margin: 1em !important; */
  box-shadow: 1px rgba(29, 29, 29, 0.1019607843) !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 2px 6px #00000029; */
  /* border-radius: 5px; */
  opacity: 1;
  /* border-bottom: 1px solid; */
  margin-top: 1.2em !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.ft-size-wine-details {
  font-size: 16px !important;
}

.wine-details {
  text-align: left;
  text-transform: capitalize;
  font-family: "poppins", sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0px;
  color: #2b2b2b;
  display: flex;
  flex-direction: column;
}

.flx-d-none {
  flex-direction: unset !important;
}

.wine-qty-details {
  /* text-align: left; */
  /* text-transform: capitalize; */
  font-size: 1.6rem;
  font-family: "poppins", sans-serif;
  letter-spacing: 0px;
  color: #2b2b2b;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.cart-qty-input {
  font-family: "poppins", sans-serif !important;
  text-align: center;
  width: 57px;
  font-size: 1.3rem;
  font-weight: 600;
}

.input-padding {
  padding: 6px 1px !important;
}

.price-div {
  margin-left: auto;
}

.cart-price {
  text-align: end;
  letter-spacing: 0px;
  color: #9a9191;
  opacity: 1;
  font-weight: 550;
  font-family: "poppins", sans-serif !important;
  font-size: 1.9rem;
}

.qty-label {
  color: #676262;
  font-size: 14px;
}

.cart-remove-wine {
  font-family: "poppins", sans-serif;
  letter-spacing: 0px;
  color: grey;
  opacity: 1;
  font-size: 1.9rem;
  font-weight: 600;
}

.sub-titles {
  color: grey;
  font-size: 1.6rem;
}

.qty-div {
  text-align: start;
}

.price-div {
  float: right;
}

.cart-delete {
  width: 4.6%;
  margin-left: auto;
}

.cart-input-div {
  text-align: end;
}

.sub-title-price {
  color: #a84551;
  font-size: 16px;
  font-weight: 600;
}

.wine-img-container {
  display: flex;
  flex-direction: row;
}

.wine-sub-titles-container {
  text-align: left;
  text-transform: capitalize;
  font-size: 1.9rem;
  font-family: "poppins", sans-serif;
  letter-spacing: 0px;
  color: #2b2b2b;
  display: flex;
  flex-direction: row;
}

.cart-delete-newqty-row-width {
  width: 100%;
}

.price-div-new {
  position: absolute;
  display: flex;
  bottom: 0;
  float: right;
  /* left: 0; */
  right: 0;
}

@media (max-width: 320px) {
  .sub-title-price {
    font-size: 14px;
  }
}
@media (max-height: 600px) {
  .wine-qty-details {
    margin-top: 0 !important;
  }
}
.ellipsis-wine-name {
  max-width: 20ch;
  /* overflow: hidden; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.qyt-drop-option-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  width: 52px;
  /* border: none; */
  /* height: 40px !important; */
  /* text-transform: capitalize; */
  /* border: 1px solid #1d1d1d1a; */
  /* padding-left: 2px; */
  padding: 2px 0px 2px 13px !important;
  height: 32px !important;
  margin-top: 1px;
}

.cross-sign {
  font-size: 13px;
  color: #000000;
}

.update-qty {
  margin-left: 77px;
  padding-top: 3px;
}

.update-qty-btn {
  font-size: 12px !important;
  padding: 6px 6px;
  background-color: #a84551;
}
.shipping-address-header-label {
  text-align: center;
  margin-bottom: 1em;
  color: #2b2b2b;
  font-weight: 700;
  font-size: medium;
}

.shipping-addresses {
  display: flex;
  flex-direction: row;
  margin-left: 1em;
  margin-right: 1em;
  color: #a84551;
  font-size: medium;
  font-weight: 600;
}

.add-new-address {
  margin-left: auto;
}

.add-new-icon {
  width: 16%;
  margin-bottom: 0.2em;
  margin-right: 0.2em;
}

.empty-data {
  text-align: center;
}

.stepper-new-margin {
  margin-bottom: 1em !important;
}

.address-select-error-div {
  bottom: 50px;
  width: 95%;
  position: fixed;
  transition: top 0.6s;
  z-index: 5;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  background: #F2F2F2 0% 0% no-repeat padding-box !important;
  /* box-shadow: 5px -6px 10px #9A9A9A33; */
  padding: 10px 17px 10px 17px;
  color: red;
  font-size: 16px;
  justify-content: center;
}
.payment-options-header-label {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  color: #2b2b2b;
  font-weight: 700;
  font-size: medium;
}

.saved-cards {
  display: flex;
  flex-direction: row;
  margin-left: 1em;
  margin-right: 1em;
  color: #a84551;
  font-size: medium;
  font-weight: 600;
}

.add-new-card {
  margin-left: auto;
}

.grand-total-card {
  display: flex;
  padding: 12px;
  background: #3f3d56 0% 0% no-repeat;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1607843137);
  opacity: 0.8;
  letter-spacing: 0.5px;
}

.grand-total-value {
  color: white;
  font-size: 1.5rem;
}

.grand-total-div {
  margin-left: auto;
}

/**
* The CSS shown here will not be introduced in the Quickstart guide, but shows
* how you can use CSS to style your Element's container.
*/
.card-value {
  vertical-align: middle;
}
.popup-label {
  font-family: "poppins", SemiBold 14px/21px sans-serif;
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 1.9rem;
}

:root {
  --borderWidth: 3px;
  --height: 21px;
  --width: 10px;
  --borderColor: white;
}

.check {
  display: inline-block;
  transform: rotate(45deg);
  height: var(--height);
  width: var(--width);
  border-bottom: var(--borderWidth) solid var(--borderColor);
  border-right: var(--borderWidth) solid var(--borderColor);
}

.add-to-cart-popup-container {
  display: flex;
  flex-direction: row;
}
.help-page-header {
  width: 100%;
  /* align-content: center !important; */
  background-color: #193c38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 7%;
  padding-bottom: 15%;
  position: absolute;
}

.help-header-text {
  font-size: 1.5em !important;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-left: 7%;
}

.help-page-sub-header {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
  align-items: center;
}

.help-page-pair-anything-logo {
  width: 15%;
  margin-right: 5%;
  margin-left: auto;
}

.help-page-header-content {
  text-align: center;
}

.new-margin-and-css {
  margin-left: 10% !important;
  margin-right: 13%;
  /* font-size: 1.2em; */
  font-weight: 500 !important;
  margin-top: 2%;
  font-size: 1.1em;
}

.you-tube-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.help-section {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.youtube-responsive-container-help-page {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.bottom-scroll-padding {
  padding-top: 60px;
}

.youtube-video-container {
  display: flex;
  flex-direction: row;
}

.youtube-responsive-container-help-page iframe,
.youtube-responsive-container-help-page object,
.youtube-responsive-container-help-page embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.you-tube-card,
.you-tube-card2 {
  border-radius: 10px !important;
  /* margin: 1em!important; */
  padding: 1em !important;
  border: 2px solid rgba(0, 0, 0, 0.125);
  /* background-clip: border-box; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: relative;
  background-color: #fff;
}

.link-header {
  font-size: medium;
  font-weight: 500;
  margin-top: 0.5em;
}

.link-sub-header {
  font-size: small;
  font-weight: 400;
}

.resp-margin-css-to-overlap-with-header {
  margin-top: 1%;
}

@media only screen and (max-width: 1024px) {
  .resp-margin-css-to-overlap-with-header {
    margin-top: 1%;
  }
  .youtube-video-container {
    display: flex;
    flex-direction: column !important;
  }
}
.contact-us-container-help {
  margin-top: 15px;
  color: #2b2a2a;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.contact-us-contents-help {
  margin-top: 10px;
  white-space: pre-wrap;
  color: #959292;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.help-thin-divider-line {
  height: 1px;
  background-color: #959292;
  margin-top: 10px;
}

.help-page-text-info {
  font-size: 1.5em;
  font-weight: 500;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
}

@media (min-width: 1025px) {
  .help-page-header-content {
    text-align: center;
  }
  .help-section {
    margin-left: 10% !important;
    width: 80% !important;
  }
  .you-tube-card {
    width: 48% !important;
  }
  .you-tube-card2 {
    width: 48% !important;
    margin-left: 2% !important;
  }
  .resp-margin-css-to-overlap-with-header {
    margin-top: 20% !important;
  }
  .help-page-pair-anything-logo {
    width: 7% !important;
  }
  .back-btn-recommended-wines {
    width: 1.5%;
    margin-left: 2% !important;
  }
  .help-header-text {
    margin-left: 3% !important;
  }
  .new-margin-and-css {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }
  .help-page-header {
    padding-top: 1% !important;
    padding-bottom: 1% !important;
  }
}
.empty-state-master-container-myevents {
  margin-top: 50%;
}
.search-wine-for-dish-main-desktop {
  width: 100%;
}

.search-box-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1%;
}

.dropText {
  padding-left: 15px;
  font-style: italic;
  color: #000;
  font-family: "poppins", sans-serif !important;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.thin-divider-line-recommendations {
  background: #cccccc;
  height: 1px;
  margin-top: 1%;
}

.search-wine-for-dish-container-desktop {
  width: 35%;
  position: absolute;
  overflow: auto !important;
  display: block;
  margin-top: 1%;
  margin-left: 1%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  height: 75vh;
}

.search-btn-desktop {
  color: white;
  background: #a84551;
  margin-left: 15px;
  height: 40px;
  width: 100px;
}

.search-btn-desktop:hover {
  color: white !important;
  text-decoration: none;
}

.search-wine-form-row-input-desktop {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 40px !important;
  text-transform: capitalize;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
  display: flex;
  align-items: center;
  width: 93%;
}

.search-wine-form-row-recommand-input-desktop {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: auto;
  text-transform: capitalize;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
  align-items: center;
  width: 93%;
}

.search-wine-form-tab-desktop-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 50px !important;
  text-transform: capitalize;
  display: flex;
  width: 93%;
}

.search-dish-header-text {
  margin-top: 1%;
  margin-left: 3%;
  color: #727272;
  opacity: 1;
  font-size: 18px;
  font-family: "poppins", sans-serif !important;
}

.recommendations-desktop-main-container {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 90px;
}

.pairing-recommendations-main-header {
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  margin-top: 2%;
  margin-left: 2%;
}

.recommended-wines-desktop-main-container {
  margin-left: 35%;
  width: 65%;
  height: 75vh;
  overflow-y: auto;
}

.dsk-err {
  margin-top: 10%;
  width: 100% !important;
}
.recommended-wines-main-container-desktop {
  width: 95%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 2.5%;
}

.recommendations-container-desktop {
  width: 100%;
  border: 1px solid #cccccc;
}

.recommended-wines-result-container {
  margin-left: 3%;
}

.recommendations-response-container-desktop {
  margin-top: 3%;
  margin-bottom: 3%;
  width: 100%;
}

.recommendations-response-text-desktop {
  color: #727272;
  font-family: "poppins", sans-serif !important;
  font-size: 18px;
  margin-left: 5%;
  margin-right: 5%;
  display: block;
  word-break: break-word;
  font-style: italic;
}
.active-order-heading {
  padding: 4%;
  display: flex;
  justify-content: center;
}

.order-history-heading {
  padding: 2% 4% 4% 4%;
  display: flex;
  justify-content: center;
}

.order-history-container:after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}
.order-details-history-header {
  margin: 0em 1em 0.5em 1em;
  display: flex;
  flex-direction: row;
}

.order-details-history-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0em 1em 0.5em 1em;
  border-radius: 10px !important;
  border: 1px solid rgba(222, 216, 216, 0.125);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 11px;
  padding-bottom: 11px;
}

.history-status {
  display: flex;
  justify-content: center;
}

.history-wine-name-div {
  margin: 0em 1em 0.5em 1em;
  display: flex;
  flex-direction: row;
  color: #727272;
  font-size: small;
}

.history-wine-date-div {
  margin: 0em 1em 0em 1em;
  display: flex;
  flex-direction: row;
  color: #a84551;
  font-size: small;
  letter-spacing: 0.5px;
}

.winery-name-container {
  margin: 0em 1em 0.5em 1em;
  display: flex;
  flex-direction: row;
  color: #5d5d5d;
  font-weight: 550;
}

.order-hist-winery-name {
  font-weight: bold;
}

.order-hist-lbl-colour {
  color: #6A6A6A;
}

.shpmt-shipped-lbl {
  color: rgba(8, 203, 236, 0.8392156863);
}
.order-receipt-status-img {
  display: flex;
  justify-content: center;
  min-width: 26%;
}

.order-receipt-details-header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: center;
  margin: 1em;
  font-size: 1.5rem;
}

.receipt-wine-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0em 1em 1em 1em;
  border-radius: 10px !important;
  border: 1px solid rgba(222, 216, 216, 0.125);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.card-bottom {
  border-bottom: 1px solid #d6cece;
}

.order-details-img-wine-details-div {
  display: flex;
  flex-direction: row;
  margin: 1em 1em 1em 1em;
}

.receipt-wine-details-div {
  display: flex;
  flex-direction: column;
  /* margin-left: auto; */
  width: 65%;
  padding: 10px;
  line-height: 1.9;
}

.receipt-win-img-div {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.receipt-details-wine-img {
  width: 80%;
}

.receipt-details-wine-empty-img {
  width: 100%;
}

.receipt-winery-name {
  color: #727272;
}

.receipt-placed-on-div {
  display: flex;
  /* justify-content: center; */
  flex-direction: row;
  /* justify-content: center; */
  margin: 0em 1em 1em 1em;
}

.margin-split-container {
  margin-left: 1em;
  margin-right: 1em;
}

.receipt-qty-breakup-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
}

.receipt-paid-via-div {
  display: flex;
  flex-direction: row;
  margin-left: 1em;
  margin-right: 1em;
}

.ln-hght {
  line-height: 1.9;
}

.ft-size-receipts {
  font-size: 1.5rem;
}

.rcpt-details-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0em 1em 0.5em 1em;
  border-radius: 10px !important;
  border: 1px solid rgba(222, 216, 216, 0.125);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 11px;
  padding-bottom: 11px;
}

.card-container-details {
  margin: 0em 1em 0em 1em;
  display: flex;
  flex-direction: row;
}

.order-s-padding {
  padding-top: 11px;
}

.order-summary-details-container::after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}

@media (min-width: 1025px) {
  .receipt-details-wine-img {
    width: 24% !important;
  }
  .receipt-win-img-div {
    width: 20% !important;
  }
  .receipt-details-wine-empty-img {
    width: 40% !important;
  }
}
@media (min-width: 768px) {
  .receipt-details-wine-img {
    width: 41% !important;
  }
  .receipt-win-img-div {
    width: 20% !important;
  }
  .receipt-details-wine-empty-img {
    width: 49% !important;
  }
}
@media (min-width: 540px) {
  .receipt-details-wine-img {
    width: 28% !important;
  }
  .receipt-win-img-div {
    width: 20% !important;
  }
  .receipt-details-wine-empty-img {
    width: 74% !important;
  }
}
@media (min-width: 1280px) {
  .receipt-details-wine-empty-img {
    width: 40% !important;
  }
}
@media (min-width: 1024px) {
  .receipt-details-wine-empty-img {
    width: 49% !important;
  }
}
@media (min-width: 1440px) {
  .receipt-win-img-div {
    width: 12% !important;
  }
  .receipt-details-wine-empty-img {
    width: 53% !important;
  }
}
.shipping-address-details-history-container::after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}

.order-details-shpng-add-container {
  margin: 0em 1em 0em 1em;
  display: flex;
  flex-direction: column;
}

.shpng-address-inner-container {
  margin-bottom: 0.5em;
}

.tick-icon {
  width: 25px;
}

.shpmt-open-lbl {
  color: rgba(12, 8, 236, 0.768627451);
}

.shpmt-transit-lbl {
  color: rgba(236, 139, 8, 0.8392156863);
}

.shpmt-cancld-lbl {
  color: rgba(206, 24, 24, 0.7803921569);
}

.shpmt-delvrd-lbl {
  color: rgba(52, 206, 24, 0.6588235294);
}
.desktop-av-wines-main {
  margin-top: 7% !important;
}

.search-wine-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 40px !important;
  text-transform: capitalize;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
  display: flex;
  align-items: center;
  width: 85%;
}
.new-sing-up-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.social-login-container {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 600px) {
  .social-login-container {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 600px) {
  .social-login-container {
    display: flex;
    flex-direction: column;
  }
}
.my-facebook-button-class {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(0.27548vw + 12.71074px);
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px);
  width: 100%;
}
.my-facebook-button-class .fa {
  margin-right: calc(0.34435vw + 3.38843px);
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.padng-style {
  padding: 10px 20px 10px 20px;
}

.back-btn-width {
  width: 18px;
}

.three-space-even-width {
  width: 33.33%;
}

.new-sign-up-logo {
  display: flex;
  margin-left: auto;
  width: 65%;
}

.sign-up-title {
  display: flex;
  color: #2b2a2a;
  justify-content: center;
  font-size: 1.5em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}

.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
}

.new-sing-up-address-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.new-sing-up-address-form {
  width: 100%;
  /* margin-bottom: 7%; */
}

.singup-form-card {
  margin: 1em 0em !important;
}

.chck-box-label {
  padding-left: 10px;
  color: #6f6c6c;
  font-size: 13px;
  font-family: "poppins", sans-serif !important;
  font-weight: normal !important;
  display: inline !important;
}

.overall-font {
  font-family: "poppins", sans-serif !important;
}

.alrdy-sing-in-text {
  color: #a84551;
  font-weight: 550;
}

.already-div {
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-sign-upform-error-shipping-address-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1% 0%;
  text-align: center;
}

.common-form-error-text {
  color: red;
  font-size: 1.25em !important;
}

.new-sign-up-input-design {
  border: 1px solid #cdcdcd !important;
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
}

.new-signup-label {
  color: #353030;
}

@media screen and (min-width: 1025px) and (max-height: 800px) {
  .padng-style {
    height: 85vh !important;
  }
}
.new-paddin-style-logo-header {
  padding: 5% 5% 5% 5% !important;
}

.new-pa-logo-width {
  width: 36%;
}

.new-sign-up-title {
  display: flex;
  align-items: center;
  color: #2b2a2a;
  justify-content: center;
  font-size: 1.4em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}

.dob-fnt-size {
  font-size: 0.9em !important;
}

.chckbox-input-style {
  display: flex;
  float: left;
}

.label-chckbx-style {
  text-align: justify;
}

.new-error-msg-font {
  font-size: 1.1em !important;
}

.new-grey-font {
  color: #6f6c6c;
}

.new-input-form-height {
  height: 38px !important;
  font-size: 1.1em !important;
}

.txt-transform-none {
  text-transform: none !important;
}

.signup-button-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.signup-button {
  width: 50% !important;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1.1 !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.terms-and-conditions {
  margin-top: 5px;
  text-align: justify;
  color: #6f6c6c;
  font-size: 13px;
  font-family: "poppins", sans-serif !important;
  font-weight: normal !important;
  display: inline !important;
}

.passErrorFont {
  font-size: 13px !important;
}
.facebook-btn {
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  width: 149px;
  height: 45px;
  color: #fff;
  justify-content: space-around;
  align-items: center;
  border: none;
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  background: #475993 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}
.facebook-btn .metro {
  width: 149px;
  color: #fff;
  height: 45px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: none;
  text-align: left;
  font: normal normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  background: #475993 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

@media (min-width: 300px) and (max-width: 375px) {
  .facebook-btn {
    display: flex;
    width: 130px !important;
    height: 45px;
    color: #fff;
    justify-content: space-around;
    align-items: center;
    border: none;
    text-align: left;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #475993 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }
  .facebook-btn .metro {
    width: 130px !important;
    color: #fff;
    height: 45px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: none;
    text-align: left;
    font: normal normal normal 14px/21px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #475993 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
  }
}
.new-sing-up-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.sign-up-btn {
  transition: opacity 0.5s ease 0s;
  width: 100% !important;
  font-size: 14px !important;
  height: 45px;
  letter-spacing: 0px;
  border-radius: 3px;
  color: #ffffff;
  opacity: 1;
}

.social-login-container {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 600px) {
  .social-login-container {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 600px) {
  .social-login-container {
    display: flex;
    flex-direction: column;
  }
}
.term-cond {
  font-size: 12px !important;
}

.my-facebook-button-class {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(0.27548vw + 12.71074px);
  text-decoration: none;
  text-transform: uppercase;
  transition: none;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px);
  width: 100%;
}
.my-facebook-button-class .fa {
  margin-right: calc(0.34435vw + 3.38843px);
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.padng-style {
  padding: 10px 20px 10px 20px;
}

.back-btn-width {
  width: 18px;
}

.three-space-even-width {
  width: 33.33%;
}

.new-sign-up-logo {
  display: flex;
  margin-left: auto;
  width: 65%;
}

.sign-up-title {
  display: flex;
  color: #2b2a2a;
  justify-content: center;
  font-size: 1.5em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}

.new-sing-up-address-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100vh; */
  overflow: auto;
}

.new-sing-up-address-form {
  width: 100%;
  /* margin-bottom: 7%; */
}

.singup-form-card {
  margin: 1em 0em !important;
}

.chck-box-label {
  text-align: left;
  color: #6f6c6c;
  font-size: 13px;
  font-family: "poppins", sans-serif !important;
  font-weight: normal !important;
  display: inline !important;
}

.overall-font {
  font-family: "poppins", sans-serif !important;
}

.alrdy-sing-in-text {
  color: #a84551;
  font-weight: 550;
}

.already-div {
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-sign-upform-error-shipping-address-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1% 0%;
  text-align: center;
}

.common-form-error-text {
  color: red;
  font-size: 1.25em !important;
}

.new-sign-up-input-design {
  border: 1px solid #cdcdcd !important;
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
}

.new-signup-label {
  color: #353030;
}

@media screen and (min-width: 1025px) and (max-height: 800px) {
  .padng-style {
    height: 85vh !important;
  }
}
.new-paddin-style-logo-header {
  padding: 5% 5% 5% 5% !important;
}

.new-pa-logo-width {
  width: 36%;
}

.new-sign-up-title {
  display: flex;
  align-items: center;
  color: #2b2a2a;
  justify-content: center;
  font-size: 24px;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}

.dob-fnt-size {
  font-size: 0.9em !important;
}

.chckbox-input-style {
  display: flex;
  float: left;
}

.label-chckbx-style {
  text-align: justify;
}

.new-error-msg-font {
  font-size: 1.1em !important;
}

.new-grey-font {
  color: #6f6c6c;
}

.individual-wine-details-back-btn {
  float: left;
  width: 16px !important;
  height: 16px !important;
  opacity: 1;
}

@media (min-width: 450px) and (max-width: 1440px) {
  .social-media-container {
    margin-top: 1em !important;
    margin-bottom: 0em !important;
    justify-content: space-around !important;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .social-media-container {
    margin-top: 2em !important;
    margin-bottom: 2em !important;
    display: flex;
    justify-content: space-between;
  }
  .social-media-container .button[disabled] {
    opacity: 0.65 !important;
  }
}
.social-media-container {
  margin-top: 2em;
  margin-bottom: 0em;
  display: flex;
  justify-content: space-between;
}
.social-media-container .button[disabled] {
  opacity: 0.65 !important;
}

.social-media-container-dob {
  margin-top: 0em !important;
  margin-bottom: 3em;
  display: flex;
  justify-content: space-between;
}
.social-media-container-dob .button[disabled] {
  opacity: 0.65 !important;
}

.social-media-container-signin {
  margin-top: 0em !important;
  margin-bottom: 2em;
  display: flex;
  justify-content: space-evenly;
}
.social-media-container-signin .button[disabled] {
  opacity: 0.65 !important;
}

.new-input-form-height {
  height: 38px !important;
  font-size: 1.1em !important;
}

.txt-transform-none {
  text-transform: none !important;
}
@media (min-width: 1025px) {
  .success-inner-container {
    box-shadow: 0px 3px 200px -15px #989898;
    background-color: whitesmoke;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 25px;
    width: 430px !important;
    /* height: auto !important; */
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .success-footer-container {
    width: 430px !important;
    background: none !important;
  }
}
.success-inner-container {
  width: 100%;
  height: 100vh;
}

.page-content-container {
  display: flex;
  flex-direction: column;
}

.header-container {
  width: 100%;
}

.success-body-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
}

.success-footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 20vh; */
  flex-direction: column;
  /* padding-bottom: 2em; */
  bottom: 15px;
  position: fixed;
  width: 100%;
}

.success-logo-container {
  padding: 10% 5% 5% 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continue-shpng-btn-container {
  width: 90%;
}

.view-summary-btn {
  font-size: 16px !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
}

.order-success-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.confirmation-mail-txt {
  font-size: 16px;
  font-weight: 500;
  padding: 2%;
}

.cntn-shpng-btn {
  font-size: 16px !important;
  width: 100%;
  color: #a84551;
}

.order-success-txt {
  font-size: 17px;
  font-weight: 600;
}
.shpng-adrs-label {
  letter-spacing: 0.01px;
  color: #646363;
  text-transform: uppercase;
  opacity: 1;
}

.outer-container-div {
  padding: 3%;
  width: 100%;
  background: #F2F2F2 0% 0% no-repeat padding-box;
}

.top-outer-container-div {
  width: 100%;
  height: 100vh;
  background: #F2F2F2 0% 0% no-repeat padding-box;
}

.default-address-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
}

.inner-address {
  color: #6A6A6A;
}

.name-font {
  font-size: 17px;
}

.modify-add-address-btn {
  /* text-align: center; */
  /* margin-left: 30px; */
  letter-spacing: 0px;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  color: #a84551;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #a84551;
  border-radius: 2px;
  width: 100%;
}

.paym-details-div {
  margin-top: 6%;
}

.total-brkup-div {
  display: flex;
  flex-direction: row;
}

.brkup-amnt-div {
  margin-left: auto;
}

@media (min-width: 1025px) {
  .paym-details-div {
    margin-top: 2.5% !important;
  }
  .applied-promo-div {
    margin-top: 2.5% !important;
  }
  .outer-container-div {
    padding: 1.5% 3% 3% 3% !important;
  }
}
@media (min-width: 768px) {
  .paym-details-div {
    margin-top: 3% !important;
  }
  .applied-promo-div {
    margin-top: 3% !important;
  }
}
.brkup-amnt-color {
  color: #2B2B2B;
}

.brder-btm-brkup {
  border-bottom: 1px solid #CCCCCC;
}

.ttl-amnt-color {
  color: #000000;
}

.applied-promo-div {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1607843137);
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 6%;
  padding: 10px 17px 10px 17px;
}

.applied-promo-code-div {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
}

.applied-promo-code-label {
  margin-left: 23px;
  color: #a84551;
}

.overall-top-container {
  width: 100%;
  height: 100vh;
  background: #F2F2F2 0% 0% no-repeat padding-box;
}

.outer-container-div::after {
  content: "";
  display: block;
  height: 50px;
  width: 100%;
}

.promo-code-amnt-color {
  color: #989797;
}

.new-addrss-err-font-size {
  font-size: 14px !important;
}

.disabled-btn-css {
  opacity: 1 !important;
  background-color: #d68c8c !important;
}

.new-billing-chckbox {
  margin-top: 2px !important;
  /* padding: 116px; */
  width: 15px !important;
  height: 15px !important;
}

.blng-chckbox-div {
  display: flex;
  /* justify-content: center; */
}
.discover_container__vZJyj {
  width: 100%;
  position: relative !important;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
}

.discover_card__1SQkm {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0 !important;
  margin: 1em !important;
  padding: 1em !important;
}

.discover_cardDetail__eB8ZP {
  width: 100%;
}

.discover_selectCard__16KRt {
  width: 40%;
}

.discover_logoContainer__UXusj {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.discover_nameContainer__11ga0 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
  margin-bottom: 5%;
}

.discover_subContainer__2Klx8 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.discover_cardText__3SluR {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.logo_width__3tkG8 {
  width: 36% !important;
}
.region-based-wineries_inputContainer__3-iMY {
  width: 90%;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
  border-radius: 6px;
}

.region-based-wineries_inputBox__2EEah {
  width: 100%;
  border: 1px solid rgba(29, 29, 29, 0.1019607843);
}

.region-based-wineries_flexCol__-admi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.region-based-wineries_inputSelectionBox__2WkKH {
  margin-top: 4%;
}

.region-based-wineries_wineryCard__1UHQ1 {
  width: 40%;
  background-color: #fff;
  margin: 1em;
  padding: 1em;
}

.region-based-wineries_wineryCardBox__OimBm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
  align-items: center;
  width: 100%;
}

.region-based-wineries_container__22WFE {
  width: 100%;
  position: relative !important;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
}

.region-based-wineries_card__3uXDs {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0 !important;
  margin: 1em !important;
  padding: 1em !important;
}

.region-based-wineries_cardDetail__3krVx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.region-based-wineries_selectCard__3pqc3 {
  width: 40%;
}

.region-based-wineries_logoContainer__LnVxJ {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.region-based-wineries_nameContainer__lvRaS {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
  margin-bottom: 5%;
}

.region-based-wineries_subContainer__3Bbwr {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.region-based-wineries_cardText__1joy5 {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.region-based-wineries_cardImage__3MASQ {
  width: 50%;
}

.region-based-wineries_buttonText__E9k1Y {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.region-based-wineries_buttonBox__2RV4O {
  width: 90%;
  flex-wrap: wrap;
}
.wine-history-picture {
  height: 100px;
  width: auto;
}

.wine-history-picture-frame {
  height: 100px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-error-css {
  width: 100% !important;
  padding: 0 0.5em;
}

.food-suggestions {
  width: 100%;
  left: 66px;
  top: 338px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 1rem;
  /* identical to box height, or 125% */
  text-transform: capitalize;
  /* Primary / Dark Gray */
  color: #7D7D7D;
}

.suggestions-bar {
  width: 100%;
  height: 0px;
  left: 56px;
  top: 367px;
  margin-top: 0.25rem;
  /* Primary / Light Gray */
  border: 1px solid #EFEFEF;
}

.pointer {
  cursor: pointer;
}

.search-main-embedded {
  min-width: 100%;
  border-radius: 10px;
  padding-top: 3rem;
  background-color: white;
  display: table;
}

.search-main {
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  max-height: 75%;
  min-width: 90%;
  border-radius: 10px;
  padding-top: 3rem;
  background-color: white;
  display: table;
}

.new-search-icon {
  position: absolute;
  right: 20px;
}

.pairing-result-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.logo-desc {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 14px;
  color: #7D7D7D;
}

.PA-logo-pairing {
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

.pairing-result-search-container {
  padding: 0 28px;
}

.pairing-result-search-bar-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #7D7D7D;
}

.result-search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;
}

.pairing-search-bar, select, textarea {
  color: #343434;
  background: #EFEFEF;
  opacity: 1;
  border-radius: 100px;
  border: none;
  height: 35px;
  width: 100%;
}

.food-wine-btn-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}

.wine-btn {
  border-right: 0.25rem solid #7D7D7D;
  border-top: 0.25rem solid #7D7D7D;
  border-bottom: 0.25rem solid #7D7D7D;
  border-radius: 0px 50px 50px 0px;
}

.food-btn {
  border-left: 0.25rem solid #7D7D7D;
  border-top: 0.25rem solid #7D7D7D;
  border-bottom: 0.25rem solid #7D7D7D;
  border-radius: 50px 0px 0px 50px;
}

.hist-container {
  display: flex;
  flex: 1 1;
  margin-left: 5rem;
  cursor: pointer;
}

.picture {
  height: 100px;
  width: auto;
}

.name {
  padding-top: 3rem;
  padding-left: 2rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  max-width: 221px;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  text-transform: capitalize;
  /* Primary / Dark Gray */
  color: #7D7D7D;
}

.btn-icons {
  padding-left: 5px;
}

.main-image {
  max-width: 15rem;
  width: auto;
  height: auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

.menu-icon {
  margin-right: 2rem;
  margin-top: 3rem;
}

.scott-harvey-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 4%;
  min-height: 60px;
}

.header-bar {
  min-height: 20px;
  width: 100%;
  position: absolute;
  margin-top: 130px;
  background-color: #843c44;
}

.header-inner {
  display: flex;
  justify-content: center;
}

.shopping-cart-icon {
  max-width: 25px;
  height: auto;
  margin-right: 2rem;
  margin-bottom: 5px;
}
.varietal-details-div {
  font-family: "poppins", sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0px;
  color: #2b2b2b;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.varietal-card-bottom {
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.varietal-details {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 29px;
  justify-content: center;
}

.name-details {
  color: #a84551;
  text-transform: capitalize;
}

.varietal-list-main-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 3% !important;
}

.varietal-individiual-desktop {
  width: 50% !important;
}

.varietal-details-container {
  display: flex;
  flex-direction: row;
  padding: 2px 2px 13px 2px;
  align-items: center;
}

.wine-type-img {
  width: 37%;
  background-color: rgba(201, 199, 199, 0.3);
  padding: 18px;
  border-radius: 50px;
  height: auto;
}

.varietal-reco-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0 !important;
  border-radius: 10px !important;
  margin: 1em !important;
  /* padding: 1em !important; */
  box-shadow: 1px rgba(29, 29, 29, 0.1019607843) !important;
  border: none;
}

.vareital-details-shop-btn-desktop {
  padding: 3% 3% 0% 3% !important;
}

.vareital-details-shop-btn-mob {
  padding: 3% 3% 0% 3% !important;
}

.ft-s-17 {
  font-size: 17px !important;
}

.name-container {
  padding: 3px 0%;
}

.wine-type-name-container {
  margin-top: 10px;
}

.heading-name-container {
  color: #727272;
}

@media (min-width: 1025px) {
  .wine-type-img {
    width: 40% !important;
  }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) {
  .wine-type-img {
    width: 26% !important;
  }
}
@media only screen and (min-width: 540px) {
  .wine-type-img {
    width: 30% !important;
  }
}
.pairings-main-container-desktop {
  box-shadow: 0px 3px 200px -15px #989898;
  height: 90vh;
  background-color: white;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 25px;
  width: 1150px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed !important;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  margin: auto !important;
  overflow: hidden;
}

.new-error-css {
  width: 100% !important;
  padding: 0 0.5em;
}

.food-suggestions {
  width: 100%;
  left: 66px;
  top: 338px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 1rem;
  /* identical to box height, or 125% */
  text-transform: capitalize;
  /* Primary / Dark Gray */
  color: #7D7D7D;
}

.suggestions-bar {
  width: 100%;
  height: 0px;
  left: 56px;
  top: 367px;
  margin-top: 0.25rem;
  /* Primary / Light Gray */
  border: 1px solid #EFEFEF;
}

.pointer {
  cursor: pointer;
}

.search-main-embedded {
  min-width: 100%;
  border-radius: 10px;
  padding-top: 3rem;
  background-color: white;
  display: table;
}

.search-main-desktop {
  max-height: 75%;
  width: 65%;
  border-radius: 10px;
  padding-top: 3rem;
  background-color: white;
  display: table;
}

.new-search-icon {
  position: absolute;
  right: 20px;
}

.pairing-result-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.logo-desc {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 14px;
  color: #7D7D7D;
}

.PA-logo-pairing {
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

.pairing-result-search-container {
  padding: 0 10%;
}

.pairing-result-search-bar-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #7D7D7D;
}

.result-search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;
}

.pairing-search-bar, select, textarea {
  color: #343434;
  background: #EFEFEF;
  opacity: 1;
  border-radius: 100px;
  border: none;
  height: 35px;
  width: 100%;
}

.food-wine-btn-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.food-wine-btn-container-desktop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}

.wine-btn {
  border-right: 0.25rem solid #7D7D7D;
  border-top: 0.25rem solid #7D7D7D;
  border-bottom: 0.25rem solid #7D7D7D;
  border-radius: 50px 0px 0px 50px;
}

.food-btn {
  border-left: 0.25rem solid #7D7D7D;
  border-top: 0.25rem solid #7D7D7D;
  border-bottom: 0.25rem solid #7D7D7D;
  border-radius: 50px 50px 50px 50px;
}

.hist-container {
  display: flex;
  flex: 1 1;
  margin-left: 5rem;
  cursor: pointer;
}

.picture {
  height: 100px;
  width: auto;
}

.name {
  padding-top: 3rem;
  padding-left: 2rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  max-width: 221px;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  text-transform: capitalize;
  /* Primary / Dark Gray */
  color: #7D7D7D;
}

.btn-icons {
  padding-left: 5px;
}
.app-feedback_main__2iNf7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 2rem;
  grid-gap: 24px;
  gap: 24px;
}

.app-feedback_heading__3R8xC {
  width: 100%;
}

.app-feedback_formContainer__2pzIm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 24px;
  gap: 24px;
}

.app-feedback_formInput__2ZeYq {
  width: 100%;
  padding: 16px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.app-feedback_formField__1f8WZ {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
}

.app-feedback_formFieldError__1mhw1 {
  color: red;
}

.app-feedback_asterisk__b1aMI {
  font-weight: 800;
  color: #951901;
}

.app-feedback_checkBoxOption__SHKkP {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.app-feedback_formInputTextArea__2Z40i {
  height: 120px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.app-feedback_checkBoxOption__SHKkP > input[type=checkbox] {
  margin: 0;
}

.app-feedback_radioBtn__T1lkp {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.app-feedback_radioBtn__T1lkp > input[type=radio] {
  margin: 0;
}

.app-feedback_submitBtn__3_Zek {
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.app-feedback_grayedOut__2mW32 {
  color: #7d7d7d;
  background: #efefef;
}

@media (min-width: 600px) {
  .app-feedback_formContainer__2pzIm {
    width: 70%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
  }
  .app-feedback_heading__3R8xC {
    width: 70%;
    max-width: 600px;
  }
}

.virtual-wine-tasting_main__3mWj2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 2rem;
  grid-gap: 24px;
  gap: 24px;
}

.virtual-wine-tasting_heading__2kg6_ {
  width: 100%;
}

.virtual-wine-tasting_formContainer__2C93O {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 24px;
  gap: 24px;
}

.virtual-wine-tasting_formInput__2S67r {
  width: 100%;
  padding: 16px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.virtual-wine-tasting_formField__2WJ-L {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
}

.virtual-wine-tasting_formFieldDatePicker__1kDuf {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
}

.virtual-wine-tasting_formFieldDatePicker__1kDuf > div > div > div > input {
  border: 1px solid rgba(125, 125, 125, 0.5);
  padding: 12px 16px;
  border-radius: 8px;
}

.virtual-wine-tasting_formFieldError__2UK2N {
  color: red;
}

.virtual-wine-tasting_asterisk__1jCRj {
  font-weight: 800;
  color: #951901;
}

.virtual-wine-tasting_checkBoxOption__38M5a {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.virtual-wine-tasting_formInputTextArea__2W1hH {
  height: 120px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.virtual-wine-tasting_checkBoxOption__38M5a > input[type=checkbox] {
  margin: 0;
}

.virtual-wine-tasting_radioBtn__1ezaK {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.virtual-wine-tasting_radioBtn__1ezaK > input[type=radio] {
  margin: 0;
}

.virtual-wine-tasting_submitBtn__18BPC {
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.virtual-wine-tasting_grayedOut__2OsLA {
  color: #7d7d7d;
  background: #efefef;
}

@media (min-width: 600px) {
  .virtual-wine-tasting_formContainer__2C93O {
    width: 70%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 24px;
    gap: 24px;
  }
  .virtual-wine-tasting_heading__2kg6_ {
    width: 70%;
    max-width: 600px;
  }
}
.region-wine-card-name {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  text-overflow: ellipsis;
  overflow: hidden;
  /* white-space: nowrap; */
  width: 100%;
  white-space: pre-wrap;
  max-width: 11ch;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.new-ht {
  height: 14em;
}

.new-reg-wine-logo {
  width: 40%;
}

.fl-wrap {
  flex-wrap: wrap;
}

.m-top10 {
  margin-top: 10%;
}

.region-based-search-bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1025px) {
  .new-reg-wine-logo {
    width: 29%;
  }
  .new-ht {
    height: 15em !important;
  }
  .media-width {
    width: 38% !important;
  }
  .new-logo-winery-width {
    width: 40% !important;
  }
  .visit-page-winery-container {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .new-ht {
    height: 16em !important;
  }
  .new-logo-winery-width {
    width: 29% !important;
  }
  .media-width {
    width: 22% !important;
  }
}
@media (width: 1024px) {
  .new-ht {
    height: 17em !important;
  }
  .new-logo-winery-width {
    width: 15% !important;
  }
}
.new-logo-winery-width {
  width: 65%;
}

.reg-wine-logo-container {
  height: 100px !important;
}

.swipe-container {
  flex-wrap: wrap;
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.visit-page-mob-winery-container {
  width: 100%;
  position: absolute;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
}

.slick-slider-overwrite {
  overflow: hidden !important;
  padding: bottom 2%;
}

.slick-slider-overwrite::after {
  content: "";
  display: block;
  height: 35px;
  width: 100%;
}

.slick-slider-overwrite-promo {
  overflow: hidden !important;
}

.slick-slider-overwrite-promo::after {
  content: "";
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  margin-bottom: 2%;
}
.closeBtn {
  position: absolute;
  top: 5%;
  right: 5%;
  background: #efefef;
  border: none;
  border-radius: 50%;
  color: #7d7d7d;
  height: 35px;
  min-height: 35px;
  width: 35px;
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
  text-align: center;
  outline: none;
}

.your-matches {
  margin-top: 10%;
}

.closeBtn:active, .closeBtn:focus {
  border-style: outset;
  outline: none;
}

.closeBtn:hover {
  cursor: pointer;
}

@media (min-width: 1025px) {
  .taste-quiz-container {
    height: 90vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.rec-container, .success-main-container, .closeBtn {
  font-family: "Montserrat", sans-serif;
}

.flavors {
  color: #343434;
  font-weight: bold;
}

.rec-container {
  height: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 400px;
  margin: auto;
}

.wineRec {
  position: relative;
  width: 50%;
}

.wineImage {
  position: relative;
  overflow: hidden;
  width: 70px;
  height: 300px;
  transform: rotate(-0.015turn);
  margin: 5%;
  object-fit: cover;
}

.wineImage1 {
  position: relative;
  overflow: hidden;
  width: 70px;
  height: 300px;
  transform: rotate(0.015turn);
  margin: 5%;
  object-fit: cover;
}

.test-result-small-highlight {
  margin-top: 10%;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
  margin-left: 25px;
  color: grey;
}

.test-result-small-highlight1 {
  font-size: 11px;
  font-weight: 500;
  color: #343434;
  display: inline;
}

.taste-pics {
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.taste-pics-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 200px;
}

.taste-notes {
  margin-top: 5px;
  font-size: 8px;
  display: flex;
}

.segmented-list {
  width: 35px;
  margin-left: 4px;
  background-color: lightgray;
  display: block;
  height: 5px;
}

.elem-flag {
  background-color: #951901 !important;
}

.container {
  width: 240px;
  display: inline-flex;
}

.resultTitle {
  font-family: "Montserrat", sans-serif;
  margin-left: 25px;
  width: 300px;
  font-size: 30px;
  font-weight: 800;
  line-height: 37px;
}

.drumRoll {
  position: relative;
  top: -7px;
}

.container-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.scaleBold {
  color: #951901;
  font-weight: 700;
}

.scaleNormal {
  margin-left: 120px;
}
.signup_container__WIl_o {
  width: 100%;
  position: relative !important;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
}

.signup_logoContainer__3AXPa {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.signup_nameContainer__3E_ZH {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
  margin-bottom: 5%;
}

.signup_subContainer__Elc-k {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.signup_cardText__wyF6G {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.signup_welcome__3grUT {
  font-family: Montserrat, serif;
  margin-top: 10%;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
}

.signup_start__3ELIu {
  font-family: Inter, sans-serif;
  margin-top: 1%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #7d7d7d;
}

.signup_dg__24AuR {
  color: #343434;
  margin-top: 2%;
}

.signup_phoneContainer__2h4lH {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

.signup_emailSwitch__3hhjo {
  color: #343434;
  text-decoration: underline;
}

.signup_emailSwitch__3hhjo:hover {
  text-decoration: none;
  cursor: pointer;
}

.signup_inputContainer__26Q_X {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup_inputBox__2-FKx {
  width: 100%;
  padding: 4% 5%;
  border: 1px solid #7d7d7d;
  border-radius: 8px;
}

.signup_next__3TXAl {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 600;
  background-color: #efefef;
  padding: 16px 32px;
  width: 100%;
  border-radius: 8px;
  border: none;
}

.signup_next__3TXAl:hover {
  background-color: #dedede;
}

.signup_outerContainer__3UHmq {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup_mainContainer__2CYti {
  width: 90%;
}

.signup_w100__1fKe4 {
  width: 100%;
}
/* .PhoneInput {
  display: inline !important;
  position: relative !important;

}

.PhoneInputCountry {
  position: absolute !important;
  left: 5%;
  bottom: 10%;


} */

.PhoneInput {
  width: 100% !important;
  padding: 1% 5% !important;
  border: 1px solid #7D7D7D !important;
  border-radius: 8px !important;
}

.PhoneInputInput {
  border-radius: 8px !important;
  border: none !important;
}

.character {
  border-radius: 8px;
}

.verificationContainer {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:where(.vi__wrapper) {
  position: relative;
  width: 100% !important;
}
.header-inline_PAHeader__28rlc {
  width: 100%;
}

.header-inline_container__3zbKO {
  position: relative;
  padding: 3rem 2.4rem 2rem 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  border-radius: 0px 0px 8px 8px;
}

.header-inline_containerActive__2TXEf {
  background: white padding-box;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

.header-inline_logoIconContainer__2Vect {
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-inline_logo__3T-t6 {
  cursor: pointer;
  width: 148px;
  height: auto;
  object-fit: contain;
}

.header-inline_logoDisabled__36vLk {
  width: 148px;
  height: auto;
  object-fit: contain;
}

.header-inline_clickHover__19Owp:hover {
  cursor: pointer;
  text-decoration: underline;
}

.header-inline_menuIcon__25lH7 {
  cursor: pointer;
}

.header-inline_menuContent__3H7Sq {
  z-index: 9;
  padding-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 40px;
  gap: 40px;
}

.header-inline_optionsBox__Zcb_n {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.header-inline_option__2d_Ol {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}

.header-inline_option__2d_Ol {
  cursor: pointer;
}

.header-inline_modalBackground__2bVs8 {
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
}

.header-inline_modalContent__3pXia {
  z-index: 9;
  width: 100%;
}

.header-inline_signupBox__8fbfD {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.header-inline_loginTxt__Vw_tf {
  color: #951901;
  text-decoration: underline;
}

.header-inline_loginTxt__Vw_tf:hover {
  cursor: pointer;
  text-decoration: none;
}

.header-inline_signupBtn__1Igxo {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.header-inline_logoutBtn__1hm_f {
  border: none;
  background: none;
}

.header-inline_logoutBtnTxt__tKDvN {
  color: #951901;
}

.header-inline_logoutBtnTxt__tKDvN:hover {
  text-decoration: underline;
}

.header-inline_secondaryList__3Fzjz {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}
.landing_titleBox__xmlwR {
  margin: 4rem 0 3.2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.landing_welcomeBox__2C1is {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.landing_start__2YmQc {
  margin-top: 1rem;
  margin-bottom: 4rem;
  text-align: left;
}

.landing_startAmb__2pvNi {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.landing_inputContainer__2406E {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing_inputBox__QDAvq {
  width: 100%;
  padding: 4% 5%;
  border: 1px solid #7d7d7d;
  border-radius: 8px;
}

.landing_setupBtn___rZMA {
  font-family: Inter, sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #343434;
  padding: 12px 10px;
  border-radius: 8px;
  border: none;
}

.landing_setupBtn___rZMA:hover {
  background-color: #dedede;
}

.landing_pageSection__26k5k {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.landing_pageSection2__3Lra3 {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.landing_searchContainer__2WPwB {
  width: 100%;
  margin-top: 3rem;
}

.landing_searchBox__191Co {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.landing_partnerLogo__iB3xk {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.landing_bgRed__24kdd {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  background-color: #f6efef;
}

.landing_bgWhite__ZUU1h {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  background-color: #f4ece3;
}

.landing_bg__3emoK {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
}

.landing_w60__3l7-P {
  height: 60px;
  width: 60px;
  margin: 0 4% 0 0;
}

.landing_wineImg__2-h4R {
  height: 80%;
  background-color: #f4ece3;
}

.landing_wineryName__3swm2 {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #343434;
}

.landing_phoneticResults__2qgO5 {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding: 3% 4% 3% 4%;
}

.landing_phoneticResults__2qgO5:hover {
  cursor: pointer;
}

.landing_phoneticResultsImg__2k7z6 {
  padding-right: 4%;
}

.landing_phoneticResults__2qgO5:hover {
  background-color: #dedede;
}

.landing_animationExpandable__26m8h {
  min-height: 0;
}

.landing_animationWrapper__3pSwC {
  width: 100%;
  opacity: 1;
  background-color: #efefef;
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s, opacity 0.3s, background-color 0.3s;
}

.landing_collapsed__1hJT7 {
  background-color: white;
  opacity: 0;
  grid-template-rows: 0fr;
}

.landing_closeBtn__1R2DS {
  float: right;
  background: #efefef;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  outline: none;
  min-height: 40px;
  min-width: 40px;
  flex-shrink: 0;
  flex-grow: 0;
}

.landing_btnNoClick__1MexS {
  cursor: default !important;
}

.landing_wineCardBoxCol__2Hnj6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.landing_wineCardBoxRow__2z7CE {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
  align-items: center;
}

.landing_loginSignupText__sN1T2 {
  text-decoration: underline;
  color: #951901;
}

.landing_loginSignupText__sN1T2:hover {
  text-decoration: none;
  cursor: pointer;
}

.landing_spotlightBox__3wOAg {
  margin-top: 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.landing_pairingsBox__1dmB_ {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.landing_todaysWinesBox__3-EUC {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 22px;
  gap: 22px;
}

.landing_todaysWinesBoxFocused__3sqTk {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.landing_inputWithIcon__TSFDJ {
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 16px;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  border-radius: 8px;
  background: white;
  border: 1px solid #343434;
}

.landing_noShrink__28pvZ {
  flex-shrink: 0;
  flex-grow: 1;
}

.landing_pairBtn__3J4U- {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 4px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.landing_pairBtnHidden__2PezD {
  height: 36px;
}

.landing_savedCardBox__3MI16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 22px;
  gap: 22px;
}
.spotlight-card_card__1YdIM {
  all: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.spotlight-card_ghostCard__2zQXd {
  all: unset;
  width: 100%;
}

.spotlight-card_wineImgBg__1BQ-I {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  min-width: 100px;
  background-color: #efefef;
  border-radius: 8px;
}

.spotlight-card_wineImg__1X8yf {
  object-fit: contain;
  width: 80%;
  height: 80%;
  mix-blend-mode: multiply;
}
.animation-wrapper_animationExpandable__s1kA6 {
  min-height: 0;
}

.animation-wrapper_collapseWrapper__5BU_e {
  width: 100%;
  opacity: 1;
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s, opacity 0.3s, background-color 0.3s, margin-top 0.5s, margin-bottom 0.5s;
}

.animation-wrapper_collapse__AiscE {
  opacity: 0;
  grid-template-rows: 0fr;
}

.animation-wrapper_expandWrapper__2-4Zt {
  width: 100%;
  opacity: 0;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s, opacity 0.3s, background-color 0.3s, margin-top 0.5s, margin-bottom 0.5s;
}

.animation-wrapper_expand__2bg0z {
  opacity: 1;
  grid-template-rows: 1fr;
}

.animation-wrapper_collapseHorizontalWrapper__aiDoF {
  width: 100%;
  overflow: hidden;
  transition: width 0.5s;
}

.animation-wrapper_collapseHorizontalItem__3BTvH {
  min-width: 0;
}

.animation-wrapper_collapseHorizontal__AasT3 {
  width: 0%;
}

.animation-wrapper_slideFromRight__VkT-w {
  animation: animation-wrapper_fromRight__EO0jz 0.5s;
}

.animation-wrapper_slideFromLeft__1AVh_ {
  animation: animation-wrapper_fromLeft__3qjoc 0.5s;
}

.animation-wrapper_faded__1RmGw {
  opacity: 0;
  transition: opacity 0.3s;
}

.animation-wrapper_unfaded__1Y7fe {
  opacity: 1;
  transition: opacity 0.3s;
}

.animation-wrapper_unfaded2__3GElN {
  opacity: 1;
  transition: opacity 1s;
}

.animation-wrapper_faded2__3Qulu {
  opacity: 0;
  transition: opacity 1s;
}

.animation-wrapper_collapseH__1edBo {
  animation: animation-wrapper_shrinkWidth__25mTM 0.5s forwards;
}

@keyframes animation-wrapper_shrinkWidth__25mTM {
  0% {
    width: 100%;
  }
  99% {
    width: 1%;
  }
  100% {
    width: 0;
    display: none;
  }
}
@keyframes animation-wrapper_fromRight__EO0jz {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes animation-wrapper_fromLeft__3qjoc {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.saved-wine-card_card__2TrlU {
  all: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.saved-wine-card_ghostCard__1pZm9 {
  all: unset;
  width: 100%;
}

.saved-wine-card_card__2TrlU:hover {
  cursor: pointer;
}

.saved-wine-card_wineImgBg__2A3RJ {
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100px;
  background-color: #efefef;
  border-radius: 8px;
}

.saved-wine-card_wineImg__3_Ko- {
  aspect-ratio: 1/1;
  object-fit: contain;
  width: 80%;
  height: 80%;
  mix-blend-mode: multiply;
}

.saved-wine-card_buyBtn__Hv9zn {
  display: flex;
  width: 100%;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 4px;
  border: none;
  background: #343434;
}

.saved-wine-card_buyBtn__Hv9zn:hover {
  background: #7d7d7d;
}
.saved-pairing-card_card__jPEaP {
  all: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.saved-pairing-card_ghostCard__1lOLR {
  all: unset;
  width: 100%;
}

.saved-pairing-card_card__jPEaP:hover {
  cursor: pointer;
}

.saved-pairing-card_wineImgBg__3Rucw {
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100px;
  background-color: #efefef;
  border-radius: 8px;
}

.saved-pairing-card_wineImg__LI3OG {
  aspect-ratio: 1/1;
  object-fit: contain;
  width: 80%;
  height: 80%;
  mix-blend-mode: multiply;
}

.saved-pairing-card_textBox__18K60 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.saved-pairing-card_buyBtn__1vH8d {
  display: flex;
  width: 100%;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 4px;
  border: none;
  background: #343434;
}

.saved-pairing-card_buyBtn__1vH8d:hover {
  background: #7d7d7d;
}

.saved-pairing-card_foodImg__2c0nL {
  height: 60%;
  width: 60%;
  border-radius: 50%;
  transform: translate(10%);
  object-fit: cover;
}

.saved-pairing-card_varietalImg__1lJkc {
  aspect-ratio: 1/4;
  width: 20%;
  height: 80%;
  transform: rotate(8deg) translate(-10%);
  object-fit: cover;
}
.wine-details_fromRight__1rdws {
  animation: wine-details_fromRight__1rdws 0.5s;
}

.wine-details_fromLeft__2z6Sg {
  animation: wine-details_fromLeft__2z6Sg 0.5s;
}

.wine-details_mainContainer__2Zt_Y {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.wine-details_mainContainerSection__2EKZe {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.wine-details_logoFrame__22K_g {
  width: 80px;
  aspect-ratio: 1/1;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--primary-white, #fff);
  position: absolute;
  top: 12px;
  left: 16px;
}

.wine-details_logoImg__1ylxU {
  object-fit: contain;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 4px;
}

.wine-details_imgFrame__3EpH2 {
  position: relative;
  width: 100%;
  height: 300px;
  flex-shrink: 0;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 2.4rem;
}

.wine-details_wineImg__UQ-kP {
  object-fit: contain;
  height: 90%;
  flex-shrink: 0;
  border-radius: 4px;
}

.wine-details_info__2mfUd {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.wine-details_infoLine2__1H-S9 {
  display: flex;
  width: 100%;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.wine-details_priceBox__208vf {
  display: flex;
  width: 100%;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.wine-details_price__2n9_s {
  display: flex;
  padding-right: 0px;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.wine-details_grayVert__1CGY9 {
  width: 1px;
  height: 40px;
  background-color: #efefef;
}

.wine-details_detailsBox__ldztw {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 4rem;
}

.wine-details_backToResultBtn__2Ndr_ {
  background: none;
  border: none;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
  color: #951901;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.wine-details_backToResultBtnTxt__397zN {
  color: #951901;
}

.wine-details_link__1vZDx {
  color: #951901;
}

.wine-details_link__1vZDx:hover {
  color: black;
  text-decoration: none;
}

.wine-details_padding__23Hex {
  margin-top: 5.6rem;
}

.wine-details_buyBtn__1qumU {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background-color: #343434;
}
.actions-row_fadeIn__1aH-P {
  animation: actions-row_fadeIn__1aH-P 0.3s;
}

@keyframes actions-row_fadeIn__1aH-P {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.actions-row_modalBackground__3veXy {
  padding: 2rem 2.4rem 2rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.actions-row_modalBox__3tI1h {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 24px;
  background-color: white;
  border-radius: 8px;
}

.actions-row_modalContent__HYkld {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

.actions-row_optionsBox__3TsuX {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.actions-row_option__MGig_ {
  display: flex;
  width: 100%;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.actions-row_checkbox__39XF2 {
  flex-shrink: 0;
  color: white;
  fill: white;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #343434;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.actions-row_checked__3kMDY {
  background-color: #343434;
}

.actions-row_feedBackBtn__BJccC {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.actions-row_grayedOut__27r3R {
  background: #efefef;
  color: #7d7d7d;
}

.actions-row_formInput__3g1HA {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.actions-row_thankYouTextBox__2y0hk {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.actions-row_createAccountBtn__2T8Rr {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #951901;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.actions-row_signInBtn__2i-ps {
  color: #951901;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.actions-row_signIn__3Dazl:hover p {
  color: black;
}

.actions-row_signInBtnTxt__39LMu {
  color: #951901;
}

.actions-row_main__2xiBp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actions-row_actionCircle__18z9V {
  color: #7d7d7d;
  fill: #7d7d7d;
  height: 42px;
  width: 42px;
  background-color: #efefef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, color 0.3s, fill 0.3s;
}

.actions-row_actionCircleActive__yk3yC {
  fill: white;
  color: white;
  background-color: #343434;
}

.actions-row_action__1Bn03 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 8px;
  gap: 8px;
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.actions-row_actionText__QbdE- {
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.actions-row_blueBox__7rQdg {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #1e7ed7;
  box-shadow: 0px 2px 20px 0px rgba(52, 52, 52, 0.42);
  margin: 2.4rem;
  flex-grow: 0;
  z-index: 5;
}

.actions-row_blueBoxText__LISuG {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.actions-row_animationWrapper__3U470 {
  width: 100%;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  opacity: 0;
  transition: grid-template-rows 0.3s, opacity 0.5s;
}

.actions-row_animationExpandable__2nE0M {
  min-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions-row_expanded__2LOXG {
  grid-template-rows: 1fr;
  opacity: 1;
}

.actions-row_underlined__3UxsY {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: 700;
}

.actions-row_underlined__3UxsY:hover {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-weight: 700;
  cursor: pointer;
}
.share-methods-row_fadeIn__1fsO3 {
  animation: share-methods-row_fadeIn__1fsO3 0.3s;
}

@keyframes share-methods-row_fadeIn__1fsO3 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.share-methods-row_modalBackground__2u7IM {
  padding: 2rem 2.4rem 2rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.share-methods-row_modalBox__1iMlU {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px 24px;
  background-color: white;
  border-radius: 8px;
}

.share-methods-row_modalContent__2sq7j {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

.share-methods-row_actionCircle__E75RW {
  color: #7d7d7d;
  fill: #7d7d7d;
  height: 42px;
  width: 42px;
  background-color: #efefef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  transition: background-color 0.3s, color 0.3s, fill 0.3s;
}

.share-methods-row_actionCircleActive__3Q2-H {
  fill: white;
  color: white;
  background-color: #343434;
}

.share-methods-row_action__2F6BY {
  max-width: 42px;
  text-align: center;
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.share-methods-row_underlined__1IyI3 {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  font-weight: 700;
}

.share-methods-row_underlined__1IyI3:hover {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  font-weight: 700;
  cursor: pointer;
}

.share-methods-row_inputBar__uW-9X {
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 16px;
  justify-content: space-between;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.share-methods-row_copyBtn__2Wmwl {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border: none;
  border-radius: 4px;
  background: #343434;
  color: white;
  /* P3 - 14 SemiBold */
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.share-methods-row_linkBox__D0eCZ {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.share-methods-row_blueBox__1h2Gw {
  display: flex;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 8px;
  background: #1e7ed7;
  box-shadow: 0px 2px 24px 0px rgba(52, 52, 52, 0.42);
}

.share-methods-row_blueBoxTxt__1hcAW {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.flavor-profile-card_flavorList__2T3Sc {
  width: 100%;
  margin-bottom: 6%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 6%;
}

.flavor-profile-card_flavorItem__2qarq {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 0;
  max-width: 60px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}

.flavor-profile-card_flavorItemImg__1elZQ {
  object-fit: cover;
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 20%;
}

.flavor-profile-card_varietalCard__1SyKm {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #efefef;
  padding: 5% 5% 5% 5%;
}

.flavor-profile-card_valueContainer__28Ach {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  flex-shrink: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.flavor-profile-card_valueRight__3Y1HX {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #7d7d7d;
  margin: 0;
  text-align: left;
  width: 20%;
}

.flavor-profile-card_valueLeft__3wyGp {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #7d7d7d;
  margin: 0;
  text-align: right;
  width: 20%;
}

.flavor-profile-card_valueBold__2hcU0 {
  font-weight: 700;
  color: #343434;
}

.flavor-profile-card_valuedBar__14Y2s {
  background: #343434;
  height: 3px;
  width: 18%;
  border-radius: 100px;
}

.flavor-profile-card_notValuedBar__318cl {
  background: #efefef;
  height: 3px;
  width: 18%;
  border-radius: 100px;
}
.wine-type-pill_pill__KaHTd {
  display: inline-block;
  padding: 2px 10px 1px 8px;
  border-radius: 100px;
  background-color: #efefef;
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.pairing-results_jank__1S-TA {
  animation: pairing-results_fromRight__2o9Bw 0.5s;
}

@keyframes pairing-results_fromRight__2o9Bw {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.pairing-results_welcome__f8LXl {
  font-family: Montserrat, serif;
  margin-top: 5%;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
}

.pairing-results_whatPairsWith__3Tz83 {
  font-family: Montserrat, serif;
  margin-top: 8%;
  margin-bottom: 2%;
  font-size: 22px;
  font-weight: 800;
  text-align: left;
}

.pairing-results_todaysWines__3mJWq {
  font-family: Montserrat, serif;
  margin-top: 5%;
  font-size: 16px;
  font-weight: 800;
  text-align: left;
}

.pairing-results_start__1QQjp {
  font-family: Inter, sans-serif;
  margin-top: 1%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #7d7d7d;
}

.pairing-results_dg__22WsB {
  color: #343434;
  margin-top: 2%;
}

.pairing-results_inputContainer__9kEff {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pairing-results_inputBox__j6xDZ {
  width: 100%;
  padding: 4% 5%;
  border: 1px solid #7d7d7d;
  border-radius: 8px;
}

.pairing-results_setupBtn__1ttqA {
  font-family: Inter, sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #343434;
  padding: 12px 10px;
  width: 35%;
  border-radius: 8px;
  border: none;
  margin-top: 4%;
  margin-bottom: 10%;
}

.pairing-results_setupBtn__1ttqA:hover {
  background-color: #dedede;
}

.pairing-results_mainContainer__22Oc3 {
  margin-top: 2%;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.pairing-results_w100__3Rxsc {
  width: 100%;
}

.pairing-results_searchContainer__3c83N {
  position: relative;
  background-color: white;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
  padding-top: 0;
  padding-bottom: 10%;
  transition: padding-top 0.5s;
}

.pairing-results_searchContainerExpanded__221X1 {
  padding-top: 4%;
}

.pairing-results_w60__1PtIp {
  height: 60px;
  width: 60px;
  margin: 0 4% 0 0;
}

.pairing-results_wineImg__2KGYP {
  height: 80%;
  background-color: #f4ece3;
}

.pairing-results_wineName__38KfM {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #343434;
}

.pairing-results_wineryName__1yIrf {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #343434;
}

.pairing-results_cardTextBox__33Law {
  flex-grow: 1;
  width: 100%;
}

.pairing-results_wineCardBoxRow__2C12L {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
  align-items: center;
}

.pairing-results_inputWithIcon__1O1Qb {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  border-radius: 8px;
  background-color: #efefef;
  border: none;
  margin-bottom: 2.4rem;
}

.pairing-results_searchBar__2-ond {
  flex-shrink: 0;
  flex-grow: 1;
  background-color: #efefef;
}

.pairing-results_blueBoxContainer__HHBiW {
  margin-top: -24px;
  width: 100%;
}

.pairing-results_blueBoxSuccessContainer__1I49r {
  margin-top: -40px;
}

.pairing-results_blueBox__2C-2I {
  margin: 2.4rem;
  display: flex;
  padding: 18px 20px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background: #1e7ed7;
  box-shadow: 0px 2px 24px 0px rgba(52, 52, 52, 0.42);
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pairing-results_blueBoxSuccess__2tmpf {
  margin: 2.4rem;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #1e7ed7;
  box-shadow: 0px 2px 24px 0px rgba(52, 52, 52, 0.42);
  grid-gap: 10px;
  gap: 10px;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pairing-results_bbText__AKq9E {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pairing-results_bbTextItalic__3erri {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.pairing-results_bbLink__bKZcc {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
}

.pairing-results_bbLink__bKZcc:hover {
  cursor: pointer;
  text-decoration: none;
}

.pairing-results_noResultBox__2-jLq {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
}

.pairing-results_noResultHeader__2F0Q1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}

.pairing-results_searchInstead__23qqW {
  color: #951901;
  text-decoration: underline;
}

.pairing-results_searchInstead__23qqW:hover {
  cursor: pointer;
  text-decoration: none;
}

.pairing-results_noResultImgBox__5Co1H {
  width: 141px;
  height: 122px;
}

.pairing-results_noResultImg__3DoML {
  object-fit: cover;
}

.pairing-results_sendRequestBox__bxLtN {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.pairing-results_formContainer__3vpMP {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.pairing-results_formInput__2Fl-f {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.pairing-results_formFieldError__2H2UX {
  color: red;
}

.pairing-results_submitBtn__yWscR {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pairing-results_grayedOut__Rubf4 {
  color: #7d7d7d;
  background: #efefef;
}
.varietal-card_fromRight__1gvsv {
  animation: varietal-card_fromRight__1gvsv 0.5s;
}

.varietal-card_fromLeft__25Xuh {
  animation: varietal-card_fromLeft__25Xuh 0.5s;
}

@keyframes varietal-card_fromRight__1gvsv {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes varietal-card_fromLeft__25Xuh {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.varietal-card_pairingImgBox__3NwWM {
  height: 180px;
}

.varietal-card_foodImg__Gc-dJ {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.varietal-card_varietalImg__1mj0Q {
  height: 160px;
  width: 40px;
  transform: rotate(8deg) translate(-20px);
  object-fit: cover;
}

.varietal-card_buyBtn__kRRoX {
  background-color: #343434;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  width: 100%;
  border-radius: 8px;
  border: none;
}

.varietal-card_mainContainer__iOAjO {
  margin-top: 2rem;
  width: 100%;
}

.varietal-card_mainContainerSection__RRQl4 {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.varietal-card_bg__1iHp6 {
  aspect-ratio: 1/1;
  width: 160px;
  background-color: #efefef;
  border-radius: 8px;
}

.varietal-card_wineImg__1Ae9E {
  height: 80%;
}

.varietal-card_wineName__Dk-Xf {
  max-width: 160px;
  margin-bottom: 0;
}

.varietal-card_smallBtn__14WkC {
  padding: 10px 8px;
  border-radius: 4px;
}

.varietal-card_price__FpQmO {
  color: #951901;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.varietal-card_getAtTarget__3tjI- {
  color: #343434;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.varietal-card_wineGrid__LE6oP {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.varietal-card_getBox__7VTKt {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.varietal-card_getWinesSection__1Ikte {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
  padding: 8px 0;
}

.varietal-card_rightArrow__Q8vX_ {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.varietal-card_targetLogo__1r4xh {
  width: 16px;
  height: 16px;
}

.varietal-card_seeAll__2GJCh {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
}

.varietal-card_seeAllBtn__iVZsn {
  background: none;
  border: none;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.varietal-card_seeAllBtnTxt__C5Kqm {
  color: #951901;
}

.varietal-card_link__e7IV2 {
  color: #951901;
}

.varietal-card_link__e7IV2:hover {
  color: black;
  text-decoration: none;
}

.varietal-card_wineCard__2dk2g {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.varietal-card_wineCardTextBox__3AQ7v {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.varietal-card_info__Qpz1G {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}
.pairing-feedback_helpful__2d0gm {
  margin-top: 8%;
  height: 50px;
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: #efefef;
  transition: justify-content 1s;
}

.pairing-feedback_helpfulClicked__HRWMP {
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}

.pairing-feedback_yesNo__2JSLb {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.pairing-feedback_yesNoBtn__2TwHz {
  background-color: white;
  border-radius: 4px;
  color: #343434;
  border: 1px solid white;
  padding: 8px 16px;
}

.pairing-feedback_yesNoBtn__2TwHz:hover {
  background-color: #efefef;
  border: 1px solid #7d7d7d;
}

.pairing-feedback_yesNoBtn__2TwHz:focus {
  color: white;
  background-color: #343434;
  border: 1px solid #343434;
}

.pairing-feedback_msgBox__33FX2 {
  flex-shrink: 0;
}
.wine-card_card__3ssfz {
  all: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.wine-card_ghostCard__P68zW {
  all: unset;
  width: 100%;
}

.wine-card_card__3ssfz:hover {
  cursor: pointer;
}

.wine-card_wineImgBg__3zw92 {
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100px;
  background-color: #efefef;
  border-radius: 8px;
}

.wine-card_wineImg__1aQUb {
  aspect-ratio: 1/1;
  object-fit: contain;
  width: 80%;
  height: 80%;
  mix-blend-mode: multiply;
}

.wine-card_buyBtn__Grb34 {
  display: flex;
  width: 100%;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 4px;
  border: none;
  background: #343434;
}

.wine-card_buyBtn__Grb34:hover {
  background: #7d7d7d;
}
.feedback_container__nVewO {
  width: 100%;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  border-radius: 4px;
  background: #e9f3fd;
}

.feedback_textBox__20Dsu {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
}

.feedback_btnBox__2EUST {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 12px;
  gap: 12px;
}

.feedback_btn__16MKG {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  flex: 1 0;
  border-radius: 4px;
  border: 1px solid #1e7ed7;
  background-color: white;
  color: #1e7ed7;
  transition: color 1s, background-color 1s;
  /* P3 - 14 SemiBold */
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.feedback_btnClicked__1NRym {
  color: white;
  background-color: #1e7ed7;
}

.feedback_thanksBox__2acE1 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}

.feedback_thanks__3QNt2 {
  color: #1e7ed7;
  /* P3 - 14 SemiBold */
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.results-scroll-bar_wineResultBar__1QeXb {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
}

.results-scroll-bar_wineResultBar__1QeXb::-webkit-scrollbar {
  display: none;
}

.results-scroll-bar_scrollMore__4ljVs {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.results-scroll-bar_scrollItem__2cpts {
  max-width: 160px;
  display: flex;
  padding: 10px 8px 10px 4px;
  justify-content: center;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  flex-shrink: 0;
  cursor: pointer;
}

.results-scroll-bar_scrollItemText__3t2Am {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #7d7d7d;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.results-scroll-bar_scrollItemNumber__2yl0b {
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.5;
}

.results-scroll-bar_selected__2M4Vo {
  border-bottom: 2px solid #951901;
}

.results-scroll-bar_selectedText__kXZ9Z {
  color: #951901;
}

.results-scroll-bar_grayLine__22sd8 {
  width: 100%;
  border-top: 1px solid var(--primary-light-gray, #EFEFEF);
}
.wine-varietal-card_fromRight__15Hqi {
  animation: wine-varietal-card_fromRight__15Hqi 0.5s;
}

.wine-varietal-card_fromLeft__3jJhV {
  animation: wine-varietal-card_fromLeft__3jJhV 0.5s;
}

@keyframes wine-varietal-card_fromRight__15Hqi {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes wine-varietal-card_fromLeft__3jJhV {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.wine-varietal-card_backToResultBtn__1WN71 {
  width: 100%;
  padding: 0 2.4rem;
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
  color: #951901;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.wine-varietal-card_backToResultBtnTxt__2kYi3 {
  color: #951901;
}

.wine-varietal-card_pairingImgBox__1sbZm {
  height: 192px;
  margin: 3.2rem 0;
}

.wine-varietal-card_pairingImg__3uyYc {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wine-varietal-card_logosBox__2mkzN {
  position: absolute;
  top: 0;
  left: 0;
}

.wine-varietal-card_logosBox__2mkzN > img {
  display: block;
  margin: 8px;
}

.wine-varietal-card_placerLogo__1VsDQ {
  height: 45px;
  width: 80px;
  margin-bottom: 1rem;
}

.wine-varietal-card_robleLogo__1vEGa {
  height: 80px;
  width: 80px;
}

.wine-varietal-card_varietalImg__3BL4R {
  height: 192px;
  width: auto;
  object-fit: contain;
}

.wine-varietal-card_buyBtn__1wZ3C {
  background-color: #343434;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  width: 100%;
  border-radius: 8px;
  border: none;
}

.wine-varietal-card_mainContainer__1fhL_ {
  margin-top: 2rem;
  width: 100%;
}

.wine-varietal-card_mainContainerSection__7_n9- {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.wine-varietal-card_bg__dTqlH {
  aspect-ratio: 1/1;
  width: 160px;
  background-color: #efefef;
  border-radius: 8px;
}

.wine-varietal-card_wineImg__2wNkR {
  height: 80%;
}

.wine-varietal-card_wineName__S0UqH {
  max-width: 160px;
  margin-bottom: 0;
}

.wine-varietal-card_smallBtn__kNr-C {
  padding: 10px 8px;
  border-radius: 4px;
}

.wine-varietal-card_price__loDDo {
  color: #951901;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.wine-varietal-card_getAtTarget__3LVUv {
  color: #343434;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.wine-varietal-card_wineGrid__2Nhac {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.wine-varietal-card_getBox__2LE7Q {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.wine-varietal-card_getWinesSection__9ygNs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
  padding: 8px 0;
}

.wine-varietal-card_rightArrow__1Q4M4 {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.wine-varietal-card_targetLogo__oJyCR {
  width: 16px;
  height: 16px;
}

.wine-varietal-card_seeAll__2O4LC {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
}

.wine-varietal-card_seeAllBtn__nr2Ah {
  background: none;
  border: none;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.wine-varietal-card_seeAllBtnTxt__1AuRC {
  color: #951901;
}

.wine-varietal-card_link__1iurS {
  color: #951901;
}

.wine-varietal-card_link__1iurS:hover {
  color: black;
  text-decoration: none;
}

.wine-varietal-card_wineCard__3tfns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.wine-varietal-card_wineCardTextBox__1TAut {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.wine-varietal-card_info__1S0dd {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.wine-varietal-card_varietalCard__8L_8y {
  display: flex;
  width: 100%;
  padding: 2.4rem;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid #efefef;
}

.wine-varietal-card_flavorProfBox__39ydx {
  margin: 2.4rem 0;
}

.wine-varietal-card_fadeBox__dn94p {
  position: relative;
}

.wine-varietal-card_fade__Qq0VP {
  position: relative;
  height: 2.4em; /* exactly three lines */
  overflow: hidden;
}

.wine-varietal-card_fade__Qq0VP::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 80%);
}

.wine-varietal-card_fadeSeeMore__2IM_4 {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 10;
  text-decoration: underline;
  color: #951901;
  background-color: white;
}

.wine-varietal-card_fadeSeeMore__2IM_4:hover {
  text-decoration: none;
}

.wine-varietal-card_pairingsBox__4xO6O {
  margin: 2.4rem 0;
  position: relative;
}

.wine-varietal-card_gradientContainer__2PGfq {
  position: relative;
}

.wine-varietal-card_gradient__1KIdU {
  position: absolute;
  background-image: linear-gradient(to top, white, transparent);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255) 90%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.wine-varietal-card_phoneticResults__23woJ {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding: 3% 4% 3% 4%;
}

.wine-varietal-card_phoneticResultsImg__2nI-p {
  padding-right: 4%;
}

.wine-varietal-card_viewFull__MxtE1 {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border: none;
  border-radius: 8px;
  background: var(--Primary-Burgundy, #951901);
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.flavor-profile-card2_varietalCard__2mBSQ {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.flavor-profile-card2_profileNotes__2MXyq {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.flavor-profile-card2_flavorList__2DHER {
  width: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flavor-profile-card2_flavorList2__3mESC {
  width: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (min-width: 600px) {
  .flavor-profile-card2_flavorList2__3mESC {
    padding: 0 24px;
  }
}
.flavor-profile-card2_flavorListItem__3VUXD {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.flavor-profile-card2_flavorListImg__1dyNp {
  border-radius: 50%;
  height: 72px;
  width: 72px;
  object-fit: cover;
}

.flavor-profile-card2_ratingsList__1L_dU {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.flavor-profile-card2_ratingsListItem__1bvbw {
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.flavor-profile-card2_measureBar__1GTbT {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  height: 24px;
}

.flavor-profile-card2_valueRight__IQJep {
  text-align: left;
  width: 20%;
}

.flavor-profile-card2_valueLeft__ezE5O {
  text-align: right;
  width: 20%;
}

.flavor-profile-card2_valuedBar__WbB4v {
  background: #343434;
  height: 6px;
  width: 30px;
  border-radius: 100px;
}

.flavor-profile-card2_notValuedBar__1dOPP {
  background: #efefef;
  height: 3px;
  width: 30px;
  border-radius: 100px;
}
.food-card_mainContainer__3w_fF {
  margin-top: 2rem;
  width: 100%;
}

.food-card_foodImg__3LC1k {
  height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.food-card_buyBtn__3N8-8 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  background-color: #343434;
  padding: 12px 10px;
  width: 100%;
  border-radius: 8px;
  border: none;
  margin: 1rem 0 3rem 0;
}

.food-card_foodNameText__3FDEH {
  margin-bottom: 1rem;
}

.food-card_headerInfo__2kXpv {
  margin: 2rem 0rem;
}

.food-card_descriptionText__28tGX {
  color: #7d7d7d;
}
.see-all-wines_fromRight__1BQuA {
  animation: see-all-wines_fromRight__1BQuA 0.5s;
}

.see-all-wines_fromLeft__1zy0Z {
  animation: see-all-wines_fromLeft__1zy0Z 0.5s;
}

@keyframes see-all-wines_fromRight__1BQuA {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes see-all-wines_fromLeft__1zy0Z {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.see-all-wines_backToResultBtn__3d8Vd {
  background: none;
  border: none;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
  color: #951901;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.see-all-wines_backToResultBtnTxt__2Qh5e {
  color: #951901;
}

.see-all-wines_link__m9sKC {
  color: #951901;
}

.see-all-wines_link__m9sKC:hover {
  color: black;
  text-decoration: none;
}

.see-all-wines_buyBtn__3g34X {
  font-family: Inter, sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #343434;
  padding: 12px 10px;
  width: 160px;
  border-radius: 8px;
  border: none;
  margin-top: 4%;
  margin-bottom: 10%;
}

.see-all-wines_mainContainer__1IzrT {
  margin-top: 2%;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.see-all-wines_smallBtn__3-FVj {
  padding: 10px 8px;
  border-radius: 4px;
}

.see-all-wines_price__7X50S {
  color: #951901;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.see-all-wines_getAtTarget__1W3iv {
  color: #343434;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.see-all-wines_helpful__zLijT {
  margin-top: 8%;
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: #efefef;
  margin-bottom: 10%;
}

.see-all-wines_wineGrid__sBbxl {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 22px;
  row-gap: 22px;
  grid-column-gap: 22px;
  column-gap: 22px;
}

.see-all-wines_seeAllCard__1c2Dt {
  aspect-ratio: 1/1;
  background-color: rgba(149, 25, 1, 0.08);
  border-radius: 8px;
  border: 1px solid #951901;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  flex-shrink: 0;
}

.see-all-wines_seeAllCardText__2QTZ3 {
  color: #951901;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 45%;
}

.see-all-wines_rightArrow__1zMfz {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.see-all-wines_targetLogo__1gDSQ {
  width: 16px;
  height: 16px;
}

.see-all-wines_getBox__3gKKO {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 1rem;
}

.see-all-wines_header__3Qq-j {
  margin-bottom: 2.4rem;
}
.account-setup_formContainer__zapcY {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
}

.account-setup_formField__2WMhW {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
}

.account-setup_formFieldError__2vmQM {
  color: red;
}

.account-setup_asterisk__2svTs {
  font-weight: 800;
  color: #951901;
}

.account-setup_serverErrorBox__26dil {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #f6efef;
  color: red;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.account-setup_formInput__YV_sH {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.account-setup_mainContainer__fkr7f {
  margin-top: 6rem;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.account-setup_mainContainerSection__1JURy {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 40px;
  gap: 40px;
}

.account-setup_checkbox__16p5f {
  flex-shrink: 0;
  color: white;
  fill: white;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #343434;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.account-setup_checked__wX336 {
  background-color: #343434;
}

.account-setup_ambassadorItem__1AKpo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.account-setup_animationWrapper__91PAI {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  margin-bottom: 0;
  transition: grid-template-rows 0.3s, opacity 0.3s, margin-bottom 0.3s;
}

.account-setup_animationExpandable__fZhVF {
  min-height: 0;
}

.account-setup_expanded__h3_rn {
  grid-template-rows: 1fr;
  opacity: 1;
  margin-bottom: 40px;
}

.account-setup_inputAndResultsBox__3TIua {
  display: flex;
  width: 100%;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
}

.account-setup_inputWithIcon__14la5 {
  display: flex;
  width: 100%;
  padding: 18px 16px;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  border-radius: 8px;
  background: white;
}

.account-setup_borderBottom__OG_BZ {
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
  border-radius: 8px 8px 0 0;
}

.account-setup_storesListBox__1mcqs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.account-setup_storesListItem__1Qyh4 {
  border-radius: 8px;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background: white;
}

.account-setup_storesListItem__1Qyh4:hover {
  background: #efefef;
}

.account-setup_storeText__3Y7Nx {
  color: #343434;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.account-setup_noShrink__fclVN {
  flex-shrink: 0;
  flex-grow: 1;
}

.account-setup_createAccText__2nWro {
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.account-setup_termsOfUseText__i0oy4 {
  color: #951901;
  cursor: pointer;
}

.account-setup_required__Bc_8D {
  color: #951901;
}

.account-setup_submitBtn__3GPkS {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.account-setup_grayedOut__1YCNu {
  color: #7d7d7d;
  background: #efefef;
}

.account-setup_loginBox__1-b26 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.account-setup_loginTxt__E835z {
  color: #951901;
}

.account-setup_loginTxt__E835z:hover {
  text-decoration: underline;
}
.terms-of-use_mainContainer__3Xvap {
  margin: 4rem 0;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.terms-of-use_title__36rQ- {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 2rem;
}

.terms-of-use_sectionTitleBox___J8Aq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px;
}

.terms-of-use_sectionsBox__YSCe9 {
  border-top: 1px solid #efefef;
}

.terms-of-use_section__1a088 {
  border-bottom: 1px solid #efefef;
}

.terms-of-use_borderBottom__1LPHy {
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
  border-radius: 8px 8px 0 0;
}

.terms-of-use_animationWrapper__HSgwc {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  width: 100%;
  margin-bottom: 0;
  transition: grid-template-rows 0.3s, opacity 0.3s, margin-bottom 0.3s;
}

.terms-of-use_animationExpandable__J2dGu {
  min-height: 0;
}

.terms-of-use_expanded__12P4w {
  grid-template-rows: 1fr;
}

.terms-of-use_spacing__35F0o {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
}

.terms-of-use_link__MTfmk {
  color: #951901;
}
.privacy-policy_mainContainer__32LvB {
  margin: 4rem 0;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.privacy-policy_title__2kZdd {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
  margin-bottom: 2rem;
}

.privacy-policy_sectionTitleBox__2cb9G {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px;
}

.privacy-policy_sectionsBox__37xY9 {
  border-top: 1px solid #efefef;
}

.privacy-policy_section__42ZQ2 {
  border-bottom: 1px solid #efefef;
}

.privacy-policy_borderBottom__rnUUT {
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
  border-radius: 8px 8px 0 0;
}

.privacy-policy_animationWrapper__QpI3J {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  width: 100%;
  margin-bottom: 0;
  transition: grid-template-rows 0.3s, opacity 0.3s, margin-bottom 0.3s;
}

.privacy-policy_animationExpandable__G3ncd {
  min-height: 0;
}

.privacy-policy_expanded__1h2n9 {
  grid-template-rows: 1fr;
}

.privacy-policy_spacing__3khVz {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
}

.privacy-policy_link__3Lpyy {
  color: #951901;
}
.login_formContainer__2gkxh {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
}

.login_formField__eGSAW {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
}

.login_formFieldError__2mXlS {
  color: red;
}

.login_asterisk__S_Gn3 {
  font-weight: 800;
  color: #951901;
}

.login_serverErrorBox__2hnCr {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #f6efef;
  color: red;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login_formInput__jUtKo {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.login_mainContainer__2tUs5 {
  margin-top: 6rem;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.login_mainContainerSection__2hfBv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 40px;
  gap: 40px;
}

.login_checkbox__3Tjah {
  flex-shrink: 0;
  color: white;
  fill: white;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #343434;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.login_checked__3jzuT {
  background-color: #343434;
}

.login_ambassadorItem__20ICH {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.login_animationWrapper__m69SC {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  margin-bottom: 0;
  transition: grid-template-rows 0.3s, opacity 0.3s, margin-bottom 0.3s;
}

.login_animationExpandable__2_M9S {
  min-height: 0;
}

.login_expanded__OjsO2 {
  grid-template-rows: 1fr;
  opacity: 1;
  margin-bottom: 40px;
}

.login_inputAndResultsBox__fQ9da {
  display: flex;
  width: 100%;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
}

.login_inputWithIcon__141mk {
  display: flex;
  width: 100%;
  padding: 18px 16px;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  border-radius: 8px;
  background: white;
}

.login_borderBottom__2h6FV {
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
  border-radius: 8px 8px 0 0;
}

.login_storesListBox__3J88j {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.login_storesListItem__1HRjY {
  border-radius: 8px;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background: white;
}

.login_storesListItem__1HRjY:hover {
  background: #efefef;
}

.login_storeText__qFw0l {
  color: #343434;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login_noShrink__3XK5M {
  flex-shrink: 0;
  flex-grow: 1;
}

.login_createAccText__2l_8J {
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.login_termsOfUseText__3vqA8 {
  color: #951901;
  cursor: pointer;
}

.login_required__eKNy6 {
  color: #951901;
}

.login_submitBtn__2qNvd {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.login_grayedOut__2YhJK {
  color: #7d7d7d;
  background: #efefef;
}

.login_loginBox__v_fW2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.login_loginTxt__v0ZMq {
  color: #951901;
}

.login_loginTxt__v0ZMq:hover {
  text-decoration: underline;
}
.test-screen_mainContainer__16pYU {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.test-screen_booton__1Tm59 {
  padding: 20px;
  background-color: rgb(56, 238, 238);
}
.profile_main__1-mA0 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.profile_head__3QVEA {
  padding-top: 3.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.profile_title__3egcd {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.profile_line__1lYeD {
  width: 100%;
  border-top: 1px solid #efefef;
}

.profile_personalInfoSection__14ewO {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.profile_sectionTitle__3pcMX {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.profile_editBox__zNVEq {
  all: unset;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.profile_personalInfoItem__1K9Dq {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
}

.profile_savedSection__1nLvB {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.profile_historySection__1Jtvk {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.profile_savedCardBox__1FEp6 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 22px;
  gap: 22px;
}
.role-pill_pill__2Bbyy {
  display: inline-block;
  padding: 2px 10px 1px 8px;
  border-radius: 100px;
  background-color: rgba(149, 25, 1, 0.1);
  color: #951901;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.help_formContainer__1yjw8 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 24px;
  gap: 24px;
}

.help_formField__3Gh9C {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
}

.help_formFieldError__18cmS {
  color: red;
}

.help_asterisk__3bVUM {
  font-weight: 800;
  color: #951901;
}

.help_serverErrorBox__1zGj7 {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #f6efef;
  color: red;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.help_formInput__28Kqt {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.help_formInputTextArea__2ND4Y {
  height: 140px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.help_main__2FmT5 {
  padding-top: 3.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.help_head__3eccT {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.help_title__2y9M2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.help_line__2c5eJ {
  width: 100%;
  border-top: 1px solid #efefef;
}

.help_formSection__2xFA4 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.help_mainContainerSection__6VaIM {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 40px;
  gap: 40px;
}

.help_checkbox__2IB5c {
  flex-shrink: 0;
  color: white;
  fill: white;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #343434;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.help_checked__3TUMg {
  background-color: #343434;
}

.help_ambassadorItem__2RAVW {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.help_animationWrapper__3qFEw {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  margin-bottom: 0;
  transition: grid-template-rows 0.3s, opacity 0.3s, margin-bottom 0.3s;
}

.help_animationExpandable__2xcA_ {
  min-height: 0;
}

.help_expanded__3Gn5x {
  grid-template-rows: 1fr;
  opacity: 1;
  margin-bottom: 40px;
}

.help_inputAndResultsBox__3M-HP {
  display: flex;
  width: 100%;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
}

.help_inputWithIcon__3OQp1 {
  display: flex;
  width: 100%;
  padding: 18px 16px;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  border-radius: 8px;
  background: white;
}

.help_borderBottom__1UAKV {
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
  border-radius: 8px 8px 0 0;
}

.help_storesListBox__3QvZ_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.help_noShrink__14XwE {
  flex-shrink: 0;
  flex-grow: 1;
}

.help_createAccText__GzdxR {
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.help_termsOfUseText__mjNTu {
  color: #951901;
  cursor: pointer;
}

.help_required__PqDA9 {
  color: #951901;
}

.help_submitBtn__3FZ1O {
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.help_grayedOut__3nkaN {
  color: #7d7d7d;
  background: #efefef;
}

.help_mainTY__2o8U6 {
  padding-top: 3.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.help_box__3VXOa {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
}

.help_editBox__2GGFf {
  width: 100%;
  justify-self: flex-start;
  all: unset;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}
.winery-landing_titleBox__6uLM2 {
  margin: 4rem 0 3.2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.winery-landing_welcomeBox__1gje3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.winery-landing_start__WTxpw {
  margin-top: 1rem;
  margin-bottom: 4rem;
  text-align: left;
}

.winery-landing_startAmb__3_f_P {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.winery-landing_inputContainer__2RVQQ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.winery-landing_inputBox__26B6P {
  width: 100%;
  padding: 4% 5%;
  border: 1px solid #7d7d7d;
  border-radius: 8px;
}

.winery-landing_setupBtn__1wakm {
  font-family: Inter, sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #343434;
  padding: 12px 10px;
  border-radius: 8px;
  border: none;
}

.winery-landing_setupBtn__1wakm:hover {
  background-color: #dedede;
}

.winery-landing_pageSection__3xhmQ {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.winery-landing_pageSection2__2FAB3 {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
}

.winery-landing_searchContainer__1hpq_ {
  width: 100%;
  margin-top: 3rem;
}

.winery-landing_searchBox__hD5QI {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.winery-landing_partnerLogo__2_9bN {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.winery-landing_bgRed__1NBDO {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  background-color: #f6efef;
}

.winery-landing_bgWhite__3BA62 {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  background-color: #f4ece3;
}

.winery-landing_bg__1i2ze {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
}

.winery-landing_w60__3X2iq {
  height: 60px;
  width: 60px;
  margin: 0 4% 0 0;
}

.winery-landing_wineImg__Ycrg2 {
  height: 80%;
  background-color: #f4ece3;
}

.winery-landing_wineryName__2d_dM {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #343434;
}

.winery-landing_phoneticResults__2wJx5 {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding: 3% 4% 3% 4%;
}

.winery-landing_phoneticResults__2wJx5:hover {
  cursor: pointer;
}

.winery-landing_phoneticResultsImg__3ysCS {
  padding-right: 4%;
}

.winery-landing_phoneticResults__2wJx5:hover {
  background-color: #dedede;
}

.winery-landing_animationExpandable__YboBw {
  min-height: 0;
}

.winery-landing_animationWrapper__M-IXT {
  width: 100%;
  opacity: 1;
  background-color: #efefef;
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s, opacity 0.3s, background-color 0.3s;
}

.winery-landing_collapsed__OEDO9 {
  background-color: white;
  opacity: 0;
  grid-template-rows: 0fr;
}

.winery-landing_closeBtn__2_1AQ {
  float: right;
  background: #efefef;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  outline: none;
  min-height: 40px;
  min-width: 40px;
  flex-shrink: 0;
  flex-grow: 0;
}

.winery-landing_btnNoClick__2EV3R {
  cursor: default !important;
}

.winery-landing_wineCardBoxCol__hB_3T {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.winery-landing_wineCardBoxRow__26X2- {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
  align-items: center;
}

.winery-landing_loginSignupText__3s40J {
  text-decoration: underline;
  color: #951901;
}

.winery-landing_loginSignupText__3s40J:hover {
  text-decoration: none;
  cursor: pointer;
}

.winery-landing_spotlightBox__2ftOp {
  margin-top: 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.winery-landing_pairingsBox__2k_ik {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.winery-landing_todaysWinesBox__2qOle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  grid-gap: 22px;
  gap: 22px;
}

.winery-landing_todaysWinesBoxRoble__1nAJq {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  grid-gap: 18px;
  gap: 18px;
}

.winery-landing_todaysWinesBoxFocused__3hC4Y {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.winery-landing_inputWithIcon__1a21g {
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 16px;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  border-radius: 8px;
  background: white;
  border: 1px solid #343434;
}

.winery-landing_noShrink__1g2Oo {
  flex-shrink: 0;
  flex-grow: 1;
}

.winery-landing_pairBtn__1xovQ {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 4px;
  border: none;
  background: #343434;
  color: white;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.winery-landing_pairBtnHidden__tHr7i {
  height: 36px;
}

.winery-landing_savedCardBox__1UNAj {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 22px;
  gap: 22px;
}
.spotlight-card_card__uEqlt {
  all: unset;
  width: 46%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.spotlight-card_ghostCard__21vNr {
  all: unset;
  width: 46%;
}

.spotlight-card_wineImgBg__27wW7 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  min-width: 100px;
  background-color: #efefef;
  border-radius: 8px;
}

.spotlight-card_wineImg__2CQlE {
  aspect-ratio: 1/1;
  object-fit: contain;
  width: 80%;
  height: 80%;
  mix-blend-mode: multiply;
}
.dd-landing_main__3pYIT {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dd-landing_titleTxt__1bVAT {
  width: 90%;
  margin-top: 4vh;
  font-size: 30px;
  font-weight: 800;
  color: rgb(192, 17, 17);
  font-family: Montserrat, sans-serif;
  text-align: center;
}

.dd-landing_wineRow__jgWtl {
  width: 90%;
  margin-top: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: top;
  grid-gap: 2vw;
  gap: 2vw;
  flex-wrap: wrap;
}

.dd-landing_logo__1tEPa {
  margin-top: 10vh;
  margin-bottom: 2vh;
  width: 70px;
}

.dd-landing_card__2_Jx5 {
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 48%;
  border-radius: 8px;
  grid-gap: 8px;
  gap: 8px;
}

.dd-landing_card__2_Jx5:hover {
  cursor: pointer;
}

.dd-landing_wineImgBg__3hHM5 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  min-width: 100px;
  border-radius: 8px;
}

.dd-landing_wineImg__39FIM {
  object-fit: contain;
  height: 84%;
  width: 100%;
}

.dd-landing_text__xZ9Wq {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  font-family: Inter, sans-serif;
  color: #343434;
}

.dd-landing_wineTypeText__hEBq5 {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  color: #7d7d7d;
}

@media (min-width: 600px) {
  .dd-landing_main__3pYIT {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dd-landing_titleTxt__1bVAT {
    font-size: 3.2vw;
  }
  .dd-landing_wineTypeText__hEBq5 {
    font-size: 1.6vw;
  }
  .dd-landing_text__xZ9Wq {
    font-size: 1.8vw;
  }
  .dd-landing_wineImgBg__3hHM5 {
    height: 22vw;
  }
  .dd-landing_wineRow__jgWtl {
    width: 90%;
    margin-top: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: top;
    grid-gap: 2vw;
    gap: 2vw;
    flex-wrap: nowrap;
  }
  .dd-landing_card__2_Jx5 {
    width: 100%;
    grid-gap: 1vh;
    gap: 1vh;
  }
  .dd-landing_logo__1tEPa {
    width: 10vw;
  }
}
.dd-pairing_main__1u7Ek {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dd-pairing_winePanel__3krPb {
  position: relative;
  width: 100%;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
}

.dd-pairing_wineImg__3WGvL {
  object-fit: contain;
  width: 100%;
  height: 90%;
}

.dd-pairing_backBtn__2QKhS {
  all: unset;
}

.dd-pairing_backBtn__2QKhS:hover {
  cursor: pointer;
}

.dd-pairing_detailsPanel__3pPeK {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.dd-pairing_titleBox__256wd {
  margin: 2.4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2vh;
  gap: 2vh;
  width: 92%;
}

.dd-pairing_title__sTmpu {
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: 30px;
  color: #d0011b;
  text-align: center;
}

.dd-pairing_wineDescription__bN6hD {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
  text-align: center;
}

.dd-pairing_pairsWithBox__2l-_Y {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1.6rem;
  gap: 1.6rem;
  width: 92%;
}

.dd-pairing_pairsWith__20pE_ {
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: 22px;
  color: #d0011b;
  text-align: center;
}

.dd-pairing_foodCardRow__1oHxZ {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 2.4rem 4.8rem;
  gap: 2.4rem 4.8rem;
  flex-wrap: wrap;
}

.dd-pairing_foodCard__1jvXp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48%;
  max-width: 130px;
  grid-gap: 1.2rem;
  gap: 1.2rem;
}

.dd-pairing_foodImg__26EDG {
  float: left;
  object-fit: cover;
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.dd-pairing_foodNameTxt__1Js53 {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #343434;
  text-align: center;
}

.dd-pairing_pairingTypeTxt__1Bq6N {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #7d7d7d;
  text-align: center;
}

.dd-pairing_shareBtn__3nBv- {
  all: unset;
  margin-top: 2.4rem;
  display: flex;
  padding: 16px 48px;
  justify-content: center;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  margin-bottom: 4.8rem;
  border-radius: 100px;
  background: #ac0419;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
}

.dd-pairing_shareBtnTxt__29PLv {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.dd-pairing_phoneFormBox__28UVU {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 2.4rem 4.8rem;
  margin-bottom: 2.4rem;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.25);
}

.dd-pairing_filler__37PV9 {
  margin-bottom: 2.4rem;
}

.dd-pairing_phoneFormInnerBox__1XjnS {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dd-pairing_phoneTxt__14yyz {
  margin-top: 8px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #7d7d7d;
}

.dd-pairing_formContainer__2LnLN {
  margin-top: 1.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1.8rem;
  gap: 1.8rem;
}

.dd-pairing_formField__11B3F {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.dd-pairing_formFieldError__3hYWw {
  color: red;
}

.dd-pairing_formInput__20D7l {
  width: 100%;
  padding: 1.6rem 2.4rem;
  border-radius: 1.4vw;
  background: #efefef;
  font-size: 16px;
  color: #343434;
  text-align: center;
}

.dd-pairing_submitBtn__MM9CU {
  all: unset;
  display: flex;
  padding: 1.6rem 4.8rem;
  justify-content: center;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  align-self: stretch;
  border-radius: 100px;
  background: #ac0419;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
}

.dd-pairing_submitBtn__MM9CU:hover {
  cursor: pointer;
}

.dd-pairing_submitBtnTxt__2-BwH {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.dd-pairing_submittedBox__B6E_h {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1.8rem;
  gap: 1.8rem;
}

.dd-pairing_grayedOut__1nBQJ {
  background: #7d7d7d;
}

.dd-pairing_modalBackground__14R8U {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.dd-pairing_modalBox__19LhN {
  position: relative;
  max-width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2.4rem 1.2rem 4.8rem 1.2rem;
  background-color: white;
  border-radius: 8px;
}

.dd-pairing_modalContent__1PATW {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  grid-gap: 2vw;
  gap: 2vw;
}

.dd-pairing_qrBox__1gLe2 {
  width: 60%;
  padding: 2.4rem;
  border: 1px solid rgba(125, 125, 125, 0.25);
  border-radius: 1vw;
}

.dd-pairing_closeBtn__17DL1 {
  width: 12px;
  height: 12px;
  right: 2.4rem;
  top: 2.4rem;
  position: absolute;
  cursor: pointer;
}

.dd-pairing_backBtn__2QKhS {
  width: 42px;
  height: 42px;
  left: 2.4rem;
  top: 2.4rem;
  position: absolute;
  cursor: pointer;
}

.dd-pairing_sendIcon__2Ney4 {
  width: 12px;
  height: 12px;
}

@media (min-width: 600px) {
  .dd-pairing_main__1u7Ek {
    height: 100vh;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .dd-pairing_winePanel__3krPb {
    position: relative;
    width: 34%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
  }
  .dd-pairing_wineImg__3WGvL {
    min-height: 90%;
  }
  .dd-pairing_detailsPanel__3pPeK {
    width: 66%;
  }
  .dd-pairing_titleBox__256wd {
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 2vh;
    gap: 2vh;
    width: 80%;
  }
  .dd-pairing_title__sTmpu {
    font-size: 3.2vw;
  }
  .dd-pairing_wineDescription__bN6hD {
    font-size: 1.6vw;
  }
  .dd-pairing_pairsWithBox__2l-_Y {
    margin-top: 2vh;
    grid-gap: 2.4vh;
    gap: 2.4vh;
    width: 80%;
  }
  .dd-pairing_pairsWith__20pE_ {
    font-size: 2vw;
  }
  .dd-pairing_foodCardRow__1oHxZ {
    justify-content: space-between;
    grid-gap: 2vw;
    gap: 2vw;
    flex-wrap: nowrap;
    width: 100%;
  }
  .dd-pairing_foodCard__1jvXp {
    width: 100%;
    max-width: none;
    grid-gap: 1.6vh;
    gap: 1.6vh;
  }
  .dd-pairing_foodImg__26EDG {
    width: 12vw;
    height: 12vw;
  }
  .dd-pairing_foodNameTxt__1Js53 {
    font-size: 1.4vw;
  }
  .dd-pairing_pairingTypeTxt__1Bq6N {
    font-size: 1.4vw;
  }
  .dd-pairing_shareBtn__3nBv- {
    margin-top: 6vh;
    padding: 1.4vw 6vw;
    grid-gap: 1vw;
    gap: 1vw;
    margin-bottom: 0;
  }
  .dd-pairing_shareBtnTxt__29PLv {
    font-size: 1.8vw;
  }
  .dd-pairing_phoneFormBox__28UVU {
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    padding: 0;
    margin-bottom: 0;
    border-radius: 1.4vw;
    border: 1px solid rgba(125, 125, 125, 0.25);
  }
  .dd-pairing_phoneFormInnerBox__1XjnS {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 6vh 0;
  }
  .dd-pairing_phoneTxt__14yyz {
    margin-top: 2.4vh;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 1.6vw;
    color: #7d7d7d;
  }
  .dd-pairing_formContainer__2LnLN {
    padding-top: 4vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 2.4vw;
    gap: 2.4vw;
  }
  .dd-pairing_formInput__20D7l {
    width: 100%;
    padding: 2vw 2vw;
    border-radius: 1.4vw;
    background: #efefef;
    font-size: 2vw;
    color: #343434;
    text-align: center;
  }
  .dd-pairing_submitBtn__MM9CU {
    width: 100%;
    padding: 1.4vw 0;
    grid-gap: 1vw;
    gap: 1vw;
  }
  .dd-pairing_submitBtnTxt__2-BwH {
    font-size: 2vw;
  }
  .dd-pairing_submittedBox__B6E_h {
    grid-gap: 6vh;
    gap: 6vh;
  }
  .dd-pairing_modalBackground__14R8U {
    padding: 2rem 2.4rem 2rem 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    height: auto;
    overflow: auto;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;
  }
  .dd-pairing_modalBox__19LhN {
    position: relative;
    max-width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 4vw 6vw;
    background-color: white;
    border-radius: 8px;
  }
  .dd-pairing_modalContent__1PATW {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 2vw;
    gap: 2vw;
  }
  .dd-pairing_qrBox__1gLe2 {
    max-width: 40%;
    padding: 4vw;
    border: 1px solid rgba(125, 125, 125, 0.25);
    border-radius: 1vw;
  }
  .dd-pairing_closeBtn__17DL1 {
    width: 2vw;
    height: 2vw;
    right: 3.2vw;
    top: 3.2vw;
  }
  .dd-pairing_backBtn__2QKhS {
    width: 4vw;
    height: 4vw;
    left: 4vw;
    top: 2vw;
  }
  .dd-pairing_sendIcon__2Ney4 {
    width: 1.4vw;
    height: 1.4vw;
  }
}
.landing_main__bWUmp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing_stickyHeader__2P9rT {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 24px;
  background-color: #951901;
  text-align: center;
  z-index: 10;
}

.landing_stickyHeaderTxt__5IvAe {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.landing_stickyHeaderTxt__5IvAe:hover {
  color: white;
  text-decoration: underline;
}

.landing_box__354xL {
  width: 100%;
  padding: 48px 24px;
}

.landing_titleTxt__1LKzu {
  color: #951901;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 800;
}

.landing_wineGrid__3t6Sk {
  padding-top: 24px;
  width: 100%;
  display: grid;
  grid-gap: 24px;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
}

.landing_logo__1SBZ4 {
  margin-top: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.landing_logo__1SBZ4 > img {
  display: block;
  width: 140px;
}

.landing_card__1bDyN {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.landing_cardTitle__1-84T {
  padding: 16px 12px;
  color: #343434;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.landing_cardTitle__1-84T:hover {
  color: #343434;
  text-decoration: underline;
}

.landing_cardImg__2mxAs {
  overflow: hidden;
}

.landing_cardImg__2mxAs > img {
  display: block;
  width: 100%;
  max-height: 200px;
  aspect-ratio: 8/9;
  object-fit: cover;
  object-position: center;
}

.landing_card__1bDyN:hover {
  background-color: rgb(242, 242, 242);
  cursor: pointer;
}
.pairing_main__39oWM {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pairing_stickyHeader__13CDa {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 24px;
  background-color: #951901;
  text-align: center;
  z-index: 10;
}

.pairing_stickyHeaderTxt__3AuGs {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.pairing_stickyHeaderTxt__3AuGs:hover {
  color: white;
  text-decoration: underline;
}

.pairing_box__3vyLy {
  width: 100%;
  padding: 48px 24px;
}

.pairing_pairingImgBox__1wdIU {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
}

.pairing_backBtn__1VABJ {
  all: unset;
}

.pairing_backBtn__1VABJ:hover {
  cursor: pointer;
}

.pairing_foodImg__1Zh9b {
  height: 192px;
  width: 192px;
  border-radius: 50%;
  object-fit: cover;
}

.pairing_wineImg__2IioS {
  height: 220px;
  width: auto;
  transform: rotate(8deg) translate(-20px);
  object-fit: cover;
}

.pairing_titleTxt__qVQAD {
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 800;
}

.pairing_pairsWithTxt__Pg_xj {
  color: #343434;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  padding: 8px 0;
}

.pairing_wineDescriptionTxt__36ZzU {
  padding-top: 16px;
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.pairing_lineDivider__2GP3N {
  margin: 24px 0;
  width: 100%;
  height: 1px;
  background: #efefef;
}

.pairing_ratingBox__2-zdX {
  width: 100%;
}

.pairing_ratingTitle__GPMm0 {
  color: #343434;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
  padding-bottom: 16px;
}

.pairing_buttonBox__1qQ16 {
  width: 100%;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
}

.pairing_likeBtn__2hhDj {
  all: unset;
  display: flex;
  width: 100%;
  padding: 18px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  background: #efefef;
}

.pairing_likeBtn__2hhDj > p {
  color: #343434;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.pairing_formBox__3kwVW {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pairing_formField__5FQBG {
  padding-top: 16px;
  padding-bottom: 24px;
}

.pairing_asteriskTxt__13tUZ {
  width: 100%;
  color: #951901;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

.pairing_formInput__2mntk {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.pairing_submitBtn__2pS_r {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #343434;
  border: none;
}

.pairing_submitBtnTxt__5Lr5c {
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.pairing_grayedOut__2Ya53 {
  background: #7d7d7d;
}

.pairing_grayedOut__2Ya53:hover {
  cursor: default;
}

.pairing_tyTxt__SEEHv {
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.winery-wine-details_mainContainer__3xVy- {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.winery-wine-details_mainContainerSection__2ySM3 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  width: 100%;
}

.winery-wine-details_logo__33uh7 {
  width: 96px;
  height: 96px;
  position: absolute;
  object-fit: contain;
  left: 16px;
  top: 12px;
}

.winery-wine-details_logoImg__1EK0X {
  object-fit: contain;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 4px;
}

.winery-wine-details_imgFrame__i_GSb {
  position: relative;
  width: 100%;
  height: 260px;
  flex-shrink: 0;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 2.4rem;
}

.winery-wine-details_wineImg__2ZDox {
  object-fit: contain;
  height: 90%;
  flex-shrink: 0;
  border-radius: 4px;
}

.winery-wine-details_info__3VIWX {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.winery-wine-details_infoLine2__1Lqjq {
  display: flex;
  width: 100%;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.winery-wine-details_priceBox__3NNUe {
  display: flex;
  width: 100%;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.winery-wine-details_price__3-Dz- {
  display: flex;
  padding-right: 0px;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.winery-wine-details_grayVert__25q_R {
  width: 1px;
  height: 40px;
  background-color: #efefef;
}

.winery-wine-details_detailsBox__SixKk {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.winery-wine-details_backToResultBtn__ehq4c {
  background: none;
  border: none;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
  color: #951901;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.winery-wine-details_backToResultBtnTxt__TcAkS {
  color: #951901;
}

.winery-wine-details_link__21Mrb {
  color: #951901;
}

.winery-wine-details_link__21Mrb:hover {
  color: black;
  text-decoration: none;
}

.winery-wine-details_padding__HURog {
  margin-top: 5.6rem;
}

.winery-wine-details_buyBtn__1LJX9 {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background-color: #343434;
}

.winery-wine-details_wineryLinkBtn__3Ve2C {
  border: none;
  background-color: inherit;
}

.winery-wine-details_wineryLink__2q-P9 {
  border: none;
  background-color: inherit;
  border-bottom: 1px solid #7d7d7d;
}

.winery-wine-details_wineryLink__2q-P9:hover {
  text-decoration: none;
}

.winery-wine-details_gradientContainer__2S1Dc {
  position: relative;
  width: 100%;
  height: 240px;
  overflow-y: hidden;
}

.winery-wine-details_gradient__aqcq_ {
  position: absolute;
  background-image: linear-gradient(to top, white, transparent);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  height: 100%;
  width: 100%;
  top: 44px;
  left: 0;
}

.winery-wine-details_viewFull__Jn5sD {
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translate(-50%, 0);
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border: none;
  border-radius: 8px;
  background: var(--Primary-Burgundy, #951901);
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.demo-wine-details_fromRight__2xit4 {
  animation: demo-wine-details_fromRight__2xit4 0.5s;
}

.demo-wine-details_fromLeft__1VQGf {
  animation: demo-wine-details_fromLeft__1VQGf 0.5s;
}

@keyframes demo-wine-details_fromRight__2xit4 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes demo-wine-details_fromLeft__1VQGf {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.demo-wine-details_backToResultBtn__1u_vj {
  width: 100%;
  padding: 0 2.4rem;
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
  color: #951901;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.demo-wine-details_backToResultBtnTxt__3DrV8 {
  color: #951901;
}

.demo-wine-details_pairingImgBox__1Vdeu {
  height: 192px;
}

.demo-wine-details_pairingImg__3iv75 {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-wine-details_logosBox__QlIJJ {
  position: absolute;
  top: 0;
  left: 0;
}

.demo-wine-details_logosBox__QlIJJ > img {
  display: block;
  margin: 8px;
}

.demo-wine-details_placerLogo__2_dW4 {
  height: 45px;
  width: 80px;
  margin-bottom: 1rem;
}

.demo-wine-details_robleLogo__2q7fL {
  height: 80px;
  width: 80px;
}

.demo-wine-details_varietalImg__vDTXo {
  height: 192px;
  width: 40px;
  object-fit: contain;
}

.demo-wine-details_buyBtn__39A-g {
  background-color: #343434;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  width: 100%;
  border-radius: 8px;
  border: none;
}

.demo-wine-details_mainContainer__2mYCI {
  margin-top: 2rem;
  width: 100%;
}

.demo-wine-details_mainContainerSection__vjasN {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
}

.demo-wine-details_bg__3Y8mV {
  aspect-ratio: 1/1;
  width: 160px;
  background-color: #efefef;
  border-radius: 8px;
}

.demo-wine-details_wineImg__3uyQp {
  height: 80%;
}

.demo-wine-details_wineName__2mT3Q {
  max-width: 160px;
  margin-bottom: 0;
}

.demo-wine-details_smallBtn__nfNH_ {
  padding: 10px 8px;
  border-radius: 4px;
}

.demo-wine-details_price__3n2Hq {
  color: #951901;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.demo-wine-details_getAtTarget__1Wdop {
  color: #343434;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.demo-wine-details_wineGrid__2nreS {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.demo-wine-details_getBox__ZPFtm {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.demo-wine-details_getWinesSection__2vLyn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
  padding: 8px 0;
}

.demo-wine-details_rightArrow__1lXvM {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.demo-wine-details_targetLogo__3e7Ov {
  width: 16px;
  height: 16px;
}

.demo-wine-details_seeAll__3G9Jn {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
}

.demo-wine-details_seeAllBtn__3hZiS {
  background: none;
  border: none;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  align-items: baseline;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.demo-wine-details_seeAllBtnTxt__1xDjv {
  color: #951901;
}

.demo-wine-details_link__1Nt4i {
  color: #951901;
}

.demo-wine-details_link__1Nt4i:hover {
  color: black;
  text-decoration: none;
}

.demo-wine-details_wineCard__2PiWR {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.demo-wine-details_wineCardTextBox__3OzAC {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.demo-wine-details_info__10Vff {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 8px;
  gap: 8px;
}

.demo-wine-details_varietalCard__3EfPs {
  display: flex;
  width: 100%;
  padding: 2.4rem;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 24px;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid #efefef;
}

.demo-wine-details_flavorProfBox__2OZte {
  margin: 2.4rem 0;
}

.demo-wine-details_fadeBox__h90Wa {
  position: relative;
}

.demo-wine-details_fade__TB_mF {
  position: relative;
  height: 2.4em; /* exactly three lines */
  overflow: hidden;
}

.demo-wine-details_fade__TB_mF::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255) 80%);
}

.demo-wine-details_fadeSeeMore__2W-Ob {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 10;
  text-decoration: underline;
  color: #951901;
  background-color: white;
}

.demo-wine-details_fadeSeeMore__2W-Ob:hover {
  text-decoration: none;
}

.demo-wine-details_pairingsBox__g3U1Y {
  margin: 2.4rem 0;
  position: relative;
}

.demo-wine-details_gradientContainer__lEinP {
  position: relative;
}

.demo-wine-details_gradient__2f9BK {
  position: absolute;
  background-image: linear-gradient(to top, white, transparent);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255) 90%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-wine-details_phoneticResults__oftmt {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7d7d7d;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding: 3% 4% 3% 4%;
}

.demo-wine-details_phoneticResultsImg__2rEW- {
  padding-right: 4%;
}

.demo-wine-details_viewFull__Gf7NK {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border: none;
  border-radius: 8px;
  background: var(--Primary-Burgundy, #951901);
  color: white;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
