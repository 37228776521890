.recommended-wines-main-container-desktop {
  width: 95%;
  height: fit-content;
  margin: 2.5%;
}

.recommendations-container-desktop {
  width: 100%;
  // display: flex;
  // flex-direction: row;
  border: 1px solid #cccccc;
}

.wine-list-desktop {
  //   width: 50%;
}

.recommended-wines-result-container {
  margin-left: 3%;
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: wrap;
}

.recommendations-response-container-desktop {
  margin-top: 3%;
  margin-bottom: 3%;
  width: 100%;
}

.recommendations-response-text-desktop {
  color: #727272;
  font-family: "poppins", sans-serif !important;
  font-size: 18px;
  margin-left: 5%;
  margin-right: 5%;
  display: block;
  word-break: break-word;
  font-style: italic;
}
