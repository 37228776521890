.recommendations-main-container {
  width: 100%;
  height: 100vh;
}

.back-btn-recommended-wines {
  width: 3%;
}

@media (min-width: 1025px) {
  .recommendations-main-container,
  .recommendations-container,
  .bottom-navigation {
    margin-top: 5% !important;
    overflow: auto;
  }
  .recommendations-option-container {
    display: flex;
    justify-content: space-between;
    padding: none !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
  .recommendations-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }

  .recommendations-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }

  .recommendations-main-container {
    box-shadow: 0px 0px 15px 15px #d2d2d2;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 30% !important;
    height: 80vh !important;
    border: 2px solid #c0c0c0;
    overflow: auto;
  }
  .recommendations-header {
    width: 100% !important;
    height: 14% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }

  .recommendations-text {
    margin-left: 5%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }

  .recommendations-cart-btn {
    float: right;
    // margin: 6%!important;
  }

  .recommendations-main-container {
    height: 70vh !important;
  }
  .bottom-navigation {
    // margin-bottom: 16.5%!important;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .recommendations-card-personal-details-subheader {
    margin-bottom: 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .recommendations-option-container {
    display: flex;
    justify-content: space-between;
    padding: 1% !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
}

.recommendations-header {
  width: 100%;
  height: 14%;
  background-color: #a84551;
  align-content: center !important;
  // z-index: -1!important;
}
.badge-notify {
  background: #a84551;
  position: relative;
  top: -2px;
  font-size: smaller;
  left: -8px;
}
.badge-notify-empty {
  background: none;
  position: relative;
  top: -2px;
  font-size: smaller;
  left: -8px;
}
.badge-notify-desktop-empty {
  background: none;
  position: relative;
  top: -8px;
  font-size: smaller;
  left: -8px;
}
.add-to-cart-icon-div {
  float: right;
  // margin-right: 2rem;
  // width: 2rem;
}
.cart-icon-position {
  width: 2.5rem;
  margin-top: 1.5rem;
}

.recommendations-text {
  margin-left: 5%;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.recommendations-cart-btn {
  float: right;
}

.cont-pad {
  padding: 0 2em;
}

.recommendations-container {
  // margin-top: 10%;
  margin-top: 5% !important;
  width: 100%;
  // position: absolute;
  overflow: hidden;
  // overflow: auto;
  margin-bottom: 25%;
  // overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recommendations-container:after {
  content: "";
  display: block;
  height: 130px;
  width: 100%;
}

.recommendations-card-personal-details-subheader {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 400px) {
  // >=400

  .recommendations-pic {
    // css added in px for now
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }

  .recommendations-option-container {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }
}

.recommendations-name {
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: 600 !important;
}
.recommendations-name:first-letter {
  text-transform: uppercase;
}

.recommendations-email {
  color: #959292;
  font-size: 1em;
}

.recommendations-card {
}
.recommendations-row {
  // width: 100%;
  // position: absolute;
  bottom: 0;
}

.recommendations-order-cart-details {
  margin-left: 1px;
  float: left;
  width: 49%;
  border: 1px solid #959292;
  border-top-width: thin;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
  position: relative;
}

.recommendations-orders,
.recommendations-cart {
  color: #959292;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

// .nav-link {
//   background-color: white !important;
//   color: white !important;
// }

.recommendations-subheader {
  display: flex;
  padding: 5%;
}

.recommendation-dish-name {
  position: absolute;
  color: #7d7d7d;
  margin-top: 8vh;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 40px !important;
  text-transform: capitalize;
  // border: 1px solid #1D1D1D1A;
  display: flex;
  align-items: center;
  width: 90%;
}

.recommendation-dish-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommendations-response-text,
.recommendations-pairing-note {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  margin-left: 5%;
  margin-right: 5%;
  display: block;
  word-break: break-word;
}
.recommendations-pairing-note {
  margin-top: 5%;
  font-style: italic;
}

.recommendations-winery-name,
.recommendations-dish-name {
  font-style: italic;
}

.recommendations-dish-name {
  text-transform: capitalize;
}

.recommendations-winery-name:before {
  content: "\00a0 ";
}
.recommendations-winery-name:after {
  content: "\00a0 ";
}

.recommendations-dish-name:before {
  content: "\00a0 ";
}
.recommendations-dish-name:after {
  content: "\00a0 ";
}

.recommendations-response-container,
.wine-name-container {
  animation: 0.75s ease-out 0s 1 slideInFromLeft;
}
