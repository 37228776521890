@media (min-width: 1025px) {
    .height-for-terms-page {
        height: 80vh !important;
        }
    }
    .height-for-terms-page {
        padding: 10px 20px 10px 20px !important;
    }

.all-terms-header-font {
    font-weight: bold;
    color: #2B2B2B;
    font-size: 17px !important;
}
.terms-btns-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.terms-accept-click {
    background:#a84551 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    color: white;
}
.terms-accept-click:hover {
    background:#a84551 0% 0% no-repeat padding-box;
    color: white;
}
.terms-cancel-click {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid#a84551;
    border-radius: 2px;
    color:#a84551;
}
.terms-cancel-click:hover {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color:#a84551;
}
.terms-header {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    justify-content: space-between;
}
.terms-conditions-justify {
    text-align: justify;
}
.p-terms-width {
    width: 33.33%;
}
.term-header-label {
    font-size: 17px !important;
    font-weight: bold;
}
