.availableWinesMainContainer {
  width: 100%;
  height: 100vh;
}

@media (min-width: 410px) {
  .available-wines-badge-notify {
      top: -6px !important;
      left: -9px !important;
  }
}

@media (min-width: 1025px) {
  .search-wine-for-dish-subheader {
      padding-top: 0% !important;
      padding-bottom: 0% !important;
  }

  .avWinesContainer {
      margin-top: 2% !important;
  }

  .available-wines-pic {
      width: 50px !important;
      height: 50px !important;
      border-radius: 100px;
  }


  .available-wines-header {
      width: 100% !important;
      height: 15% !important;
      background-color:#a84551 !important;
      align-content: center !important;
  }

  .available-wines-text {
      margin: 7%;
      font-size: 1.5em !important;
      color: #ffffff;
      font-family: "poppins", sans-serif !important;
      font-weight: 600 !important;
  }

  .available-wines-cart-btn {
      float: right;
      // margin: 6%!important;
  }
}

.available-wines-header {
  width: 100%;
  height: 14%;
  background-color:#a84551;
  align-content: center !important;
  // z-index: -1!important;
}

.available-wines-text {
  margin: 7%;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.available-wines-cart-btn {
  float: right;
}

.avWinesContainer {
  // margin-top: -9vh;
  width: 100%;
  position: relative;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
  // margin-bottom: 20%;
}
.cart-icon-margin {
  margin-top: 0.7rem !important;
}

.available-wines-badge-notify {
  background:#a84551;
  position: relative;
  top: -33px;
  font-size: smaller;
  left: 17px;
}
.input-height {
  height: 40px !important;
}
.available-wines-search-input {
  color: #959292;
  font-style: italic;
  justify-content: space-between;
  font-size: 1.25em;
  // border-radius: 4px;
  padding-left: 9px !important;
}

.avWinesContainer:after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}

@media (min-width: 768px) {
  .cart-icon-padding {
      padding-left: 3.5em;
  }
}

@media (min-width: 1025px) {
  .cart-icon-padding {
      padding-left: 4.5em;
  }
}

@media (min-width: 400px) {
  // >=400

  .available-wines-pic {
      // width: 40%!important;
      // /* height: 50%; */
      // border-radius: 50%!important;

      // css added in px for now
      width: 100px !important;
      height: 100px !important;
      border-radius: 100px !important;
  }
}

.available-wines-row {
  bottom: 0;
}

.available-wines-cart {
  color: #959292;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}


.available-wines-subheader {
  display: flex;
  justify-content: space-between;
  // padding: 5%;
}

.mt-5em {
  margin-top: 5em !important;
}

.new-common-header-change {
  justify-content: space-between;
}