@media (min-width: 1025px) {
    .taste-preference-option-card-main-container {
        width: 27% !important;
    }

    .intro-screen-explainer-text {
        margin-top: 3% !important;
    }
}
.taste-preference-option-card-main-container {
    display: flex;
    width: 40%;
    flex-direction: column;
}

@media (min-width: 300px) and (max-width: 400px) {
    .taste-preference-option-card-main-container {
        // width: 50%!important;
    }
}

.taste-preference-option-card-sub-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heart-status-image {
    width: 35%;
}

.option-card-name {
    color: black;
    font-family: "poppins", sans-serif !important;
}
.option-card-name {
    text-transform: capitalize;
}
.taste-preference-option-card-name-container {
    text-align: center;
    justify-content: center;
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.taste-preference-option-card-image-container {
    display: flex;
    justify-content: center;
    width: 70%;
}

.option-card-image {
    width: 100%;
}

.taste-preference-option-card-heart-image-container {
    display: flex;
    justify-content: center;
}
