.nav-link-bottom-nav:focus {
  outline: none;
}

// .chat-count {

//   position: absolute;
//   margin-left: 5%;
//   margin-top: 2%;
//   top: 0%;
//   color: red;
//   font-size: 1.25em;
// }

.nav-link-bottom-nav {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
}

// .nav-link - border radius

.nav-link {
  border-radius: 0px !important;
  color: white !important;
}

.nav-tabs .nav-link {
  border: unset !important;
}

.nav-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom-nav-title {
  font-size: 0.9em !important;
}

.bottom-nav-active-title {
  font-size: 1em !important;
  color: #a84551;
  // border-bottom: 3px solid#a84551;
  // animation: shake 1s ease;
  // animation: 1s ease-out 0s 1 slideInFromLeft;
}

.bottom-nav-icon-disabled {
  opacity: 0.5;
}

.events-btn-not-selected {
  opacity: 0.6;
  height: 19.67px;
  width: 19.25px;
}

.events-btn-selected {
  height: 19.67px;
  width: 19.25px;
}
