.result-search-bar-container{
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;
}

.pairing-search-bar{
 
  background: #EFEFEF;
  opacity: 0.5;
  border-radius: 100px;
  border: none;
  height:35px;
  width: 100%; 
}

// input {
//   padding: 9px 40px 9px 20px;
// }

.pairing-result-title {
  position: relative;
  margin-top: 5px;
}

.pairing-result-name {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: Montserrat;
  color:#343434;
  font-size: 12px;
}

.cheers {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-size: 30px;
  font-weight:bolder;
  color: #D0011B;

}

.pointer-reco {
  margin-bottom: -1rem;
}

.combo-patp-container {
display: flex;
width: 100%;
}

.pairing-result-combo-container-desktop {

width: 50%;
margin: 0 auto;

}

.pairing-result-combo-container{
  position: relative;
  margin-top: 2rem;
}


.pairing-result-combo-container-images{
  position: relative;
  margin-top: 2rem;
}

.pairing-result-main-desktop {
display: flex;
flex-direction: column;
align-items: center;
}

.wine-image-container {
  position: absolute;
  transform: rotate(-13deg);
  width:56px;
  height:189px;
  max-width: 100%;
  margin: 0 auto;
  left:-100px;
  right:0;
  z-index: 1;
}

.wine-image-container-foodres{
  position: absolute;
  transform: rotate(13deg);
  max-width: 56px;
  max-height: 189px;
  margin: 0 auto;
  left: 200px;
  margin-bottom: 1px;
  right: 0;
  z-index: 1;
}

.wine-image {
 object-fit: cover;
 width:56px;
 height:189px;
}

.food-image-container {
  position: absolute;
  width: 167px;
  height:167px;
  max-width: 100%;
  left: 50px;
  right:0;
  margin: 0 auto;
}

.food-image-container-foodres {
  position: absolute;
  width: 167px;
  height: 167px;
  max-width: 100%;
  left: -2px;
  right: 0;
  margin: 0 auto;
}

.food-image{
  object-fit: cover;
  width: 167px;
  height:167px;
  filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.15));
  border-radius: 50%;
}

.wine-title-container {
  position: relative;
  text-align: center;
  top:100px;
  right:100px;
}


.wine-text {
  display:block;
  margin-top: 2rem;
}

.wine-desc{
  display:block;
  position:relative;
  margin: 0 auto;
  height:50px;
  width:100px;
  font-family: Montserrat;
  font-size: 10px;
  font-style: italic;
  color:#343434;
}

.wine-desc-foodres{
  position: relative;
  margin: 0 auto;
  height: 50px;
  left:-50px;
  width:100px;
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.food-title-container {
  position: relative;
  text-align: center;  
  left: 120px;
  top: -10px;;
}

.food-desc {
 display:block;
 position:relative;
 margin: 0 auto;
 left:-50px;
 width:100px;
 height: auto;
 color:#D0011B;
}

.food-text {
  display:block;
  position:relative;
  margin: 0 auto;
  width: 100%;
  color:#D0011B;
  width: 100px;
}

.new-pairing-result-buttons {
  row-gap: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  top:40px;
}

.shop-btn {
  margin-right: 0px;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 0.5px solid #951901;
  width: max-content;
  height:41px;
  font-size: 12px;
  color:#951901;
  font-family: Montserrat;
  font-weight:700;
}

.recipe-btn {
  margin-left: 0px !important;
  margin-right: 0px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #951901;
  border: none;
  width:max-content;
  height:41px;
  font-size: 12px;
  color:white;
  font-family: Montserrat;
  font-weight:700;
}



