@use "src/app/styles/theme" as s;

.jank {
  //transform: translateX(0);
  animation: fromRight 0.5s;
}

@keyframes fromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.welcome {
  font-family: Montserrat, serif;
  margin-top: 5%;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
}

.whatPairsWith {
  font-family: Montserrat, serif;
  margin-top: 8%;
  margin-bottom: 2%;
  font-size: 22px;
  font-weight: 800;
  text-align: left;
}

.todaysWines {
  font-family: Montserrat, serif;
  margin-top: 5%;
  font-size: 16px;
  font-weight: 800;
  text-align: left;
}

.start {
  font-family: Inter, sans-serif;
  margin-top: 1%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: s.$gray;
}

.dg {
  color: s.$gray-dark;
  margin-top: 2%;
}

.inputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputBox {
  width: 100%;
  padding: 4% 5%;
  border: 1px solid s.$gray;
  border-radius: 8px;
}

.setupBtn {
  font-family: Inter, sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: s.$gray-dark;
  padding: 12px 10px;
  width: 35%;
  border-radius: 8px;
  border: none;
  margin-top: 4%;
  margin-bottom: 10%;
}

.setupBtn:hover {
  background-color: s.$gray-light-hover;
}

.mainContainer {
  margin-top: 2%;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.w100 {
  width: 100%;
}

.searchContainer {
  position: relative;
  background-color: white;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
  padding-top: 0;
  padding-bottom: 10%;
  transition: padding-top 0.5s;
}

.searchContainerExpanded {
  padding-top: 4%;
}

.w60 {
  height: 60px;
  width: 60px;
  margin: 0 4% 0 0;
  //margin-right: 4%;
}

.wineImg {
  height: 80%;
  background-color: s.$peach-dark;
}

.wineName {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: s.$gray-dark;
}

.wineryName {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: s.$gray-dark;
}

.cardTextBox {
  flex-grow: 1;
  width: 100%;
}

.wineCardBoxRow {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
  align-items: center;
}

.inputWithIcon {
  display: flex;
  width: 100%;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background-color: s.$gray-light;
  border: none;
  margin-bottom: 2.4rem;
}

.searchBar {
  flex-shrink: 0;
  flex-grow: 1;
  background-color: s.$gray-light;
}

.blueBoxContainer {
  margin-top: -24px;
  width: 100%;
}

.blueBoxSuccessContainer {
  margin-top: -40px;
}

.blueBox {
  margin: 2.4rem;
  display: flex;
  padding: 18px 20px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background: #1e7ed7;
  box-shadow: 0px 2px 24px 0px rgba(52, 52, 52, 0.42);

  color: white;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blueBoxSuccess {
  margin: 2.4rem;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #1e7ed7;
  box-shadow: 0px 2px 24px 0px rgba(52, 52, 52, 0.42);
  gap: 10px;

  color: white;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



.bbText {
  color: white;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bbTextItalic {
  color: white;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.bbLink {
  color: white;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  //text-decoration-line: underline;
  text-decoration: underline;
}

.bbLink:hover {
  cursor: pointer;
  text-decoration: none;
}

.noResultBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.noResultHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}

.searchInstead {
  color: s.$red;
  text-decoration: underline;
}

.searchInstead:hover {
  cursor: pointer;
  text-decoration: none;
}

.noResultImgBox {
  width: 141px;
  height: 122px;
}

.noResultImg {
  object-fit: cover;
}

.sendRequestBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.formInput {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.formFieldError {
  color: red;
}

.submitBtn {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: s.$gray-dark;

  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.grayedOut {
  color: s.$gray;
  background: s.$gray-light;
}
