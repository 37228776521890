.container {
  width: 100%;
  position: relative !important;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
}

.card {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0 !important;
  margin: 1em !important;
  padding: 1em !important;
}

.cardDetail {
  width: 100%;
}

.selectCard {
  width: 40%;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
  margin-bottom: 5%;
}

.subContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cardText {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
