@use "src/app/styles/theme" as s;

.fadeIn {
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modalBackground {
  padding: 2rem 2.4rem 2rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.modalBox {
  //width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px 24px;
  background-color: white;
  border-radius: 8px;
}

.modalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

// .main {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   gap: 24px;
// }

.actionCircle {
  color: s.$gray;
  fill: s.$gray;
  height: 42px;
  width: 42px;
  background-color: s.$gray-light;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  transition: background-color 0.3s, color 0.3s, fill 0.3s;
}

.actionCircleActive {
  fill: white;
  color: white;
  background-color: s.$gray-dark;
}

.action {
  max-width: 42px;
  text-align: center;
  color: s.$gray;
  font-family: s.$font-1;
  font-size: 12px;
  font-weight: 500;
}

.underlined {
  text-decoration-line: underline;
  font-weight: 700;
}

.underlined:hover {
  text-decoration-line: none;
  font-weight: 700;
  cursor: pointer;
}

.inputBar {
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.copyBtn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border: none;
  border-radius: 4px;
  background: s.$gray-dark;

  color: white;

  /* P3 - 14 SemiBold */
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.linkBox {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blueBox {
  display: flex;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: #1e7ed7;
  box-shadow: 0px 2px 24px 0px rgba(52, 52, 52, 0.42);
}

.blueBoxTxt {
  color: white;

  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
