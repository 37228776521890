$cAccent: #3978ef;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);

.accordion-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  max-width: 30rem;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0 0.8rem 0.1rem rgba(darken($cBlack, 20%), 0.06),
    0 20px 30px -10px rgba(darken($cBlack, 20%), 0.2);

  &__item {
    & + & {
      border-top: 1px solid $cLightGray;
    }
  }
}

.accordion-item {
  $self: &;

  &--opened {
    #{ $self }__icon {
      transform: rotate(180deg);
    }

    #{ $self }__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__line {
    display: block;
    padding: 0.8rem 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // z-index: 2;
    position: relative;
  }

  &__title {
    font-size: 1.6rem;
    margin: 0;
    font-weight: 700;
    color: $cBlack;
  }

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;

    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
    opacity: 0.6;
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    // z-index: 1;
    position: relative;
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    // padding: 0 1.2rem 1.2rem;
    padding: 1rem;
  }

  &__paragraph {
    margin: 0;
    font-size: 1rem;
    color: $cGray;
    font-weight: 300;
    line-height: 1.3;
  }
}

.accordion-item::after {
  // circles on timeline
  content: "";
  position: absolute;
  left: -25px;
  border: 8px solid#a84551;
  border-radius: 100%;
  // opacity: 65%;
}

.recommendation-history-card-accordion-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.recommendation-history-card-accordion-date {
  color: #7d7d7d;
  font-size: 0.8em;
}
.accordion-item__line {
  z-index: 0 !important;
}

.accordion-card-inner-content {
  display: flex;
  flex-direction: column;
}

.accordion-card-wine-name,
.accordion-card-wine-data {
  color: black;
  font-family: "poppins", sans-serif !important;
  font-size: 16;
  // white-space: nowrap;
}
.accordion-card-wine-name-desktop {
  // font-weight: 400;
  // color: black;
  color: #a84551;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}
.accordion-card-wine-data {
  margin-bottom: 5%;
}

.accordian-card-wine-specifications {
  // text-align: center;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  font-family: "poppins", sans-serif !important;
  color: #2b2b2b;
}
.accordion-card-wine-name {
  // font-weight: bold;
  font-size: 12px;
  color: #a84551;
}

.accordion-card-wine-tab-name {
  color: #a84551;
  font-size: 12px;
  font-family: "poppins", sans-serif !important;
}

.accordion-card-wine-name::first-letter,
.accordion-card-wine-name-desktop::first-letter,
.accordion-card-wine-data::first-letter {
  text-transform: capitalize;
}

.accordion-item__icon {
  background-image: url("../../../assets/images/RecommendationHistory/down-arrow.png") !important;
  // RecommendationHistory/down-arrow.png for black color
  opacity: 1 !important;
  padding: 5%;
}

.accordion-row-container {
  display: flex;
  flex-direction: row;
}

.accordion-item__paragraph {
  font-size: 1em !important;
}

.star-rating {
  &__star {
    display: inline-block;
    padding: 3px;
    vertical-align: middle;
    line-height: 1;
    font-size: 1.5em;
    color: #d2d2d2;
    transition: color 0.2s ease-out;

    &:hover {
      cursor: pointer;
    }

    &.is-selected {
      color: #f7d002;
      // animation: 0.3s ease-out 0s 1 shake;
    }

    &.is-disabled:hover {
      cursor: default;
    }
  }
}

.recommendation-history-card-dishname {
  color: black;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  text-transform: capitalize;
}

.recommendation-history-card-dishname::first-letter {
  text-transform: capitalize;
}

.rating-recommendation-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.rating-recommendation-text {
  color: black;
  font-family: "poppins", sans-serif !important;
  // font-size: 0.8em;
  font-size: 1em;
}

.already-rated-ratingbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  animation: right 0.3s ease, left-end 0.4s ease 0.4s;
}

// .already-rated-rating {
//   font-size: 1.15em!important;
// }

.rating-star-accordion {
  // padding: 13px!important;
}

.is-selected {
  animation: right 0.3s ease, left-end 0.4s ease 0.4s;
}

.rate-recommendation-btn-container {
  display: flex;
  justify-content: flex-end;
}
.rate-recommendation-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100% !important;
  padding: 0.8rem 1.2rem;
}
.add-to-cart-reco {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100% !important;
  padding: 0.8rem 1.2rem;
  margin: 0 3% 0 3%;
}
.add-to-cart-reco-desktop {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100% !important;
  padding: 0.8rem 1.2rem;
}
.rate-recommendation-history-btn {
  font-family: "poppins", sans-serif !important;
  // font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
  padding: 1rem 0;
}

.li-dot-desktop {
  height: 10px;
  width: 10px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}

.li-dot {
  height: 8px;
  width: 8px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
}

.accordion-card-wine-name-container {
  display: flex;
  flex-direction: row;
}

.li-dot-container {
  margin-right: 10px;
}

.accordian-card-wine-specifications-desktop {
}

.share-food-pairing-button {
  position: absolute;
  bottom: 0.5%;
  right: 2.7%;
}

@media (min-width: 1025px) {
  .accordion-item__icon {
    // padding: 2.5% !important;
  }
}
@media (min-width: 1025px) {
  .accordion-item__icon {
    padding: 2.5% !important;
  }
}
// @media (min-width: 1280px) {
//   .accordion-item__icon {
//     padding: 2.5% !important;
//   }
// }
// @media (min-width: 1440px) {
//   .accordion-item__icon {
//     padding: 2.5% !important;
//   }
// }
