.desktop-av-wines-main {
    margin-top: 7% !important;
}
.available-wines-main-container {
    // height: 85vh !important;
}

@media (min-height: 950px){
.available-wines-main-container {
    // height: 92vh !important;
    }
}
@media (min-height: 900px){
    .available-wines-main-container {
        // height: 88vh !important;
    }
}

.search-wine-input{
    color: #2b2a2a;
    font-family: "poppins", sans-serif !important;
    font-size: 1.25em;
    border: none;
    height: 40px !important;
    text-transform: capitalize;
    border: 1px solid #1d1d1d1a;
    display: flex;
    align-items: center;
    width: 85%;
}