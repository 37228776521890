@use "src/app/styles/theme" as s;

.varietalCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profileNotes {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.flavorList {
  width: 100%;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flavorList2 {
  width: 100%;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media (min-width: 600px) {
  .flavorList2 {
    padding: 0 24px;
  }
}

.flavorListItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.flavorListImg {
  border-radius: 50%;
  height: 72px;
  width: 72px;
  object-fit: cover;
}

.ratingsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.ratingsListItem {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
}

.measureBar {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
}

.valueRight {
  text-align: left;
  width: 20%;
}

.valueLeft {
  text-align: right;
  width: 20%;
}

.valuedBar {
  background: s.$gray-dark;
  height: 6px;
  width: 30px;
  border-radius: 100px;
}

.notValuedBar {
  background: s.$gray-light;
  height: 3px;
  width: 30px;
  border-radius: 100px;
}
