* {
  outline: none !important;
}

.bg {
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header-text {
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 18px;
  letter-spacing: 0px;
  color: #a84551;
  opacity: 1;
  margin-top: -25px;
}

.first-dd {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
}

.first-dd-text {
  width: 50%;
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  margin-right: 5px;
  color: #2b2b2b;
  opacity: 1;
}

.second-dd {
  display: flex;
  flex-direction: column;
}

.btn-selector-grp-dd {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.three-btn-grp {
  margin-left: 18px;
  margin-top: 15px;
  height: 42px;
}

.btn-grp {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
}

.second-dd-text {
  text-align: left;
  margin-left: 5%;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  margin-top: 15px;
  margin-right: 5px;
  color: #2b2b2b;
  opacity: 1;
  margin-bottom: 5px;
}

.time-slot-dd {
  width: 100%;
}

.time-slot-container {
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.thin-line-div {
  height: 60px;
  width: 100%;
  vertical-align: middle;
}

.thin-line {
  background: #cccccc 0% 0% no-repeat padding-box;
  border-radius: 65px;
  opacity: 1;
  margin-left: 35%;
  height: 2px;
  width: 30%;
  display: inline-block;
}

.btn-grp-text {
  width: 50%;
  margin-left: 18px;
  margin-top: 8px;
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  letter-spacing: 0px;
  margin-right: 5px;
  color: #2b2b2b;
  opacity: 1;
}

.footer-buttons {
  margin-bottom: 28px;
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.cancel-event-rsvp {
  margin-left: 18px;
  font-family: "poppins", sans-serif !important;
  width: 200px;
  margin-top: 5px;
  color: #a84551;
}

.cancel-button {
  height: 33px;
  // width: 100px;
  text-align: center;
  margin-left: 30px;
  letter-spacing: 0px;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  color: #a84551;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid#a84551;
  border-radius: 2px;
}

.reason-text-box {
  padding: 6px;
  width: 100%;
  font-size: 16px;
  border: 1px solid #1d1d1d1a;
  border-radius: 5px;
  resize: none;
  width: 100%;
}

.textarea-container {
  margin-left: 18px;
  margin-top: 25px;
  margin-right: 18px;
  margin-bottom: 35px;
}

.save-button {
  background: #a84551 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  height: 35px;
  margin-left: -14px;
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}

.cancel-button-edit-form {
  height: 33px;
  width: 100%;
  text-align: center;
  letter-spacing: 0px;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
  color: #a84551;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid#a84551;
  border-radius: 2px;
}

.save-button-edit-form {
  background: #a84551 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;
  height: 35px;
  margin-top: -1px;
  margin-left: -18px;
  width: 100%;
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  letter-spacing: 0px;
  color: #ffffff;
}

.cancelBtn-container {
  width: 50%;
  margin-left: 10%;
}

.saveBtn-container {
  width: 50%;
  margin-left: 10%;
}

.pass-count-center-btn {
  pointer-events: none;
}

.show-cancel-sucess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin-left: 10%;
  font-size: 17px;
  height: 350px;
  margin-top: -60px;
  text-align: center;
  font-family: "poppins", sans-serif !important;
}

.show-edit-sucess {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  margin-left: 10%;
  margin-top: -60px;
  height: 350px;
  font-size: 17px;
  text-align: center;
  font-family: "poppins", sans-serif !important;
}

.checkbox-container {
  margin-left: 13px;
  margin-top: 10px;
  height: 30px;
  display: flex;
  flex-direction: row;
}

.checkbox-contents {
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  margin-top: 1px;
  line-height: 30px;
  vertical-align: middle;
}

.saved-icon {
  width: 70px;
  height: auto;
  margin-bottom: 25px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1025px) {
  .MuiPaper-root {
    width: 50% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
