@use "src/app/styles/theme" as s;

.container {
  width: 100%;
  position: relative !important;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}

.nameContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
  margin-bottom: 5%;
}

.subContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cardText {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.welcome {
  font-family: Montserrat, serif;
  margin-top: 10%;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
}

.start {
  font-family: Inter, sans-serif;
  margin-top: 1%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: s.$gray
}

.dg {
  color: s.$gray-dark;
  margin-top: 2%;
}

.phoneContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

.emailSwitch {
  color: s.$gray-dark;
  text-decoration: underline;

}

.emailSwitch:hover {
  text-decoration: none;
  cursor: pointer;
}

.inputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputBox {
  width: 100%;
  padding: 4% 5%;
  border: 1px solid s.$gray;
  border-radius: 8px;
}

.next {
  color: s.$gray;
  font-size: 16px;
  font-weight: 600;
  background-color: s.$gray-light;
  padding: 16px 32px;
  width: 100%;
  border-radius: 8px;
  border: none;
}

.next:hover {
  background-color: s.$gray-light-hover;
}

.outerContainer {
  display: flex;
  align-items: center;
  justify-content: center;

}

.mainContainer {
  width: 90%;
}

.w100 {
  width: 100%
}




