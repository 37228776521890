.cart-delete-icon {
  text-align: end;
}
.cart-delete-img {
  width: 2rem;
}
.cart-container {
  position: relative;
  // display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
}
.new-wine-width {
  max-width: 60px;
  max-height: 60px;
  // width: 65% !important;
  //   padding: 7px;
}
.no-wine-image-width {
  width: 100% !important;
  padding: 7px;
}
.wine-bottle-desgin {
  // text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  //   box-shadow: 0px 1px 6px #00000029;
  //   border: 1px solid #e6e6e6;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-wine-detail-container {
  width: 100%;
  /* margin: 1em !important; */
  box-shadow: 1px #1d1d1d1a !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 2px 6px #00000029; */
  /* border-radius: 5px; */
  opacity: 1;
  /* border-bottom: 1px solid; */
  // border-bottom: 1px solid rgba(119, 118, 118, 0.5);
  margin-top: 1.2em !important;
  // min-height: 25vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 5px;
  border: 1px solid #cccccc;
}
.ft-size-wine-details {
  font-size: 16px !important;
}
.wine-details {
  text-align: left;
  text-transform: capitalize;
  font-family: "poppins", sans-serif;
  font-size: 1.5rem;
  //   font: SemiBold 13px/24px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  display: flex;
  flex-direction: column;
}
.flx-d-none {
  flex-direction: unset !important;
}
.wine-qty-details {
  /* text-align: left; */
  /* text-transform: capitalize; */
  font-size: 1.6rem;
  font-family: "poppins", sans-serif;
  letter-spacing: 0px;
  color: #2b2b2b;
  display: flex;
  flex-direction: row;
  // justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.cart-qty-input {
  font-family: "poppins", sans-serif !important;
  text-align: center;
  width: 57px;
  font-size: 1.3rem;
  font-weight: 600;
}
.input-padding {
  padding: 6px 1px !important;
}
.price-div {
  margin-left: auto;
}
.cart-price {
  text-align: end;
  letter-spacing: 0px;
  color: #9a9191;
  opacity: 1;
  font-weight: 550;
  font-family: "poppins", sans-serif !important;
  //   width: 60%;
  //   padding: 3px;
  font-size: 1.9rem;
}
.qty-label {
  color: #676262;
  // font-weight: 600;
  font-size: 14px;
}
.cart-remove-wine {
  font-family: "poppins", sans-serif;
  letter-spacing: 0px;
  color: grey;
  //   text-transform: uppercase;
  opacity: 1;
  font-size: 1.9rem;
  font-weight: 600;
}

.sub-titles {
  color: grey;
  font-size: 1.6rem;
}
.qty-div {
  text-align: start;
}
.price-div {
  float: right;
}
.cart-delete {
  // width: 5%;
  width: 4.6%;
  margin-left: auto;
}
.cart-input-div {
  text-align: end;
}
.sub-title-price {
  color: #a84551;
  font-size: 16px;
  font-weight: 600;
}
.wine-img-container {
  display: flex;
  flex-direction: row;
}
.wine-sub-titles-container {
  text-align: left;
  text-transform: capitalize;
  font-size: 1.9rem;
  font-family: "poppins", sans-serif;
  //   font: SemiBold 13px/24px Poppins;
  letter-spacing: 0px;
  color: #2b2b2b;
  display: flex;
  flex-direction: row;
}
.cart-delete-newqty-row-width {
  width: 100%;
}
.price-div-new {
  position: absolute;
  display: flex;
  bottom: 0;
  float: right;
  /* left: 0; */
  right: 0;
}

@media (min-width: 768px) {
  // .cart-wine-detail-container {
  //   // margin: 1em !important;
  //   box-shadow: 1px #1d1d1d1a !important;
  //   background: #ffffff 0% 0% no-repeat padding-box;
  //   box-shadow: 0px 2px 6px #00000029;
  //   border-radius: 5px;
  //   opacity: 1;
  //   /* border-bottom: 1px solid; */
  //   // border-bottom: 1px solid rgba(119, 118, 118, 0.5);
  //   /* margin-top: 0.5rem !important; */
  //   width: 59%;
  //   // min-height: 21vh;
  // }
}

@media (min-width: 1025px) {
  //   .cart-wine-detail-container {
  //     margin: 1em !important;
  //     box-shadow: 1px #1d1d1d1a !important;
  //     background: #ffffff 0% 0% no-repeat padding-box;
  //     box-shadow: 0px 2px 6px #00000029;
  //     border-radius: 5px;
  //     opacity: 1;
  //     /* border-bottom: 1px solid; */
  //     // border-bottom: 1px solid rgba(119, 118, 118, 0.5);
  //     /* margin-top: 0.5rem !important; */
  //     width: 35%;
  //     // min-height: 22vh;
  // }
  .wine-name-dtls-div {
    // line-height: 2em !important;
  }
}
@media (max-width: 320px) {
  .sub-title-price {
    font-size: 14px;
  }
}
@media (min-width: 1025px) {
  .wine-name-dtls-div {
    // line-height: 2.5em !important;
  }
}

@media (max-height: 600px) {
  .wine-qty-details {
    margin-top: 0 !important;
  }
}

.ellipsis-wine-name {
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // max-width: 18ch;
  // overflow: hidden;
  max-width: 20ch;
  /* overflow: hidden; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.wine-name-dtls-div {
  // line-height: 2em;
}

.qyt-drop-option-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  width: 52px;
  /* border: none; */
  /* height: 40px !important; */
  /* text-transform: capitalize; */
  /* border: 1px solid #1d1d1d1a; */
  /* padding-left: 2px; */
  padding: 2px 0px 2px 13px !important;
  height: 32px !important;
  margin-top: 1px;
}

.cross-sign {
  font-size: 13px;
  color: #000000;
}
.update-qty {
  margin-left: 77px;
  padding-top: 3px;
}
.update-qty-btn {
  font-size: 12px !important;
  padding: 6px 6px;
  background-color: #a84551;
}
