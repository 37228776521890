.override-shipping-address-form-header {
  padding: 0 !important;
  font-size: small !important;
  text-align: center !important;
}
.fadeIn-css {
  opacity: 0.4;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.shpng-form-save-btn {
  color: white;
  background: #a84551;
  padding: 6px 30px;
  font-size: medium;
  font-weight: 500;
  border: 1px solid#a84551;
}
.shpng-form-cancel-btn {
  color: #a84551;
  background: white;
  padding: 6px 20px;
  border: 1px solid#a84551;
  border: 1px thin;
  font-size: medium;
  font-weight: 500;
}
.margin-change {
  margin: 0em 1em 1em 1em !important;
}

.address-title-change {
  margin-left: 0 !important;
}
.margin-align-for-shpng-cart {
  // margin: 5em 1em 1em 1em !important;
}

.font-family-address-label {
  font-family: "poppins", sans-serif !important;
}
