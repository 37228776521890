$gray: #7d7d7d;
$gray-dark: #343434;
$gray-light: #efefef;
$gray-light-hover: #dedede;
$red-light: #f6efef;
$peach-dark: #f4ece3;
$red: #951901;

$font-1: Inter, sans-serif;
$font-2: Montserrat, sans-serif;

$mainPad: 0 2.4rem 0 2.4rem;
