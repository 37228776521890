@use "src/app/styles/theme" as s;

.mainContainer {
  margin-top: 2rem;
  width: 100%;
}

.foodImg {
  height: 180px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.buyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: s.$gray-dark;
  padding: 12px 10px;
  width: 100%;
  border-radius: 8px;
  border: none;
  margin: 1rem 0 3rem 0;
}

.foodNameText {
  margin-bottom: 1rem;
}

.headerInfo {
  margin: 2rem 0rem;
}

.descriptionText {
  color: s.$gray;
}
