
.wine-education-card-container {
    overflow: auto;
}

.wine-education-card-type-subcontainer {
    display: flex;
    align-items: center;

}

.wine-education-card-title {
    color: #2B2A2A;
    font-family: 'poppins', sans-serif !important;
    font-size: 1em;
    font-weight: bold;
}
 
.wine-education-card-details {
    width: 100%;
}


  .wine-education-card-title-container , .wine-education-card-type-subcontainer {
      margin-bottom: 5%;
  }


  .wine-education-card-subcontainer {
    display: flex;
    flex-direction: column;
  }

  .wine-education-card  {
    width: 100%;     
  }

  .wine-education-card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .wine-education-card-content {
      color: #7D7D7D;
      font-family: 'poppins', sans-serif !important;
      font-size: 0.75em;
  }

  .wine-education-card-bottle-logo-container , .wine-education-card-bottle-logo {
      width: 100%;
  }