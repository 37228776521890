.checkintext-style {
    display: flex;
    margin: 1em;
    font-size: 17px;
    color: #000000;
    justify-content: center;
}
.new-thin-line-div {
    height: auto !important;
}

.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_circle {
    position: absolute;
    width:22px;
    height:22px;
    background-color: green;
    border-radius:11px;
    left:0;
    top:0;
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:#fff;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:#fff;
    left:8px;
    top:12px;
}