.container {
  width: 100%;
  background-color: #193c38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 4%;
  min-height: 60px;
}

@media (min-width: 1025px) {
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .logo {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    height: 50px;
  }
}

.innerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.headerText {
  font-size: 1.25em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 300 !important;
  margin-left: 5%;
}

.logo {
  width: 10%;
  margin-right: 3%;
}

.burger {
  padding-left: 4%;
  margin-right: 4%;
}
