.event-desc-main-container {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: -1px;
}

.available-event-card {
    height: fit-content;
}

.available-event-details {
    width: 100%;
}

.cover-picture {
    width: calc(100% + 28px);
    height: 28vw;
    object-fit: cover;
    margin-left: -14px;
    margin-top: -14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

@media (min-width: 300px) and (max-width: 350px) {
    .event-name {
        width: 200px;
        text-align: left;
        font-family: "poppins", sans-serif !important;
        font-size: 1.1em;
        letter-spacing: 0px;
        color: #2b2b2b;
        opacity: 1;
        font-weight: 600;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .available-event-card {
        height: 350px;
    }
}

@media (min-width: 1025px) {
    .cover-picture {
        height: 22vh;
    }

    .event-desc-main-container {
        width: 50% !important;
        background:none !important;
    }
}

.event-name {
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 1.1em;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
    font-weight: 600;
}

.event-title {
    width: 100%;
    margin-top: 20px;
    display: inline-flex;
    overflow-wrap: anywhere;
    flex-direction: row;
}

.title-icons {
    position: absolute;
    right: 0;
}

.share-icon {
    margin-right: 20px;
}

.like-icon {
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5px;
}

.event-card-bottom {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.event-price {
    text-align: left;
    color: #727272;
    font-size: 17px;
    margin-top: 7px;
    letter-spacing: 1px;
    font-family: "poppins", sans-serif !important;
    opacity: 1;
}

.rsvp-button {
    outline: none !important;
    width: 100px;
    height: 35px;
    position: absolute;
    right: 0;
    align-content: center;
    font-family: "poppins", sans-serif !important;
    font-size: 1em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    margin-right: 14px;
    justify-content: center;
}

.rsvp-button:focus {
    outline: none !important;
    box-shadow: none;
}

.event-date {
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 15px;
    letter-spacing: 0px;
    opacity: 1;
    display: flex;
    flex-direction: row;
}

.event-date-container {
    margin-top: 5px;
}

.calendar-icon {
    height: 14px;
    width: auto;
}

.date-content {
    margin-left: 10px;
    font-size: 17px;
}

.fully-booked-btn {
    outline: none !important;
    pointer-events: none;
    width: 107px;
    height: 35px;
    position: absolute;
    right: 0;
    align-content: center;
    font-family: "poppins", sans-serif !important;
    font-size: 16px;
    color: #2b2b2b;
    margin-right: 33px;
    justify-content: center;
}

.fully-booked-btn:focus {
    outline: none !important;
    box-shadow: none;
}

.cancelled-btn {
    outline: none !important;
    pointer-events: none;
    width: 107px;
    height: 35px;
    position: absolute;
    right: 0;
    align-content: center;
    font-family: "poppins", sans-serif !important;
    font-size: 16px;
    color: #2b2b2b;
    margin-right: 9px;
    justify-content: center;
}

.cancelled-btn :focus {
    outline: none !important;
    box-shadow: none;
}

.attending-btn {
    outline: none !important;
    pointer-events: none;
    width: 100px;
    height: 35px;
    position: absolute;
    right: 0;
    align-content: center;
    font-family: "poppins", sans-serif !important;
    font-size: 14px;
    color: #2b2b2b;
    margin-right: 26px;
    justify-content: center;
}

.attended-btn {
    outline: none !important;
    pointer-events: none;
    width: 100px;
    height: 35px;
    position: absolute;
    right: 0;
    align-content: center;
    font-family: "poppins", sans-serif !important;
    font-size: 14px;
    color: #2b2b2b;
    margin-right: 9px;
    justify-content: center;
}

.attending-btn:focus {
    outline: none !important;
    box-shadow: none;
}

.check-icon {
    height: 14px;
    width: auto;
    margin-right: 5px;
    margin-bottom: 2px;
}

.fully-booked-icon {
    height: 16px;
    margin-right: 5px;
    margin-bottom: 2px;
    width: auto;
}

.cancelled-icon {
    height: 16px;
    margin-right: 5px;
    margin-bottom: 3px;
    width: auto;
}
