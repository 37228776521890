@use "src/app/styles/theme" as s;

.fromRight {
  //transform: translateX(0);
  animation: fromRight 0.5s;
}

.fromLeft {
  //transform: translateX(0);
  animation: fromLeft 0.5s;
}

@keyframes fromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.backToResultBtn {
  background: none;
  border: none;
  display: flex;
  gap: 8px;
  align-items: baseline;
  color: s.$red;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 400;
}

.backToResultBtnTxt {
  color: s.$red;
}

.link {
  color: s.$red;
}

.link:hover {
  color: black;
  text-decoration: none;
}

.buyBtn {
  font-family: s.$font-1;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: s.$gray-dark;
  padding: 12px 10px;
  width: 160px;
  border-radius: 8px;
  border: none;
  margin-top: 4%;
  margin-bottom: 10%;
}

.mainContainer {
  margin-top: 2%;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.smallBtn {
  padding: 10px 8px;
  border-radius: 4px;
}

.price {
  color: s.$red;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 700;
}

.getAtTarget {
  color: s.$gray-dark;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.helpful {
  margin-top: 8%;
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: s.$gray-light;
  margin-bottom: 10%;
}

.wineGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 22px;
  column-gap: 22px;
}

.seeAllCard {
  aspect-ratio: 1 / 1;
  background-color: rgba(149, 25, 1, 0.08);
  border-radius: 8px;
  border: 1px solid s.$red;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.seeAllCardText {
  color: s.$red;
  text-align: center;
  font-family: s.$font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 45%;
}

.rightArrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.targetLogo {
  width: 16px;
  height: 16px;
}

.getBox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;
}

.header {
  margin-bottom: 2.4rem;
}
