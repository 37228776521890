.wine-type-subcontainer {
  display: flex;
  flex-direction: column;
}

.wine-subcontainer-parent {
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  padding: 0 3% 0 3% !important;
}
.wine-name {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.2em;
  font-weight: 600;
  word-wrap: break-word; // next line
}

.wd-card {
  padding: 1em 2.5em;

}

.wine-name {
  text-transform: capitalize;
}
.wine-subtype-subcontainer {
  display: flex;
  flex-direction: column;
}

.wine-subtype-name {
  color: grey !important;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  white-space: nowrap;
}
.wine-subtype-name::first-letter {
  text-transform: capitalize;
}

.wine-subtype-title {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 0.75em;
  display: flex;
  justify-content: center;
}

.wine-details {
  width: 100%;
  // padding-top: 5%;
}

.wine-price-text {
  color: #a84551;
  font-weight: bold;
}

.wine-bottle-size {
  color: #2b2a2a;
}

.wine-details-row {
  // width: 100%;
  // position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.wine-details-card-available {
  padding: 0% !important;
  background-color: white;
}

.wine-details-card-available-desktop {
  // height: 245px;
  padding: 0% !important;
  background-color: white;
}

.wine-details-card-not-available {
  // padding: 0% !important;
  background-color: #e0e0e0;
}

.wine-details-order-cart {
  // margin-left: 1px;
  // float: left;
  width: 50%;
  border: 1px solid #ece8e8;
  border-top-width: thin;
  border-bottom-style: none;
  // border-left-style: none;
  // border-right-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
  height: 70%;
  position: relative;
}

.wine-details-order-cart-desktop {
  // margin-left: 1px;
  // float: left;
  width: 50%;
  border: 1px solid #ece8e8;
  border-top-width: thin;
  border-bottom-style: none;
  // border-left-style: none;
  // border-right-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
  position: relative;
  bottom: 0;
}
.pos {
  position: relative !important;
}
.br-none {
  border-right-style: none;
}
.bl-none {
  border-left-style: none;
}

.wine-details-add-cart-title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
}
.bg-color {
  background-color: #a84551;
}
.wine-details-price-title {
  color: #7d7d7d;
}
.add-to-cart {
  width: 100%;
  margin: 0 !important;
}

.shop-at-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  font-weight: 700;
  color: #a84551 !important;
  //background-color: #a84551 !important;
  width: 100%;
  padding: 0.5rem;
  text-decoration: underline;

}

.wine-details-add-cart-title,
.wine-details-price-title {
  margin-right: 5%;
}

.wine-name-container,
.wine-type-subcontainer {
  //   margin-bottom: 5%;
}
.wine-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
}

.wine-details-data-subholder {
  // display: flex;
  // flex-direction: row;
  width: 100%;
  padding: 0.75em 0.75em 0.75em 0.75em;
  display: flex;
  // flex-direction: column;
}

.wine-details-data-holder {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // height: 175px;
}

.wine-bottle-logo-container {
  justify-content: center;
  margin-bottom: 3%;
  align-items: center;
  display: flex;
}
.wine-bottle-logo {
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.wine-bottle-logo-for-api {
  height: auto;
  max-height: 300px;
  width: auto;
  max-width: 200px;
}

//   .wine-bottle-logo-container::before {
//     animation : shimmer 2s infinite;
//     background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
//     background-size: 1000px 100%;
//   }
.add-to-cart-new-design {
  padding: 0.8rem !important;
  width: 80% !important;
}
.share-wine-button {
  // padding: 0.3em 0.5em 1.0em 0em !important;
  position: relative;
  left: 5%;
  margin-top: auto;
  margin-bottom: auto;
}

.wine-share {
  position: absolute;
  top: 0;
  right: 0;
}
