@use "src/app/styles/theme" as s;

.fromRight {
  //transform: translateX(0);
  animation: fromRight 0.5s;
}

.fromLeft {
  //transform: translateX(0);
  animation: fromLeft 0.5s;
}

.mainContainer {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.mainContainerSection {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.logoFrame {
  width: 80px;
  aspect-ratio: 1 / 1;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--primary-white, #fff);
  position: absolute;
  top: 12px;
  left: 16px;
}

.logoImg {
  object-fit: contain;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 4px;
}

.imgFrame {
  position: relative;
  width: 100%;
  height: 300px;
  flex-shrink: 0;
  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 2.4rem;
}

.wineImg {
  //width: 100%;
  object-fit: contain;
  //width: 80%;
  height: 90%;
  flex-shrink: 0;
  border-radius: 4px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.infoLine2 {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 24px;
}

.priceBox {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;

  border-top: 1px solid s.$gray-light;
  border-bottom: 1px solid s.$gray-light;
}

.price {
  display: flex;
  padding-right: 0px;
  align-items: center;
  gap: 8px;
}

.grayVert {
  width: 1px;
  height: 40px;
  background-color: s.$gray-light;
}

.detailsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 4rem;
}

.backToResultBtn {
  background: none;
  border: none;
  display: flex;
  gap: 8px;
  align-items: baseline;
  color: s.$red;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 400;
}

.backToResultBtnTxt {
  color: s.$red;
}

.link {
  color: s.$red;
}

.link:hover {
  color: black;
  text-decoration: none;
}

.padding {
  margin-top: 5.6rem;
}

.buyBtn {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background-color: s.$gray-dark;
}

// @keyframes fromRight {
//   from {
//     transform: translateX(100%);
//   }
//   to {
//     transform: translateX(0);
//   }
// }

// @keyframes fromLeft {
//   from {
//     transform: translateX(-100%);
//   }
//   to {
//     transform: translateX(0);
//   }
// }
