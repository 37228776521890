.help-page-header {
  width: 100%;
  //   height: 21%;
  /* align-content: center !important; */
  background-color: #193c38;
  // background-image: linear-gradient(to bottom, #bd6767,#a84551);
  // background-image: linear-gradient(to bottom, #f5425d, #6e0a11);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 7%;
  padding-bottom: 15%;
  position: absolute;
}

.help-header-text {
  font-size: 1.5em !important;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-left: 7%;
}

.help-page-sub-header {
  display: flex;
  /* justify-content: space-between; */
  //   padding-top: 7%;
  flex-direction: row;
  align-items: center;
}
.help-page-pair-anything-logo {
  width: 15%;
  margin-right: 5%;
  margin-left: auto;
}

.help-page-header-content {
  text-align: center;
}
.new-margin-and-css {
  margin-left: 10% !important;
  margin-right: 13%;
  /* font-size: 1.2em; */
  font-weight: 500 !important;
  margin-top: 2%;
  font-size: 1.1em;
}
.you-tube-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
  // margin-top: 19vh;
  // position: absolute;
  width: 100%;
}
.help-section {
  padding: 15px;
  display: flex;
  flex-direction: column;
  // margin-top: 19vh;
  // position: absolute;
  width: 100%;
}
.youtube-responsive-container-help-page {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.bottom-scroll-padding {
  padding-top: 60px;
}

.youtube-video-container {
  display: flex;
  flex-direction: row;
}

.youtube-responsive-container-help-page iframe,
.youtube-responsive-container-help-page object,
.youtube-responsive-container-help-page embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.you-tube-card,
.you-tube-card2 {
  border-radius: 10px !important;
  /* margin: 1em!important; */
  padding: 1em !important;
  border: 2px solid rgba(0, 0, 0, 0.125);
  /* background-clip: border-box; */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: relative;
  background-color: #fff;
  // margin-top: 9em;
}
// .ytp-title-channel-logo {
//   width: 30px !important;
//   height: 30px !important;
// }
// .ytp-title-text {
//   font-size: 12px !important;
// }
.link-header {
  font-size: medium;
  font-weight: 500;
  margin-top: 0.5em;
}
.link-sub-header {
  font-size: small;
  font-weight: 400;
}
.resp-margin-css-to-overlap-with-header {
  margin-top: 1%;
}

@media only screen and (max-width: 1024px) {
  .resp-margin-css-to-overlap-with-header {
    margin-top: 1%;
  }
  .youtube-video-container {
    display: flex;
    flex-direction: column !important;
    // margin-top: 48% !important;
  }
}

.contact-us-container-help {
  margin-top: 15px;
  color: #2b2a2a;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.contact-us-contents-help {
  margin-top: 10px;
  white-space: pre-wrap;
  color: #959292;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.help-thin-divider-line {
  height: 1px;
  background-color: #959292;
  margin-top: 10px;
}

.help-page-text-info {
  font-size: 1.5em;
  font-weight: 500;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
}

@media (min-width: 1025px) {
  .help-page-header-content {
    text-align: center;
  }

  .you-tube-container {
    // margin-left: 10% !important;
    // width: 80% !important;
  }
  .help-section {
    margin-left: 10% !important;
    width: 80% !important;
  }
  .you-tube-card {
    width: 48% !important;
  }

  .you-tube-card2 {
    width: 48% !important;
    margin-left: 2% !important ;
  }

  .resp-margin-css-to-overlap-with-header {
    margin-top: 20% !important;
  }

  .help-page-pair-anything-logo {
    width: 7% !important;
  }

  .back-btn-recommended-wines {
    width: 1.5%;
    margin-left: 2% !important;
  }

  .help-header-text {
    margin-left: 3% !important;
  }

  .new-margin-and-css {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  .help-page-header {
    padding-top: 1% !important;
    padding-bottom: 1% !important;
  }
}
