.varietal-details-div {
    // text-align: left;
    // text-transform: capitalize;
    font-family: "poppins", sans-serif;
    font-size: 1.5rem;
    letter-spacing: 0px;
    color: #2b2b2b;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.varietal-card-bottom {
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.varietal-details {
    display: flex;
    flex-direction: column;
    padding: 10px 10px 10px 29px;
    justify-content: center;
}
.name-details {
    color:#a84551;
    text-transform: capitalize;
}
.varietal-list-main-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 3% !important;
}
.varietal-individiual-desktop {
    width: 50% !important;
}
.varietal-details-container {
    display: flex;
    flex-direction: row;
    padding: 2px 2px 13px 2px;
    align-items: center;
}
.wine-type-img {
    width: 37%;
    background-color: rgba(201, 199, 199, 0.3);
    padding: 18px;
    border-radius: 50px;
    height: auto;
}
.varietal-reco-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 0 !important;
    border-radius: 10px !important;
    margin: 1em !important;
    /* padding: 1em !important; */
    box-shadow: 1px #1d1d1d1a !important;
    border: none;
}
.vareital-details-shop-btn-desktop {
    padding: 3% 3% 0% 3% !important;
}
.vareital-details-shop-btn-mob {
    padding: 3% 3% 0% 3% !important;
}
.ft-s-17 {
    font-size: 17px !important;
}
.name-container {
    padding: 3px 0%;
}
.wine-type-name-container {
    margin-top: 10px;
}
.heading-name-container {
    color: #727272;
}
@media (min-width: 1025px) {
    .wine-type-img {
      width: 40% !important;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-width: 768px)  {
    .wine-type-img {
        width: 26% !important;
      }
  }
  @media only screen and (min-width: 540px)  {
    .wine-type-img {
        width: 30% !important;
      }
  }

