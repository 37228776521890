.splash-icon {
    width: 80%;
    animation: 1s ease-out 0s 1 init;
    height: auto;
}

.splash-container {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

@media only screen and (min-width: 320px) 
  and (max-height: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

    // .splash-icon {
    //     margin-top: 40%;
    //     width: 80%;
    //     height: auto;
    // }
    
}




// .splash-icon {
//   animation: shake 0.5s;
//   animation-iteration-count: infinite;
// }

// @keyframes shake {
//   0% { transform: translate(1px, 1px) rotate(0deg); }
//   10% { transform: translate(-1px, -2px) rotate(-1deg); }
//   20% { transform: translate(-3px, 0px) rotate(1deg); }
//   30% { transform: translate(3px, 2px) rotate(0deg); }
//   40% { transform: translate(1px, -1px) rotate(1deg); }
//   50% { transform: translate(-1px, 2px) rotate(-1deg); }
//   60% { transform: translate(-3px, 1px) rotate(0deg); }
//   70% { transform: translate(3px, 1px) rotate(-1deg); }
//   80% { transform: translate(-1px, -1px) rotate(1deg); }
//   90% { transform: translate(1px, 2px) rotate(0deg); }
//   100% { transform: translate(1px, -2px) rotate(-1deg); }
// }