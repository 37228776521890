/* .PhoneInput {
  display: inline !important;
  position: relative !important;

}

.PhoneInputCountry {
  position: absolute !important;
  left: 5%;
  bottom: 10%;


} */

.PhoneInput {
  width: 100% !important;
  padding: 1% 5% !important;
  border: 1px solid #7D7D7D !important;
  border-radius: 8px !important;
}

.PhoneInputInput {
  border-radius: 8px !important;
  border: none !important;
}

.character {
  border-radius: 8px;
}

.verificationContainer {
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

:where(.vi__wrapper) {
  position: relative;
  width: 100% !important;
}