.new-error-css {
  width: 100% !important;
  padding: 0 0.5em;
}

.food-suggestions {
  width: 100%;
  left: 66px;
  top: 338px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 1rem;
  /* identical to box height, or 125% */

  text-transform: capitalize;

  /* Primary / Dark Gray */

  color: #7D7D7D;

}
.suggestions-bar{
  width: 100%;
  height: 0px;
  left: 56px;
  top: 367px;
  margin-top: 0.25rem;

  /* Primary / Light Gray */

  border: 1px solid #EFEFEF;
}

.pointer {
  cursor: pointer;
}

.search-main-embedded {
  min-width: 100%;
  border-radius: 10px;
  padding-top: 3rem;
  background-color: white;
  display: table;
}

.search-main{
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  max-height: 75%;
  min-width: 90%;
  border-radius: 10px;
  padding-top: 3rem;
  //box-shadow:  0 4px 8px 0 rgba(192, 191, 191, 0.2), 0 6px 20px 0 rgba(170, 169, 169, 0.19);
  background-color: white;
  display: table;
}
.new-search-icon{
  position: absolute;
  right: 20px;
}
.pairing-result-logo-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.logo-desc {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 14px;
  color: #7D7D7D;
}

.PA-logo-pairing {
  width: 12px;
  height: 12px;
  margin: 0 5px;
}

.pairing-result-search-container {
  padding: 0 28px;
}

.pairing-result-search-bar-text {

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #7D7D7D;

}

.result-search-bar-container{
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;
}

.pairing-search-bar, select, textarea{
  color: #343434;
  background: #EFEFEF;
  opacity: 1;
  border-radius: 100px;
  border: none;
  height:35px;
  width: 100%;
}

// input {
//   padding: 9px 40px 9px 20px;
// }

.food-wine-btn-container{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}
.wine-btn{
  border-right: 0.25rem solid #7D7D7D;
  border-top: 0.25rem solid #7D7D7D;
  border-bottom: 0.25rem solid #7D7D7D;
  border-radius: 0px 50px 50px 0px;
}
.food-btn{
  border-left: 0.25rem solid #7D7D7D;
  border-top: 0.25rem solid #7D7D7D;
  border-bottom: 0.25rem solid #7D7D7D;
  border-radius: 50px 0px 0px 50px;
}

.hist-container{
  display: flex;
  flex: 1;
  margin-left: 5rem;
  cursor: pointer;
}

.picture{
  height: 100px;
  width: auto
}
.name{
  padding-top: 3rem;
  padding-left: 2rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  max-width: 221px;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  text-transform: capitalize;

  /* Primary / Dark Gray */

  color: #7D7D7D;
}
.btn-icons{
  padding-left: 5px;
}