@use "src/app/styles/theme" as s;

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stickyHeader {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 24px;
  background-color: #951901;
  text-align: center;
  z-index: 10;
}

.stickyHeaderTxt {
  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-weight: 700;
}

.stickyHeaderTxt:hover {
  color: white;
  text-decoration: underline;
}

.box {
  width: 100%;
  padding: 48px 24px;
}

.titleTxt {
  color: #951901;
  text-align: center;
  font-family: s.$font-2;
  font-size: 30px;

  font-weight: 800;
}

.wineGrid {
  padding-top: 24px;
  width: 100%;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;
}

.logo {
  margin-top: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.logo > img {
  display: block;
  width: 140px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: 8px;
  background: white;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);

  overflow: hidden;
}

.cardTitle {
  padding: 16px 12px;
  color: #343434;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 600;
}

.cardTitle:hover {
  color: #343434;
  text-decoration: underline;
}

.cardImg {
  overflow: hidden;
}

.cardImg > img {
  display: block;
  width: 100%;
  max-height: 200px;
  aspect-ratio: 8 / 9;
  object-fit: cover;
  object-position: center;
}

.card:hover {
  background-color: rgb(242, 242, 242);
  cursor: pointer;
}
