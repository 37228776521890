@use "src/app/styles/theme" as s;

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titleTxt {
  width: 90%;
  margin-top: 4vh;
  font-size: 30px;
  font-weight: 800;
  color: rgb(192, 17, 17);
  font-family: s.$font-2;
  text-align: center;
}

.wineRow {
  width: 90%;
  margin-top: 6vh;
  display: flex;
  justify-content: space-between;
  align-items: top;
  gap: 2vw;
  flex-wrap: wrap;
}

.logo {
  margin-top: 10vh;
  margin-bottom: 2vh;
  width: 70px;
}

.card {
  min-height: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 48%;
  border-radius: 8px;
  gap: 8px;
  //padding-top: 1rem;
}

.card:hover {
  //background-color: rgb(236, 236, 236);
  cursor: pointer;
}

.wineImgBg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  min-width: 100px;
  border-radius: 8px;
}

.wineImg {
  object-fit: contain;
  height: 84%;
  width: 100%;
  //mix-blend-mode: multiply;
}

.text {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  font-family: s.$font-1;
  color: s.$gray-dark;
  //align-self: flex-start;
}

.wineTypeText {
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  font-family: s.$font-1;
  color: s.$gray;
}

@media (min-width: 600px) {

  .main {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .titleTxt {
    font-size: 3.2vw;
  }

  .wineTypeText {
    font-size: 1.6vw;
  }

  .text {
    font-size: 1.8vw;
  }

  .wineImgBg {
    height: 22vw;
  }

  .wineRow {
    width: 90%;
    margin-top: 6vh;
    display: flex;
    justify-content: space-between;
    align-items: top;
    gap: 2vw;
    flex-wrap: nowrap;
  }

  .card {
    width: 100%;
    gap: 1vh;
  }

  .logo {
    width: 10vw;
  }

}
