.animationExpandable {
  min-height: 0;
}

.collapseWrapper {
  width: 100%;
  opacity: 1;
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s, opacity 0.3s, background-color 0.3s,
  margin-top 0.5s, margin-bottom 0.5s;
}

.collapse {
  opacity: 0;
  grid-template-rows: 0fr;
}

.expandWrapper {
  width: 100%;
  opacity: 0;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s, opacity 0.3s, background-color 0.3s,
    margin-top 0.5s, margin-bottom 0.5s;
}

.expand {
  opacity: 1;
  grid-template-rows: 1fr;
}

.collapseHorizontalWrapper {
  width: 100%;
  overflow: hidden;
  transition: width 0.5s;
}

.collapseHorizontalItem {
  min-width: 0;
}

.collapseHorizontal {
  width: 0%;
}

.slideFromRight {
  //transform: translateX(0);
  animation: fromRight 0.5s;
}

.slideFromLeft {
  //transform: translateX(0);
  animation: fromLeft 0.5s;
}

.faded {
  opacity: 0;
  transition: opacity 0.3s;
}

.unfaded {
  opacity: 1;
  transition: opacity 0.3s;
}

.unfaded2 {
  opacity: 1;
  transition: opacity 1s;
}

.faded2 {
  opacity: 0;
  transition: opacity 1s;
}

.collapseH {
  animation: shrinkWidth 0.5s forwards;
}

@keyframes shrinkWidth {
  0% {
    width: 100%;
  }
  99% {
    width: 1%;
  }
  100% {
    width: 0;
    display: none;
  }
}

@keyframes fromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
