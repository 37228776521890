@media (min-width: 1025px) {
  .select-winery-card-card {
    width: 15% !important;
    // width: 250px;
    // height: 250px;
  }
  .select-winery-card-display {
    width: 40%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .select-winery-card-display-mob {
    // width: 15% !important;
    width: 40%;
  }
  .select-winery-card-card-disabled {
    width: 15% !important;
    opacity: 0.5;
  }

  .select-winery-bg {
    background: #f2f2f2;
  }
}
.select-winery-card-display {
  width: 40%;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.select-winery-card-name {
  color: #707070;
  font-family: "poppins", sans-serif !important;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-winery-card-name::first-letter {
  text-transform: capitalize;
}

.select-winery-card-details {
  width: 100%;
  // padding-top: 5%;
}

.select-winery-card-price-text {
  color: #a84551;
  font-weight: bold;
}

.select-winery-card-details-row {
  // width: 100%;
  // position: absolute;
  bottom: 0;
}

.select-winery-card-name-container {
  margin-bottom: 5%;
}

.select-winery-card-details-data-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-winery-card-bottle-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 130px;
  height: 100px;
  width: 100%;
  // min-height: 10vh;
  // background-color: #F1EEEE;

  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}
.select-winery-card-bottle-logo {
  width: 50%;
}

.select-winery-card-bottle-logo-from-api {
  // width: 45%; // ratio - 2:5
  width: 80%;
  max-height: 100px;
  object-fit: contain;
  background-repeat: no-repeat;
}

.select-winery-card-subcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.select-winery-card-card {
  width: 40%;
  // height: 200px;
  // min-height: 10vh;
  // border: solid;
}

.select-winery-card-card-disabled {
  width: 40%;
  opacity: 0.5;
}
.select-winery-card-name-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
}
.select-wineries-section-div {
  // border-bottom: 2px solid #a84551;
}
.select-wineries-heading-div {
  display: flex;
  justify-content: center;
}
.select-wineries-heading {
  padding-top: 5px;
  text-align: center;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #0f0e0e;
  // text-transform: uppercase;
  opacity: 1;
}
.select-winery-bg {
  // background: #f2f2f2;
  height: 100%;
  width: 100%;
}
.select-winery-card-display-mob {
  // width: 15% !important;
  width: 40%;
}

.select-type {
  text-align: center;
}
.selected-type {
  text-align: center;
  color: #a84551;
}
.selected-winery-type-card {
  border: 2px solid #a84551;
}
