// For JIRA PAIR-1570

.share-button { 
    background-color: transparent;
    outline: none;
    
    border: none;
    
    // padding: 10px 10px;
}

.share-image { 
    max-width: 20px;
    max-height: 20px;
}