.google-btn-span {
	.button[disabled] {
		opacity: 0.65 !important;
	}
	::selection {
	color: #fff;
	background: #F14436;
	}
}

.google-btn {
	display: flex;
	width: 149px;
	height: 45px;
	color: #fff;
	user-select: none;
	justify-content: space-around;
	align-items: center;
	border: none;
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;	background: #F14436 0% 0% no-repeat padding-box;
	border-radius: 3px;
	:selection {
	color: #FFFFFF !important;
	background: #F14436;
	}
	:hover {
	color: #FFFFFF !important;
	background: #F14436;
	}
	:disabled {
		transition: opacity 0.5s ease 0s;
	}
}
	.google-btn:disabled {
		transition: opacity 0.5s ease 0s;
	}
.google-btn:disabled,
.google-btn[disabled]{
	transition: opacity 0.5s ease 0s;
}


@media (min-width: 300px) and (max-width: 375px) {
.google-btn {
	display: flex;
	width: 130px !important;
	height: 45px;
	color: #fff;
	justify-content: space-around;
	align-items: center;
	border: none;
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;	background: #F14436 0% 0% no-repeat padding-box;
	border-radius: 3px;
}
}