@use "src/app/styles/theme" as s;

.card {
  all: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.ghostCard {
  all: unset;
  width: 100%;
}

.card:hover {
  cursor: pointer;
}

.wineImgBg {
  aspect-ratio: 1 / 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100px;
  background-color: s.$gray-light;
  border-radius: 8px;
}

.wineImg {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  width: 80%;
  height: 80%;
  mix-blend-mode: multiply;
}

.textBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.buyBtn {
  display: flex;
  width: 100%;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  //border: 1px solid s.$gray-dark;
  border: none;
  background: s.$gray-dark;
}

.buyBtn:hover {
  background: s.$gray;
}

.foodImg {
  height: 60%;
  width: 60%;
  border-radius: 50%;
  transform: translate(10%);
  object-fit: cover;
}

.varietalImg {
  aspect-ratio: 1 / 4;
  // height: 160px;
  // width: 40px;
  width: 20%;
  height: 80%;
  transform: rotate(8deg) translate(-10%);
  object-fit: cover;
}

////////////////

// .wineCard {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 16px;
//   cursor: pointer;
// }

// .wineImgBg {
//   display: flex;
//   width: 160px;
//   height: 160px;
//   padding: 16px 20px;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   border-radius: 8px;
//   background: s.$gray-light;
// }

// .wineImg {
//   //width: 160px;
//   height: 130px;
//   flex-shrink: 0;
//   border-radius: 8px;
//   background: url(<path-to-image>), lightgray 50% / contain no-repeat;
//   mix-blend-mode: multiply;
// }

// .buyBtn {
//   display: flex;
//   width: 160px;
//   padding: 10px 8px;
//   justify-content: center;
//   align-items: center;
//   gap: 8px;
//   border-radius: 4px;
//   border: 1px solid s.$gray-dark;
//   background: s.$gray-dark;
// }

// .wineCardTextBox {
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 8px;
//   max-width: 160px;
// }

// .wineName {
//   max-width: 160px;
// }

// .price {
//   color: s.$red;
// }
