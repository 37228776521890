/* Swipeable card customization */
@media (min-width: 1025px) {
    // .card-control-section.swipe_card_layout{
    //     height:115vh;
    // }
    .card-desktop-bg {
        box-shadow: 0px 3px 36px #cccccc4d;
        width: 50% !important;
        height: fit-content !important;
        /* border: 2px solid #C0C0C0; */
        border-radius: 25px;
        overflow: auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        margin: auto !important;
        position: fixed !important;
    }

    .e-card-resize-container {
        padding: 10%;
        padding-top: 5% !important;
    }
    .card-control-section.swipe_card_layout {
        // min-height: 450px;
        // min-height: 115vh !important;
    }
    .card-control-section.swipe_card_layout #horizontal_product .e-card {
        margin-left: -13px !important;
        margin-top: 10px !important;
    }
    .slick-prev {
        left: 2% !important;
        z-index: 1;
      }
      .slick-next {
        right: 2% !important;
        z-index: 1;
      }
      .slick-prev:before{
          color:#a84551 !important;
          font-size: 30px !important;
          line-height: 3 !important;
      }
      .slick-next:before{
          color:#a84551 !important;
          font-size: 30px !important;
          line-height: 3 !important;
      }
}

.card-control-section.swipe_card_layout .row.card-layout {
    display: flex;
}

.card-control-section.swipe_card_layout .row.card-layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
    margin: 0 auto;
}

.card-control-section.swipe_card_layout .e-card.e-card-horizontal .e-card-stacked .e-card-actions {
    flex-grow: 1;
}

.card-control-section.swipe_card_layout .e-card.e-card-horizontal .e-card-stacked .e-card-actions button {
    background: #fff;
    margin-left: 5px;
    margin-right: 5px;
    background-color: transparent;
}

.card-control-section.swipe_card_layout .e-card.e-card-horizontal .e-card-stacked > :first-child {
    flex-grow: initial;
}

.card-control-section.swipe_card_layout #horizontal_product .e-card {
    user-select: none;
    // width: calc(100% - 15px);
    width: 100%;
}

.card-control-section.swipe_card_layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
    position: relative;
    top: 50px;
    width: 100%;
}

.card-control-section.swipe_card_layout {
    // min-height: 450px;
    min-height: 100vh;
}

.card-control-section.swipe_card_layout #horizontal_product .e-card {
    position: absolute;
    transition: all 200ms;
    touch-action: none;
}

.card-control-section.swipe_card_layout .card-out {
    // animation: card-out 0.3s cubic-bezier(.8, .2, .1, 0.8);
    // transform: translateX(0px) rotate(0deg) translateY(0px) scale(1);
    // transform: translateX(0px) translateY(0px);
}

.card-control-section.swipe_card_layout .card-out-left {
    // animation: card-out-left 0.3s cubic-bezier(.8, .2, .1, 0.8);
    // transform: translateX(0px) translateY(0px);
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

.card-out-left {
    animation: 0.3s ease-out 0s 1 card-out-left;
}
.card-out {
    animation: 0.3s ease-out 0s 1 card-out;
}

@keyframes card-out-left {
    0% {
        z-index: 20;
        transform: translateX(0px) translateY(0px);
    }
    10% {
        transform: translateX(-50px) translateY(0px);
    }
    20% {
        transform: translateX(-100px) translateY(0px);
    }
    30% {
        transform: translateX(-150px) translateY(0px);
    }
    40% {
        transform: translateX(-200px) translateY(0px);
    }
    50% {
        transform: translateX(-250px) translateY(0px);
    }
    60% {
        transform: translateX(-200px) translateY(0px);
        z-index: 0;
    }
    70% {
        transform: translateX(-150px) translateY(0px);
        z-index: 0;
    }
    80% {
        transform: translateX(-100px) translateY(0px);
        z-index: 0;
    }
    90% {
        transform: translateX(-50px) translateY(0px);
        z-index: 0;
    }
    100% {
        transform: translateX(0px) translateY(0px) scale(1);
    }
}

@keyframes card-out {
    0% {
        z-index: 20;
        transform: translateX(0px) translateY(0px);
    }
    10% {
        transform: translateX(50px) translateY(0px);
    }
    20% {
        transform: translateX(100px) translateY(0px);
    }
    30% {
        transform: translateX(150px) translateY(0px);
    }
    40% {
        transform: translateX(200px) translateY(0px);
    }
    50% {
        transform: translateX(250px) translateY(0px);
    }
    60% {
        transform: translateX(200px) translateY(0px);
        z-index: 0;
    }
    70% {
        transform: translateX(150px) translateY(0px);
        z-index: 0;
    }
    80% {
        transform: translateX(100px) translateY(0px);
        z-index: 0;
    }
    90% {
        transform: translateX(50px) translateY(0px);
        z-index: 0;
    }
    100% {
        transform: translateX(0px) translateY(0px) scale(1);
    }
}

.card-control-section.swipe_card_layout .e-card > * {
    max-width: 100%;
    justify-content: flex-start;
}

@media (min-width: 540px) {
    .card-control-section.swipe_card_layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
        width: 50%;
    }
    .card-control-section.swipe_card_layout .card-layout {
        margin: auto;
        max-width: 870px;
    }
}

// The above CSS is from syncfusion

// The below CSS is customly written by us
.slick-prev {
    left: 2% !important;
    z-index: 1;
  }
  .slick-next {
    right: 2% !important;
    z-index: 1;
  }
  .slick-prev:before{
      color:#a84551 !important;
      font-size: 30px !important;
      line-height: 3 !important;
  }
  .slick-next:before{
      color:#a84551 !important;
      font-size: 30px !important;
      line-height: 3 !important;
  }

.e-card .e-card-horizontal {
    height: 30% !important;
    margin-left: 50% !important;
}

.col-xs-6 {
    width: 100% !important;
}

.e-card-resize-container {
    padding: 10%;
    padding-top: 5%;
}

.row .card-layout {
    width: 100%;
}



// .slides {
//     position: relative;
//     .slick-prev, .slick-next {
//       position: absolute;
//       top: 50%;
//     }
//     .slick-prev {
//       left: 5%;
//     }
//     .slick-next {
//       right: 5%;
//     }
//     .slick-next:before{
//         color: red;
//     }
//   }

.control-pane {
    // background-image:url('../../../assets/images/taste-pref-bg.png');
    // background-color:#a84551 !important;
    // opacity: 30%;
}

.card-sub-container {
    //   margin-top: 15%;
}
.swipeablecard-sub-container {
    //  remove margin top in the swipeable card
    //  screens so as to increase the size of the card
    //   margin-top: 5%;
}

.swipe-card-sub-header-section {
    display: inline-grid;
    margin-top: 5%;
    margin-left: 5%;
    width: 100%;
}

.header-text-row {
    margin-top: 3%;
    margin-left: 6%;
}

.taste-preference-sub-text {
    margin-left: 6%;
    color: white;
}

.swipe-header-section {
    width: 100%;
    box-shadow: #1d1d1d1a !important;
    // height: 20vh;
    position: absolute;
    display: inline-block;
    align-content: center !important;
}

.e-card {
    box-shadow: none !important;
}

.card-out-margin {
    animation: MoveUpDown 0.8s;
}

.swipe-left-right-arrow-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    margin-top: 80%;
}
.swipe-right-arrow,
.swipe-left-arrow {
    width: 7%;
}

// css for dots - number of page indicator
.slick-dots {
    bottom: 2px !important;
    font-size: 8px !important;
    overflow: auto !important;
}

.slick-dots li {
    width: 10px !important;
}

.slick-list {
    margin-bottom: -6% !important;
}

ul {
    margin-top: -17px !important;
    margin-right: 14% !important;
}

.slick-dots li.slick-active button:before {
    opacity: 1 !important;
}

@media (min-width: 300px) and (max-width: 400px) {
    ul {
        margin-top: 10px !important;
    }
}
