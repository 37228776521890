.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 0 !important;
}

.card-title {
  text-align: left;
  // font: normal normal normal 15px/21px Poppins;
}
.img-square-wrapper {
  max-height: 200px;
  height: auto;
}
