@use "src/app/styles/theme" as s;

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 4rem 2rem;
  gap: 24px;
}

.heading {
  width: 100%;
}

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}

.formInput {
  width: 100%;
  padding: 16px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.formField {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
}

.formFieldError {
  color: red;
}

.asterisk {
  font-weight: 800;
  color: s.$red;
}

.checkBoxOption {
  display: flex;
  align-items: center;
  gap: 8px;
}

.formInputTextArea {
  height: 120px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.checkBoxOption > input[type="checkbox"] {
  margin: 0;
}

.radioBtn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.radioBtn > input[type="radio"] {
  margin: 0;
}

.submitBtn {
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: s.$gray-dark;

  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.grayedOut {
  color: s.$gray;
  background: s.$gray-light;
}

@media (min-width: 600px) {
  .formContainer {
    width: 70%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
  }

  .heading {
    width: 70%;
    max-width: 600px;
  }

}
