.quantity-left-minus {
    color:#a84551;
  }
  .quantity-div {
    border: 1px solid #ccc;
  }
  .cart-input {
    border-bottom-style: none;
    border-top-style: none;
    font-weight: 700;
    width: 5rem;
  }
  .quantity-right-plus {
    color:#a84551;
  }