.tp-detail-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tp-footer-pop-btn-css {
    margin-bottom: 28px;
    margin-top: 22px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.select-tp-btn {
    background:#a84551 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    height: 35px;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #ffffff;
}
.tp-do-it-later-btn-css{
    height: 33px;
    text-align: center;
    letter-spacing: 0px;
    font-size: 15px;
    color:#a84551;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
}
.pa-title {
    font-style: italic;
    font-weight: bold;
}