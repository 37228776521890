.search-wine-for-dish-main-desktop {
  width: 100%;
}

.search-box-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-left: 3%;
  margin-top: 1%;
  // width: 48%;
}

.dropText {
  padding-left: 15px;
  font-style: italic;
  color: #000;
  font-family: "poppins", sans-serif !important;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.thin-divider-line-recommendations {
  background: #cccccc;
  height: 1px;
  margin-top: 1%;
}

.search-wine-for-dish-container-desktop {
  width: 35%;
  position: absolute;
  overflow: auto !important;
  display: block;
  // flex-direction: column;
  margin-top: 1%;
  margin-left: 1%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  height: 75vh;
}

.search-btn-desktop {
  color: white;
  background: #a84551;
  margin-left: 15px;
  height: 40px;
  width: 100px;
}

.search-btn-desktop:hover {
  color: white !important;
  text-decoration: none;
}

.search-wine-form-row-input-desktop {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 40px !important;
  text-transform: capitalize;
  border: 1px solid #1d1d1d1a;
  display: flex;
  align-items: center;
  width: 93%;
}

.search-wine-form-row-recommand-input-desktop {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: auto;
  text-transform: capitalize;
  border: 1px solid #1d1d1d1a;
  // display: flex;
  align-items: center;
  width: 93%;
}

.search-wine-form-tab-desktop-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 50px !important;
  text-transform: capitalize;
  // border: 1px solid #1d1d1d1a;

  display: flex;
  // align-items: center;
  width: 93%;
}

.search-dish-header-text {
  margin-top: 1%;
  margin-left: 3%;
  color: #727272;
  opacity: 1;
  font-size: 18px;
  font-family: "poppins", sans-serif !important;
}

.recommendations-desktop-main-container {
  width: 100%;
  height: fit-content;
  margin-top: 90px;
}

.pairing-recommendations-main-header {
  text-align: left;
  font-family: "poppins", sans-serif !important;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #2b2b2b;
  opacity: 1;
  margin-top: 2%;
  margin-left: 2%;
}

.recommended-wines-desktop-main-container {
  margin-left: 35%;
  width: 65%;
  height: 75vh;
  overflow-y: auto;
}
.dsk-err {
  margin-top: 10%;
  width: 100% !important;
}
