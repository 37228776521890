@media (min-width: 1025px) {
    // :root {
    //     --bg-color-main: #f8f8f8 0% 0% no-repeat padding-box;
    //   }

    // :root{
    //     background: var(--bg-color-main);
    // }

    .sign-in-main-container-background {
        // background: #f8f8f8 0% 0% no-repeat padding-box !important;
    }

    .sign-in-btn {
        margin-top: 4% !important;
        margin-bottom: 4% !important;
        font-family: "poppins", sans-serif !important;
        font-size: 18px !important;
    }

    .welcome-title {
        font-size: 30px !important;
        margin-left: 7.5% !important;
        justify-content: left !important;
    }

    .signin-form-row-input {
        margin-top: 2% !important;
    }

    .signin-form-email-text {
        margin-top: 10px;
        font-family: "poppins", sans-serif !important;
        font-size: 14px;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        letter-spacing: 0px;
        color: #69696d;
        opacity: 1;
    }

    .signin-form-password-text {
        font-family: "poppins", sans-serif !important;
        font-size: 14px;
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: Regular 18px/27px Poppins;
        letter-spacing: 0px;
        color: #69696d;
        opacity: 1;
    }

    .sign-in-main-container {
        box-shadow: 0px 3px 200px -15px #928f8f62;
        background: #ffffff 0% 0% no-repeat padding-box;
        width: 430px !important;
        height: fit-content !important;
        border-radius: 25px;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        margin: auto !important;
        position: fixed !important;
    }

    .logo-container {
        padding: 8%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo {
        width: 40% !important;
    }

    .signin-form-row {
        font-size: 1.5em !important;
    }

    .forgot-password-text {
        margin-bottom: 25px;
        cursor: pointer;
        font-family: "poppins", sans-serif !important;
        font-size: 18px;
        padding: 10px;
    }
    .new-sign-up-div {
        margin-bottom: 25px;
        font-size: 18px;
        padding: 10px;
    }
}

@media (min-width: 300px) and (max-width: 400px) {
    .signin-form-row-input {
        margin-top: 5%;
        color: #2b2a2a;
        font-family: "poppins", sans-serif !important;
        font-size: 1.25em;
        border: none;
        height: 40px !important;
        // text-transform: capitalize;
        border: 1px solid #1d1d1d1a;
        width: 100% !important;
    }
}

.sign-in-main-container {
    margin-top: 5%;
    overflow: auto !important;
    position: relative;
    // height: 100vh;
    // background-image:url('../../../assets/images/sign-in-background.png');
    background-repeat: no-repeat;
    // background-position: center top!important;
    background-size: contain !important;
}
.logo {
    width: 55%;
    height: 50%;
}

.header {
    width: 100%;
}
.sign-up-text {
    margin-top: 5%;
    margin-right: 5%;
    // width: 100%;
    font-size: 1.25em;
    color:#a84551;
    text-align: end;
}

.new-account-text {
    color: #2b2a2a !important;
    margin-right: 2%;
}

.logo-container {
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcome-title {
    display: flex;
    align-items: center;
    color: #2b2a2a;
    justify-content: center;
    font-size: 1.5em;
    font-family: "poppins", sans-serif !important;
    font-weight: bold !important;
}

.sign-in-form-container {
    width: 85%;
    text-align: center;
}

.sign-in-text-container {
    width: 100%;
}

.sign-in-form {
    // padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sign-in-form-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signin-form-group {
    width: 100% !important;
}

.invalid-messages {
    // text-transform: lowercase;
}
.invalid-messages:first-letter {
    // text-transform: lowercase!important;
}
@media (min-width: 400px) {
    .signin-form-row-input {
        margin-top: 5%;
        color: #2b2a2a;
        font-family: "poppins", sans-serif !important;
        font-size: 1.25em;
        border: none;
        height: 50px !important;
        // text-transform: capitalize;
        border: 1px solid #1d1d1d1a;
        width: 100% !important;
    }
}

.signin-form-row {
    font-size: 1.25em !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 5%;
    margin-top: 10%;
    width: 100%;
    border: 1px solid #1d1d1d1a !important;
    box-shadow: none !important;
}
.form-row-error {
    padding: 4%;
    margin-top: 10%;
    width: 95%;
    box-shadow: 1px 1px 1px 1px #1d1d1d1a;
    border: 1px solid #ff0000;
}

.next-btn {
    margin-top: 10%;
    font-family: "poppins", sans-serif !important;

    font-size: 1.25em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    border-radius: 10%;
}

.sign-in-btn {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: "poppins", sans-serif !important;
    font-size: 1.5em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 100%;
}

// code for adding placeholder color

::placeholder {
    // font-size: 1.25em!important;
    color: #959292;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    //    font-size: 1.25em!important;
    color: #959292;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    //    font-size: 1.25em!important;
    color: #959292;
}

.sign-in-using-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1.5em;
    color: #2b2a2a;
    margin-top: 1%;
}

.facebook-logo {
    width: 18%;
}

.forgot-password-text {
    font-size: 1.25em;
    color:#a84551;
    float: right !important;
    // margin-top: 2%;
}
.new-sign-up-div {
    font-size: 1.25em;
    font-family: "poppins", sans-serif !important;
    color: #000000;
    text-align: center;
}
.new-frgt-pwd-text {
    color:#a84551;
    float: right !important;
    font-family: "poppins", sans-serif !important;
    font-size: 1em;
}
.new-sign-up-color {
    color:#a84551;
    font-weight: 550;
}
.desktop-div-scroll-signin {
    height: 80vh;
    overflow-y: hidden;
    overflow-x: hidden;
}
