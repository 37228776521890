@use "src/app/styles/theme" as s;

.pill {
  display: inline-block;
  padding: 2px 10px 1px 8px;
  border-radius: 100px;
  background-color: s.$gray-light;

  color: s.$gray;
  font-family: s.$font-1;
  font-size: 12px;
  font-weight: 500;
}