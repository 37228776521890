.order-details-history-header {
  margin: 0em 1em 0.5em 1em;
  display: flex;
  flex-direction: row;
  // color: #5d5d5d;
  // font-weight: 550;
}
.order-details-history-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0em 1em 0.5em 1em;
  border-radius: 10px !important;
  border: 1px solid rgba(222, 216, 216, 0.125);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 11px;
  padding-bottom: 11px;
}
.history-status {
  // margin-right: 0.3em;
  display: flex;
  justify-content: center;
}
.history-wine-name-div {
  margin: 0em 1em 0.5em 1em;
  display: flex;
  flex-direction: row;
  color: #727272;
  font-size: small;
}
.history-wine-date-div {
  margin: 0em 1em 0em 1em;
  display: flex;
  flex-direction: row;
  color:#a84551;
  font-size: small;
  letter-spacing: 0.5px;
}
.winery-name-container {
  margin: 0em 1em 0.5em 1em;
    display: flex;
    flex-direction: row;
    color: #5d5d5d;
    font-weight: 550;
}
.order-hist-winery-name {
  font-weight: bold;
}
.order-hist-lbl-colour {
  color: #6A6A6A;

}
.shpmt-shipped-lbl {
  color: #08cbecd6
}
