@media (min-width: 1025px) {
    .set-password-main-container {
        box-shadow: 0px 3px 200px -15px #928f8f62;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        margin: auto !important;
        position: absolute !important;
        width: 430px !important;
        height: fit-content !important;
        overflow: auto;
        overflow-x: hidden !important;
        overflow-y: hidden !important;
        border-radius: 25px;
        background: #ffffff 0% 0% no-repeat padding-box;
        // height: 100vh;
        // background-image:url('../../../assets/images/set-password-background.png');
        background-repeat: no-repeat;
        // background-position: center top!important;
        background-size: contain !important;
    }

    .user-greeting-title-desktop {
        text-align: center;
        font-size: 30px !important;
        font-family: "poppins", sans-serif !important;
        letter-spacing: 0px;
        color: #1e1e1e;
        opacity: 1;
        font-weight: 600;
    }

    .set-password-main-background {
        // background: #f8f8f8 0% 0% no-repeat padding-box;
    }

    .confirm-btn {
        margin-top: 1% !important;
        margin-bottom: 10% !important;
    }
}

.winery-name {
    font-weight: bold;
}

.set-password-main-container {
    position: relative;
    // height: 100vh;
    // background-image:url('../../../assets/images/set-password-background.png');
    background-repeat: no-repeat;
    // background-position: center top!important;
    background-size: contain !important;
    overflow: auto !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.logo {
    width: 40%;
    height: 50%;
}

.header {
    width: 100%;
}

.logo-container {
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.set-password-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2a2a;
    font-size: 2em;
    font-family: "poppins", sans-serif !important;
    font-weight: bold !important;
    margin-top: 5%;
    margin-bottom: 5% !important;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .user-greeting-title {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2b2a2a;
        font-size: 1.25em !important;
        font-family: "poppins", sans-serif !important;
        margin-top: 5% !important;
        word-wrap: break-word;
        padding: 1%;
    }
}
/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .user-greeting-title {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2b2a2a;
        font-size: 2em !important;
        font-family: "poppins", sans-serif !important;
        margin-top: 5% !important;
        word-wrap: break-word;
        padding: 1%;
    }
}

.user-greeting-title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2a2a;
    font-size: 1.5em;
    font-family: "poppins", sans-serif !important;
    margin-top: 5%;
    word-wrap: break-word;
    padding: 1%;
    animation: 1s ease-out 0s 1 slideInFromLeft;
}
.winery-name-set-password {
    text-transform: capitalize;
}

.set-password-form-container {
    width: 80%;
    text-align: center;
}

.sign-in-text-container {
    width: 100%;
}

.set-password-form {
    // padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.set-password-form-parent {
    // margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-row {
    padding: 4%;
    margin-top: 10%;
    width: 95%;
    border: none;
    box-shadow: 1px 1px 1px 1px #1d1d1d1a;
}
.form-row-error {
    padding: 4%;
    margin-top: 10%;
    width: 95%;
    box-shadow: 1px 1px 1px 1px #1d1d1d1a;
    border: 1px solid #ff0000;
}

.confirm-btn {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: "poppins", sans-serif !important;
    font-size: 1.5em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 100% !important;
}

.set-password-btn {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: "poppins", sans-serif !important;
    font-size: 1.5em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 95%;
}

// code for adding placeholder color

::placeholder {
    color: #959292;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #959292;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #959292;
}

.set-password-using-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1.5em;
    color: #2b2a2a;
    margin-top: 1%;
}
