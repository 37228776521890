.new-sing-up-header {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  width: 100%;
  justify-content: center;
  align-items: center;
  // padding: 5%;
}
.social-login-container {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 600px) {
  .social-login-container {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 600px) {
  .social-login-container {
    display: flex;
    flex-direction: column;
  }
}
.my-facebook-button-class {
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: calc(0.27548vw + 12.71074px);
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #4c69ba;
  border: calc(0.06887vw + 0.67769px) solid #4c69ba;
  padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px);
  width: 100%;
  .fa {
    margin-right: calc(0.34435vw + 3.38843px);
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
}
.padng-style {
  padding: 10px 20px 10px 20px;
}
.back-btn-width {
  width: 18px;
}
.three-space-even-width {
  width: 33.33%;
}
.back-div {
  // display: flex;
}
.new-sign-up-logo {
  display: flex;
  margin-left: auto;
  width: 65%;
}
.sign-up-title {
  display: flex;
  // align-items: center;
  color: #2b2a2a;
  justify-content: center;
  font-size: 1.5em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}
.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height:100%
  padding-top: 10vh;
}
.new-sing-up-address-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height:100%;
  //  height: 100vh;
  overflow: auto;
}
.new-sing-up-address-form {
  width: 100%;
  /* margin-bottom: 7%; */
}
.singup-form-card {
  // border-radius: 10px !important;
  margin: 1em 0em !important;
  // padding: 1em !important;
  // box-shadow: 1px #1d1d1d1a !important;
  // border: none;
}
.chck-box-label {
  padding-left: 10px;
  color: #6f6c6c;
  font-size: 13px;
  font-family: "poppins", sans-serif !important;
  font-weight: normal !important;
  display: inline !important;
}
.chck-box-div {
  // display: flex;
}
.overall-font {
  font-family: "poppins", sans-serif !important;
}
.alrdy-title {
}
.alrdy-sing-in-text {
  color: #a84551;
  font-weight: 550;
}
.already-div {
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-sign-upform-error-shipping-address-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1% 0%;
  text-align: center;
}
.common-form-error-text {
  color: red;
  font-size: 1.25em !important;
}
.new-sign-up-input-design {
  border: 1px solid #cdcdcd !important;
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
}
.new-signup-label {
  color: #353030;
}
@media screen and (min-width: 1025px) and (max-height: 800px) {
  .padng-style {
    height: 85vh !important;
  }
}
.new-paddin-style-logo-header {
  padding: 5% 5% 5% 5% !important;
}
.new-pa-logo-width {
  width: 36%;
}
.new-sign-up-title {
  display: flex;
  align-items: center;
  color: #2b2a2a;
  justify-content: center;
  font-size: 1.4em;
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
}
.dob-fnt-size {
  font-size: 0.9em !important;
}
.chckbox-input-style {
  display: flex;
  float: left;
}
.label-chckbx-style {
  text-align: justify;
}
// input.new-sign-up-input-design {
//     transform: scale(10);
//     -webkit-transform: scale(10);
//     -moz-transform: scale(10);
//     -ms-transform: scale(10);
//     -o-transform: scale(10);
// }

.new-error-msg-font {
  font-size: 1.1em !important;
}
.new-grey-font {
  color: #6f6c6c;
}
.new-input-form-height {
  height: 38px !important;
  font-size: 1.1em !important;
}
.txt-transform-none {
  text-transform: none !important;
}
.signup-button-div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.signup-button {
  width: 50% !important;
  // margin-bottom: 10px;
  // margin-top: 10%;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1.1 !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  justify-content: center;
  width: fit-content;
}

.terms-and-conditions {
  margin-top: 5px;
  text-align: justify;
  color: #6f6c6c;
  font-size: 13px;
  font-family: "poppins", sans-serif !important;
  font-weight: normal !important;
  display: inline !important;
}
.passErrorFont {
  font-size: 13px !important;
}
