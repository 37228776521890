@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;800&display=swap');

.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.bottom-middle {
  position: absolute;
  bottom: 0;
  left: 35%;
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.entrance-content-container {
  position: relative;
  margin-top: 35%;
  padding-left: 10%;
  padding-right: 10%;
}

.entrance-title {
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #951901;
}

.entrance-query {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #C73C32;
  padding-left: 0;
  width: 100%;

  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 37px;
}

.entrance-query-container {
  position: relative;
}

.entrance-search-icon {
  position: absolute;
  right: 1%;
  top: 25%;
  height: 32px;
  width: 32px;
}

.entrance-subtitle-container {
  margin-top: 5%;
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #951901;
}

.bold {
  font-weight: 800 !important;
}