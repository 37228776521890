@media (min-width: 1025px) {
    .rate-recommendation-text-subcontainer {
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }
}

.rate-recommendation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: 90vh;
    display: flex;
    justify-content: center;
    align-content: center !important;
    flex-wrap: wrap;
    position: relative;
    overflow: auto;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    margin-bottom: 5%;
}

.rate-recommendation-bottom {
    display: flex;
    flex-direction: column;
}

// .rate-recommendation-container:after {
//     content: "";
//     display: block;
//     height: 130px;
//     width: 100%;
//   }

.rate-recommendation-back-btn {
    margin-left: 5%;
    margin-top: 5%;
}
.rate-recommendation-text-container {
    margin-top: 10%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rate-recommendation-text-container-desktop {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rate-recommendation-dishname {
    text-align: center;
    color: #2b2a2a;
    font-size: 1.25em;
    font-family: "poppins", sans-serif !important;
    text-transform: capitalize;
}

.rate-recommendation-dishname-desktop {
    text-align: center;
    color: #2b2a2a;
    font-size: 1.25em;
    font-family: "poppins", sans-serif !important;
    text-transform: capitalize;
}

.rate-recommendation-text1 {
    text-align: center;
    color: #7d7d7d;
    // font-weight: bold;
    font-size: 1em;
    font-family: "poppins", sans-serif !important;
}

.rate-recommendation-text1-desktop {
    text-align: center;
    color: #7d7d7d;
    // font-weight: bold;
    font-size: 16px;
    margin-top: 5%;
    font-family: "poppins", sans-serif !important;
}

.rate-recommendation-text2 {
    color: #2b2a2a;
    font-weight: 500;
    font-size: 1.3em;
    font-family: "poppins", sans-serif !important;
}
@media (min-width: 300px) and (max-width: 400px) {
    .rate-recommendation-text2 {
        color: #2b2a2a;
        font-weight: 500;
        font-size: 1.25em !important;
        font-family: "poppins", sans-serif !important;
    }
}

// iPhone 5/SE
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .rate-recommendation-text2 {
        color: #2b2a2a;
        font-weight: 500;
        font-size: 1em !important;
        font-family: "poppins", sans-serif !important;
    }
}

.rate-recommendation-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.rate-recommendation-icon-desktop {
    width: 50%;
    margin-top: 5%;
}

.rate-recommendation-text-subcontainer {
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rate-recommendation-icon {
    width: 80%;
    margin-top: 10%;
}

.rating-star-container {
    display: flex;
    flex-direction: row;
}

.star-rating__star-ratingpage {
    padding: 13px !important;
    // font-size: 2em!important;
}

.rate-reco-text {
    // border-bottom: 3px solid#a84551;
}

.star-rating-ratingpage {
    &__star {
        display: inline-block;
        padding: 13px;
        vertical-align: middle;
        line-height: 1;
        font-size: 1.75em;
        color: #d2d2d2;
        transition: color 0.2s ease-out;

        &:hover {
            cursor: pointer;
        }

        &.is-selected {
            color: #f7d002;
            // animation: 0.3s ease-out 0s 1 shake;
        }

        &.is-disabled:hover {
            cursor: default;
        }
    }
}

.rating-star-recommendation-page-desktop {
    font-size: 1.3em !important;
}

.rating-star-recommendation-page {
    font-size: 1.75em !important;
}

.recommendation-rating-btn-container {
    width: 100%;
}

.feedback-text-container {
    margin-top: 5%;
    margin-bottom: 2%;
}

.rate-recommendation-submit-btn {
    font-family: "poppins", sans-serif !important;
    font-size: 1em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 100%;
    padding: 0.8rem 1.2rem;
}

.rate-recommendation-submit-btn-desktop {
    font-family: "poppins", sans-serif !important;
    font-size: 1em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 100%;
    padding: 0.8rem 1.2rem;
}

.rate-recommendation-submit-btn-desktop {
    font-family: "poppins", sans-serif !important;
    font-size: 1em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 400px;
    padding: 0.8rem 1.2rem;
}

.rate-recommendation-feedback-text {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #7d7d7d;
    font-size: 1em;
    font-family: "poppins", sans-serif !important;
}
