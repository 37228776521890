
.wine-news-feed-card-container {
    overflow: auto;
}

.wine-news-feed-card-type-subcontainer {
    display: flex;
    align-items: center;

}

.wine-news-feed-card-title {
    color: #2B2A2A;
    font-family: 'poppins', sans-serif !important;
    font-weight: bold;
    margin-left: 5%;
    font-size: 1.25em;
}
 
.wine-news-feed-card-details {
    width: 100%;
    padding: 2%;
    animation: 0.5s ContentFadeIn ease-out 0s 1 forwards;

}


  .wine-news-feed-card-title-container , .wine-news-feed-card-type-subcontainer {
      margin-bottom: 5%;
  }


  .wine-news-feed-card-subcontainer {
    display: flex;
    flex-direction: column;
  }

  .wine-news-feed-card  {
    width: 100%;     
  }

  .wine-news-feed-card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .wine-news-feed-card-content {
      color: #7D7D7D;
      font-family: 'poppins', sans-serif !important;
      font-size: 0.75em;
  }

 .wine-news-feed-card-feed-logo {
    width: 50px;
    height: 40px;
    border-radius: 50%;
    // animation: ImageFadeIn .5s 1.4s forwards;

}

  .wine-news-feed-card-header {
      display: flex;     
      align-items: center;
  }

  .wine-news-feed-card-container {
      margin-top: 3%;
  }

  @media only screen and (min-width: 320px) 
  and (max-height: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {


  }