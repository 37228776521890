@media (min-width: 1025px) {
    .reset-password-main-container {
        box-shadow: 0px 3px 200px -15px #928f8f62;
        background: #ffffff 0% 0% no-repeat padding-box;
        width: 430px !important;
        height: fit-content !important;
        border-radius: 25px;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        margin: auto !important;
        position: fixed !important;
    }

    .reset-password-btn {
        margin-bottom: 10%;
        margin-top: 0% !important;
        width: 100% !important;
    }

    .reset-password-form-row {
        font-size: 1.25em !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 4%;
        margin-top: 10%;
        width: 100%;
        border: 1px solid #1d1d1d1a !important;
        box-shadow: none !important;
        font-size: 1.25em !important;
    }
}

.reset-password-main-container {
    overflow: scroll !important;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain !important;
}
.logo {
    width: 40%;
    height: 50%;
}

.header {
    width: 100%;
}

.logo-container {
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-password-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2a2a;
    font-size: 2em;
    margin-bottom: 3% !important;
    font-family: "poppins", sans-serif !important;
    font-weight: bold !important;
}

.reset-password-form-container {
    width: 80%;
    text-align: center;
}

.sign-in-text-container {
    width: 100%;
}

.reset-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reset-password-form-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.reset-password-form-row {
    font-size: 1.25em !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 4%;
    margin-top: 10%;
    width: 100%;
    border: 1px solid #1d1d1d1a !important;
    box-shadow: none !important;
    font-size: 1.25em !important;
}
.form-row-error {
    padding: 4%;
    margin-top: 10%;
    width: 95%;
    box-shadow: 1px 1px 1px 1px #1d1d1d1a;
    border: 1px solid #ff0000;
}

.confirm-btn {
    margin-top: 10%;
    margin-bottom: 10%;
    font-family: "poppins", sans-serif !important;
    font-size: 1.5em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 95%;
}

.reset-password-btn {
    font-family: "poppins", sans-serif !important;
    font-size: 1.5em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 100%;
    margin-top: 10%;
}

// code for adding placeholder color

::placeholder {
    color: #959292;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #959292;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #959292;
}

.reset-password-title {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2a2a;
    font-size: 2em;
    font-family: "poppins", sans-serif !important;
    font-weight: bold !important;
}
