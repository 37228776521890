@use "src/app/styles/theme" as s;


.titleBox {
  margin: 4rem 0 3.2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.welcomeBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.start {
  margin-top: 1rem;
  margin-bottom: 4rem;
  text-align: left;
}

.startAmb {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.inputContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputBox {
  width: 100%;
  padding: 4% 5%;
  border: 1px solid s.$gray;
  border-radius: 8px;
}

.setupBtn {
  font-family: Inter, sans-serif;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: s.$gray-dark;
  padding: 12px 10px;
  border-radius: 8px;
  border: none;
}

.setupBtn:hover {
  background-color: s.$gray-light-hover;
}

.pageSection {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.pageSection2 {
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}

.searchContainer {
  width: 100%;
  margin-top: 3rem;
}

.searchBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  //flex-shrink: 0;
}

.partnerLogo {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  //background-color: s.$gray;
}

.bgRed {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  background-color: s.$red-light;
}

.bgWhite {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  background-color: s.$peach-dark;
}

.bg {
  margin: 0 0 4% 0;
  border-radius: 8px;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
}

.w60 {
  height: 60px;
  width: 60px;
  margin: 0 4% 0 0;
  //margin-right: 4%;
}

.wineImg {
  height: 80%;
  background-color: s.$peach-dark;
}

.wineryName {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: s.$gray-dark;
}

.phoneticResults {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: s.$gray;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding: 3% 4% 3% 4%;
}

.phoneticResults:hover {
  cursor: pointer;
}

.phoneticResultsImg {
  padding-right: 4%;
}

.phoneticResults:hover {
  background-color: s.$gray-light-hover;
}

.animationExpandable {
  min-height: 0;
}

.animationWrapper {
  width: 100%;
  opacity: 1;
  background-color: #efefef;
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
  transition: grid-template-rows 0.5s, opacity 0.3s, background-color 0.3s;
}

.collapsed {
  background-color: white;
  opacity: 0;
  grid-template-rows: 0fr;
}

.closeBtn {
  float: right;
  background: s.$gray-light;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
  outline: none;
  min-height: 40px;
  min-width: 40px;
  flex-shrink: 0;
  flex-grow: 0;
}

.btnNoClick {
  cursor: default !important;
}

.wineCardBoxCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.wineCardBoxRow {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 4%;
  align-items: center;
}

.loginSignupText {
  text-decoration: underline;
  color: #951901;
}

.loginSignupText:hover {
  text-decoration: none;
  cursor: pointer;
}

.spotlightBox {
  margin-top: 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.pairingsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.todaysWinesBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 22px;
}

.todaysWinesBoxRoble {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 18px;
}

.todaysWinesBoxFocused {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //gap: 8px;
}

.inputWithIcon {
  display: flex;
  width: 100%;
  padding: 8px 8px 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: white;
  border: 1px solid s.$gray-dark;
}

.noShrink {
  flex-shrink: 0;
  flex-grow: 1;
}


.pairBtn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: none;
  background: s.$gray-dark;
  color: white;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pairBtnHidden {
  height: 36px;
}

.savedCardBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 22px;
}
