@use "src/app/styles/theme" as s;

.pill {
  display: inline-block;
  padding: 2px 10px 1px 8px;
  border-radius: 100px;
  background-color: rgba(149, 25, 1, 0.10);
  color: s.$red;
  font-family: s.$font-1;
  font-size: 12px;
  font-weight: 500;
}