@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800&display=swap');

.winery-details-main-container {
  margin-bottom: 20%;
  overflow: auto;
  width: 100%;
  position: absolute;
  // height: 80vh;
  margin: auto;
  // margin-top: -9vh;
}
.winery-details-main-container:after {
  content: "";
  display: block;
  height: 70px;
  width: 100%;
}
.winery-details-header {
  padding: 5%;
  display: flex;
  align-items: center;
}

.winery-location-btn {
  // margin-right: 10px;
  // margin-bottom: 10px;
  height: 10px;
  width: 10px;
  margin-right: 2%;
  margin-top: 5px;
  // padding-right: 2%;
}

.winery-logo-div {
  // background-color: rgba(0, 0, 0, 0.5);
  height: 233px;
  display: flex;
  width: 100%;
}
.winery-logo-parent-div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
}
.winery-row {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.winery-details-title {
  // margin-top: 3%;
  font-weight: 500 !important;
  // margin-left: 3%;
  font-size: 1.75em;
  color: #2b2a2a;
}

.winery-logo {
  // width: 40%!important;
  // /* height: 50%; */
  // border-radius: 50%!important;

  // css added in px for now
  width: 100px;
  height: 100px;
  // border-radius: 100px;
}
.winery-name-title {
  padding-top: 2%;
  // margin-left: 1em;
  // margin-right: 2%;
  // color: #2b2a2a;
  font-size: 1.4em;
  font-weight: bold;
  line-height: 1.5em;
  font-family: "poppins", sans-serif !important;
  // position: absolute;
  // bottom: 0;
  // margin-bottom: 60px;
  color: white;
  max-width: 170px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.winery-name {
  margin-top: 5%;
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: bold;
  font-family: "poppins", sans-serif !important;
}
.winery-website-url {
  margin-top: 5%;
}
.winery-website-url,
.winery-phone-number {
  font-family: "poppins", sans-serif !important;
  // color: #959292;
  font-style: italic;
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  color: white;
}

.winery-details-card,
.winery-location-details {
  box-shadow: 0px 0px 10px 0px #1d1d1d1a;
}

.winery-card-body {
  // width: 100%!important;
}

.winery-call-details {
  width: 50%;
  border-color: #1d1d1d1a;
  float: left;
}
.winery-call-details {
  width: 50%;
  float: left;
}

.winery-details-card-header {
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.winery-details-card-subheader {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  // justify-content: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-right: 1em;
  margin-left: 1em;
}

.winery-details {
  margin-left: 1px;
  float: left;
  width: 49%;
  border: 1px solid #efefef;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
}

.call-btn,
.visit-website-btn {
  width: 10%;
}

.call-text,
.visit-website-text {
  margin-left: 5%;
  font-size: 1.25em;
  color: #959292;
}

.winery-location-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.winery-shw-location-container {
  display: flex;
  margin-left: 13px;
  align-items: center;
}

.winery-location-text {
  text-align: center;
  color: #c50808ad;
  font-size: 1em;
  margin: 5%;
  text-transform: capitalize;
}

.winery-about-title {
  color: #2b2a2a;
  font-size: 1.5em;
}

.winery-message-title {
  margin-top: 10px;
  color: #2b2a2a;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.winery-contact-title {
  margin-top: 10px;
  color: #2b2a2a;
  font-size: 1.5em;
  margin-bottom: 10px;
}

.winery-contact-contents {
  white-space: pre-wrap;
  color: #959292;
  font-family: "poppins", sans-serif !important;
}

.winery-about-title-container {
  width: 100%;
}

.contact-container {
  width: 100%;
  margin-top: 10px;
}

.winery-about-message {
  white-space: pre-wrap;
  color: #959292;
  font-family: "poppins", sans-serif !important;
}

.winery-about-text {
  white-space: pre-wrap;
  color: #959292;
  font-family: "poppins", sans-serif !important;
}
.winery-about-text:first-letter {
  text-transform: uppercase;
}

.rounded-circle {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0% !important;
}

.avatar {
  position: relative;
  display: inline-block;
}

.avatar-xl {
  width: 17rem;
  height: 10rem;
}
.winery-details-main-container {
  // animation:  slideInFromLeft .8s 1s forwards;
  animation: 0.5s ImageFadeIn ease-out 0s 1 forwards;
}

.winery-about-container {
  // margin-bottom: 25%;
  text-align: justify;
}

.winery-logo {
  // animation: 1s ease-out 0s 1 slideInFromLeft;
  //    animation: ImageFadeIn .8s 1s forwards;
}
.winery-social-media-container {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
}
.social-media-logo {
  // width: 13%;
  // padding:2%
  // margin-right: 3%;
  // margin-left: 3%;
}
.new-winery-d-header-container {
  justify-content: space-between;
}

@media (min-width: 1025px) {
  .winery-details-main-container {
    height: 80vh;
  }
  .winery-name-title {
    padding-top: 2%;
    // margin-left: 1em;
    // margin-right: 2%;
    // color: #2b2a2a;
    font-size: 1.4em;
    font-weight: bold;
    // line-height: 1.5em;
    font-family: "poppins", sans-serif !important;
    // position: absolute;
    // bottom: 0;
    // margin-bottom: 60px;
    color: white;
    // max-width: 10px;
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
  .winery-location-details,
  .winery-details-card {
    width: 90% !important;
    margin-left: 5% !important;
  }

  .winery-details-card-header {
    margin-bottom: 0% !important;
  }

  .winery-social-media-container {
    margin-top: 1% !important;
  }

  .winery-website-url {
    margin-top: 0% !important;
  }

  .bottom-navigation {
    display: flex;
    align-content: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .winery-details-container {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .winery-details-title {
    font-weight: 500 !important;
    // margin-left: 3%;
    font-size: 1.5em;
    color: #2b2a2a;
  }

  .winery-details-header {
    padding: 3%;
    display: flex;
    align-content: center;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.shw-ve-image{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding: 20px;
}
 
.strip-title{
  font-weight: bold;
}
 
.strip-image-container{
  // display: flex;
}
 
.strip-image{
  justify-content: center;
  // padding: 10px;
  // padding-top: 10px;
  object-fit: cover;
  border-radius:5px;
  width:88px;
  height:88px;
  flex: 1;
}
 
.strip-text{
  padding: 10px;  
  flex: 4;
  max-height: 88px;
}
 
.strip{   
  border: 1px #ccc solid;
  border-radius: 5px;
  margin: 20px;
  // padding: 10px;
  display: flex;
  flex-direction: row;
}
 
.strip-title{
  
}


.address-title{
  padding-left: 10px;

  /* Subheading 12 gray */

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold !important;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */
  /* Secondary / Mine Shaft */
  color: #343434;

}


.T{
  // align-content: left;
  text-align: left;
  margin-right: 45px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* or 140% */
  color: #7D7D7D;
}

.T{
  width: 112px;
  height: 14px;
  left: 43px;
  top: 305px;

  /* Body 10 gray */
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  // font-family: 'Montserrat', sans-serif;

  /* Primary / Dark Gray */

  color: #7D7D7D;
}
 
.heading-logo-section{
  margin: 20px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.heading-text-section{
  padding-left: 35px;
  margin-right: 35px;
  display: flex;
  flex-direction: row;
}

.heading-right{
  padding-left: 30px;
}

.T{
  margin: 10px;
}

.L-container{
  display: flex;
  flex-direction: column;

  &:hover{
    cursor: pointer;
  }
}

.section-title{
  /* Title 16 */
  padding-left: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */


  /* Secondary / Mine Shaft */

  color: #343434;
}

.section{
  padding-top: 10px;
  padding-bottom: 10px;
}

.section:nth-child(even) {
  background-color:white !important;
}

.L-text{
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* Primary / Burgundy */

  color: #951901;
}
.header {
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 35px;
  background-color:white !important;
}
.headerImg1 {
  position: relative;
  top: 0;
  left: 0;
}

.headerImg2{
  position: absolute;
  top: 100px;
  left: 20px;
}

.address-entry{
  padding: 5px;
  margin-left: 25px;
}
.address-flex-container{
  /* Body 10 gray */

  padding-left: 35px;
  margin-right: 35px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height, or 140% */


  /* Primary / Dark Gray */

  color: #7D7D7D;
  display: flex;
  flex-direction: row;
}

.logo-text{
  padding: 1px;
}

.address-flex-container-left, .address-flex-container-right{
  flex:1;
  display: flex;
  flex-direction: column;
}

.address-flex-container-right{
  left: 300px;
  padding-left: 60px;
  // justify-content: space-between;
}
.schedule-button-container {
  display: flex;
  justify-content: center;
}

.schedule-button{
  margin: 20px;
  align-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;

  color:white;
  width: 360px;
  height: 44px;
  left: 27px;
  top: 993px;
  background: #951901;
  border-radius: 5px;
  border: none;
}

.events-container{
  display: flex;
  flex-direction: row;
}

.events-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .event-card {
    flex: 0 0 auto;
  }
}

.events-container {
  -webkit-overflow-scrolling: touch;
}

.events-container {
  &::-webkit-scrollbar {
    display: none;
  }
}
.event-card {
  flex:1;
  border-radius: 6px;
  border-color: grey;
  border: solid lightgray;
  border-width: 1px;
  margin: 10px;
  width: 300px;
  // height: 233px;
}




.event-image{
  width:299px; //TODO change to percentage to accommodate more devices
  height:233px;
  // overflow: hidden;\
  border-radius: 5px;
  object-fit: cover;
}

.event-image-container{
  // position: relative;
  width: 299px;
  height: 233px;
  border-color: red;

  margin-bottom: 12px;

  //  overflow: hidden;
}

.event-date-title-container{
  display: flex;
  flex-direction: row;
}

.event-date-container{
  flex: 1 !important;

  display: flex;
  flex-direction: column !important;
  justify-content: center !important;

  background: #F5EAE8;
  height: 60px !important;
  width: 50px !important;
  border-radius: 5px;
  margin: 15px;
}

.event-title-address-container{
  flex: 3 !important;
  display: flex !important;
  flex-direction: column !important;
}

.event-title{
  // padding-left: 40px;

  /* Subheading 12 gray */
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* or 117% */

  /* Secondary / Mine Shaft */

  color: #343434;
}

.event-month{
  flex: 1;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* or 160% */
  padding-top: 10px;
  text-align: center;

  /* Primary / Burgundy */

  color: #951901;
}

.event-day{
  flex: 1;
  text-align: center;

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;


  /* or 100% */

  text-align: center;

  /* Primary / Burgundy */

  color: #951901;
}

.event-title-address-container{
  display: flex;
  flex-direction: row;
}
.logo-container{
  margin-top: 5px;
  margin-left: 35px;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-around; 
}

.youtube-logo{
  flex: 1;
}

.facebook-logo{
  flex: 1;
}

.instagram-logo{
  flex: 1;
}

.twitter-logo{
  flex: 1;
}
.yelp-logo{
  flex: 1;
}

.follow-us-text{
  /* Subheading 12 gray */
  margin-top: 20px;
  margin-left: 35px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 117% */


  /* Primary / Burgundy */

  color: #951901;
  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
}

.map-container{
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.strip-title{
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 26px;
  /* identical to box height, or 217% */
  
  
  /* Secondary / Mine Shaft */
  
  color: #343434;
}

.strip-paragraph{
/* Body 10 gray */

font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 14px;
/* or 140% */


/* Secondary / Mine Shaft */

color: #343434;
}