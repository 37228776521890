@use "src/app/styles/theme" as s;

.fromRight {
  //transform: translateX(0);
  animation: fromRight 0.5s;
}

.fromLeft {
  //transform: translateX(0);
  animation: fromLeft 0.5s;
}

@keyframes fromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.backToResultBtn {
  width: 100%;
  padding: 0 2.4rem;
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: baseline;
  color: s.$red;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 400;
}

.backToResultBtnTxt {
  color: s.$red;
}

.pairingImgBox {
  height: 192px;
}

.pairingImg {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logosBox {
  position: absolute;
  top: 0;
  left: 0;
}

.logosBox > img {
  display: block;
  margin: 8px;
}

.placerLogo {
  height: 45px;
  width: 80px;
  margin-bottom: 1rem;
}

.robleLogo {
  height: 80px;
  width: 80px;
}

.varietalImg {
  height: 192px;
  width: 40px;
  // transform: rotate(8deg) translate(-20px);
  object-fit: contain;
}

.buyBtn {
  background-color: s.$gray-dark;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  //padding: 12px 10px;
  width: 100%;
  border-radius: 8px;
  border: none;
}

.mainContainer {
  margin-top: 2rem;
  //padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.mainContainerSection {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.bg {
  aspect-ratio: 1/1;
  width: 160px;
  background-color: s.$gray-light;
  border-radius: 8px;
}

.wineImg {
  height: 80%;
}

.wineName {
  max-width: 160px;
  margin-bottom: 0;
}

.smallBtn {
  padding: 10px 8px;
  border-radius: 4px;
}

.price {
  color: s.$red;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.getAtTarget {
  color: s.$gray-dark;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.wineGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.getBox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.getWinesSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 8px 0;
}

.rightArrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.targetLogo {
  width: 16px;
  height: 16px;
}

.seeAll {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.seeAllBtn {
  background: none;
  border: none;
  display: flex;
  gap: 8px;
  align-items: baseline;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.seeAllBtnTxt {
  color: s.$red;
}

.link {
  color: s.$red;
}

.link:hover {
  color: black;
  text-decoration: none;
}

.wineCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.wineCardTextBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.varietalCard {
  display: flex;
  width: 100%;
  padding: 2.4rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 16px;
  border: 1px solid #efefef;
}

.flavorProfBox {
  margin: 2.4rem 0;
}

.fadeBox {
  position: relative;
}

.fade {
  position: relative;
  height: 2.4em; /* exactly three lines */
  overflow: hidden;
}
.fade::after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.2em;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 80%
  );
}
.fadeSeeMore {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 10;

  text-decoration: underline;
  color: #951901;
  background-color: white;
}

.fadeSeeMore:hover {
  text-decoration: none;
}

.pairingsBox {
  margin: 2.4rem 0;
  position: relative;
}

.gradientContainer {
  position: relative;
}

.gradient {
  position: absolute;
  background-image: linear-gradient(to top, white, transparent);
  backdrop-filter: blur(2px);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

// .pairingsBox::after {
//   content: "";
//   //text-align: right;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(
//     to bottom,
//     rgba(255, 255, 255, 0),
//     rgba(255, 255, 255, 1) 90%
//   );
// }

.phoneticResults {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: s.$gray;
  font-family: Inter, sans-serif;
  font-weight: 400;
  padding: 3% 4% 3% 4%;
}

.phoneticResultsImg {
  padding-right: 4%;
}

.viewFull {
  //   position: absolute;
  // top: 0;
  // z-index: 50;
  //   left: 50%;
  //   transform: translate(-50%, 0);
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 8px;
  background: var(--Primary-Burgundy, #951901);

  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
