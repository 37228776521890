.wine-history-picture {
  height: 100px;
  width: auto;

}

.wine-history-picture-frame {
  height: 100px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}