.payment-options-header-label {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
    color: #2b2b2b;
    font-weight: 700;
    font-size: medium;
  }
  .saved-cards {
    display: flex;
    flex-direction: row;
    margin-left: 1em;
    margin-right: 1em;
    color:#a84551;
    font-size: medium;
    font-weight: 600;
  }
  .add-new-card {
    margin-left: auto;
  }
  .grand-total-card {
    display: flex;
    padding: 12px;
    background: #3f3d56 0% 0% no-repeat;
    box-shadow: 0px 2px 10px #00000029;
    opacity: 0.8;
    letter-spacing: 0.5px
  }
  .grand-total-value {
    color: white;
    // margin-left: 2rem;
    font-size: 1.5rem;
  }
  .grand-total-div {
    margin-left: auto;
  }

  /**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
// .StripeElement {
//   box-sizing: border-box;

//   height: 40px;

//   padding: 10px 12px;

//   border: 1px solid transparent;
//   border-radius: 4px;
//   background-color: white;

//   box-shadow: 0 1px 3px 0 #e6ebf1;
//   -webkit-transition: box-shadow 150ms ease;
//   transition: box-shadow 150ms ease;
//   // width: 20%;
//   font-size: 10px;
// }

// .StripeElement--focus {
//   box-shadow: 0 1px 3px 0 #cfd7df;
// }

// .StripeElement--invalid {
//   border-color: #fa755a;
// }

// .StripeElement--webkit-autofill {
//   background-color: #fefde5 !important;
// }

// .ElementsApp, .ElementsApp .InputElement {
//   color: #32325d;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
//   font-size: 4px;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }