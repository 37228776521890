.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.fruit-preferences-next-btn {
  margin-bottom: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.2em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 75%;
}
.skip {
  color: white;
  margin-right: 10%;
  font-family: "poppins", sans-serif !important;
  font-style: italic;
  font-size: 1.25em;
}

.taste-preference-text {
  float: left;
  font-size: 2em;
  color: #e8e8e8;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}
.header-text-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*  default css className for Bootstrap Card */
.card {
  border-radius: 10px !important;
  margin: 1em;
  padding: 1em;
  box-shadow: 1px #1d1d1d1a !important;
  border: none;
}

.next-btn {
  /* margin-bottom: 5em; */
  font-family: "poppins", sans-serif !important;
  font-weight: bold !important;
  font-size: 2em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  border-radius: 10%;
  width: 90% !important;
}

.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center !important;
  flex-wrap: wrap;
  position: relative;
  overflow: auto;
  overflow-x: hidden !important;
}

.chat-btn-container {
  bottom: 0;
  width: 100%;
  position: fixed;
  margin-bottom: 20%;
}

.chat-icon {
  float: right;
  margin-right: 10%;
  border-radius: 50%;
  margin-bottom: 2%;
  background-color: white;
}

/* Empty Page Component CSS */
.empty-page-main-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 35%;
}
::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(238, 238, 238);
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.desktop-div-scroll {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* 
  #NOTE: .form-errors-display CSS Class is used in Forgot Password, Reset Password, Signin ,
          signup screens for handling error messages
*/
@media (min-width: 1025px) {
  .form-errors-display {
    text-align: center;
    font-size: 1.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
  .success-inner-container {
    box-shadow: 0px 3px 200px -15px #989898;
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    background-color: whitesmoke;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 25px;
    width: 430px !important;
    /* height: -moz-fit-content !important; */
    /* height: auto !important; */
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .success-footer-container {
    width: 430px !important;
    background: none !important;
  }
}
@media only screen and (min-width: 1000px) and (max-height: 1400px) {
  .form-errors-display {
    text-align: center;
    font-size: 1.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .form-errors-display {
    text-align: center;
    font-size: 1.75em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) /* and (max-height: 1024px) */ {
  .form-errors-display {
    text-align: center;
    font-size: 1.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
}

.form-errors-display {
  text-align: center;
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .form-errors-display {
    text-align: center;
    font-size: 1em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red !important;
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ContentFadeIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes ImageFadeIn {
  0% {
    transform: translate(-0.5rem, -0.5rem) scale(1.05);
    opacity: 0;
    filter: blur(2px);
  }
  50% {
    opacity: 1;
    filter: blur(2px);
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0) translateX(-100%);
  }
  /* 90% {
		transform: scale(1.05);
	} */
  100% {
    opacity: 1;
    transform: scale(1) translateX(0);
  }
}

@keyframes init {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    opacity: 1;
  }
}

@keyframes puff {
  0% {
    top: 100%;
    height: 0px;
    padding: 0px;
  }
  100% {
    top: 50%;
    height: 100%;
    padding: 0px 100%;
  }
}

@keyframes borderRadius {
  0% {
    border-radius: 100%;
  }
  100% {
    border-radius: 0px;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes right {
  0%,
  100% {
    transform-origin: 17% 100%;
  }
  50% {
    transform: rotate(-12deg) skewX(12deg);
  }
}

@keyframes left {
  0%,
  100% {
    transform-origin: 83% 100%;
  }
  50% {
    transform: rotate(12deg) skewX(-12deg);
  }
}

@keyframes left-end {
  0%,
  100% {
    transform-origin: 83% 100%;
  }
  40% {
    transform: rotate(6deg) skewX(-4deg) scaleX(0.92);
  }
  70% {
    transform: rotate(-1deg) skewX(8deg) scaleX(1.04);
  }
}

@keyframes move-to-left {
  40% {
    transform: translateX(-3px) rotate(var(--r));
  }
}

@keyframes active-4 {
  40% {
    transform: scale(1.25);
  }
}

@keyframes active-span {
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.16);
    opacity: 0;
  }
}

@keyframes active-5 {
  15% {
    transform: rotate(180deg) scale(1.1);
  }
  30% {
    transform: rotate(360deg) scale(1.2);
  }
  70% {
    transform: rotate(360deg) translateY(14%) scaleY(0.72);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes double-right {
  60% {
    filter: drop-shadow(-5px 0 1px var(--active-pale))
      drop-shadow(4px 0 1px var(--active-pale));
  }
}

@keyframes scale {
  60% {
    transform: scaleX(1.32);
    filter: blur(0.5px);
  }
}

@keyframes blink {
  0%,
  5%,
  15%,
  100% {
    transform: scaleY(1);
  }
  10% {
    transform: scaleY(0.4);
  }
}

@keyframes flip-scale-down-diag-2 {
  0% {
    transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    transform: scale(0.4) rotate3d(-1, 1, 0, -90deg);
  }
  100% {
    transform: scale(1) rotate3d(-1, 1, 0, -180deg);
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.error-boundary-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
}
.error-boundary-title {
  font-size: 1.75em;
  color: #a84551;
  font-family: "poppins", sans-serif !important;
  text-align: center;
}
.error-boundary-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-boundary-image {
  width: 80%;
}

.page-main-container {
  width: 100%;
  height: 100vh;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  display: inline-table;
}
.font-family {
  font-family: "poppins", sans-serif !important;
}
.font-color {
  color: #a84551;
}
.ft-wt-600 {
  font-weight: 600;
}
.ft-s-m {
  font-size: medium;
}
.common-page-header {
  width: 100%;
  /* align-content: center !important; */
  background-color: #193c38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4%;
  padding-bottom: 4%;
  min-height: 60px;

  /* position: absolute; */
}
.input:focus {
  background-color: white;
}
.common-page-sub-header {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
  align-items: center;
}
.common-sub-header-back-icon {
  width: 3%;
  margin-left: 1.5rem !important;
}
.common-sub-header-text {
  font-size: 1.25em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 300 !important;
  margin-left: 5%;
}
.common-sub-header-logo {
  width: 10%;
  margin-right: 3%;
  margin-left: auto;
}
.page-container-bg {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.theme-color {
  color: #a84551;
}
.ft-color-light-grey {
  color: #727272;
}
.ft-size-1em {
  font-size: 1em !important;
}
.mobile-compatible-msg {
  /* box-shadow: 0px 0px 15px 15px #d2d2d2; */
  /* position: relative; */
  /* margin-top: 5% !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  /* width: 30% !important; */
  height: 100vh !important;
  /* border: 2px solid #C0C0C0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pair-err-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pair-err-logo {
  width: 38%;
  height: auto;
}
.pair-mob-compatable-span-msg {
  font-size: 1.25em;
  color: #a84551;
}
.ptr-clss {
  cursor: pointer;
}
.desktop-error-container-margin {
  margin-top: 10% !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ft-weight-normal {
  font-weight: normal !important;
}
.cursor-none {
  cursor: none;
}
.cursor-text {
  cursor: text;
}

.txt-transform-uppercase {
  text-transform: uppercase;
}
.ft-w-normal {
  font-weight: normal !important;
}
.theme-color {
  color: #a84551;
}
.bl-none {
  border-left-style: none;
}
.bl-none {
  border-left-style: none;
}
