.popup-label {
    font-family: "poppins", SemiBold 14px/21px sans-serif;
    font-size: 1.5rem;
    color: white;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: 1.9rem;
  }
  
  :root {
    --borderWidth: 3px;
    --height: 21px;
    --width: 10px;
    --borderColor: white;
  }
  
  .check {
    display: inline-block;
    transform: rotate(45deg);
    height: var(--height);
    width: var(--width);
    border-bottom: var(--borderWidth) solid var(--borderColor);
    border-right: var(--borderWidth) solid var(--borderColor);
  }
  
  .popup-text{
    font-family: "poppins", sans-serif !important;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0px;
    color:#a84551;
    opacity: 1;
    margin-top: 15px;
    margin-bottom: 15px;
  }