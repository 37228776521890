@use "src/app/styles/theme" as s;

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.winePanel {
  position: relative;
  width: 100%;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: s.$gray-light;
}

.wineImg {
  object-fit: contain;
  width: 100%;
  height: 90%;
}

.backBtn {
  all: unset;
}

.backBtn:hover {
  cursor: pointer;
}

.detailsPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.titleBox {
  margin: 2.4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
  width: 92%;
}

.title {
  font-family: s.$font-2;
  font-weight: 800;
  font-size: 30px;
  color: #d0011b;
  text-align: center;
}

.wineDescription {
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 400;
  color: s.$gray;
  text-align: center;
}

.pairsWithBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  width: 92%;
}

.pairsWith {
  font-family: s.$font-2;
  font-weight: 800;
  font-size: 22px;
  color: #d0011b;
  text-align: center;
}

.foodCardRow {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2.4rem 4.8rem;
  flex-wrap: wrap;
}

.foodCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48%;
  max-width: 130px;
  gap: 1.2rem;
}

.foodImg {
  float: left;
  object-fit: cover;
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.foodNameTxt {
  font-family: s.$font-2;
  font-weight: 700;
  font-size: 16px;
  color: s.$gray-dark;
  text-align: center;
}

.pairingTypeTxt {
  font-family: s.$font-1;
  font-weight: 400;
  font-size: 12px;
  color: s.$gray;
  text-align: center;
}

.shareBtn {
  all: unset;
  margin-top: 2.4rem;
  display: flex;
  padding: 16px 48px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-bottom: 4.8rem;

  border-radius: 100px;
  background: #ac0419;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
}

.shareBtnTxt {
  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-weight: 600;
}

.phoneFormBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 2.4rem 4.8rem;
  margin-bottom: 2.4rem;

  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.25);
}

.filler {
  margin-bottom: 2.4rem;
}

.phoneFormInnerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: 80%;
  //padding: 6vh 0;
}

.phoneTxt {
  margin-top: 8px;
  font-family: s.$font-1;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: s.$gray;
}

.formContainer {
  margin-top: 1.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
}

.formField {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.formFieldError {
  color: red;
}

.formInput {
  width: 100%;
  padding: 1.6rem 2.4rem;
  border-radius: 1.4vw;
  //border: 1px solid rgba(125, 125, 125, 0.5);
  background: s.$gray-light;

  font-size: 16px;
  color: s.$gray-dark;
  text-align: center;
}

.submitBtn {
  all: unset;
  display: flex;
  padding: 1.6rem 4.8rem;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  border-radius: 100px;
  background: #ac0419;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
}

.submitBtn:hover {
  cursor: pointer;
}

.submitBtnTxt {
  font-family: s.$font-1;
  font-weight: 600;
  font-size: 16px;
  color: white;
}

.submittedBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
}

.grayedOut {
  background: s.$gray;
}

.modalBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.modalBox {
  position: relative;
  max-width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2.4rem 1.2rem 4.8rem 1.2rem;
  background-color: white;
  border-radius: 8px;
}

.modalContent {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 2vw;
}

.qrBox {
  width: 60%;
  padding: 2.4rem;
  border: 1px solid rgba(125, 125, 125, 0.25);
  border-radius: 1vw;
}

.closeBtn {
  width: 12px;
  height: 12px;
  right: 2.4rem;
  top: 2.4rem;
  position: absolute;
  cursor: pointer;
}

.backBtn {
  width: 42px;
  height: 42px;
  left: 2.4rem;
  top: 2.4rem;
  position: absolute;
  cursor: pointer;
}

.sendIcon {
  width: 12px;
  height: 12px;
}

@media (min-width: 600px) {
  .main {
    height: 100vh;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .winePanel {
    position: relative;
    width: 34%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: s.$gray-light;
  }

  .wineImg {
    min-height: 90%;
  }

  .detailsPanel {
    width: 66%;
  }

  .titleBox {
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    width: 80%;
  }

  .title {
    font-size: 3.2vw;
  }

  .wineDescription {
    font-size: 1.6vw;
  }

  .pairsWithBox {
    margin-top: 2vh;
    gap: 2.4vh;
    width: 80%;
  }

  .pairsWith {
    font-size: 2vw;
  }

  .foodCardRow {
    justify-content: space-between;
    gap: 2vw;
    flex-wrap: nowrap;
    width: 100%;
  }

  .foodCard {
    width: 100%;
    max-width: none;
    gap: 1.6vh;
  }

  .foodImg {
    width: 12vw;
    height: 12vw;
  }

  .foodNameTxt {
    font-size: 1.4vw;
  }

  .pairingTypeTxt {
    font-size: 1.4vw;
  }

  .shareBtn {
    margin-top: 6vh;
    padding: 1.4vw 6vw;
    gap: 1vw;
    margin-bottom: 0;
  }

  .shareBtnTxt {
    font-size: 1.8vw;
  }

  .phoneFormBox {
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    padding: 0;
    margin-bottom: 0;

    border-radius: 1.4vw;
    border: 1px solid rgba(125, 125, 125, 0.25);
  }

  .phoneFormInnerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 6vh 0;
  }

  .phoneTxt {
    margin-top: 2.4vh;
    font-family: s.$font-1;
    font-weight: 400;
    font-size: 1.6vw;
    color: s.$gray;
  }

  .formContainer {
    padding-top: 4vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4vw;
  }

  .formInput {
    width: 100%;
    padding: 2vw 2vw;
    border-radius: 1.4vw;
    //border: 1px solid rgba(125, 125, 125, 0.5);
    background: s.$gray-light;

    font-size: 2vw;
    color: s.$gray-dark;
    text-align: center;
  }

  .submitBtn {
    width: 100%;
    padding: 1.4vw 0;
    gap: 1vw;
  }

  .submitBtnTxt {
    font-size: 2vw;
  }

  .submittedBox {
    gap: 6vh;
  }

  .modalBackground {
    padding: 2rem 2.4rem 2rem 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    height: auto;
    overflow: auto;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;
  }

  .modalBox {
    position: relative;
    max-width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 4vw 6vw;
    background-color: white;
    border-radius: 8px;
  }

  .modalContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2vw;
  }

  .qrBox {
    max-width: 40%;
    padding: 4vw;
    border: 1px solid rgba(125, 125, 125, 0.25);
    border-radius: 1vw;
  }

  .closeBtn {
    width: 2vw;
    height: 2vw;
    right: 3.2vw;
    top: 3.2vw;
  }

  .backBtn {
    width: 4vw;
    height: 4vw;
    left: 4vw;
    top: 2vw;
  }

  .sendIcon {
    width: 1.4vw;
    height: 1.4vw;
  }
}
