.nav-link-top-nav:focus {
    outline: none;
}

// .chat-count {

//   position: absolute;
//   margin-left: 5%;
//   margin-top: 2%;
//   top: 0%;
//   color: red;
//   font-size: 1.25em;
// }
.top-nav-main {
    background: #0c0524 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 16px #00000029;
    opacity: 1;
    height: 80px;
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-link-top-nav {
    display: flex;
    flex-direction: row;
}

// .nav-link - border radius
@media (min-width: 1025px) {
    .nav-link {
        margin-right: 2% !important;
    }
}

.nav-tabs .nav-link {
    border: unset !important;
    // animation: 1s ease-out 0s 1 MoveUpDown;
}

.nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nav-logo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1%;
    margin-right: 1%;
    cursor: pointer;
}

.top-nav-title-selected {
    margin-left: 7px;
    font-size: 16px;
    font-family: "poppins", sans-serif !important;
    border-bottom: 3px solid #ffffff;
}

.top-nav-title-not-selected {
    margin-left: 7px;
    font-size: 16px;
    font-family: "poppins", sans-serif !important;
    color: #e4e4e4b3;
}

.pair-logo-top-nav {
    height: 60px;
    width: auto;
}

.icon-style {
    width: 18px;
    height: 18px;
}

.top-nav-icon-disabled {
    opacity: 0.5;
}

.winery-details-top-nav {
    height: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    margin-right: 2%;
}

.winery-logo-top-nav {
    height: 60px;
    margin-left: 12px;
    width: 60px;
    // border-radius: 50%;
}

.winery-logo-container-top-nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.winery-name-container-top-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
}

.more-winery-details-top-nav-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 16px #00000029;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    position: absolute;
    z-index: 2;
    right: 0;
    margin-right: 5%;
    flex-direction: row;
    width: 30%;
}

.winery-logo-container-top-nav-popup {
    height: 60px;
    margin-top: 10px;
    width: auto;
    border-radius: 50%;
}

.winery-text-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.winery-name-popup {
    margin-top: 10px;
    margin-bottom: 10px;
    font: Medium 14px/21px var(--unnamed-font-family-poppins);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-2b2b2b);
    text-align: left;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
    font-weight: bold;
}

.winery-message-popup {
    margin-bottom: 15px;
}

.popup-icons-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.more-winery-details-top-nav {
    display: flex;
    flex-direction: row;
}

.chat-logo-popup {
    padding: 5px;
}

.chat-logo-popup-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 50%;
    margin-right: 15px;
}

.switch-winery-logo-popup {
    padding: 5px;
}

.switch-winery-logo-popup {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border-radius: 50%;
}
.hamburger-menu-top-nav {
    padding-left: 2%;
}