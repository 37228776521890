.wine-rating-title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;

  img{
    height: 100%;
    margin-right: 25px;
  }

  p{
    font-size: 20px;
    margin: 0;
    font-family: "poppins", sans-serif !important;
  }
}

.wine-rating-title-text{
  font-size: 28px;
  font-weight: 600;
  font-family: "poppins", sans-serif !important;
}

.wine-rating-review-title{
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 0 10px;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.wine-rating-review-text{
  width: 100%;
  border-radius: 5px;
  border: 1px solid black;
  height: 130px;
  padding: 10px;
  font-family: "poppins", sans-serif !important;
  font-size: 16px;
}

.wine-rating-submit-button{
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #cd4545;
  margin-top: 10px;
  margin-bottom: 20px;
  color: white;
  font-size: 22px;
  font-family: "poppins", sans-serif !important;

  &:hover{
    cursor: pointer;
  }
}

.wine-rating-star{
  font-size: 1.8em !important;
}

.wine-rating-error-text{
  font-family: "poppins", sans-serif !important;
  color: #cd4545;
}

.wine-review-thank-you-container{
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    height: 120px;
    margin-bottom: 40px;
  }

  p{
    font-family: "poppins", sans-serif !important;
    font-size: 20px;
  }
}