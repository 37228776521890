.events-main-container-grey {
  width: 100%;
  background: #f2f2f2;
}

.events-main-container-white {
  width: 100%;
  height: 100vh;
  background: white;
}

@media (min-width: 1025px) {
  .events-main-container,
  .taste-container,
  .bottom-navigation {
    overflow: auto;
  }
  .events-main-container-grey {
    background: none !important;
  }
  .available-events-container::after {
    content: "";
    background: #f2f2f2;
    height: 50px;
  }

  .available-events-container {
    margin-bottom: 6% !important;
    flex-direction: row !important;
    flex-wrap: wrap;
  }
}

.recommendation-history-main-container-events-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-left: 5%;
  border-left: 1pt solid white;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.available-events-container {
  width: 100%;
  position: relative;
  margin-bottom: 15.5%;
  margin-left: 0% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-state-image {
  width: 70%;
  margin: auto;
  display: block;
}
.empty-state-main-container {
  height: 70%;
}
.empty-state-master-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty-state-text {
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 23px;
  letter-spacing: 0px;
  color:#a84551;
  opacity: 1;
}
