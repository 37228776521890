@use "src/app/styles/theme" as s;

.mainContainer {
  margin: 4rem 0;
  padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  margin-bottom: 2rem;
}

.sectionTitleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px;
}

.sectionsBox {
  border-top: 1px solid s.$gray-light;
}

.section {
  border-bottom: 1px solid s.$gray-light;
}

.borderBottom {
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
  border-radius: 8px 8px 0 0;
}

.animationWrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  //opacity: 0;
  width: 100%;
  margin-bottom: 0;
  transition: grid-template-rows 0.3s, opacity 0.3s, margin-bottom 0.3s;
}

.animationExpandable {
  min-height: 0;
}

.expanded {
  grid-template-rows: 1fr;
  //opacity: 1;
  //margin-bottom: 40px;
}

.spacing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.link {
  color: s.$red;
}