.modal-style {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-bg {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
}
.white-bg {
  background-color: #ffffff;
}
.headertext-style {
  display: flex;
  margin: 1em;
  font-size: 17px;
  color: #000000;
}
.new-thin-line-div {
  height: auto !important;
}
.new-cancel-css {
  // width: unset !important;
  // margin-left: auto !important;
  margin-right: 7%;
}
.new-yes-css {
  // width: unset !important;
  // margin-right: 7% !important;
}

.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_circle {
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: green;
  border-radius: 11px;
  left: 0;
  top: 0;
}

.checkmark_stem {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #fff;
  left: 11px;
  top: 6px;
}

.checkmark_kick {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #fff;
  left: 8px;
  top: 12px;
}
.new-cnacel-btn-css {
  margin-left: 0 !important;
}
.new-proceed-btn-css {
  padding: 6px 27px !important;
}
.new-yes-btn-css {
  padding: 6px 20px !important;
}
.new-footer-pop-btn-css {
  margin-bottom: 28px;
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1025px) {
  .modal-bg {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px !important;
  }
}
