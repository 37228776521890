@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800;900&display=swap');


.closeBtn {
    position: absolute;
    top: 5%;
    right: 5%;
    background: #efefef;
    border: none;
    border-radius: 50%;
    color: #7d7d7d;
    height: 35px;
    min-height: 35px;
    width: 35px;
    font-size: 16px;
    font-style: bold;
    font-weight: 700;
    text-align: center;
    outline: none;
}

.your-matches {
  margin-top: 10%;
}

.closeBtn:active, .closeBtn:focus {
    border-style: outset;
    outline: none;
}

.closeBtn:hover {
    cursor: pointer;
}

@media (min-width: 1025px) {
  .taste-quiz-container {
    height: 90vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.rec-container, .success-main-container, .closeBtn {
    font-family: 'Montserrat', sans-serif;
}

.flavors {
    color: #343434;
    font-weight: bold;
}

.rec-container{
    height: 300px;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 400px;
    margin: auto;
}

.wineRec {
    position: relative;
    //left: -25px;
    width: 50%;
}

.wineImage {
    position: relative;
    overflow: hidden;
    //left: -30px;
    //top: -50px;
    width: 70px;
    height: 300px;
    transform: rotate(-0.015turn);
    margin: 5%;
    object-fit: cover;
}

.wineImage1 {
    position: relative;
    overflow: hidden;
    // left: -30px;
    // top: -50px;
    width: 70px;
    height: 300px;
    transform: rotate(0.015turn);
    margin: 5%;
    object-fit: cover;
}

.test-result-small-highlight{
    margin-top: 10%;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    position: relative;
    margin-left: 25px;
    color: grey;
}
.test-result-small-highlight1{
    font-size: 11px;
    font-weight: 500;
    color:  #343434;
    display: inline;
}
.taste-pics{
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.taste-pics-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 200px;

}
.taste-notes{
    margin-top: 5px;
    font-size: 8px;
    display: flex;
    
}
.segmented-list{
    width: 35px;
    margin-left: 4px;
    background-color: lightgray;
    display: block;
    height: 5px;
    
}
.elem-flag{
    background-color: #951901 !important; 
}
.container{
    width: 240px;
    display: inline-flex;
}

.resultTitle {
    font-family: 'Montserrat', sans-serif;
    margin-left: 25px;
    width: 300px;
    font-size: 30px;
    font-weight: 800;
    line-height: 37px;
}

.drumRoll {
    position: relative;
    top: -7px;
}

.container-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.scaleBold {
    color: #951901;
    font-weight: 700;
}
.scaleNormal {
    margin-left: 120px;
}
