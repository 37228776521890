.select-winery-main-container {
  width: 100%;
  height: 100vh;
}

.select-winery-pair-anything-logo {
  width: 30%;
  margin-top: 4%;
}

.select-winery-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.select-winery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .select-winery-pair-anything-logo {
    width: 20% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }

  .select-winery-header {
    height: fit-content !important;
  }

  .select-winery-title-container {
    margin-top: 3% !important;
    margin-bottom: 2% !important;
  }
}

@media (min-width: 1025px) {
  .select-winery-main-container,
  .select-winery-container {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .select-winery-title-container {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }

  .select-winery-pair-anything-logo {
    width: 12% !important;
    margin-top: 1% !important;
    margin-bottom: 1% !important;
  }

  .select-winery-main-container {
    overflow: auto;
  }
  .select-winery-header {
    width: 100% !important;
    height: fit-content !important;
    background-color: #3f3d56 !important;
    align-content: center !important;
  }
}

.select-winery-header {
  width: 100%;
  height: 16%;
  background-color: #3f3d56;
  align-content: center !important;
  // z-index: -1!important;
}

.select-winery-title {
  font-size: 1.5em;
  color: #000;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.select-winery-container {
  // margin-top: -9vh;
  width: 100%;
  position: absolute;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
  // margin-bottom: 20%;
}

.select-winery-container:after {
  content: "";
  display: block;
  height: 130px;
  width: 100%;
}

.select-winery-subheader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-winery-container {
  animation: 1s ease-out 0s 1 slideInFromLeft;
  // animation: fadeInScale .8s 1.5s;
}

.error-data-from-selected-winery-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: absolute;
  margin-bottom: 5%;
}

.error-data-from-selected-winery {
  color: #a84551;
  text-align: center;
  font-size: 1.5em;
  font-family: "poppins", sans-serif !important;
}

.horizontal-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  // max-height: 400px;
  margin-right: 1em;
}
.display-horizontal {
  flex: 0 0 auto;
}
.valid-text {
  color: #959292;
  font-size: 12px;
}
.promo-title {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
}

.discover-container {
  // margin-top: -9vh;
  width: 100%;
  position: absolute;
  overflow: auto;
  justify-content: center;
  align-items: center;
  margin-left: 0% !important;
  // margin-bottom: 20%;
}

.discover-container {
  animation: 1s ease-out 0s 1 slideInFromLeft;
  // animation: fadeInScale .8s 1.5s;
}
