@use "src/app/styles/theme" as s;

.flavorList {
  width: 100%;
  margin-bottom: 6%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 6%;
}

.flavorItem {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 0;
  max-width: 60px;
  font-family: s.$font-1;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}

.flavorItemImg {
  object-fit: cover;
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 20%;
}

.varietalCard {
  width: 100%;
  border-radius: 8px;
  border: 1px solid s.$gray-light;
  padding: 5% 5% 5% 5%;
}

.valueContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  flex-shrink: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.valueRight {
  font-family: s.$font-1;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: s.$gray;
  margin: 0;
  text-align: left;
  width: 20%;
}

.valueLeft {
  font-family: s.$font-1;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: s.$gray;
  margin: 0;
  text-align: right;
  width: 20%;
}

.valueBold {
  font-weight: 700;
  color: s.$gray-dark;
}

.valuedBar {
  background: s.$gray-dark;
  height: 3px;
  width: 18%;
  border-radius: 100px;
}

.notValuedBar {
  background: s.$gray-light;
  height: 3px;
  width: 18%;
  border-radius: 100px;
}