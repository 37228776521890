@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap');
  
  .close-btn {
    margin-top: 8px;
    background: #efefef;
    border: none;
    border-radius: 50%;
    color: #7d7d7d;
    min-height: 35px;
    font-size: 16px;
    font-style: bold;
    font-weight: 700;
    text-align: center;
    outline: none;
    height: 35px;
    width: 35px;
    position: absolute;
    top: 5%;
    right: 5%;
  }
  .close-btn:active,
  .close-btn:focus {
    border-style: outset;
    outline: none;
  }

  .close-btn:hover {
    cursor: pointer;
  }


  .transition {
    animation: .6s slide-in;
  }

  @keyframes slide-in {
    0% {
      transform: translate(50%, 0%);
    }
    100% {
      transition: transform 1000ms ease-in-out;
    }
  }

  .tastePreferenceCard {
    display: block;
    margin-top: 46px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    width: 458px;
  }

  .mainCard {
    height: 90vh !important;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f5f5f5;
    box-shadow: 0px 3px 200px -15px #989898;
    border-radius: 25px !important;
  }



  .main {
    position: relative;
    top: -35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .image1{
    height: auto;
    width: auto;
  }

  .card-style {
    animation: fadeIn 0.2s forwards;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
    width: 45%;
    border-radius: 5px !important;
    cursor: pointer;
  }

  .fade_Out {
    animation: fadeOut 0.2s forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 0.5;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }

  .card-text {
    display: flex;
    align-items: center;
    height: 22px;
    margin-left: 5px;
    vertical-align: middle;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
  }

  .card-image {
    width: 100%;
  }
  
  .titleContainer {
    margin-top: 10%;
    display: flex;
    white-space: nowrap;
    margin-left: 10px;
  }
  
  .questionNumber {
    font-weight: 700;
    font-size: 11px;
    line-height: 10px;
    text-transform: uppercase;
    color: #951901;
  }
  
  .title {
    margin-left: 32px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 10px;
    color: #7d7d7d;
  }

  .progress-bar {
    background-color: #951901;
  }

  .bar {
    width: 150px;
    margin-top: 5px;
    margin-left: 10px;
    background-color: #efefef;
    height: 0.5rem;
    min-height: 0.5rem;
    border-radius: 0px; 
  }
  
  .AppTitle {
    margin: 20px 0 10px 0;
    position: relative;
    top: -20px;
    width: 350px;
    margin-left: 10px;
    font-size: 31px;
    font-weight: 800;
    line-height: 33px;
    text-align: left;
    padding: none;
  }
  
  .selectTitle {
    position: relative;
    top: -30px;
    font-size: 14px;
    font-weight: 500;
    color: #951901;
    margin: 20px 0 10px 10px;
  }
  
  .additionalOption {
    display: none;
    position: relative;
    top: -25px;
    margin-left: 10px;
    margin-bottom: 0px;
    cursor: pointer;
    text-decoration: underline;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    color: #951901;
  }

  .continueBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    height: 52px;
    cursor: pointer;
    background: #951901;
    border-radius: 5px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  @media (max-width: 1025px) {
  .tastePreferenceCard {
    content: unset !important;
    display: unset !important;
    height: unset !important;
    width: unset !important;
  }
  .mainContainer {
    margin-top: 20px;
  }

  .mainCard {
    height: 100% !important;
    margin-top: 40px !important;
    background-color: white;
    box-shadow: none;
    border-radius: none !important;
  }

  .titleContainer, .bar, .AppTitle, .selectTitle {
    margin-left: 8%;
  }

  .card-style {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: 30%;
  }
  button {
    //margin-left: 90%;
  }

}
@media (max-width: 550px) {
  .mainContainer {
    margin-top: 10px;
  }

  .mainCard {
    margin-top: 20px !important;
  }

  .AppTitle {
    font-size: 28px;
    line-height: 30px;
    width: 290px;
    margin: 20px 0 10px 0;
  }

  .titleContainer, .bar, .AppTitle, .selectTitle {
    margin-left: 5%;
  }

  .card-style {
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
    width: 40%;
  }

  .card-text {
    font-size: 10px !important;
  }

  .additionalOption {
    top: -30px;
    font-size: 10px;
  }

  .continueBtn {
    width: 70%;
    height: 42px;
    font-size: 12px;
  }
}


  @media (max-width: 385px) {
    .mainCard {
      margin-top: 5px !important;
    }

    .AppTitle {
      font-size: 25px;
      line-height: 25px;
      width: 280px;
      margin: 20px 0 10px 0;
    }

    .card-style {
      margin-left: 10px;
      margin-right: 10px;
      width: 40%;
    }

    .card-text {
      font-size: 8px !important;
    }
  }
  @media (max-width: 320px) {
    .close-btn {
      margin-left: 85%;
    }

    .AppTitle {
      width: 230px;
      font-size: 20px;
      margin: 20px 0 10px 0;
    }
  }

