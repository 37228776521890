@use "src/app/styles/theme" as s;

.fromRight {
  //transform: translateX(0);
  animation: fromRight 0.5s;
}

.fromLeft {
  //transform: translateX(0);
  animation: fromLeft 0.5s;
}

@keyframes fromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.pairingImgBox {
  height: 180px;
}

.foodImg {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
}

.varietalImg {
  height: 160px;
  width: 40px;
  transform: rotate(8deg) translate(-20px);
  object-fit: cover;
}

.buyBtn {
  background-color: s.$gray-dark;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  //padding: 12px 10px;
  width: 100%;
  border-radius: 8px;
  border: none;
}

.mainContainer {
  margin-top: 2rem;
  //padding: 0 2.4rem 0 2.4rem;
  width: 100%;
}

.mainContainerSection {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.bg {
  aspect-ratio: 1/1;
  width: 160px;
  background-color: s.$gray-light;
  border-radius: 8px;
}

.wineImg {
  height: 80%;
}

.wineName {
  max-width: 160px;
  margin-bottom: 0;
}

.smallBtn {
  padding: 10px 8px;
  border-radius: 4px;
}

.price {
  color: s.$red;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.getAtTarget {
  color: s.$gray-dark;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 800;
}

.wineGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.getBox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.getWinesSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 8px 0;
}

.rightArrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.targetLogo {
  width: 16px;
  height: 16px;
}

.seeAll {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.seeAllBtn {
  background: none;
  border: none;
  display: flex;
  gap: 8px;
  align-items: baseline;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.seeAllBtnTxt {
  color: s.$red;
}

.link {
  color: s.$red;
}

.link:hover {
  color: black;
  text-decoration: none;
}

.wineCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.wineCardTextBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
