.active-order-heading {
    padding: 4%;
    display: flex;
    justify-content: center;
}
.order-history-heading {
    padding: 2% 4% 4% 4%;
    display: flex;
    justify-content: center;
}
.order-history-container:after {
    content: "";
    display: block;
    height: 100px;
    width: 100%;
}
