.verticalScroll {
  height: 100%;
  overflow-y: auto;
}

.verticalScroll::-webkit-scrollbar {
  display: none;
}

.pageBottom {
  padding-bottom: 6rem;
}