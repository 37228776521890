@use "src/app/styles/theme" as s;

.mainContainer {
  padding: s.$mainPad;
  width: 100%;
}

.booton {
  padding: 20px;
  background-color: rgb(56, 238, 238);
}