@use "src/app/styles/theme" as s;

.PAHeader {
  width: 100%;
}

.container {
  position: relative;
  padding: 3rem 2.4rem 2rem 2.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: white padding-box;
  z-index: 9;
  border-radius: 0px 0px 8px 8px;
}

.containerActive {
  background: white padding-box;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

.logoIconContainer {
  //background-color: white;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  cursor: pointer;
  width: 148px;
  height: auto;
  object-fit: contain;
}

.logoDisabled {
  width: 148px;
  height: auto;
  object-fit: contain;
}

.clickHover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.menuIcon {
  cursor: pointer;
}

.menuContent {
  z-index: 9;
  padding-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  //position: absolute;
}

.optionsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.option {
  display: flex;
  align-items: center;
  gap: 12px;
}

.option {
  cursor: pointer;
}

.modalBackground {
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
}

.modalContent {
  z-index: 9;
  width: 100%;
}

.signupBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.loginTxt {
  color: s.$red;
  text-decoration: underline;
}

.loginTxt:hover {
  cursor: pointer;
  text-decoration: none;
}

.signupBtn {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: s.$gray-dark;
  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.logoutBtn {
  border: none;
  background: none;
}

.logoutBtnTxt {
  color: s.$red;
}

.logoutBtnTxt:hover {
  text-decoration: underline;
}

.secondaryList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
