@media (min-width: 1025px)  {
    .preference-screen-card-main-container{
        border-radius: 25px; 
        width: 100% !important;
    }

    .intro-screen-explainer-text {
        margin-top: 3% !important;
    }

    .intro-exit-preference-screen-card-container{
        margin-top: 2% !important;
    }
    .intro-screen-text-bottom{
        margin-bottom: 10% !important;
        margin-top: 10% !important;
    }

    .fruit-preferences-next-btn{
        margin-bottom: 0% !important;
        
    }
    .exit-btn-container {
        position: 'absolute';
        bottom:0;
        justify-content: center;
        width: 100%;
        display: flex;
        margin-top: 0em !important;
    }

    .later-text{
        margin-top: 40px;
    }

    .taste-preference{
        margin-bottom: 0% !important;
        font-family: "poppins", sans-serif !important;
        font-size: 1.2em !important;
        color: #ffffff !important;
        background-color:#a84551 !important;
        width: 45%;
    }

    .preference-screen-card-main-container{
        height: 90vh !important;
    }

    .exit-preference-screen-card-title{
        margin-top: 2% !important;
    }
}
.preference-screen-card-main-container {
    width: 100%;
    height: 85vh;
    // display: flex;
    // justify-content: center;
    // align-content: center!important;
    // flex-wrap: wrap;
    position: relative;
    // overflow: auto;
    // overflow-x: hidden!important;
    // border: 1px solid #d2d2d2;
}
.intro-exit-preference-screen-card-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    text-align: center;
}
.preference-screen-card-category-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // margin-top: 20%;
    text-align: center;
}
.preference-screen-card-sub-container {
    
    flex-wrap: wrap;
    display: flex;
    justify-content: center; 
    // margin-top: 20%;
}

.intro-screen-explainer-text {
    // text-transform: capitalize;
}

.intro-screen-explainer-text-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.intro-screen-explainer-text {
    margin-top: 10%;
    font-size: 1.5em;  
    // color:#a84551;
    font-family: 'poppins!important';
}
 

.later-text{
    color:#a84551;
    font-size: 1.2em !important;
    font-family: poppins !important;
}

.intro-screen-explainer-pair-anything-logo {
    width: 30%;
}
.gesture-logo {
    width: 75%;
}

.intro-screen-text-bottom {
    color: #2B2B2B;
    // font-family: 'trajan-sans-pro,sans-serif';
    font-family: 'poppins', sans-serif !important;
    font-size: 1.5em;
    margin-top: 15%;
}
.exit-screen-text-bottom {
    color: #2B2B2B;
    font-family: 'poppins', sans-serif !important;
    font-size: 1em;
    margin-top: 15%;
   
}
.exit-screen-text {
    color: #2B2B2B;
    // font-family: 'trajan-sans-pro,sans-serif';
    font-family: 'poppins', sans-serif !important;
    font-size: 1em;
    margin-top: 15%;
}

.intro-screen-pairanything-text {
    color:#a84551;
    // font-family: 'trajan-sans-pro,sans-serif';
    // font-family: 'poppins', sans-serif !important;
    // font-size: 1em;
}

.exit-screen-logo {
    width: 60%;
 }

.preference-screen-card-question {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.question-name {
    font-size: 2.5em;
    text-transform: capitalize; 
    // font-family: trajan-sans-pro,sans-serif
    font-family: 'poppins', sans-serif !important;
}

@font-face {
    font-family: 'Trajan Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Trajan Pro Regular'), url('TrajanPro-Regular.ttf') format('ttf');
}
.intro-exit-screen-content-text {
    // font-family: trajan-sans-pro,sans-serif;
    font-family: 'poppins', sans-serif !important;
    margin-top: 5%;
    font-size: 1.5em !important;
    color: black !important;
}
.intro-exit-preference-screen-card-title {

    //   font-family: trajan-sans-pro,sans-serif;    
      font-family: 'poppins', sans-serif !important;
      margin-top: 5%;
      font-size: 1.5em!important;
      color: black!important;
}
.exit-preference-screen-card-title {
    margin-top: 5%;
    width: 90%;
    font-size: 1.3em!important;
    color: black!important;
    font-family: 'poppins', sans-serif !important;

}

.preference-screen-card-title {
  font-family: 'poppins', sans-serif !important;
//   font-weight: 600!important;    
  margin-top: 5%;
  font-size: 1.5em!important;
  color: #918E8E!important;

}

.preference-screen-card-title::first-letter {
    text-transform: capitalize;
}

.selected-preference-screen-image {
    background-image:url('../../../assets/images/selected-heart.svg');
}

.preference-screen-image {
    background-image:url('../../../assets/images/not-selected-heart.svg');
}

.next-btn-container {
    position: 'absolute';
    bottom:0;
    justify-content: center;
    width: 100%;
    display: flex;
    margin-top: 4em;
}

.exit-btn-container {
    position: 'absolute';
    bottom:0;
    justify-content: center;
    width: 100%;
    display: flex;
    margin-top: 4em;
}

.slidecontainer {
    width: 80%;
}


// customizing slider icon

.swipe-card-slider::-webkit-slider-thumb {
    -webkit-appearance: none!important;
    appearance: none!important;
    width: 1.5em!important;
    height: 1.5em!important;
    border: 0!important;
    background-image: url('../../../assets/images/slider-heart.svg')!important;
    cursor: pointer;
  }

//   .swipe-card-slider input[type="range"]::-webkit-slider-thumb {
//     background-color: rgb(66, 139, 202)!important;
// }


.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 3.5em!important;
    height: 3.5em!important;
    border-radius: 0%!important;
    background: #d2d2dd!important;
    background: url('../../../assets/images/slider-heart.svg')!important;
    background-repeat: none!important;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
    background:#a84551;
    cursor: pointer;
}


.exit-page-logo {
    margin-top: -9%;
    margin-bottom: 5%;
    width: 40%;
}

.heart-intro-icon {
    margin-top: 5%!important;
    width: 15%;
}

@media only screen and (min-width: 375px)  // iPhone X
and (max-width: 375px)  and (min-height: 812px)  // iPhone X
and (max-height: 812px)  {
  .exit-screen-logo {
      margin-top: 15%!important;
      margin-bottom: 15%!important;
  }
}


  @media only screen and (min-width: 400px) 
  and (max-height: 900px)  {
    .exit-screen-logo {
        margin-top: 6%!important;
        margin-bottom: 6%!important;
    }
  }


  // iPhone 5/SE  
  @media only screen and (min-width: 320px) 
  and (max-height: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .next-btn-container {
        position: relative!important;
    }

    .exit-btn-container{
        position: relative!important;
    }

    .intro-screen-text-bottom , .exit-preference-screen-card-title {
        font-size: 1.25em!important;
    }
  
    .intro-exit-preference-screen-card-title {
          font-size: 1.25em!important;
          margin-top: 2%!important;
      }
      .intro-screen-explainer-text {
        margin-top: 5%!important;
      }

      .intro-exit-screen-content-text  {
          font-size: 1em!important;
      }
  
  }
  
  @media (min-width: 300px) and (max-width: 400px) {

    .intro-exit-preference-screen-card-title, .intro-exit-screen-content-text {
        font-size: 1.25em!important;
        margin-top: 2%!important;
    }
    .intro-screen-explainer-text {
      margin-top: 5%!important;
    }


  }

  @media screen and (min-width: 375px) and (max-width:375px) {

    .intro-exit-preference-screen-card-title, .intro-exit-screen-content-text {
        font-size: 1.25em!important;
        margin-top: 5%!important;
    }
    .intro-screen-explainer-text {
      margin-top: 5%!important;
    }
    .preference-screen-card-main-container{
        height: auto;
    }

  }


  .exit-screen-button {
    margin-bottom: 15%;
    font-family: 'poppins', sans-serif !important;
    font-size: 1.5em!important;
    color: #ffffff!important;
    background-color:#a84551!important;
    width: 90%;
  }
