.available-wine-name {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  margin-top: 10px;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
  //   max-width: 15ch;
}
.available-wine-name::first-letter {
  text-transform: capitalize;
}
.available-wine-subtype-subcontainer {
  display: flex;
  flex-direction: column;
  margin-right: 5%;
}
.hyper {

  text-decoration-line: underline; 
}

.hyper:hover {

  text-decoration-line: none; 
}

.available-wine-subtype-name {
  color: #2b2a2a !important;
  font-family: "poppins", sans-serif !important;
}

.available-wine-subtype-title {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.available-wine-subtype-title::first-letter {
  text-transform: capitalize;
}

.available-wine-details {
  width: 100%;
  // display: flex;
  // padding-top: 5%;
}

.available-wine-price-text {
  color: #a84551;
  font-weight: bold;
}

.cart-qty-style {
  position: relative;
  display: table;
  border-collapse: separate;
  border: 1px solid #ccc;
}

.available-wine-details-row {
  // width: 100%;
  // position: absolute;
  bottom: 0;
}

.available-wine-details-data-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.available-wine-bottle-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 50%;
  // min-height: 10vh;
  // background-color: #F1EEEE;

  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}
.available-wine-bottle-logo {
  // width: 49%;
  // object-fit: contain;
  // background-repeat: no-repeat;
  height: auto;
  max-height: 200px;
  width: auto;
  max-width: 200px;
}

.available-wine-bottle-logo-from-api {
  // width: 32%;
  // object-fit: contain;
  // background-repeat: no-repeat;
  height: auto;
  max-height: 220px;
  width: auto;
  max-width: 200px;
}
.available-wines-add-to-cart-width {
  width: 100%;
  padding: 0px 0px;
}
.available-wines-add-to-cart-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
  padding: 0.5rem;
}
.cart-increment-decrement-icon {
  // padding: 0px 11px;
}
.available-wine-subcontainer {
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  width: 100%;
}
.first-review-text {
  margin: 0;
  // z-index: 2;
  // font-size: 12px;
  &:hover {
    text-decoration: underline;
  }
}
.first-review-text-desktop {
  margin: 0;
  // z-index: 2;
  // font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
}
.available-wine-card {
  width: 100%;
  // min-height: 10vh;
  // border: solid;
}

.available-wine-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  height: 70%;
  width: 100%;
}
.add-to-cart-button {
  margin-top: 5%;
}
.box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  // width: 100%;
}
.wine-logo {
  flex-grow: 9;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.share {
  position: absolute;
  top: 0;
  right: 0;
}
.like {
  margin-right: 1em;
}

.wine-card-rating-start {
  font-size: 1.5em;
  color: #d2d2d2;
}

.wine-card-selected-rating-star {
  font-size: 1.5em;
  color: #f7d002;
}
.wine-text-div {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  width: 100%;
  justify-content: space-around;
}
.available-wine-details-list {
  width: 100%;
  height: 100%;
  display: flex;
}
@media (min-width: 1025px) {
  .available-wine-name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
    height: 70%;
    width: 100%;
  }
  .available-wine-card {
    width: 100% !important;
    // height: 60% !important;
  }
  .available-wine-bottle-logo {
    // width: 49%;
    // object-fit: contain;
    // background-repeat: no-repeat;
    height: auto;
    max-height: 200px;
    width: auto;
    max-width: 200px;
  }

  .available-wine-bottle-logo-from-api {
    // width: 32%;
    // object-fit: contain;
    // background-repeat: no-repeat;
    height: auto;
    max-height: 220px;
    width: auto;
    max-width: 200px;
  }
}
