
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

  .textarea {
    background: "blue";
    white-space: nowrap;
    overflow:hidden;
  }
  .copy-button { 
    background-color: transparent;
    outline: none;
    border: none;
    // margin: -12px 0 0 5px;
//     padding: 10px 10px;
  }


  .text { 
    font-size: 50px;
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
    padding: 10px 0 10px 0;
    margin: 10px 0 10px 0 !important;
   
  }

  .copy-success-text { 
      vertical-align: center;
  }

::-webkit-scrollbar {
    height: 4px;
    border: 1px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(189,189,189,.1); 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}