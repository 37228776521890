.order-receipt-status-img {
  display: flex;
  justify-content: center;
  min-width: 26%;
}
.order-receipt-details-header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: center;
  margin: 1em;
  font-size: 1.5rem;
}
.receipt-wine-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0em 1em 1em 1em;
  border-radius: 10px !important;
  border: 1px solid rgba(222, 216, 216, 0.125);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  // padding-top: 3%;
  //   padding-bottom: 3%;
}
.receipt-wine-details-card {
  //   margin: 1em 1em 1em 1em;
}
.card-bottom {
  border-bottom: 1px solid #d6cece;
  //   margin: 0 !important;
}
.order-details-img-wine-details-div {
  display: flex;
  flex-direction: row;
  margin: 1em 1em 1em 1em;
}
.receipt-wine-details-div {
  display: flex;
  flex-direction: column;
  /* margin-left: auto; */
  width: 65%;
  padding: 10px;
  line-height: 1.9;
}
.receipt-win-img-div {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.receipt-details-wine-img {
  width: 80%;
}
.receipt-details-wine-empty-img {
  width: 100%;
}
.receipt-winery-name {
  color: #727272;
}
.receipt-placed-on-div {
  display: flex;
  /* justify-content: center; */
  flex-direction: row;
  /* justify-content: center; */
  margin: 0em 1em 1em 1em;
}
.margin-split-container {
  margin-left: 1em;
  margin-right: 1em;
}
.receipt-qty-breakup-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
}
.receipt-paid-via-div {
  display: flex;
  flex-direction: row;
  margin-left: 1em;
  margin-right: 1em;
}
.ln-hght {
  line-height: 1.9;
}
.ft-size-receipts {
  font-size: 1.5rem;
}

.rcpt-details-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0em 1em 0.5em 1em;
  border-radius: 10px !important;
  border: 1px solid rgba(222, 216, 216, 0.125);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 11px;
  padding-bottom: 11px;
}
.card-container-details {
  margin: 0em 1em 0em 1em;
  display: flex;
  flex-direction: row;
}
.order-s-padding {
  padding-top: 11px;
}
.order-summary-details-container::after {
  content: "";
    display: block;
    height: 100px;
    width: 100%;
}

@media (min-width: 1025px) {
  .receipt-details-wine-img {
    width: 24% !important;
  }
  .receipt-win-img-div {
    width: 20% !important;
  }
  .receipt-details-wine-empty-img {
    width: 40% !important;
  }
}

@media (min-width: 768px) {
  .receipt-details-wine-img {
    width: 41% !important;
  }
  .receipt-win-img-div {
    width: 20% !important;
  }
  .receipt-details-wine-empty-img {
    width: 49% !important;
  }
}

@media (min-width: 540px) {
  .receipt-details-wine-img {
    width: 28% !important;
  }
  .receipt-win-img-div {
    width: 20% !important;
  }
  .receipt-details-wine-empty-img {
    width: 74% !important;
  }
}
@media (min-width: 1280px) {
  .receipt-details-wine-empty-img {
    width: 40% !important;
  }
}
@media (min-width: 1024px) {
  .receipt-details-wine-empty-img {
    width: 49% !important;
  }
}
// @media (min-width: 540px) {
//   .receipt-details-wine-img {
//     width: 28% !important;
//   }
// }

@media (min-width: 1440px) {
  .receipt-win-img-div {
    width: 12% !important;
  }
  .receipt-details-wine-empty-img {
    width: 53% !important;
  }
}
.shipping-address-details-history-container::after {
  content: "";
  display: block;
  height: 100px;
  width: 100%;
}
.order-details-shpng-add-container {
  margin: 0em 1em 0em 1em;
  display: flex;
  flex-direction: column;
}
.shpng-address-inner-container {
  margin-bottom: 0.5em;
}

.tick-icon {
  width: 25px;
}
.shpmt-open-lbl {
  color: #0c08ecc4;
}
.shpmt-transit-lbl {
  color: #ec8b08d6;
}
.shpmt-cancld-lbl {
  color: #ce1818c7;
}
.shpmt-delvrd-lbl {
  color: #34ce18a8;
}