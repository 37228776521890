@media (min-width: 1025px) {
  .success-inner-container {
    box-shadow: 0px 3px 200px -15px #989898;
    // background: #ffffff 0% 0% no-repeat padding-box;
    background-color: whitesmoke;
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 25px;
    width: 430px !important;
    // height: -moz-fit-content !important;
    /* height: auto !important; */
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }
  .success-footer-container {
    width: 430px !important;
    background: none !important;
  }
}
.success-inner-container {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  height: 100vh;
}
.page-content-container {
  display: flex;
  flex-direction: column;
  // height: 75vh;
  // justify-content: space-between;
}
// .success-inner-container > * {   flex: 1; }
.header-container {
  width: 100%;
  // height: 20vh;
}
.success-body-container {
  // height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  // margin-top: 7em;
}
.success-footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 20vh; */
  flex-direction: column;
  /* padding-bottom: 2em; */
  bottom: 15px;
  position: fixed;
  width: 100%;
}
.success-logo-container {
  padding: 10% 5% 5% 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.continue-shpng-btn-container {
  width: 90%;
}
.view-summary-btn {
  font-size: 16px !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
}

.order-success-logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.confirmation-mail-txt {
  font-size: 16px;
  font-weight: 500;
  padding: 2%;
}
.cntn-shpng-btn {
  font-size: 16px !important;
  width: 100%;
  color: #a84551;
}
.order-success-txt {
  font-size: 17px;
  font-weight: 600;
}
.success-main-container {
  // width: 100%;
  // height: 100vh;
}
