.individual-wine-details-main-container {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
  //overflow-y: auto;
  //-webkit-overflow-scrolling: touch;
  -webkit-animation: 0.5s ease-out 0s 1 slideInFromLeft;
  background-color: #f2f2f2;
}
.bottom-nav-induvidual-wines {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}
.individual-wine-details-main-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.individual-wine-details-image-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  border-radius: 10px !important;
  flex-direction: column;
  box-shadow: 1px #1d1d1d1a !important;
  border: none;
  height: 300px;
}

.individual-wine-details-sub-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.individual-wine-details-sub-container1 {
  width: 100%;
}
.individual-wine-details-sub-container2 {
  // padding: 5%;
  padding-top: 0% !important;
  width: 100%;
  margin-bottom: 5%;
}
.individual-wine-details-price-type-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  // padding: 3%;
}

.individual-wine-details-scroll-container {
  margin-bottom: 20%;
  overflow: auto;
  width: 100%;
  position: absolute;
  margin-top: -9vh;
  // height: 100%;
}

@media (min-width: 1025px) {
  .bottom-nav-induvidual-wines {
    bottom: 0;
    border: solid 0.4px #d2d2d2;
    position: fixed;
    transition: top 0.6s;
    padding: 0.5rem !important;
    padding-bottom: 1em !important;
    width: 430px !important;
    margin-bottom: 5vh;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }
  .type-text-cooperage {
    margin-left: 30% !important;
  }

  .individual-wine-details-subheader {
    padding: 3.5% !important;
  }

  .individual-wine-details-back-btn {
    width: 2% !important;
  }

  .individual-wine-details-title {
    margin-left: 2% !important;
    margin-top: 1px;
  }

  .individual-wine-details-header1 {
    height: 15% !important;
  }

  .individual-wine-details-sub-container2 {
    padding-top: 0% !important;
    width: 100%;
    margin-bottom: 5%;
  }

  .wine-detail-add-to-cart-btn {
    width: 90% !important;
    margin-bottom: 2% !important;
    margin-top: 2% !important;
  }

  .individual-wine-details-sub-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .individual-wine-details-scroll-container {
    margin-bottom: 20%;
    overflow: auto;
    width: 100%;
    position: absolute;
    margin-top: -7vh;
  }

  .individual-wine-details-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }

  .individual-wine-details-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }

  .individual-wine-detail-footer {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .individual-wine-details-header1 {
    width: 100%;
    height: 18%;
    background-color: #193c38;
    align-content: center !important;
  }

  .individual-wine-details-header {
    width: 100% !important;
    height: 38% !important;
    // background-color:#a84551!important;
    align-content: center !important;
    // background-image: url('../../../assets/images/individual-wine-details-bg.svg');
    background-repeat: no-repeat;
    border-bottom-right-radius: 4%;
    border-bottom-left-radius: 4%;
  }

  .individual-wine-details-text {
    color: #2b2a2a;
    font-family: "poppins", sans-serif !important;
    font-size: 1em;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .individual-wine-details-text::first-letter {
    text-transform: uppercase;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .user-individual-wine-details-pic {
    width: 80px !important;
    height: 80px !important;
    border-radius: 100px !important;
  }
}

.individual-wine-details-title {
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-left: 5%;
}
.individual-wine-details-subheader {
  display: flex;
  padding: 5%;
  align-items: center;
}
.individual-wine-details-header1 {
  // display: flex;
  width: 100%;
  height: 18%;
  background-color: #193c38;
  align-content: center !important;
  padding-top: 4%;
  padding-bottom: 4%;
}

.individual-wine-details-header {
  width: 100%;
  height: 38%;
  // background-color:#a84551;
  align-content: center !important;
  background-color: #a84551;
  background-repeat: no-repeat;
  border-bottom-right-radius: 4%;
  border-bottom-left-radius: 4%;
}

.individual-wine-details-text {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.2em;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.individual-wine-details-text::first-letter {
  text-transform: uppercase;
}

.individual-wine-details-back-btn {
  float: left;
  width: 3%;
}

.individual-wine-details-container {
  // margin-top: -9vh;
  width: 100%;
  position: absolute;
  overflow: auto;
  padding: 2%;
  display: flex;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
}

@media (min-width: 411px) and (max-height: 740) {
  // pixel2

  .individual-wine-details-image-not-present-container {
    margin-top: 10%;
    margin-right: 5%;
  }
}

@media (min-width: 375px) and (max-width: 400px) and (height: 812px) {
  .individual-wine-details-image-not-present-container {
    margin-top: 30% !important;
    margin-right: 5% !important;
  }
}

@media (min-width: 411px) {
  // >=400

  .individual-wine-details-image-not-present-container {
    margin-top: 10%;
    margin-right: 5%;
  }

  .individual-wine-details-pic,
  .user-individual-wine-details-pic {
    // width: 40%!important;
    // /* height: 50%; */
    // border-radius: 50%!important;

    // css added in px for now
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }

  .individual-wine-details-header {
    height: 33% !important;
  }

  .individual-wine-details-image {
    width: 25%;
    // height: 70%!important;
    margin-top: 5%;
    // margin-right: 5%!important;
  }
}

.individual-wine-details-image-placeholder {
  height: 60% !important;
}

// iPhone 5/SE
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .type-text-header {
    // color: #7d7d7d;
    font-family: "poppins", sans-serif !important;
    font-size: 1em;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // max-width: 15ch;
  }

  .individual-wine-details-image {
    width: 25%;
    // height: 70%!important;
    margin-top: 5%;
  }
  .individual-wine-details-image-present-container {
    margin-top: 2% !important;
  }

  .individual-wine-text-name {
    font-size: 1em !important;
  }

  .individual-wine-text-content,
  .individual-wine-paired-content {
    font-size: 0.8em !important;
  }

  .individual-wine-details-header {
    height: 40% !important;
  }
  .individual-wine-details-image {
    // height: 65%!important;
    width: 25%;
    margin-top: 5%;
  }

  .individual-wine-details-text {
    font-size: 1.25em !important;
  }
  .individual-wine-details-text::first-letter {
    text-transform: uppercase;
  }

  .individual-wine-details-image-present-container {
    // margin: 5% !important;
    // margin-top: -10% !important;
    // width: 25%!important;
    display: flex;
    justify-content: center;
  }

  .individual-wine-text-name {
    font-size: 1em !important;
  }
}

.individual-wine-details-subheader {
}

.individual-wine-details-subheader-row {
  display: flex;
  align-items: center;
  padding: 2%;
}

.individual-wine-details-type-content-container {
  margin: 5%;
  margin-top: 2%;
  padding: 2%;
}

.type-title-text,
.price-title-text {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  font-size: 1em;
  margin-top: 1%;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
}

.specifications-type-title {
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  font-size: 1.15em;
  margin-top: 1%;
  margin-bottom: 1%;
  text-transform: capitalize;
  display: flex;
}
.price-text {
  color: #a84551;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  font-size: 1.25em;
  margin-top: 1%;
  text-transform: capitalize;
}
.specifications-type-container1 {
  margin-bottom: 5%;
}

.type-text-price {
  color: #a84551;
  word-wrap: break-word; // next line
  font-weight: bold;
  font-family: "poppins", sans-serif !important;
  text-align: center !important;
  font-size: 1em;
  margin-top: 1%;
  text-transform: capitalize;
  text-align: left;
}
.type-text {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.15em;
  white-space: pre-wrap;
  word-break: keep-all;
  margin-top: 1%;
  text-align: left;
  border: none;
  background-color: transparent;
}

.type-text-cooperage {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.15em;
  margin-top: 1%;
  margin-left: 10%;
  text-transform: capitalize;
  text-align: left;
}

.type-text-vineyard {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.15em;
  margin-top: 1%;
  margin-left: 15%;
  text-transform: capitalize;
  text-align: left;
}

.type-text-header {
  white-space: pre-wrap;
  word-wrap: break-word; // next line
  //   color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  //   max-width: 15ch;
}

.type-text-wine-profile {
  word-wrap: break-word; // next line
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  // font-family:  'Georgia', sans-serif!important;
  font-size: 1.15em;
  margin-top: 1%;
  text-transform: capitalize;
  text-align: left;
}

.individual-wine-details-type-container,
.individual-wine-details-amount-container {
  display: flex;
  flex-direction: column;
  // margin-bottom: 5%;
  margin-bottom: 25%;
}

.individual-wine-details-content-container {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  width: 100%;
}

.individual-wine-details-content-subcontainer1,
.individual-wine-details-content-subcontainer2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
}
.individual-wine-text-name {
  color: #2b2a2a;
  font-size: 1.5em !important;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-top: 2%;
  text-transform: capitalize;
}

.individual-wine-text-content {
  color: #2b2a2a;
  font-size: 1.25em !important;
  font-family: "poppins", sans-serif !important;
  // font-weight: lighter !important;
  margin-top: 2%;
}

.individual-wine-text-content::first-letter,
.individual-wine-paired-content::first-letter {
  text-transform: uppercase;
}

.individual-wine-paired-name {
  color: #2b2a2a;
  font-size: 1.5em !important;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-top: 2%;
}

.individual-wine-paired-content {
  color: #2b2a2a;
  font-size: 1em !important;
  font-family: "poppins", sans-serif !important;
  font-weight: lighter !important;
  margin-top: 2%;
}

.add-to-cart-btn-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.individual-wine-details-type-content-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.individual-wine-details-image {
  //   height: 80%;
  width: 25%;
  margin-top: 5%;
}

.individual-wine-details-image-present-container {
  //   margin: 5%;
  //   margin-top: 2%;
  //   width: 25%;
}

.individual-wine-details-content-type-price-container {
  display: flex;
  justify-content: space-between;
}

.individual-wine-based-on-title {
  color: #7d7d7d;
  font-size: 1em;
}
.individual-wine-based-on-text {
  color: #a84551;
  font-weight: bold;
  font-size: 1.25em;
}

@media (min-width: 300px) and (max-width: 400px) {
  .type-title-text,
  .type-text,
  .amount-title-text,
  .amount-text {
    font-size: 1.15em !important;
  }

  .individual-wine-details-image-not-present-container {
    // margin: 5%!important;
    // margin-top: -7%!important;
    margin-right: 5%;
    margin-top: 10%;
  }
  .individual-wine-details-image {
    // height: 60%!important;
    width: 25%;
    margin-top: 5%;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .individual-wine-details-container {
    justify-content: normal !important;
  }

  .individual-wine-details-content-container {
    width: 100%;
  }
}

.individual-wine-details-image-present-container {
  margin: 5%;
  margin-top: 2%;
  padding: 2%;
  margin-right: 5%;
}

.individual-wine-details-container-row {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
.individual-wine-details-data-subcontainer {
  //    margin-top: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.individual-wine-details-type-subcontainer,
.individual-wine-details-price-subcontainer {
  display: flex;
  flex-direction: column;
}

.individual-wine-details-wine-name {
  color: #2b2a2a;
  font-size: 1.25em !important;
  font-family: "poppins", sans-serif !important;
  font-weight: bold;
  margin-bottom: 3%;
}

.accordion-item__icon {
  background-image: url("../../../assets/images/RecommendationHistory/down-arrow.png") !important;
  opacity: 1 !important;
  padding: 5%;
}

.accordion-rotate-item__icon {
  transform: rotate(180deg);
}

.individual-wine-detail-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  //    margin-bottom: 25%;
}

.specifications-type-container {
  justify-content: space-between;
  margin-bottom: 2%;
  // margin-left: 3%;
  // for displaying title-content next to each other
  display: flex;
  flex-direction: row;
  word-wrap: break-word; // next line
}

.individual-wine-details-data-subcontainer,
.individual-wine-details-data-subcontainer {
  padding: 5%;
}
hr {
  position: relative;
  border: none;
  height: 1px;
  background: #cccccc;

  margin-top: 2% !important;
  margin-bottom: 0px !important;
  border: 0;
}

.individual-wine-details-type {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.individual-wine-details-url {
  color: #7d7d7d;
  font-size: 1em;
  font-style: italic;
}

.logo-container-header-individual-winedetails-page {
  width: 100%;
  display: flex;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.openbrowser-wineurl-image-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.winerylogo-container-ind-winedetails-page {
  display: flex;
  justify-content: flex-start;
}
.winerylogo-ind-winedetails-page {
  width: 30%;
  border-radius: 100px;
}
.openbrowser-wineurl-image {
}

.wine-detail-add-to-cart-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 90%;
}
.individual-wine-details-scroll-container:after {
  content: "";
  display: block;
  height: 70px;
  width: 100%;
}
.dsktp-header {
  margin-top: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.dsktp-header-title {
  margin-left: 3% !important;
  width: 100%;
  font-weight: 500 !important;
  font-size: 1.5em;
  color: #2b2a2a;
}

.individual-wine-image-card {
  position: relative;
  display: flex;
  flex-direction: row;
}

// .individual-wine-share-button {
//    position: absolute;
//    top: 6%;
//    right: 2%;

// }

.box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.wine-logo {
  flex-grow: 8;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}
.share {
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
}
.like {

}

.discount-like-box {
  display: flex;
  margin-left: 2%;
  margin-top: 2%;

}

.vimeoWrapper {
  padding-top: 10px;
  margin: 0 auto;
}