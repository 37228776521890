@use "src/app/styles/theme" as s;

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}

.formField {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  justify-content: center;
}

.formFieldError {
  color: red;
}

.asterisk {
  font-weight: 800;
  color: s.$red;
}

.serverErrorBox {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: s.$red-light;

  color: red;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formInput {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.formInputTextArea {
  // display: flex;
  // align-items: flex-start;
  // justify-content: flex-start;
  height: 140px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.main {
  padding-top: 3.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.head {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.line {
  width: 100%;
  border-top: 1px solid #efefef;
}

.formSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.mainContainerSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.checkbox {
  flex-shrink: 0;
  color: white;
  fill: white;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid s.$gray-dark;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.checked {
  background-color: s.$gray-dark;
}

.ambassadorItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}

.animationWrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  opacity: 0;
  width: 100%;
  margin-bottom: 0;
  transition: grid-template-rows 0.3s, opacity 0.3s, margin-bottom 0.3s;
}

.animationExpandable {
  min-height: 0;
}

.expanded {
  grid-template-rows: 1fr;
  opacity: 1;
  margin-bottom: 40px;
}

.inputAndResultsBox {
  display: flex;
  width: 100%;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
}

.inputWithIcon {
  display: flex;
  width: 100%;
  padding: 18px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: white;
}

.borderBottom {
  border-bottom: 1px solid rgba(125, 125, 125, 0.5);
  border-radius: 8px 8px 0 0;
}

.storesListBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.noShrink {
  flex-shrink: 0;
  flex-grow: 1;
}

.createAccText {
  color: s.$gray;
  font-family: s.$font-1;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.termsOfUseText {
  color: s.$red;
  cursor: pointer;
}

.required {
  color: s.$red;
}

.submitBtn {
  margin-top: 16px;
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: s.$gray-dark;

  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.grayedOut {
  color: s.$gray;
  background: s.$gray-light;
}

.mainTY {
  padding-top: 3.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.editBox {
  width: 100%;
  justify-self: flex-start;
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px;
}
