
.radio-btn-address-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #00000029;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: medium;
}
.address-selection-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  //   margin-left: 1em;
  //   margin-right: 1em;
}
.shpng-address-details {
  margin-left: 3.1em;
  font-weight: 500;
}
.address-type-label {
  margin-left: 1em;
  font-weight: 700;
}
.shpng-lctn {
  opacity: 0.7;
}
.shpng-address-edit-delete {
  margin-left: 3.1em;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  color:#a84551;
}

.select-address-radio {
  input[type="radio"] {
    -webkit-appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    outline: none;
    border: 3px solid gray;
  }
  input[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 18% auto;
    border-radius: 50%;
  }
  input[type="radio"]:checked:before {
    background:#a84551;
    opacity: 0.9;
  }

  input[type="radio"]:checked {
    border-color:#a84551;
    opacity: 0.9;
  }
}
