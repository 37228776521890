@media (min-width: 1025px) {
  .date-of-birth-check-text2 {
    margin-top: 1% !important;
  }
  // .dsktp-crctn {
  //   padding: 0em 1em !important;
  // }
  .date-of-birth-check-main-container {
    box-shadow: 0px 3px 200px -15px #928f8f62;
    // overflow: auto!important;
    position: relative;
    // background-image:url('../../../assets/images/sign-in-background.png');
    // background-repeat: no-repeat;
    // background-position: center top!important;
    // background-size: contain!important;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 430px !important;
    height: fit-content !important;
    border-radius: 25px;
    overflow: auto;
    height:95% !important;
    // in px
    // width: 400px!important;
    // height: 600px!important
  }

  .logo-container {
    padding: 1% !important;
  }

  .confirm-dob-btn {
    margin-left: 5%;
    margin-bottom: 5%;
  }

  .date-of-birth-check-card-background-container {
    // background: #f8f8f8 0% 0% no-repeat padding-box;
  }
}

.date-of-birth-check-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  // margin-top: 10%;
  flex-direction: column;
  margin-bottom: -2rem;
}
.date-of-birth-check-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.date-of-birth-check-text1 {
  color: #2b2a2a;
  font-weight: bold;
  font-size: 1.4em;
  font-family: "poppins", sans-serif !important;
}

.date-of-birth-check-text2 {
  color: #2b2a2a;
  font-weight: 500;
  font-size: 1.3em;
  font-family: "poppins", sans-serif !important;
  margin-top: 4%;
  text-align: center;
}
@media (min-width: 300px) and (max-width: 400px) {
  .date-of-birth-check-text2 {
    color: #2b2a2a;
    font-weight: 500;
    font-size: 1.25em !important;
    font-family: "poppins", sans-serif !important;
  }
}

@media only screen and (min-width: 300px) and (max-height: 400px) {
  .confirm-dob-btn {
    bottom: 4% !important;
  }
}

// iPhone 5/SE
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .date-of-birth-check-container {
    margin-top: 1% !important;
  }
  .date-of-birth-check-text2 {
    color: #2b2a2a;
    font-weight: 500;
    font-size: 1em !important;
    font-family: "poppins", sans-serif !important;
  }
  // .submit-dob-container {
  //   margin-top: 15% !important;
  // }
}

.date-of-birth-check-text-subcontainer {
  margin-top: 5%;
}

.date-of-birth-check-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dob-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dob-error-message-text {
  color: red;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em !important;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // color: red;
  // text-align: center;
  // padding: 3%;
  // font-size: 1.3em;
}

.dob-screen-pairanything-logo-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.dob-screen-pairanything-logo {
  width: 30%;
}

.submit-dob-container {
  // margin-top: 10%;

  // width: 100%;
  justify-content: center;
  display: contents;
  align-items: center;
  margin-bottom: 10%;
}

.dob-check-picker {
  font-size: 1.5em !important;
}

// input[type="date"] {
//   -webkit-appearance: none;
// }

// .datePicker::-webkit-inner-spin-button,
// .datePicker::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
// }

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}

.confirm-dob-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color:#a84551 !important;
  width: 90%;
  margin-left: 5%;
  // bottom: 5%;
  // position: fixed;
  opacity: 1 !important;
}

.logo-container {
  margin-bottom: -1rem !important;
}
@media screen and (min-width: 1025px) and (max-height : 800px){
  .date-of-birth-check-main-container {
    height:95% !important;
  }
  }
.dob-verification-container-padding {
  padding: 10px 20px 10px 20px;
}
.dob-before-margin-bottom {
  margin-bottom: 6px !important;
}
.dob-msg-css {
    font-size: 11px;
    float: right;
    color: #b1abab;
    margin-top: 3px;
}