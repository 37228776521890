.powered-by-container {
  font-family: Montserrat, serif;
  line-height: 14px;
  font-size: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #FFFFFF;
}

.powered-by-pre {
  font-weight: 600;
  align-self: center;
  margin: 0;
  color: #7D7D7D;
  opacity: 0.5;
}

.powered-by-logo {
  width: 12px;
  height: 11px;
  margin-left: 5px;
  margin-right: 2px;
}

.powered-by-pair {
  color: orangered;
  font-weight: 800;
  align-self: center;
  margin: 0;
  padding-right: 1px;
}

.powered-by-anything {
  color: darkgray;
  font-weight: 800;
  align-self: center;
  margin: 0;
}