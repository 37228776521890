.main-image{
    max-width: 15rem;
    width: auto;
    height: auto;
}
.header-container{
    display: flex;
    justify-content:space-between;
}
.menu-icon{
    margin-right: 2rem;
    margin-top: 3rem;
}
.scott-harvey-header{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4%;
    padding-bottom: 4%;
    min-height: 60px;
}
.header-bar{
    min-height: 20px;
    width: 100%;
    position: absolute;
    margin-top: 130px;
    background-color: #843c44;
}
.header-inner{
    display: flex;
    justify-content: center;
}
.shopping-cart-icon{
    max-width: 25px;
    height: auto;
    margin-right: 2rem;
    margin-bottom: 5px;
}