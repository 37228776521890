@use "src/app/styles/theme" as s;

.card {
  all: unset;
  width: 46%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.ghostCard {
  all: unset;
  width: 46%;
}

.wineImgBg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  min-width: 100px;
  background-color: s.$gray-light;
  border-radius: 8px;
}

.wineImg {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  width: 80%;
  height: 80%;
  mix-blend-mode: multiply;
}


