@use "src/app/styles/theme" as s;

.fadeIn {
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modalBackground {
  padding: 2rem 2.4rem 2rem 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.modalBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 24px;
  background-color: white;
  border-radius: 8px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.optionsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.option {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
}

.checkbox {
  flex-shrink: 0;
  color: white;
  fill: white;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid s.$gray-dark;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.checked {
  background-color: s.$gray-dark;
}

.feedBackBtn {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: s.$gray-dark;

  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.grayedOut {
  background: s.$gray-light;
  color: s.$gray;
}

.formInput {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.thankYouTextBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.createAccountBtn {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: s.$red;

  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.signInBtn {
  color: s.$red;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.signIn:hover p {
  color: black;
}

.signInBtnTxt {
  color: s.$red;
}

// .link {
//   color: s.$red;
// }

// .link:hover {
//   color: black;
//   text-decoration: none;
// }

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actionCircle {
  color: s.$gray;
  fill: s.$gray;
  height: 42px;
  width: 42px;
  background-color: s.$gray-light;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, color 0.3s, fill 0.3s;
}

.actionCircleActive {
  fill: white;
  color: white;
  background-color: s.$gray-dark;
}

.action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  color: s.$gray;
  font-family: s.$font-1;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.actionText {
  color: s.$gray;
  font-family: s.$font-1;
  font-size: 12px;
  font-weight: 500;
}

.blueBox {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #1e7ed7;
  box-shadow: 0px 2px 20px 0px rgba(52, 52, 52, 0.42);
  margin: 2.4rem;
  flex-grow: 0;
  z-index: 5;
}

.blueBoxText {
  color: white;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.animationWrapper {
  width: 100%;
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  opacity: 0;
  transition: grid-template-rows 0.3s, opacity 0.5s;
}

.animationExpandable {
  min-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expanded {
  grid-template-rows: 1fr;
  opacity: 1;
}

.underlined {
  text-decoration-line: underline;
  font-weight: 700;
}

.underlined:hover {
  text-decoration-line: none;
  font-weight: 700;
  cursor: pointer;
}
