@media (min-width: 1025px) {
    .empty-page-card-logo-container {
        // margin-top: 5%;
    }

    .empty-page-card-content {
        margin-bottom: 15%;
    }
}

.empty-page-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.empty-page-card-type-subcontainer {
    display: flex;
    align-items: center;
}

.empty-page-card-title {
    color: #2b2a2a;
    font-family: "poppins", sans-serif !important;
    font-size: 1.25em;
    font-weight: bold;
}

.empty-page-card-details {
    width: 100%;
}

.empty-page-card-title-container,
.empty-page-card-type-subcontainer {
    margin-bottom: 5%;
}

.empty-page-card-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

.empty-page-card {
    width: 100%;
}

.empty-page-card-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.empty-page-card-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty-page-card-content {
    text-align: center;
    color: #7d7d7d;
    font-family: "poppins", sans-serif !important;
    font-size: 1em;
    margin-top: 5%;
    font-style: italic;
}

.empty-page-card-logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-page-card-logo {
    width: 20%;
}

// iPhone 5/SE
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .empty-page-card-content {
        font-size: 1em !important;
    }
}
