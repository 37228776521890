@use 'sass:color';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


body {
  font-family: "poppins", sans-serif !important;
}

.content-container {
  width: 100%;
  height: 100vh;
}

@media (min-width: 1025px) {
  .content-container {
    box-shadow: 0 3px 200px -15px #989898;
    // background: #ffffff 0% 0% no-repeat padding-box;
    background-color: white; //whitesmoke;
    border-radius: 25px;
    width: 430px !important;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto !important;
    position: fixed !important;
  }

  .bottom-nav {
    bottom: 0;
    border: solid 0.4px #d2d2d2;
    position: fixed;
    transition: top 0.6s;
    padding: 0.5rem !important;
    padding-bottom: 1em !important;
    width: 430px !important;
    margin-bottom: 5vh;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }

  .success-footer-container {
    width: 430px !important;
    background: none !important;
  }
}

.sub-container {
  padding: 1.5% 3% 3% 3%;
}

.new-discover-container {
  position: relative !important;
}

.bottom-nav {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

@media (min-width: 1025px) {
  .new-discover-select-card {
    width: 40% !important;
  }
  .new-discover-inner-container {
    height: 90vh !important;
    overflow: hidden;
  }
  .new-discover-inner-container-induvidual-wines {
    height: 90vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
@media screen and (max-height: 799px) and (min-width: 1025px) {
  .new-discover-inner-container {
    height: 90vh !important;
    overflow: hidden;
  }
  .new-discover-inner-container-induvidual-wines {
    height: 90vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.new-discover-outer-container-div::after {
  content: unset !important;
  display: unset !important;
  height: unset !important;
  width: unset !important;
}
.new-discover-card-logo {
  width: 65%;
}

.thanks-fonts-style {
  margin: 0 0 4% 0%;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
}

.font-family {
  font-family: "poppins", sans-serif !important;
}

.header-container {
  display: flex;
  justify-content:space-between;
}

.new-discover-logo-container {
  padding-top: 5% !important;
}

.success-logo-container {
  padding: 10% 5% 5% 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0% 0% 4% 0%;
  font-size: 1.4em;
  font-weight: 700;
  text-align: center;
}

.outer-container {
  padding: 3%;
  width: 100%;
  background: #EFEFEF 0% 0% no-repeat padding-box;
}

.outer-container-no-pad {

  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.top-outer-container {
  width: 100%;
  height: 100vh;
  background: #EFEFEF 0% 0% no-repeat padding-box;
}

::-webkit-scrollbar {
  height: 4px;
  border: 1px;
  width: 10px;
  background-color: rgb(238, 238, 238);
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(189,189,189,.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.unset-children::after {
  content: unset !important;
  display: unset !important;
  height: unset !important;
  width: unset !important;
}

// .outer-container {
//   padding: 1.5% 3% 3% 3% !important;
// }

a {
  color: #337ab7;
  text-decoration: none;

  &:hover,
  &:focus {
    color: shift-color(#337ab7, 20%);
    text-decoration: underline;
  }
}

.header-logo {
  margin-right: unset !important;
  padding-right: 5px;
}

.search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;

  // margin-top: -3.5vh;
  width: 100% !important;
  // position: absolute;
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}

.recommendation-input {
  color: #959292;
  font-style: italic;
  font-size: 1.25rem;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.posRelative {
  position: relative;
}

.page-bottom {
  padding-bottom: 8rem;
}

//main container
.mc {
  width: 100%;
  padding: 0 2.4rem 0 2.4rem;
}

