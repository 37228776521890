.wine-education-main-container {
    width: 100%;
    height: 100vh;
}

@media (min-width: 1025px) {
    .wine-education-main-container,
    .wine-education-container,
    .bottom-navigation {
        margin-top: 5%;
        overflow: hidden;
    }
    .wine-education-option-container {
        display: flex;
        justify-content: space-between;
        padding: none !important;
        color: #959292;
        font-weight: 400;
        font-size: 1.15em;
        margin-top: 3%;
        margin-left: 3%;
    }

    .wine-education-main-container {
        box-shadow: 0px 0px 15px 15px #d2d2d2;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 30% !important;
        height: 80vh !important;
        border: 2px solid #c0c0c0;
        overflow: auto;
    }
    .wine-education-header {
        width: 100% !important;
        height: 20% !important;
        background-color:#a84551 !important;
        align-content: center !important;
    }

    .wine-education-text {
        margin: 5% !important;
        float: left;
        font-size: 1.5em !important;
        color: #ffffff;
        font-family: "poppins", sans-serif !important;
        font-weight: 600 !important;
    }

    .wine-education-main-container {
        height: 70vh !important;
    }
    .bottom-navigation {
        // margin-bottom: 16.5%!important;
    }
}

@media (min-width: 300px) and (max-width: 400px) {
    .wine-education-card-personal-details-subheader {
        margin-bottom: 0% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .wine-education-option-container {
        display: flex;
        justify-content: space-between;
        padding: 1% !important;
        color: #959292;
        font-weight: 400;
        font-size: 1.15em;
        margin-top: 3%;
        margin-left: 3%;
    }
}

.wine-education-header {
    width: 100%;
    height: 18%;
    background-color:#a84551;
    align-content: center !important;
    // z-index: -1!important;
}

.wine-education-text {
    margin: 7%;
    float: left;
    font-size: 2em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
}

.wine-education-cart-btn {
    float: right;
    margin: 10%;
}

.wine-education-container {
    margin-top: -9vh;
    width: 100%;
    position: absolute;
    margin-bottom: 25%;
}

.wine-education-card-personal-details-subheader {
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width: 400px) {
    // >=400
}

.wine-education-row {
    // width: 100%;
    // position: absolute;
    bottom: 0;
}

.wine-education-right-arrow-btn {
    float: right;
}

.bottom-navigation {
    bottom: 0;
    width: 100%;
    border: solid 0.4px #d2d2d2;
    position: fixed;
    transition: top 0.6s;
    padding: 0.5rem !important;
}

.user-wine-education-pic-container {
    display: flex;
    justify-content: center;
}

//   .nav-link {
//       background-color: white!important;
//       color: white!important;
//   }
