$gray: #7d7d7d;
$gray-dark: #343434;
$gray-light: #efefef;
$gray-light-hover: #dedede;
$red-light: #f6efef;
$peach-dark: #f4ece3;
$red: #951901;
$font-1: Inter, sans-serif;
$font-2: Montserrat, sans-serif;

.h1-30 {
  /* H1 - 30 ExtraBold */
  color: $gray-dark;
  font-family: $font-2;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px; /* 113.333% */
  letter-spacing: -0.84px;
}

.h2-22 {
  /* H2 - 22 ExtraBold */
  color: $gray-dark;
  font-family: $font-2;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.33px;
}

.h3-16 {
  /* 16 ExtraBold */
  color: $gray-dark;
  font-family: $font-2;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.448px;
}

.p1-reg {
  /* P1 - 14 Reg */
  color: $gray;
  font-family: $font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.p1-bold {
  /* P1 - 14 Bold */
  color: $gray-dark;
  font-family: $font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.p1-bold-red {
  /* P1 - 14 Bold */
  color: $red;
  font-family: $font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.p1-semi {
  /* P1 - 14 Bold */
  color: $gray-dark;
  font-family: $font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.p1-it {
  /* P1 - 14 Bold */
  color: $gray;
  font-family: $font-1;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
}

.p2-reg {
  color: $gray;
  font-family: $font-1;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.p3-bold {
  color: $gray-dark;
  font-family: $font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.p4-x-bold {
  color: $gray-dark;

  /* 16 ExtraBold */
  font-family: $font-2;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.448px;
}

.p5-med {
  color: $gray-dark;
  text-align: center;

  /* P5 - 12 Med */
  font-family: $font-1;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
}

.p7-reg {
  color: $gray;

  /* P7 - 12 Bold */
  font-family: $font-1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
}

.p7-bold {
  color: $gray-dark;

  /* P7 - 12 Bold */
  font-family: $font-1;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 100% */
}

.pb-lg {
  color: white;
  font-family: $font-1;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pb-sm {
  color: white;
  font-family: $font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.unset-margin-left {
  margin-left: unset !important;
}

.ml-0 {
  margin-left: 0;
}

.desktop-scroll {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.font-success {
  font-size: 17px;
  font-weight: 600;
}

.font-slim {
  font-weight: 500;
}

.font-medium {
  font-weight: 700;
}

.bg-none {
  background: none !important;
}

.no-shrink {
  flex-shrink: 0;
}

.m0 {
  margin: 0 !important;
}

.lh0 {
  line-height: 0;
}

.w100 {
  width: 100%;
}

.wa {
  width: auto;
}

//negate main container padding
.nmc {
  margin: 0 -2.4rem 0 -2.4rem;
}

//main container margin
.mcm {
  margin: 0 2.4rem 0 2.4rem;
}

svg {
  flex-shrink: 0;
  flex-grow: 0;
}

p {
  margin: 0;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

input {
  padding: 0;
  border: none;
}

label {
  margin: 0;
}

button {
  padding: 0;
}
