* {
    outline: none !important;
}

.event-desc-card {
    height: fit-content;
}

.event-desc-card-container {
    margin-bottom: 80px;
}

.event-desc-root-container {
    width: 100%;
    height: 100%;
    opacity: 1;
}

.attending-btn-details {
    pointer-events: none;
    width: 100px;
    height: 35px;
    position: absolute;
    right: 0;
    align-content: center;
    font-family: "poppins", sans-serif !important;
    font-size: 14px;
    color: #2b2b2b;
    margin-top: -10px;
    margin-right: 20px;
    justify-content: center;
}

.cover-picture-desc {
    width: calc(100% + 28px);
    height: 38vw;
    object-fit: cover;
    margin-left: -14px;
    margin-top: -14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.event-title {
    width: 100%;
    margin-top: 10px;
    display: inline-flex;
    flex-direction: row;
}

.event-name {
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 19px;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
    font-weight: 600;
}

.event-date-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.time-slot-header {
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 16px;
    letter-spacing: 0px;
    color: #2b2b2b;
}

.event-date-content {
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 15px;
    letter-spacing: 0px;
    opacity: 1;
    margin-left: 9px;
    margin-top: 10px;
}

.location-icon {
    height: 16px;
    width: auto;
    margin-bottom: 3px;
}

.online-icon {
    height: 16px;
    width: auto;
    margin-bottom: 3px;
}

.event-venue {
    margin-top: 10px;
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 15px;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
    display: flex;
    flex-direction: row;
}

.event-url {
    margin-top: 10px;
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 15px;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
}

.event-venue-contents {
    margin-left: 8px;
}

.event-url-title {
    margin-left: 5px;
}

.event-url-contents {
    margin-left: 21px;
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 13px;
    letter-spacing: 0px;
    color: #727272;
    opacity: 1;
}

.event-venue-contents {
    margin-left: 5px;
}

.event-desc {
    margin-top: 10px;
    margin-bottom: 10px;
}

.event-desc-title {
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 16px;
    letter-spacing: 0px;
    color: #2b2b2b;
}

.time-slot-header-container {
    display: flex;
    flex-direction: row;
}

.event-desc-content {
    text-align: justify;
    letter-spacing: 0px;
    color: #727272;
    opacity: 1;
    margin-bottom: 5px;
    margin-top: 5px;
    font-family: "poppins", sans-serif !important;
    font-size: 14px;
}

.date-slot-container {
    margin-top: -10px;
}

.rsvp-button-event-desc {
    width: 90%;
    // position: fixed;
    // bottom: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    align-content: center;
    font-family: "poppins", sans-serif !important;
    font-size: 1.3em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    margin-left: 5%;
    justify-content: center;
}

.attending-button {
    pointer-events: none;
    width: 90%;
    margin-bottom: 10px;
    font-family: "poppins", sans-serif !important;
    font-size: 1.3em !important;
    align-content: center;
    box-shadow: 0px 2px 6px #00000029;
    border: 1px solid#a84551;
    border-radius: 3px;
    opacity: 1;
    margin-left: 5%;
    justify-content: center;
    color:#a84551;
}

.calendar-icon-desc {
    height: 14px;
    width: auto;
    margin-top: 13px;
}

.user-icon {
    height: 14px;
    width: 12px;
    margin-right: 5px;
    margin-left: 20px;
    margin-bottom: 5px;
    margin-right: 5px;
}

.time-icon {
    height: 17px;
    width: 17px;
    margin-top: 13px;
    margin-right: 8px;
    margin-bottom: 5px;
}

.recommendations-custom-line-events {
    margin-left: 3%;
    width: 2px;
    background-color:#a84551;
    // background-image: radial-gradient(#cd4545, #fa9c9c);
    // animation: right .3s ease, left-end .4s ease .4s;
}

.date-details-container {
    display: flex;
    flex-direction: row;
}

.dot {
    height: 17px;
    width: 17px;
    margin-left: -9.5px;
    background-color:#a84551;
    border-radius: 50%;
    display: inline-block;
    margin-top: -10px;
}

.dot-without-line {
    height: 17px;
    width: 17px;
    margin-top: -10px;
    margin-left: 2px;
    background-color:#a84551;
    border-radius: 50%;
    display: inline-block;
}

.user-contents {
    text-align: left;
    padding-left: 5px;
    font-family: "poppins", sans-serif !important;
    font-size: 16px;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
    margin-top: -1px;
}

.time-contents {
    text-align: left;
    font-family: "poppins", sans-serif !important;
    font-size: 15px;
    letter-spacing: 0px;
    color: #2b2b2b;
    opacity: 1;
    margin-left: 8px;
    margin-top: -2px;
    margin-bottom: 15px;
}

.event-winery-container {
    margin-top: 14px;
    display: flex;
    flex-direction: row;
}

.events-winery-logo {
    width: 10%;
    height: 10%;
    margin-right: 10px;
}

.event-winery-name {
    font-family: "poppins", sans-serif !important;
    color: #2b2b2b !important;
    font-size: 1em;
    text-transform: capitalize;
    letter-spacing: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
}

.event-users {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.event-times {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.edit-pass {
    margin-top: 10px;
    text-align: left;
    text-decoration: underline;
    font-family: "poppins", sans-serif !important;
    font-size: 16px;
    letter-spacing: 0px;
    color:#a84551;
    opacity: 1;
}

.cancel-pass {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: underline;
    font-family: "poppins", sans-serif !important;
    font-size: 16px;
    letter-spacing: 0px;
    color:#a84551;
    opacity: 1;
}

.bottom-btn-container {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: fit-content;
}

@media (min-width: 1025px) {
    .cover-picture-desc {
        height: 22vh !important;
    }

    .event-desc-card-container {
        width: 80% !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .rsvp-button-event-desc {
        width: 76% !important;
        margin-left: 12% !important;
    }

    .events-winery-logo {
        width: 5% !important;
        height: 5% !important;
    }
}

@media screen and (max-width: 320px) {
    .dot-without-line {
        margin-left: 0px !important;
    }
}

@media screen and (min-device-width: 768px) {
    .dot-without-line {
        margin-left: 2.1% !important;
    }
}

.box {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    // width: 100%;
}

.event-details {
    flex-grow: 9;
}
.box .share-event {
    flex-grow: 1;
    justify-content: flex-end;
    text-align: right;
    padding-top: 10px;
}