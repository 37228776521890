@media (min-width: 1025px) {
    .forgot-password-main-container {
        box-shadow: 0px 3px 200px -15px #928f8f62;
        width: 430px !important;
        height: fit-content !important;
        /* border: 2px solid #C0C0C0; */
        border-radius: 15px;
        overflow: auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        margin: auto !important;
        position: fixed !important;
    }

    .forgot-password-form-container {
        width: 85% !important;
    }

    .set-password-title {
        margin-top: 10%;
        justify-content: left !important;
        margin-left: 7.5% !important;
    }
    .form-email-text {
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font-family: "poppins", sans-serif !important;
        font-size: 14px;
        letter-spacing: 0px;
        color: #69696d;
        opacity: 1;
    }

    .forgot-password-main-container-background {
        background: #f8f8f8 0% 0% no-repeat padding-box;
    }

    .back-to-signin-text {
        margin-bottom: 25px;
        cursor: default;
        font-size: 18px;
        color:#a84551;
        float: right !important;
    }

    .forgot-password-btn {
        margin-bottom: 25px;
    }

    .reset-password-text {
        color: #7d7d7d;
        font-family: "poppins", sans-serif !important;
        font-size: 1.25em !important;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
    }

    .form-row {
        font-size: 1.5em !important;
        width: 100% !important;
        margin-top: 10% !important;
        border: 1px solid #1d1d1d1a !important;
    }

    .forgot-password-form-row {
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 4%;
        margin-top: 10%;
        width: 100%;
        border: 1px solid #1d1d1d1a !important;
        box-shadow: none !important;
        font-size: 1.5em !important;
    }
}

.forgot-password-header {
    padding: 5%;
    display: flex;
    align-items: center;
}

.forgot-password-title {
    font-weight: 500 !important;
    margin-left: 3%;
    font-size: 1.75em;
    color: #2b2a2a;
}

.forgot-password-form-parent {
    // margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.back-btn-container {
    margin-top: 5%;
    margin-left: 5%;
}
.forgot-password-form-container {
    width: 80%;
    // text-align: center;
}

.forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-row {
    font-size: 1.25em !important;
    width: 100% !important;
    margin-top: 10% !important;
    border: 1px solid #1d1d1d1a !important;
}

.forgot-password-form-row {
    font-size: 1.25em !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 4%;
    margin-top: 10%;
    width: 100%;
    border: 1px solid #1d1d1d1a !important;
    box-shadow: none !important;
    font-size: 1.25em !important;
}

.reset-password-text {
    color: #7d7d7d;
    font-family: "poppins", sans-serif !important;
    font-size: 1.125em;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.reset-password-text-container {
    margin-top: 5%;
}

.forgot-password-btn {
    font-family: "poppins", sans-serif !important;
    font-size: 1.5em !important;
    color: #ffffff !important;
    background-color:#a84551 !important;
    width: 100%;
}
