@use "src/app/styles/theme" as s;

.helpful {
  margin-top: 8%;
  height: 50px;
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: s.$gray-light;
  transition: justify-content 1s;
}

.helpfulClicked {
  justify-content: center;
  gap: 8px;
}

.yesNo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.yesNoBtn {
  background-color: white;
  border-radius: 4px;
  color: s.$gray-dark;
  border: 1px solid white;
  padding: 8px 16px;
}

.yesNoBtn:hover {
  background-color: s.$gray-light;
  border: 1px solid s.$gray;
}

.yesNoBtn:focus {
  color: white;
  background-color: s.$gray-dark;
  border: 1px solid s.$gray-dark;
}

.msgBox {
  flex-shrink: 0;
}
