

.page-not-found-container {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.page-not-found-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.page-not-found-text1 {
    color: #2B2A2A;
    font-weight: bold;
    font-size: 2em;
    font-family: 'poppins', sans-serif !important;
}

.page-not-found-text2 {
    color: #2B2A2A; 
    font-weight: 500;
    font-size: 1.3em;
    font-family: 'poppins', sans-serif !important;
}
@media (min-width: 300px) and (max-width: 400px) {

    .page-not-found-text2 {
        color: #2B2A2A; 
        font-weight: 500;
        font-size: 1.25em!important;
        font-family: 'poppins', sans-serif !important;
    }

}

// iPhone 5/SE  
@media only screen and (min-width: 320px) 
  and (max-height: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .page-not-found-text2 {
        color: #2B2A2A; 
        font-weight: 500;
        font-size: 1em!important;
        font-family: 'poppins', sans-serif !important;
    }

  }



.page-not-found-text-subcontainer {
    margin-top: 5%;
}