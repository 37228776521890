* {
  outline: none !important;
}

.taste-main-container {
  width: 100%;
  height: 100vh;
}

.back-btn-recommended-wines {
  width: 3%;
}

.edit-taste-pref-card-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {
  .btn-grp {
    width: 80%;
    margin-left: 10%;
  }

  .edit-taste-pref-card-container {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .edit-wine-type-card {
    width: 47% !important;
  }

  .taste-header {
    width: 100% !important;
    height: 14% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }

  .taste-text {
    margin-left: 5%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .taste-header {
    width: 100%;
    height: 14%;
    background-color: #a84551;
    align-content: center !important;
    // z-index: -1!important;
  }

  .taste-text {
    margin-left: 5%;
    font-size: 1.5em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
}

.card-header {
  text-align: left;
  font: SemiBold Poppins;
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

.result-container {
  margin-right: 9px;
  margin-top: 5px;
}

.results-selected {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid#a84551;
}

.results-not-selected {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #a84551 !important;
  background-color: #ffffff !important;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid#a84551;
}

.save-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 80%;
  border-radius: 2px;
}

.cancel-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #a84551 !important;
  background-color: #fff !important;
  width: 80%;
  border-radius: 2px;
  border: 1px solid#a84551;

  // margin-right: 10%;
  // margin-left: 10%;
  // font-family: "poppins", sans-serif !important;
  // font-size: 1.5em !important;
  // color: #a84551 !important;
  // background-color: #ffffff !important;
  // width: 80%;
  // border: 1px solid#a84551;
  // border-radius: 2px;
}

.display-results-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  //flex-wrap: wrap;
  //padding: 10px;
}

.btn-grp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.cancel-btn-container {
  width: 50%;
}

.save-btn-container {
  width: 50%;
}
