.shpng-adrs-label {
    letter-spacing: 0.01px;
    color: #646363;
    text-transform: uppercase;
    opacity: 1;
}

.shpng-adrs-div {
    // margin-top: 2.5%;
}
.outer-container-div {
    // background: #F2F2F2 0% 0% no-repeat padding-box;
    padding: 3%;
    width: 100%;
    // height: 100vh;
    background: #F2F2F2 0% 0% no-repeat padding-box;
}
.top-outer-container-div {
    width: 100%;
    height: 100vh;
    background: #F2F2F2 0% 0% no-repeat padding-box;
}
.default-address-div {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #00000029; 
    border: 1px solid #cccccc; 
    border-radius: 5px; 
    font-size: 16px;
}

.inner-address {
    color: #6A6A6A;
}
.name-font {
    font-size: 17px;
}
.modify-add-address-btn {
    // height: 33px;
    /* text-align: center; */
    /* margin-left: 30px; */
    letter-spacing: 0px;
    font-family: "poppins", sans-serif !important;
    font-size: 16px;
    color:#a84551;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid#a84551;
    border-radius: 2px;
    width: 100%;
}

.paym-details-div {
    margin-top: 6%;
}

.total-brkup-div {
    display: flex;
    flex-direction: row;
}

.brkup-amnt-div {
    margin-left: auto;
}

@media (min-width: 1025px) {
    .paym-details-div {
        margin-top: 2.5% !important;
    }
    .applied-promo-div {
        margin-top: 2.5% !important;
    }
    .outer-container-div {
        padding: 1.5% 3% 3% 3% !important;
    }
}
@media (min-width: 768px) {
    .paym-details-div {
        margin-top: 3% !important;
    }
    .applied-promo-div {
        margin-top: 3% !important;
    }
}

.brkup-amnt-color {
    color: #2B2B2B;
}
.brder-btm-brkup {
    border-bottom: 1px solid #CCCCCC;
}
.ttl-amnt-color {
    color: #000000;
}

.applied-promo-div {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #00000029;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 6%;
    padding: 10px 17px 10px 17px;
}
.applied-promo-code-div {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: center; */
    // background: #ffffff 0% 0% no-repeat padding-box;
    // box-shadow: 5px -6px 10px #9A9A9A33;
    // padding: 10px 17px 10px 17px;
    // color: #2B2B2B;
    // font-size: 14px;
    // border: 1px solid #cccccc;
    // border-radius: 5px;
}
.applied-promo-code-label {
    margin-left: 23px;
    color:#a84551;
}

.bottom-scroll-margin {
    // margin-bottom: 20%;
}

.overall-top-container {
    width: 100%;
    height: 100vh;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    // overflow: scroll;
}
// .overall-top-container::after {
//     content: "";
//     display: block;
//     height: 100px;
//     width: 100%;
// }
.outer-container-div::after {
    content: "";
    display: block;
    height: 50px;
    width: 100%;
}
.promo-code-amnt-color {
    color: #989797;
}

.new-addrss-err-font-size {
    font-size: 14px !important;
}
.disabled-btn-css {
    opacity: 1 !important;
    background-color: #d68c8c !important;
}
.new-billing-chckbox {
    margin-top: 2px !important;
    /* padding: 116px; */
    width: 15px !important;
    height: 15px !important;
}
.blng-chckbox-div {
    display: flex;
    /* justify-content: center; */
    // align-items: center;
}