.search-wine-for-dish-main-container {
  width: 100%;
  height: 100vh;
}
.recommendation-input-error-message {
  font-size: 1.25em !important;
  color: red;
  font-family: "poppins", sans-serif !important;
}

.srch-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 92%;
  padding-top: 5px;
}

.srch-bar-input {
  color: #343434;
  background: #EFEFEF;
  opacity: 1;
  border-radius: 100px;
  border: none;
  height:35px;
  width: 100%;
}

.search-main-swfd{
  margin-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  max-height: 75%;
  min-width: 90%;
  border-radius: 10px;
  padding-top: 1rem;
  //box-shadow:  0 4px 8px 0 rgba(192, 191, 191, 0.2), 0 6px 20px 0 rgba(170, 169, 169, 0.19);
  background-color: white;
  display: table;
}



.search-btn {
  color: white;
  background: #a84551;
}

.search-btn-tab {
  color: #ffffff;
  width: 50%;
  border-color: rgba(#7D7D7D, 1.0);
}

.search-btn:hover {
  color: white !important;
  text-decoration: none;
}
@media (min-width: 1025px) {
  .switch-winery-description {
    width: 93% !important;
  }

  .chat-switch-winery {
    width: 5% !important;
    text-align: right;
    padding-right: 0 !important;
  }

  .switch-winery-icon {
    width: 65% !important;
  }

  .search-box {
    width: 30% !important;
  }
  .search-wine-for-dish-main-container,
  .bottom-navigation {
    margin-top: 5%;
    // overflow: auto;
  }
  .search-wine-for-dish-container {
    width: 100% !important;
    overflow: auto;
    margin-top: 5% !important;
  }

  .search-wine-for-dish-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }

  .search-wine-for-dish-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }

  .search-wine-for-dish-main-container {
    // box-shadow: 0px 0px 15px 15px #d2d2d2;
    // margin-left: auto !important;
    // margin-right: auto !important;
    // width: 30% !important;
    // height: 80vh !important;
    // border: 2px solid #c0c0c0;
    // overflow: auto;
  }
  .search-wine-for-dish-header {
    width: 100% !important;
    height: fit-content !important;
    // background-color:#a84551!important;
    align-content: center !important;
    // background-repeat: no-repeat;
  }

  .search-wine-for-dish-text {
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
    margin-left: 5%;
  }
  .search-wine-for-dish-main-container {
    height: 70vh !important;
  }
  .bottom-navigation {
    // margin-bottom: 16.5%!important;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .user-search-wine-for-dish-pic {
    width: 80px !important;
    height: 80px !important;
    border-radius: 100px !important;
  }
}

.search-wine-for-dish-header {
  width: 100%;
  // height: 14%;
  // background-color:#a84551;
  align-content: center !important;
  // z-index: -1!important;
  // background-image: url('../../../assets/images/search-screen-bg.png');
  background-color: #193c38;
  // background-repeat: no-repeat;
}

.search-wine-for-dish-text {
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-left: 5%;
}

.search-wine-for-dish-empty-page-container {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-top: 15%;
  justify-content: center;
  align-items: center;

  overflow: auto;
  margin-bottom: 25%;
}

.search-wine-for-dish-container {
  // // width: 90%; - for recommendationhistorycard
  // width: 100%;
  // // padding: 5%;
  // position: absolute;
  // overflow: auto !important;
  // display: flex;
  // flex-direction: column;
  // margin-top: 5%;
  // justify-content: center;
  // align-items: center;
  // overflow: auto;
  // // margin-bottom: 25%;

  overflow: auto !important;
  margin-top: 5%;
}

.search-wine-for-dish-container:after {
  content: "";
  display: block;
  height: 5rem;
  width: 100%;
}

@media (min-width: 400px) {
  // >=400

  .search-wine-for-dish-pic,
  .user-search-wine-for-dish-pic {
    // width: 40%!important;
    // /* height: 50%; */
    // border-radius: 50%!important;

    // css added in px for now
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }
}

.search-wine-for-dish-name {
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: 600 !important;
}
.search-wine-for-dish-name:first-letter {
  text-transform: uppercase;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
}

.top-navigation {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: #ffffff;
  padding: 0.5rem;
}

.user-search-wine-for-dish-pic-container {
  display: flex;
  justify-content: center;
}

// .nav-link {
//     background-color: white !important;
//     color: white !important;
// }

.search-wine-for-dish-subheader {
  display: flex;
  justify-content: space-between;
  padding-top: 4%;
  align-items: center;
  padding-bottom: 4%;
}

.input-search-box {
  border: none;

  width: 80%;
}

.input-search-box-wine {
  border: none;
  padding: 0px;
  width: 90%;
}

.input-search-box:focus {
  outline: none;
}

.search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2%;

  // margin-top: -3.5vh;
  width: 100% !important;
  // position: absolute;
}

.search-wine-form-row-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 50px !important;
  text-transform: capitalize;
  border: 1px solid #1d1d1d1a;

  display: flex;
  align-items: center;
  width: 90%;
}

.search-wine-form-row-recommand-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: auto;
  text-transform: capitalize;
  border: 1px solid #1d1d1d1a;
  // display: flex;
  align-items: center;
  width: 90%;
}

.search-wine-form-tab-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 50px !important;
  text-transform: capitalize;
  // border: 1px solid #1d1d1d1a;

  display: flex;
  // align-items: center;
  width: 98%;
}

.search-wine-form-tab-desktop-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 50px !important;
  text-transform: capitalize;
  // border: 1px solid #1d1d1d1a;

  display: flex;
  // align-items: center;
  width: 93%;
}

@media (min-width: 300px) and (max-width: 400px) {
  .search-wine-for-dish-empty-page-container {
    margin-top: 0% !important;
  }
}
@media (min-width: 375px) and (max-width: 812px) {
  .search-wine-for-dish-empty-page-container {
    margin-top: 10% !important;
  }
}

// iPhone 5/SE
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .search-wine-for-dish-empty-page-container {
    margin-top: 0% !important;
  }

  .search-wine-form-row-input {
    font-size: 1em !important;
  }

  .search-wine-for-dish-text {
    font-size: 1.25em !important;
  }
}

.recommendation-input {
  color: #959292;
  font-style: italic;
  // justify-content: space-between;
  font-size: 1.25em;
}

.recommendation-tab-input {
  color: #959292;
  font-size: 1.25em;
  margin-bottom: 1rem;
  margin: 0 auto;
  box-shadow: inset 0 0px 0px rgb(0 0 0 / 0%);
}
.search-wine-for-dish-pair-anything-logo {
  width: 10%;
  margin-right: 5%;
}

.search-wine-basedon-dish-btn {
  font-family: "poppins", sans-serif !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recommendation-history-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 93%;
  border-left: 1pt solid white;
  // border-image: url('../../../assets/images/call-btn.svg') 25 30 10 20 repeat;;
}

.submit-recommendation-container {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4%;
}
.recommendation-input-error-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
}

.recommendation-history-main-container,
.empty-page-main-container {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.switch-winery-accordion-item__line {
  padding: 0 0 0 0 !important;
}

.switch-winery-name {
  margin-left: 5%;
  font-family: "poppins", sans-serif !important;
  color: #a84551 !important;
  font-size: 1em;
  text-transform: capitalize;
}
.switch-winery-logo {
  width: 15%;
}

.switch-winery-description {
  font-family: "poppins", sans-serif !important;
  color: #2b2a2a !important;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}

.switch-winery-accordion-item__content {
  // // margin-left: 12%;
  // margin-bottom: 10%;
  // width: 75%;
  text-align: justify;
}

.switch-winery-accordion-item__inner-container {
  display: inherit;
  width: 30%;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-right: 6%;
}

.switch-winery-accordion-item__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
}

.switch-winery-icon {
  width: 83%;
}

.chat-winery-icon-disabled {
  width: 83%;
  opacity: 0.5;
}

.switch-winery-accordion-item__icon {
  color: #a84551;
}

.switch-winery-summary-container {
  align-items: center;
  display: flex;
  justify-content: start;
}

.reco-main {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.recommendations-custom-line {
  opacity: 0.5;
  margin-left: 6%;
  width: 2px;
  background-color: #a84551;
  background-image: radial-gradient(#cd4545, #fa9c9c);
  // animation: right .3s ease, left-end .4s ease .4s;
}

.search-wine-empty-page-main-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.hamburger-menu {
  padding-left: 4%;
}

.new-logo-header-design {
  // width: 15%;
  margin-right: unset !important;
  padding-right: 5px;
}
.new-search-wine-for-dish-text {
  margin-left: unset !important;
}
.new-header-text {
  text-align: center;
}

// recommandation tab

$hover-input-color: rgba(88, 159, 207, 0.835);
$solid-color: rgba(15, 15, 15, 0.2);
$result-text-color: #424444;

@media (min-width: 1200px) {
  .container {
    width: 300px;
  }
}

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin-bottom: 1em;
    margin-top: 5em;
    font-size: 27px;
    color: #cb2c59;
  }

  .content {
    input {
      width: 15em;
      height: 42px;
      border: 0;
      border-radius: 3px;
      box-shadow: 0px 0px 15px 3px $solid-color;
      padding: 0 16px;
      font-size: 18px;
      outline: 0;
      color: #2b2a2a;
      transition: all, 200ms ease-in-out;
      &::placeholder {
        color: grey;
      }
      &:focus {
        box-shadow: 0px 0px 19px 3.1px $hover-input-color;
      }
    }
  }
}

.dropText {
  padding-left: 15px;
  padding-right: 15px;
  font-style: italic;
  color: #000;
  font-family: "poppins", sans-serif !important;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

//Popup
.popup {
  width: inherit;
  min-height: 5em;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 15px 2px $solid-color;
  margin-top: 7px;
  border-radius: 0px 0px 5px 5px;
  justify-content: center;
  align-items: center;
  position: relative;

  .container {
    .content {
      height: 100%;
      padding: 0 0 10px 0;

      .item {
        color: $result-text-color;
        cursor: pointer;
        transition: all, 230ms ease-in;
        &:hover {
          background-color: #aacbe06e;
        }
      }
    }
    .footer {
      position: absolute;
      font-size: 13px;
      bottom: 3px;
      width: 99%;
      left: 0px;
      display: flex;
      border-top: 1px solid $solid-color;
      padding: 2px 3px;
      color: blue;
    }
  }
}
