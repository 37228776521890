@use "src/app/styles/theme" as s;

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stickyHeader {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 24px;
  background-color: #951901;
  text-align: center;
  z-index: 10;
}

.stickyHeaderTxt {
  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-weight: 700;
}

.stickyHeaderTxt:hover {
  color: white;
  text-decoration: underline;
}

.box {
  width: 100%;
  padding: 48px 24px;
}

.pairingImgBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
}

.backBtn {
  all: unset;
}

.backBtn:hover {
  cursor: pointer;
}

.foodImg {
  height: 192px;
  width: 192px;
  border-radius: 50%;
  object-fit: cover;
}

.wineImg {
  height: 220px;
  width: auto;
  transform: rotate(8deg) translate(-20px);
  object-fit: cover;
}

.titleTxt {
  text-align: left;
  font-family: s.$font-2;
  font-size: 30px;
  font-weight: 800;
}

.pairsWithTxt {
  color: #343434;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;

  padding: 8px 0;
}

.wineDescriptionTxt {
  padding-top: 16px;
  color: #7d7d7d;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 400;
}

.lineDivider {
  margin: 24px 0;
  width: 100%;
  height: 1px;
  background: #efefef;
}

.ratingBox {
  width: 100%;
}

.ratingTitle {
  color: #343434;
  font-family: s.$font-2;
  font-size: 16px;
  font-weight: 800;

  padding-bottom: 16px;
}

.buttonBox {
  width: 100%;
  display: flex;
  gap: 16px;
}

.likeBtn {
  all: unset;
  display: flex;
  width: 100%;
  padding: 18px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  background: #efefef;
}

.likeBtn > p {
  color: #343434;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 600;
}

.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formField {
  padding-top: 16px;
  padding-bottom: 24px;
}

.asteriskTxt {
  width: 100%;
  color: #951901;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

.formInput {
  width: 100%;
  padding: 18px 16px;
  border-radius: 8px;
  border: 1px solid rgba(125, 125, 125, 0.5);
  background: white;
}

.submitBtn {
  display: flex;
  width: 100%;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: #343434;
  border: none;
}

.submitBtnTxt {
  color: white;
  font-family: s.$font-1;
  font-size: 16px;
  font-weight: 600;
}

.grayedOut {
  background: #7d7d7d;
}

.grayedOut:hover {
  cursor: default;
}

.tyTxt {
  color: #7d7d7d;
  font-family: s.$font-1;
  font-size: 14px;
  font-weight: 400;
}
