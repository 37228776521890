@use "src/app/styles/theme" as s;

.container {
  width: 100%;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  //align-self: stretch;

  border-radius: 4px;
  background: #e9f3fd;
}

.textBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
}

.btn {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: 4px;
  border: 1px solid #1e7ed7;
  background-color: white;

  color: #1e7ed7;

  transition: color 1s, background-color 1s;

  /* P3 - 14 SemiBold */
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.btnClicked {
  color: white;
  background-color: #1e7ed7;
}

.thanksBox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.thanks {
  color: #1e7ed7;

  /* P3 - 14 SemiBold */
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
