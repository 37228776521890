// @media screen and (max-height: 450px) {
//     .sidebar {padding-top: 15px;}
//     .sidebar a {font-size: 18px;}
//   }

//   .sidebar {
//     height: 100%;
//     width: 0;
//     position: fixed;
//     z-index: 10;
//     top: 0;
//     left: 0;
//     //background-color: #111;
//     overflow-x: hidden;
//     //transition: 0.5s;
//     padding-top: 38px;
//     // width: 250px;
//     background: #FFFFFF 0% 0% no-repeat padding-box;
//     box-shadow: 4px 0px 16px #00000029;
//     animation: 0.5s ease-out 0s 1 slideInFromLeft;
//     -webkit-animation: 0.5s ease-out 0s 1 slideInFromLeft;
// }
  
//   .sidebar a {
//     padding: 8px 8px 8px 32px;
//     text-decoration: none;
//     font-size: 25px;
//     color: #818181;
//     display: block;
//     transition: 0.3s;
//   }
  
//   .sidebar a:hover {
//     color:#a84551;
//   }
  
//   .sidebar .closebtn {
//     // position: absolute;
//     // top: 0;
//     // right: 25px;
//     // font-size: 36px;
//     // margin-left: 50px;
//     position: absolute;
//     top: 0;
//     right: 13px;
//     font-size: 30px;
//   }
  .side-nav-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .side-nav-logo-width {
      width: 56%;
  }
  .side-nav-links-container {
      padding-top: 38px;
  }
  .nav-link-container {
    color: #2B2B2B;
    font-weight: 400;
    font-size: 17px;
    padding: 8% 15%;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
  }
  .nav-right-arrow {
    margin-top: 6px;
    margin-left: auto;
  }
  @media (min-width: 1025px) {
    // .sidebar {
    //   // width: 317px !important;
    // }
  }