.chat-main-container {
  overflow-x: hidden;
}
.chat-header {
  padding: 5%;
  //display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: white;
}

.chat-title {
  font-weight: 500 !important;
  margin-left: 5%;
  font-size: 1.75em;
  color: #2b2a2a;
}

.chat-container {
  display: flex;
  height: calc(100vh - 57px);
  flex-direction: column;
}

.confirm-chat-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 100%;
}

// .nav-link {
//   color: #1bc715;
//   background-color: #e0ffdf;
//   font-size: 0.75em;
//   border-radius: 100px;
// }

.online-capsule-container {
  float: right;
}

//

.conversations {
  margin-bottom: 5%;
}

@media screen and (max-width: 991px) {
  .conversations {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.conversations {
  display: flex;
  // height: calc(100vh - 57px);
  flex-direction: column;
}
.conversations .message-header {
  background: #fff;
  padding: 0.5rem;
  box-shadow: 0 1px 0 0 rgba(61, 70, 79, 0.075);
  z-index: 1;
}
.conversations .conversations-body {
  padding: 1.5rem 1rem;
}
.conversations .conversations-body {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem 2rem;
}

.conversations .conversations-content {
  // margin-top: 15%;
  padding: 1.5em;
}
// .bg-white {
//     background-color: #fff!important;
// }

.messages-wrapper .messages-body .message-content-wrapper,
.conversations-body .message-content-wrapper {
  display: inline-block;
  width: 100%;
}

.conversations .messages-form {
  margin: 0;
  // padding: .7rem 1rem;
  background: #fff;
}
.messages-form {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  // margin-top: 30px;
  // border-top: 1px solid #eee;
  // padding-top: 20px;
}

.conversations-body .message-in .message-body .message-content {
  background: #1d1d1d1a;
  padding: 12px 15px;
  border-radius: 5px;
  margin-left: 10px;
  position: relative;
  width: fit-content;
  overflow-wrap: anywhere;
}
.message-in .message-body .date {
  margin-left: 10px;
  margin-top: 8px;
  font-size: 11px;
  color: #83848a;
  // padding-left: 12px;
}
.message-out .message-body .date {
  margin-top: 8px;
  font-size: 11px;
  color: #83848a;
  margin-right: 10px;
  float: right;
}

.chat-winery-logo-placeholder {
  width: 2.5em;
  border-radius: 100px;
}

.avatar-sm .border {
  // border-width: 3px !important;
}
.avatar-img {
  width: 20% !important;
}
.avatar .border {
  // border-width: 3px !important;
}
.message-avatar {
  vertical-align: none;
  // float: right!important;
}

.conversations-body .message-out .message-body .message-content {
  background: #a84551;
  padding: 12px 15px;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
  width: fit-content;
  overflow-wrap: anywhere;
}

.conversations-body {
  margin-bottom: 25px;
}

.message-body {
  margin-top: 1%;
}

.conversations-body .message-content-wrapper {
  display: inline-block;
  width: 100%;
}

.conversations-body .message-out {
  float: right;
  margin-left: 40px;
}

.conversations-body .message {
  display: table;
  table-layout: fixed;
  padding: 8px 0;
}

.conversations .messages-form {
  margin: 0;
  // padding: .7rem 1rem;
  background: #fff;
}

.input-solid {
  background: #ffffff !important;
  border-color: #ffffff !important;
  border: none;
}
.input-pill {
  // border-radius: 1.3rem !important;
}
.messages-form-control {
  width: 100%;
  font-size: 18px;
  border-color: #ebedf2;
  // padding: .6rem 1rem;
  height: inherit !important;
  margin-left: 5%;
}

.content {
  color: #ffffff;
  font-size: 1.25em;
}
.messages-form-send {
  margin-right: 5%;
}

.messages-form-send-text {
  color: #a84551;
}

.messages-form {
  // position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  // padding: 15px;
}

.winery-admin-content {
  color: black;
  font-size: 1.25em;
}

.input-main-container {
  // margin-top: 5%;
}

.bottom-chat-container {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.75em !important;
  // margin-bottom: 15%; - if bottom nav is needed
}

.input-solid:focus {
  outline: none !important;
  border: none !important;
}

.chat-error-message-container {
  padding: 5%;
  text-align: center;
  margin-top: 50%;
}

.chat-not-found-page {
  margin-top: 1% !important;
}

.commonMessageDate {
  text-align: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
}

.business-timing {
  font-size: 1em;
  color: grey;
  margin-left: 10%;
}
.width-88 {
  width: 88% !important;
}
.socket-network-error {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #ded5d5 0% 0% no-repeat padding-box; */
  /* border: 1px solid #CDCDCD; */
  border-radius: 3px;
  height: 2em;
  /* padding: 10px; */
  overflow: visible;
  font-size: 14px;
}
.socket-network-error-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  /* margin-left: 2%; */
  /* margin-left: 60px; */
  background: #ded5d5 0% 0% no-repeat padding-box;
  border: 1px solid #cdcdcd;
  /* padding-left: 10px; */
  // margin-top: 1%;
  /* margin-left: 4%; */
  width: 111%;
}
.refresh-now {
  color: #212529 !important;
  text-decoration: underline;
  padding-left: 5px;
}
.chat-new-arrow {
  width: 16px;
}
.new-chat-header {
  top: 0;
  position: fixed;
  z-index: 100;
}
.dummy-margin {
  margin-left: 1.5rem;
}
.new-business-timings-css {
  font-size: 1em;
  color: #ffffff;
  // margin-left: 8%;
  font-weight: normal !important;
}
