
.shipping-address-header-label {
  text-align: center;
  // margin-top: 1em;
  margin-bottom: 1em;
  color: #2b2b2b;
  font-weight: 700;
  font-size: medium;
}
.shipping-addresses {
  display: flex;
  flex-direction: row;
  margin-left: 1em;
  margin-right: 1em;
  color:#a84551;
  font-size: medium;
  font-weight: 600;
}
.add-new-address {
  margin-left: auto;
}
.add-new-icon {
  width: 16%;
  margin-bottom: 0.2em;
  margin-right: 0.2em;
}
.empty-data {
  text-align: center;
}
// .container-set {
//   width: 100%;
//   height: 100vh;
// }
.stepper-new-margin {
  margin-bottom: 1em !important;
}
.address-select-error-div {
  bottom: 50px;
  width: 95%;
  // border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  z-index: 5;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  background: #F2F2F2 0% 0% no-repeat padding-box !important;
  /* box-shadow: 5px -6px 10px #9A9A9A33; */
  padding: 10px 17px 10px 17px;
  color: red;
  font-size: 16px;
  justify-content: center;
}

