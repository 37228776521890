.age-confirmation-container {
  margin-top: 15px;
}
.age-confirm-button {
  width: 50%;
  margin-bottom: 10px;
  margin-top: 10%;
  align-content: center;
  font-family: "poppins", sans-serif !important;
  font-size: 1.1 !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  justify-content: center;
}
.age-confirm-head {
  margin-top: 10vh;
}

.age-container {
  height: 70vh;
  display: flex;
  // align-items: center;
}

.age-text {
  margin-bottom: 10% !important;
}
