.profile-main-container {
  width: 100%;
  height: 100vh;
}

@media (min-width: 1025px) {
  .profile-main-container,
  .bottom-navigation {
    overflow: hidden;
  }

  .points-earned-main-container {
    margin-top: 0% !important;
  }

  .profile-container {
    width: 100% !important;
    // margin-left: 15% !important;
    margin-top: auto !important;
  }
  .profile-option-container {
    display: flex;
    justify-content: space-between;
    padding: none !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }

  .profile-option-container-disabled {
    display: flex;
    justify-content: space-between;
    padding: none !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
    opacity: 0.5;
  }
  .profile-name {
    color: #2b2a2a;
    font-size: 1em !important;
    font-weight: 600 !important;
  }

  .profile-pic {
    width: 50px !important;
    height: 50px !important;
    border-radius: 100px;
  }

  .profile-main-container {
    margin-left: auto !important;
    margin-right: auto !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .profile-header {
    width: 100% !important;
    height: 15% !important;
    background-color: #193c38 !important;
    align-content: center !important;
  }

  .profile-text {
    margin: 4% !important;
    float: left;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }

  .profile-cart-btn {
    float: right;
    margin: 6% !important;
  }

  .profile-signout-btn {
    width: 3% !important;
    float: right;
  }

  .bottom-navigation {
    // margin-bottom: 16.5%!important;
  }

  .click-here-text-label {
    font-weight: 400 !important;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .click-here-text-label {
    font-weight: 500 !important;
  }
  .user-profile-pic {
    width: 80px !important;
    height: 80px !important;
    border-radius: 100px !important;
  }

  .profile-card-personal-details-subheader {
    margin-bottom: 0% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .profile-option-container {
    display: flex;
    justify-content: space-between;
    padding: 1% !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }

  .profile-option-container-disabled {
    display: flex;
    justify-content: space-between;
    padding: 1% !important;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
    opacity: 0.5;
  }
}

.profile-header {
  width: 100%;
  height: 18%;
  background-color: #193c38;
  align-content: center !important;
  // z-index: -1!important;
}

.profile-text {
  margin: 7%;
  float: left;
  font-size: 1.5em;
  color: #ffffff;
  font-family: "poppins", sans-serif !important;
  font-weight: 600 !important;
}

.profile-cart-btn {
  float: right;
  margin: 10%;
}

.profile-container {
  // margin-top: -9vh;
  background-color: #f2f2f2;
  width: 100%;
  // position: absolute;
  // min-height: 620px;
}

.profile-container:after {
  content: "";
  display: block;
  height: 130px;
  width: 100%;
}

.profile-card-personal-details-subheader {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-card-personal-details-subheader {
  // animation: 1s ease-out 0s 1 slideInFromLeft;
  // animation: ContentFadeIn .8s 0.5s forwards;
}

@media (min-width: 400px) {
  // >=400

  .profile-pic,
  .user-profile-pic {
    // width: 40%!important;
    // /* height: 50%; */
    // border-radius: 50%!important;

    // css added in px for now
    width: 100px !important;
    height: 100px !important;
    border-radius: 100px !important;
  }

  .profile-option-container {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
  }

  .profile-option-container-disabled {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    color: #959292;
    font-weight: 400;
    font-size: 1.15em;
    margin-top: 3%;
    margin-left: 3%;
    opacity: 0.5;
  }
}

.profile-name {
  color: #2b2a2a;
  font-size: 1.5em;
  font-weight: 600 !important;
}
.profile-name:first-letter {
  text-transform: uppercase;
}

.profile-email {
  color: #959292;
  font-size: 1em;
}

.profile-card {
}
.profile-row {
  // width: 100%;
  // position: absolute;
  bottom: 0;
}

.profile-order-cart-details {
  margin-left: 1px;
  float: left;
  width: 49%;
  border: 1px solid #959292;
  border-top-width: thin;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 3%;
  position: relative;
}

.profile-orders,
.profile-cart {
  color: #959292;
}

.profile-right-arrow-btn {
  float: right;
}

//   .profile-option-container {
//     //   border-bottom-style: solid;
//     //   border-bottom-width: thin;
//     //   border-bottom-color: #D5D5D5;
//       display: flex;
//       justify-content: space-between;
//       padding: 2%;
//       color: #959292;
//       font-weight: 400;
//       font-size: 1.15em;
//       margin-top: 3%;
//       margin-left: 3%;
//   }

.profile-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  color: #959292;
  font-weight: 400;
  font-size: 1.15em;
  margin-top: 3%;
  margin-left: 3%;
}

.profile-container:after {
  content: "";
  display: block;
  height: 130px;
  width: 100%;
}

.profile-signout-text {
  float: left;
  margin-left: 3%;
}

.profile-signout-btn {
  float: right;
  width: 4%;
  float: right;
  margin-left: 72%;
}

.bottom-navigation {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  padding: 0.5rem !important;
  z-index: 2;
}

.consumer-image-input {
  display: none !important;
}

.user-profile-pic-container {
  display: flex;
  justify-content: center;
  // animation: ImageFadeIn .8s 1s forwards;
}

.add-pic-image {
  width: 5%;
  height: 5%;
}

// .nav-link {
//   background-color: white !important;
//   color: white !important;
// }
.points-earned-main-container {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
.points-earned-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.points-value {
  color: #e9112c;
  font-weight: bold;
  font-family: "poppins", sans-serif !important;
  margin-left: 3%;
}

.chat-count {
  margin-left: 50%;
  color: red !important;
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .chat-count {
    margin-left: 40% !important;
  }

  .profile-signout-btn {
    width: 5% !important;
    margin-left: 65% !important;
  }
}

.crop-container {
  width: 100%;
  height: 400px;
  position: relative;
}

.controls {
  margin: auto;
  width: 70%;
  display: flex;
  align-items: center;
}

.slider {
  padding: 30px 0px;
}

.button {
  text-align: center;
}
.sliderLableZoom {
  margin-right: 3rem;
  font-size: 1.3rem;
}
.sliderLableRotation {
  margin-right: 1.5rem;
  font-size: 1.3rem;
}

.MuiButton-root {
  font-size: 1.3rem !important;
}
