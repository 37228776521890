* {
  outline: none !important;
}

.taste-main-container {
  margin: auto;
  width: 100%;
  height: 100vh;
}

.back-btn-recommended-wines {
  width: 3%;
}

.edit-taste-pref-card-container {
  // position: relative;
  //height: 100% !important;
  margin: auto !important;
  //margin-top: 160px !important;
  justify-content: center;
  justify-self: center;
  justify-items: center;

  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: center;

  //overflow-y: auto;
}
.edit-wine-type-card {
  // height: 100% !important;
  width: 80% !important;
}

.card-header {
  text-align: left;
  font: SemiBold Poppins;
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

.solution-header {
  display: flex;
  flex-direction: row;
  font: SemiBold Poppins;
  font-size: 14px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}
.card-header-2 {
  display: flex;
  flex-direction: column;
  font: SemiBold Poppins;
  font-size: 16px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

// .result-container {
//   margin-right: 9px;
//   //margin-top: 5px;
// }

.results-selected {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid#a84551;
}

.small-selected {
  font-size: 12px !important;
}

.results-not-selected {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #a84551 !important;
  background-color: #ffffff !important;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid#a84551;
}
.show-edit-sucess {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  margin-left: 10%;
  margin-top: -60px;
  height: 350px;
  font-size: 17px;
  text-align: center;
  font-family: "poppins", sans-serif !important;
}
// .result-container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-between;
// }

// .results-correct {
//   margin-top: 2%;
//   margin-left: 2%;
//   font-family: "poppins", sans-serif !important;
//   font-size: 14px;
//   color: #ffffff !important;
//   background-color:#a84551 !important;
//   width: fit-content;
//   border-radius: 20px;
//   border: 1px solid#a84551;
// }

.results-incorrect {
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: white !important;
  background-color: black !important;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid black;
}

.save-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.2em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 80%;
  //height: 10%;
  border-radius: 2px;
}

.next-question-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 0.9em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 80%;
  //height: 10%;
  border-radius: 2px;
}
.return-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 0.9em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 80%;
  border-radius: 2px;
}

.cancel-btn {
  margin-right: 10%;
  margin-left: 10%;
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #a84551 !important;
  background-color: #ffffff !important;
  width: 80%;
  border: 1px solid#a84551;
  border-radius: 2px;
  margin: auto;
}

.display-results-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  //flex-wrap: wrap;
  //padding: 10px;
}
.display-results-row-2 {
  justify-content: left;
  justify-items: left;
  justify-self: left;
  align-items: left;
  align-content: left;
  align-self: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px;
}
.btn-grp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.cancel-btn-container {
  width: 50%;
}

.save-btn-container {
  width: 50%;
  margin: auto;
}
.next-question-btn-container {
  width: 50%;
  margin: auto;
}
.inner-div {
  background: #ffffff;
  overflow-y: auto;
}

.content-style {
  height: 100% !important;
  font-family: Poppins, Regular sans-serif !important;
  font-size: 18px !important;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  overflow-y: auto;
  //margin: auto;
  padding: 50px 20px 30px 20px;
}

.font-bold {
  font-weight: bold !important;
}
.double-column {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  flex-wrap: wrap !important;
}
.line-break {
  width: 100%;
}
.exit-button {
  float: left;
  position: absolute !important;
  margin: -10% 5% 0 1% !important;
  bottom: 95%;
  right: 83%;
  overflow-y: hidden;
  overflow-x: hidden;

  background: none;
  border: none;
}

.exit-button-desktop {
  margin: 0 10% 0 1%;
  position: absolute;
  overflow-y: hidden;
  overflow-x: hidden;
  bottom: 90%;
  right: 85%;
  background: none;
  border: none;
}

.discount-percent-off::-webkit-scrollbar {
  display: none;
}
.discount-code-style::-webkit-scrollbar {
  display: none;
}
.content-style::-webkit-scrollbar {
  display: none;
}
.inner-div::-webkit-scrollbar {
  display: none;
}

@media (min-width: 200px) and (max-width: 328px) {
  .content-style {
    //height: 100% !important;
    font-family: Poppins, Regular sans-serif !important;
    font-size: 12px !important;
    background: #ffffff;
    margin: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    overflow-y: auto;

    //margin: auto;
    //margin: auto;
    padding: 50px 20px 30px 20px;
  }

  .btn-grp {
    width: 100%;
    //margin-left: 10%;
  }
  .taste-header {
    //margin-top: 5%;
    width: 100%;
    height: 1%;
    background-color: #a84551;
    align-content: center !important;
    // z-index: -1!important;
  }

  .taste-text {
    margin-left: 5%;
    font-size: 1.5em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .results-incorrect {
    font-size: 8px;
    width: fit-content;
  }
  .results-selected {
    font-size: 8px;
    width: fit-content;
  }
  .results-not-selected {
    font-size: 8px;
    width: fit-content;
  }
  .save-btn {
    margin: auto;
    font-size: 0.8em !important;
    width: 100%;
    border-radius: 2px;
  }
  .cancel-btn {
    margin: auto;
    font-size: 0.8em !important;
    width: 100%;
    border-radius: 2px;
  }
  .next-question-btn {
    margin: auto;
    font-size: 0.8em !important;
    width: 100%;
  }
  .next-question-btn-container {
    width: 100%;
    margin: auto;
  }

  .display-results-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    //flex-wrap: wrap;
    //padding: 10px;
  }
  .display-results-row-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    //padding: 10px;
  }

  .card-header {
    font-size: 12px;
  }
  .btn {
    // max-width: 12em;
    white-space: normal;
  }
  // .result-container {
  //   margin-right: 9px;

  // }
}

@media (min-width: 330px) and (max-width: 480px) {
  .btn-grp {
    width: 100%;
    //margin-left: 10%;
  }
  .taste-header {
    //margin-top: 5%;
    width: 100%;
    height: 1%;
    background-color: #a84551;
    align-content: center !important;
    // z-index: -1!important;
  }

  .taste-text {
    margin-left: 5%;
    font-size: 1.5em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .results-incorrect {
    font-size: 12px;
    width: fit-content;
  }
  .results-selected {
    font-size: 12px;
    width: fit-content;
  }
  .results-not-selected {
    font-size: 12px;
    width: fit-content;
  }
  .save-btn {
    margin: auto;
    font-size: 1em !important;
    width: 80%;
    border-radius: 2px;
  }
  .cancel-btn {
    margin: auto;
    font-size: 1em !important;
    width: 80%;
    border-radius: 2px;
  }
  .next-question-btn {
    margin: auto;
    font-size: 1em !important;
    width: 100%;
  }
  .next-question-btn-container {
    width: 80%;
    margin: auto;
  }

  .display-results-row {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    //flex-wrap: wrap;
    //padding: 10px;
  }
  .display-results-row-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    //padding: 10px;
  }

  .card-header {
    font-size: 14px;
  }
  .btn {
    // max-width: 12em;
    white-space: normal;
  }
  // .result-container {
  //   margin-right: 9px;

  // }
}

@media (min-width: 1024px) {
  .btn-grp {
    width: 80%;
    margin-left: 10%;
  }

  // .edit-taste-pref-card-container {
  //   margin-right: 10%;
  //   margin-top: 10%;
  //   flex-direction: row !important;
  //   flex-wrap: wrap;

  // }

  // .edit-wine-type-card {

  //   width: 80% !important;
  // }

  .taste-header {
    width: 100% !important;
    height: 14% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }

  .taste-text {
    margin-left: 5%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
  .next-question-btn {
    margin-right: 10%;
    margin-left: 10%;
    font-family: "poppins", sans-serif !important;
    font-size: 0.9em !important;
    color: #ffffff !important;
    background-color: #a84551 !important;
    width: 100%;
    border-radius: 2px;
  }
}
