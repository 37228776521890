.shopping-cart-container {
  width: 100%;
  height: 100vh;
}
.current-shipping-address {
  display: flex;
  padding: 12px;
  background: #3f3d56 0% 0% no-repeat;
  box-shadow: 0px 2px 10px #00000029;
  opacity: 0.8;
}
.shipping-location {
  color: white;
  margin-left: 2rem;
  font-size: 1.5rem;
}
.empty-cart {
  margin-top: 17vh;
}
.empty-cart-error-msg-div {
  text-align: center;
}
.shopping-cart-heading {
  margin-right: 11rem;
  margin-left: 0%;
}

.dot-cart-stepper {
  background-color: transparent;
  border-radius: 50%;
  /* display: inline-block; */
  /* top: 14px; */
  /* display: flex; */
  /* align-items: center; */
  /* vertical-align: middle; */
  /* text-align: center; */
  margin-top: 0px;
  border: 3px solid #bbb;
  margin-left: -5px;
  height: 12px;
  width: 12px;
}
.dot-cart-stepper-select {
  background-color: transparent;
  border-radius: 50%;
  /* display: inline-block; */
  /* top: 14px; */
  /* display: flex; */
  /* align-items: center; */
  /* vertical-align: middle; */
  /* text-align: center; */
  margin-top: 0px;
  border: 3px solid #1d4403;
  margin-left: -5px;
  height: 12px;
  width: 12px;
}
.dot-cart-non-select-stepper {
  background-color: #bbb;
  border-radius: 50%;
  /* display: inline-block; */
  /* top: 14px; */
  /* display: flex; */
  /* align-items: center; */
  /* vertical-align: middle; */
  /* text-align: center; */
  margin-top: 0px;
  // border: 3px solid #bbb;
  margin-left: -5px;
  height: 12px;
  width: 12px;
}
.line:after {
  right: 0;
}

.separator-stepper {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "poppins", SemiBold 13px/24px;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  width: 65%;
}
.separator-stepper::before {
  content: "";
  flex: 1;
  border-bottom: 1px solid #9a9a9a;
}
.separator-stepper::before {
  margin-right: 0.25em;
}
.separator-stepper::after {
  margin-left: 0.25em;
}
.cart-name-new-css {
  color: #3b3b3b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}

.separator-stepper-select {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "poppins", SemiBold 13px/24px;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  width: 65%;
}
.separator-stepper-select::before {
  content: "";
  flex: 1;
  border-bottom: 1px solid #1d4403;
}
.separator-stepper-select::before {
  margin-right: 0.25em;
}
.separator-stepper-select::after {
  margin-left: 0.25em;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "poppins", SemiBold 13px/24px;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #3b3b3b;
}
.separator::before {
  margin-right: 0.25em;
}
.separator::after {
  margin-left: 0.25em;
}
.cart-total {
  bottom: 0;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  z-index: 5;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  background: #ffffff 0% 0% no-repeat padding-box;
  // box-shadow: 5px -6px 10px #9A9A9A33;
}
.cart-total-value {
  /* padding: 7px 0px 1px 15px; */
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  width: 50%;
  /* height: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 9px 10px 9px 17px;
}
.total-price-label {
  text-align: left;
  font-family: "poppins", SemiBold 16px/24px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.cart-proceed-div {
  background: #a84551 0% 0% no-repeat padding-box;
  opacity: 1;
  // padding: 9px 17px 9px 9px;
  /* text-align: center; */
  /* margin-left: auto; */
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.proceed-to-next {
  // width: 3%;
  transform: rotate(180deg);
  padding-right: 1rem;
  /* margin-bottom: 0.5rem; */
  // margin-left: auto;
}
.proceed-lable {
  color: #ffffff;
  opacity: 1;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.continue-button-container {
  bottom: 0;
  position: fixed;
  align-items: center;
  width: 100%;
  font-family: "poppins", sans-serif;
  // font-size: 1.5rem;
  text-align: center;
}
.continue-button {
  color: #ffffff;
  background-color: #a84551;
  width: 95%;
  padding: 10px;
  font-size: 1.2em;
  letter-spacing: 0.5px;
}
.total-display {
  font-family: "poppins", SemiBold 16px/24px;
  // border-top: 1px solid rgba(119, 118, 118, 0.5);
  // border-left: 1px solid rgba(119, 118, 118, 0.5);
  // border-right: 1px solid rgba(119, 118, 118, 0.5);
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  padding: 6px 0px;
  // border-radius: 4px;
  font-weight: 600;
  font-size: 1.6rem;
  width: 95%;
}
.total-label {
  // margin-left: 1rem;
  font-size: 15px;
  color: #2b2b2b;
}
.total-amount {
  margin-left: auto;
  // margin-right: 1rem;
  letter-spacing: 0.5px;
}
.new-empty-cart-width {
  width: 70% !important;
}

.new-shpng-cart-pages-dsktp-main-container-changes {
  // height: auto !important;
  background: #f2f2f2 0% 0% no-repeat padding-box !important;
}
@media (min-width: 1025px) {
  .continue-button-container {
    bottom: 0;
    position: fixed;
    align-items: center;
    width: 100%;
    font-family: "poppins", sans-serif;
    // font-size: 1.5rem;
    width: 430px !important;
    text-align: center;
    margin-bottom: 5.5vh !important;
  }
  .new-shpng-cart-pages-dsktp-main-container-changes {
    height: 100vh !important;
    margin-top: 0 !important;
    width: unset !important;
  }
  .empty-cart {
    margin-top: 10vh;
  }
  .new-empty-cart-width {
    width: 35% !important;
  }
  .first-stepper-css {
    width: 28% !important;
  }
  .second-stepper-css {
    width: 25% !important;
  }
  .final-stepper-css {
    width: 61% !important;
    // width: 47% !important;
  }
}

@media (min-width: 768px) {
  .cart-stepper-header {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 5% 3%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
.flex-prop {
  display: flex;
  flex-direction: column;
}
.new-cntr-dsgn {
  padding: 0% 3% 3% 3% !important;
  background: #f2f2f2 0% 0% no-repeat padding-box !important;
}
.cart-stepper-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 5% 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // align-items: center;
}

.stepper-label-non-select {
  color: #9a9a9a;
}
.promo-code-div {
  bottom: 66px;
  width: 100%;
  border: solid 0.4px #d2d2d2;
  position: fixed;
  transition: top 0.6s;
  z-index: 5;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px -6px 10px #9a9a9a33;
  padding: 10px 17px 10px 17px;
  color: #2b2b2b;
  font-size: 14px;
}
.promo-right-icon-div {
  margin-left: auto;
  width: 8.5px;
}
.completed-cart-stepper {
  background-color: #1d4403 !important;
}
.first-stepper-css {
  width: 26%;
}
.second-stepper-css {
  width: 35%;
}
.final-stepper-css {
  width: 52%;
  // width: 39%;
}
