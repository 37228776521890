@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');

.patpCard {
  font-family: Montserrat, serif;
  color: #7D7D7D;
  font-size: 12px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  margin: 0 auto;
  margin-top: 20%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.patpCard-desktop {
  font-family: Montserrat, serif;
  color: #7D7D7D;
  font-size: 12px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.2);
  width: 50%;
  margin: 0 auto;
  margin-top: 4%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.wine-varietal-text {
  color: #951901;
  font-weight: 800;
  font-size: 16px;
  //line-height: 6px;

}

.tp-stats {
  //background-color: red;
  width: 100%;
  margin-bottom: 2%;
}

.tp-stats-name {
  padding: 20px;
}

.dashed-separator {
  margin-top: 2%;
  margin-bottom: 2%;
  border-bottom: 1px dashed #951901;
}

.value-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.value-reached {
  font-weight: 700;
  color: #951901;
}

.value-not-reached {
  font-weight: 400;
  color: #343434;
}

.value-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.valued-bar {
  background: #951901;
  height: 3px;
  width: 47px;
  border-radius: 0;
}

.not-valued-bar {
  background: #EFEFEF;
  height: 3px;
  width: 47px;
  border-radius: 0;
}

.reviews {
  //background-color: blue;
  width: 100%;
  margin-top: 6%;
}

.reviews-list {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;

}

.reviews-list-item {
  display: flex;
  flex-direction: row;
}

.reviews-list-item-text {
  padding-left: 10px;

}



.flavor-profile-list {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5%;
}

.flavor-profile-list-item {
  //margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 70px;
  //justify-content: space-around;

}

.flavor-profile-icon {
  float: left;
  //margin-right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}