.shopping-cart-fade-in-position-change {
    opacity: 0.4 !important;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
    position: absolute !important;
}
.new-common-header-text {
    margin-left: unset !important;
}
@media (min-width:1025px) {
    .new-common-header-text {
        margin-left: unset !important;
    }
}