
.facebook-btn {
	// transition: none;
	user-select: none;
	display: flex;
	width: 149px;
	height: 45px;
	color: #fff;
	justify-content: space-around;
	align-items: center;
	border: none;
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;	background: #475993 0% 0% no-repeat padding-box;
	border-radius: 3px;
	opacity: 1;
	.metro {
	width: 149px;
	color: #fff;
	height: 45px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border: none;
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;	background: #475993 0% 0% no-repeat padding-box;
	border-radius: 3px;
	opacity: 1;
	}
}

@media (min-width: 300px) and (max-width: 375px) {
	.facebook-btn {
	display: flex;
	width: 130px !important;
	height: 45px;
	color: #fff;
	justify-content: space-around;
	align-items: center;
	border: none;
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;	background: #475993 0% 0% no-repeat padding-box;
	border-radius: 3px;
	opacity: 1;
	.metro {
	width: 130px !important;
	color: #fff;
	height: 45px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	border: none;
	text-align: left;
	font: normal normal normal 14px/21px Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	opacity: 1;	background: #475993 0% 0% no-repeat padding-box;
	border-radius: 3px;
	opacity: 1;
	}
}
}