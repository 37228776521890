@use "src/app/styles/theme" as s;

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.head {
  padding-top: 3.2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.line {
  width: 100%;
  border-top: 1px solid #efefef;
}

.personalInfoSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.sectionTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.editBox {
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px;
}

.personalInfoItem {
  display: flex;
  align-items: center;
  gap: 16px;
}

.savedSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.historySection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.savedCardBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 22px;
}
