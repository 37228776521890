@use "src/app/styles/theme" as s;

.wineResultBar {
  display: inline-block;
  overflow-x: auto;
  width: 100%;
  
}

.wineResultBar::-webkit-scrollbar {
  display: none;
}

.scrollMore {
  display: flex;
  align-items: center;
  gap: 16px;
  //border-bottom: 1px solid var(--primary-light-gray, #EFEFEF);
}

.scrollItem {
  max-width: 160px;
  display: flex;
  padding: 10px 8px 10px 4px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  cursor: pointer;
}

.scrollItemText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: s.$gray;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.scrollItemNumber {
  color: s.$gray;
  font-family: s.$font-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.5;
}

.selected {
  border-bottom: 2px solid s.$red;
}

.selectedText {
  color: s.$red;
}

.grayLine {
  width: 100%;
  border-top: 1px solid var(--primary-light-gray, #EFEFEF);
}
