@media (min-width: 1025px) {
    .set-password-success-container-background {
        // background: #f8f8f8 0% 0% no-repeat padding-box;
    }

    .set-password-success-main-container {
        // box-shadow: 0px 3px 36px #cccccc4d;
        box-shadow: 0px 3px 200px -15px #928f8f62;
        background: #ffffff 0% 0% no-repeat padding-box;
        margin-left: auto !important;
        margin-right: auto !important;
        border-radius: 25px;
        width: 430px !important;
        height: fit-content !important;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        margin: auto !important;
        position: fixed !important;
    }

    .onboarding-text-container {
        margin-top: 0% !important;
        margin-right: 10% !important;
        margin-bottom: 0% !important;
        margin-left: 10% !important;
    }

    .set-password-success-btn {
        margin-top: 10% !important;
    }
}

.onboarding-text {
    color: #2b2a2a;
    font-size: 1.25em;
    font-family: "poppins", sans-serif !important;
    font-weight: 500;
}

.set-password-success-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.set-password-success-btn-sub-container {
    width: 90%;
    position: absolute;
    bottom: 0;
}

.set-password-success-btn {
    left: 0 !important;
    margin-bottom: 10% !important;
    width: 100% !important;
}

.onboarding-text-container {
    margin-top: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
    margin-left: 10%;
}

@media screen and (orientation: landscape) {
    .set-password-success-btn-sub-container {
        position: inherit !important;
    }
}

.set-password-title-success {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2b2a2a;
    font-size: 2em;
    font-family: "poppins", sans-serif !important;
    font-weight: bold !important;
    margin-top: 5%;
    margin-bottom: 5% !important;
}
