.render-flex {
  display: flex !important;
  // width: 100%;
  align-items: center;
  margin-top: 7px;
}
.strike-text {
  color: #4f4f4f;
  padding-left: 5px;
  font-size: 0.8em;
}
.-wine-price-text {
  color: #4f4f4f;
  font-weight: bold;
  font-size: 0.8em;
}

.badge-place {
  position: absolute;
  margin-top: 2%;
  right: 0;
  margin-right: -12px;
}
.badge-text {
  top: 0;
  position: absolute;
  right: 0;
  padding: 5px;
  margin: 7px;
  font-size: 12px;
  color: #ff0000;
  border: solid 2px #ff0000;
  background-color: white;
  // border-radius: 5px;
}
.badge-text-avail {
  position: relative;
  top: 2px;
  padding: 5px;
  font-size: 12px;
  color: #ff0000;
  border: solid 2px #ff0000;
  background-color: white;
}
.badge-text-desktop {
  top: 0;
  position: absolute;
  // margin-top: 40px;
  right: 0;
  padding: 5px;
  margin: 1em;
  font-size: 12px;
  color: #ff0000;
  border: solid 2px #ff0000;
  background-color: white;
}
.deal-name {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 15px;
  font-weight: bold;
  //display: -webkit-box;
  //-webkit-line-clamp: 2;
  //-webkit-box-orient: vertical;
  //overflow: hidden;
  //text-overflow: ellipsis;
  margin-bottom: 5px;
  // margin-top: 10px;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
}
.deal-margin {
  margin-top: 10px;
}
.spot-winery {
  display: flex;
  // justify-content: center;
  // align-content: center;
  // margin-bottom: 5px;
}
.spot-winery-name {
  margin-top: 10px;
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 10px;
  font-weight: 400;
  height: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
  //   max-width: 15ch;
}
.deal-name-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
  height: 65px;
  width: 100%;
}
.spot-winery-logo {
  width: 20px;
  height: 20px;
  margin-right: 2%;
  border-radius: 50%;
}
.spot-wine-type {
  // margin-top: 3px;
  color: #7d7d7d;
  font-family: "poppins", sans-serif !important;
  font-size: 9px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}
.spot-wine-bottle-logo {
  // width: 49%;
  // object-fit: contain;
  // background-repeat: no-repeat;
  height: auto;
  max-height: 100px;
  width: auto;
  max-width: 100px;
}

.spot-wine-bottle-logo-from-api {
  // width: 32%;
  // object-fit: contain;
  // background-repeat: no-repeat;
  height: auto;
  max-height: 100px;
  width: auto;
  max-width: 100px;
}
.spot-wine-card {
  width: 80% !important;
  // height: 450px;
}
.spot-wines-view-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 12px !important;
  color: #ffffff !important;
  background-color: #a84551 !important;

  width: 100%;
  padding: 3px;
}
@media (min-width: 1025px) {
  badge-place {
    position: absolute;
    right: 0;
    margin-right: -4%;
  }
  .deal-name-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5%;
    height: 55px;
    width: 100%;
  }
  .deal-name {
    color: #2b2a2a;
    font-family: "poppins", sans-serif !important;
    font-size: 15px;
    font-weight: bold;
    //display: -webkit-box;
    //-webkit-line-clamp: 2;
    //-webkit-box-orient: vertical;
    //overflow: hidden;
    //text-overflow: ellipsis;
    margin-bottom: 5px;
    // margin-top: 10px;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // white-space: nowrap;;
  }
  .spot-wine-card {
    width: 80% !important;
    // height: 60% !important;
  }
  .spot-wine-bottle-logo {
    // width: 49%;
    // object-fit: contain;
    // background-repeat: no-repeat;
    height: auto;
    max-height: 150px;
    width: auto;
    max-width: 150px;
  }

  .spot-wine-bottle-logo-from-api {
    // width: 32%;
    // object-fit: contain;
    // background-repeat: no-repeat;
    height: auto;
    max-height: 100px;
    width: auto;
    max-width: 100px;
  }
}

.spot-card {
  // box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  height: 300px;
  transition: 0.3s;
  background-color: #fff;
  border-radius: 0 !important;
  border-radius: 10px !important;
  margin: 1em 0.6em !important;
  padding: 1em !important;
  box-shadow: 1px #1d1d1d1a !important;
  border: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
