.shipping-address-main-container {
  display: flex;
  flex-direction: column;
}
.shipping-address-header {
  padding: 5%;
  display: flex;
}

.shipping-address-card-header {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 5%;
}

.form-row-label-header {
  color: #959292;
  font-size: 1.25em;
  font-family: "poppins", sans-serif !important;
  font-weight: 500 !important;
  margin-left: 5%;
}

.shipping-address-title {
  // margin-left: 5%;
  width: 100%;
  font-weight: 500 !important;
  // margin-left: 3%; - only if back btn is there
  font-size: 1.75em;
  color: #2b2a2a;
}
.shipping-address-header-subcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

@media (min-width: 1025px) {
  .confirm-shipping-address-btn {
    width: 67% !important;
  }

  .shipping-address-header {
    padding: 3% !important;
  }

  .confirm-shipping-address-btn-container {
    margin-bottom: 30% !important;
  }

  .shipping-address-form {
    // width: 70% !important;
    // margin-left: 15% !important;
    margin-bottom: 4% !important;
    margin-top: 0% !important;
  }

  .shipping-address-main-container {
    display: flex;
    flex-direction: column;
  }

  .shipping-address-title {
    // margin-left: 3% !important;
    width: 100%;
    font-weight: 500 !important;
    font-size: 1.5em;
    color: #2b2a2a;
  }
}

.shipping-address-container {
  display: flex;
  // padding: 2%;
  flex-direction: column;
  width: 100%;
  // height: 100vh;
  // overflow: auto;
}

.shipping-address-form {
  width: 100%;
  margin-bottom: 7%;
}

.form-row-label {
  color: #959292;
  font-size: 1.25em;
  font-family: "poppins", sans-serif !important;
  font-weight: 500 !important;
}

.form-row-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
  height: 40px !important;
  text-transform: capitalize;
  border: 1px solid #1d1d1d1a;
}

// input[type="date"] {
//   -webkit-appearance: none;
// }

// input[type=date]::-webkit-inner-spin-button {
//     -webkit-appearance: none !important;
//     display: none !important;
// }

// input[type=date]::-webkit-calendar-picker-indicator {
//     -webkit-appearance: none v;
//     display: none !important;
// }

.form-row-address-input {
  color: #2b2a2a;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em;
  border: none;
}

.confirm-shipping-address-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30%;
}
.confirm-shipping-address-btn {
  font-family: "poppins", sans-serif !important;
  font-size: 1.5em !important;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: 90%;

  opacity: 1 !important;
}

// iPhone 5/SE
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .confirm-shipping-address-btn {
    bottom: 11% !important;
  }
}

.form-error-shipping-address-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  text-align: center;
}
.form-error-shipping-address {
  color: red;
  font-family: "poppins", sans-serif !important;
  font-size: 1.25em !important;
}
.txt-format-none {
  text-transform: none !important;
}
.new-pinfo-header-container {
  justify-content: space-between;
}
