* {
  outline: none !important;
}

.taste-main-container {
  width: 100%;
  height: 100vh;
}

.back-btn-recommended-wines {
  width: 3%;
}

.taste-card-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {
  .taste-card-container {
    display: flex;
    flex-direction: column;
  }

  .edit-icon {
    width: 14px;
    height: 14px;
  }

  .edit-text {
    margin-left: 1% !important;
    margin-top: 1px;
  }

  .common-sub-header-back-icon {
    width: 1.5% !important;
  }

  .common-sub-header-text {
    margin-left: 2% !important;
  }

  .common-sub-header-logo {
    // width: 8% !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    height: 50px;
  }

  .common-page-header {
    padding-top: 0% !important ;
    padding-bottom: 0% !important ;
  }

  .taste-header {
    width: 100% !important;
    height: 14% !important;
    background-color: #a84551 !important;
    align-content: center !important;
  }

  .taste-text {
    margin-left: 5%;
    font-size: 1.5em !important;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
}

@media (min-width: 300px) and (max-width: 400px) {
  .taste-header {
    width: 100%;
    height: 14%;
    background-color: #a84551;
    align-content: center !important;
    // z-index: -1!important;
  }

  .taste-text {
    margin-left: 5%;
    font-size: 1.5em;
    color: #ffffff;
    font-family: "poppins", sans-serif !important;
    font-weight: 600 !important;
  }
}

.edit-text {
  margin-left: 2%;
  font: Medium 14px/24px Poppins;
  letter-spacing: 0px;
  color: #a84551;
  opacity: 1;
}

.sub-title {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 3%;
  opacity: 1;
}

.card-header {
  text-align: left;
  font: SemiBold Poppins;
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  background-color: white;
  opacity: 1;
}

.results {
  pointer-events: none;
  margin-top: 2%;
  margin-left: 2%;
  font-family: "poppins", sans-serif !important;
  font-size: 14px;
  color: #ffffff !important;
  background-color: #a84551 !important;
  width: fit-content;
  border-radius: 20px;
  border: 1px solid#a84551;
}

.display-results-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  //flex-wrap: wrap;
  //padding: 10px;
}

.result-container {
  margin-right: 9px;
  margin-top: 5px;
}

.edit-icon {
  // width: 4%;
  width: 14px;
  height: 14px;
  // margin-left: auto;
}
